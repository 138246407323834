
import React, { useState,useEffect } from 'react'
import { NavLink, useParams , useHistory} from 'react-router-dom';
import axios from "../../../helper/axios"
import "./formone.css";
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Cookies from 'js-cookie';


const Edititem = () => {

  
const history=useHistory()
const {Id}=useParams();

const[itemedit, setItemedit]=useState({
        Name:"",
        Unit:"",
       isAgree:"false",
       taxpreference:"Taxable",
       isAgree:"false",
       Costprice:"",
       Quantity:"",
       Accountone:"",
       Descriptionone:"",
       //supplierid:"",
       Manufacturer:"",
       status:""
});
const[file,setFile]=useState({avatar:""});

    const setimgfile=(e)=>{
     setFile(e.target.files[0])
     console.log(e.target.files[0])
    } 
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
    editUser();
    loadManufacturer();
    loadAll();
    },[]);
    const token = sessionStorage.getItem('jwtToken');
    const[gettoken, setToken] = useState()
    const loadAll = async () => {
      try {
        const response = await axios.post('/Login', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const UserLogedin = response.data.person[0][0].name;;
        setToken(UserLogedin);
        
      } catch (error) {
        console.log(error.response.data);
      }
    };

    const editUser = async ()=>{
        const res = await axios.get(`/Itemactive/${Id}`, {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        const newItem = await res.data;
        setItemedit(newItem)
       
      //  console.log(newItem)
        }
        const handelEdit = (e)=>{
        
          setItemedit({...itemedit,[e.target.name]:e.target.value})
        }
        const[data,setData]= useState([]);
  const loadsupplier= async(data)=>{
      
      const res = await axios.get('/SupplierGet', {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
      console.log(res.data)
  }
const[datas,setDatas]= useState([]);
  const loadManufacturer= async(data)=>{
      
      const res = await axios.get('/AllManufacturer', {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setDatas(res.data);
      console.log(res.data)
  }
  useEffect(()=>{
    loadManufacturer();
    loadsupplier();
  },[]);
  const [status, setStatus] = useState("");
  const handleCancel = async(e) => {
    e.preventDefault();

   try {
    const confirmBox = window.confirm(
      "Do you want to cancel it ?"
    )
    if (confirmBox === true){
    const response = await axios.post(`/ItemDelete/${Id}`,{status:"Inactive",Id:Id}); 
    //console.log(response.data); // Do something with the response data
    setStatus("Inactive");
    if (response.status === 200) {
      return history.push("/inventory/items");
    }
    }
    
  } catch (error) {
    console.error(error);
  }
};
       
      const handleSubmit = async(e)=>{
        e.preventDefault();

      try {
        const data = new FormData();
      data.append("document", JSON.stringify(itemedit));
      data.append("avatar",file);
      data.append("gettoken",JSON.stringify(gettoken));
     const respon = await axios.post(`/ItemUpdate/${Id}`,data,{
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      }
      },);
    
        if (respon.status === 200) {
          setSuccess(true);
          setError(false);
          return history.push("/inventory/items");
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      }
      
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState(false);
   const stage =itemedit.isgood;
  return (

    <div>
    <form onSubmit={handleSubmit}>
       <div className="top-div1"> {success && <div className="form-erroremployees">Item details submitted successfully!</div>}
      {error && <div className="form-error">Error submitting Item details. Please try again later.</div>}</div>   
    
     <div className="top-div">
          <div className="first-div">
                        <div className="row">
                        <div className="col-25">
                       
                        <label htmlFor="Name"  className="leftone">
                          Item Name
                        </label>
                        
                       </div>
                       <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="text"
                          autoComplete="off"
                          name="Name"
                          id="Name"
                          placeholder=""
                          value={itemedit.Name}
                          onChange={handelEdit}
                          
                        />
                        
                        </div>
                       
                        <br></br>
                     </div>
                      
                         <div className="row">
                         <div className="col-25">
                      <label htmlFor="Unit" className="leftones">
                      Quantity
                          </label>
                        </div>
                        <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="number"
                          autoComplete="off"
                          name="Quantity"
                          id="Quantity"
                          placeholder=""
                          value={itemedit.Quantity}
                          // onChange={handelEdit}
                          disabled
                        />
                       
                        </div>
                        
                        </div>
                     
                      
                        <div className="row">
                     <div className="col-25">
                      <label htmlFor="Unit" className="leftones">
                          Unit
                          </label>
                        </div>
                        <div className="col-75">

                        <select
                    className="inoneone"
                      type="text"
                      autoComplete="off"
                      name="Unit"
                      id="Unit"
                      value={itemedit.Unit}
                      onChange={handelEdit}
                      
                    >
                  <option></option>  
               <option>BOX</option>
              <option>PIECE</option>
              
                    </select>
                        
                        <br></br>
                      </div>
                      </div>
                      {/* <div className="row">
                     <div className="col-25">
                      
                        <label htmlFor="Costprice" id="tooltip" >
                        Rate
                          
                        </label>
                        
                        </div>
                        <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="number"
                          autoComplete="off"
                          name="Costprice"
                          id="Costprice"
                          placeholder=""
                          value={itemedit.Costprice}
                          onChange={handelEdit}
                          
                        />
                      
                        </div>
                        
                        </div> */}
                   
<div className="row">
            <div className="col-25radio">
              <label className="leftones">Tax Preference</label>
              </div>
              <div className="col-75">
              <input
           
            type="radio"
            name="taxpreference"
            className="radio"
            value="Taxable"
            checked={itemedit.taxpreference==="Taxable"}
            onChange={handelEdit}/>
            {""}Taxable
    
        
            <input
          
            type="radio"
            className="radiotwo"
            name="taxpreference"
            value="Non-Taxable"
            checked={itemedit.taxpreference==="Non-Taxable"}
            onChange={handelEdit}/>
            {""}Non-Taxable
            
            
            </div>
            </div>
            </div>
           
                     <br></br>
                     <div className="second-div">
                     <div className="fileattach">
                      <div>
                     
                      <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""
                          onChange={setimgfile}/> 
                       <img src={`https://agminfra18.com/images/${itemedit.avatar}`} style={{width:"200px",height:"150px"}}/>
         
                      </div>
                     </div>
            </div>
            
</div>

            <br></br>
             <br></br>

      
 <div className="input-container">
 <div className="input-blockerror">                   
                        <label htmlFor="Manufacturer" className="input-label">
                          Manufacturer
                        </label>
                        
                        <div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Manufacturer"
                      id="Manufacturer"
                      value={itemedit.Manufacturer}
                      onChange={handelEdit}
                      
                    ><option></option>
                      
                   {datas.map(itemedit => (
              <option value={itemedit.manufacturerid} key={itemedit.id} >{itemedit.manufacturername}</option>
    
              ))
              }
              
                    </select>

</div>
</div>
 {/* <div className="input-blockerror">
  <label htmlFor="supplier" className="input-label leftone">
                          Supplier
                        </label>
                        
<div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="supplierid"
                      id="supplierid"
                      value={itemedit.supplierid}
                      onChange={handelEdit}
                      
                    ><option></option>
                      
                      {data.map(itemedit => (
              <option value={itemedit.supplierid} key={itemedit.id} >{itemedit.name}</option>
    
              ))
              }
              
                    </select>
</div>
</div> */}
<div className="input-blockerror">
                    <label className="input-label">
        
        Model{""} 
        </label>
       <div className="input-error">
        <input
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Model"
                      id="Model"
                      value={itemedit.Model}
                      onChange={handelEdit}
                      
                    />
                
                  </div>
                  </div>



                    </div>
         
                    <div className="input-container">
                    {/* <div className="input-blockerror">                   
                        <label htmlFor="Manufacturer" className="input-label">
                          Manufacturer
                        </label>
                        
                        <div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Manufacturer"
                      id="Manufacturer"
                      value={itemedit.Manufacturer}
                      onChange={handelEdit}
                      
                    ><option></option>
                      
                   {datas.map(itemedit => (
              <option value={itemedit.manufacturerid} key={itemedit.id} >{itemedit.manufacturername}</option>
    
              ))
              }
              
                    </select>

</div>
</div> */}
        <div className="input-blockerror">
       
        <label className="input-label">Description</label>
        
        <div className="input-error">
        <textarea
                   className="" 
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="Descriptionone"
                      id="Descriptionone"
                      placeholder=""
                      value={itemedit.Descriptionone}
                      onChange={handelEdit}
                      
                    />
                    
                    </div>
                    </div> 
        
          </div>   
    <br></br>
    <hr className="hr"></hr>
         
         <br></br>
         <br></br>
           
                   <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       />
      
                    <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button" onClick={handleCancel }>
                   Cancel
                  </button></div>
                  </div>
                
          </form>


     </div> 
  )
}

      
export default Edititem  
import React from 'react'
import Sidebar from '../../components/Sidebar';
import { NavLink } from 'react-router-dom';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import NewRoadTax from './RoadTax/NewRoadTax';
import ListViewRoadTax from './RoadTax/ListViewRoadTax';
import EachRoadTax from './RoadTax/EachRoadTax';
import EditRoadTax from './RoadTax/EditRoadTax';
import RoadTaxDropDown from '../../components/Dropdown/RoadTaxDropDown';
import AllRoadtax from './RoadTax/AllRoadtax';
import InactiveRoadTax from './RoadTax/InactiveRoadTax';



export const RoadTaxDetails = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Enter New RoadTax Details</h3>
      </div>
          <div className='container' >
          <NewRoadTax/>
          </div>
          </div>
      </div>
      </div>
    )
  };
  export const VehicleRoadTax = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
       <RoadTaxDropDown/>
      <div className='title1'>
         <NavLink to='/Vehicle/roadtax'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
       <ListViewRoadTax/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  export const VehicleinactiveRoadTax = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
       <RoadTaxDropDown/>
      <div className='title1'>
         <NavLink to='/Vehicle/roadtax'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
       <InactiveRoadTax/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  export const AllVehicleRoadTax = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
       <RoadTaxDropDown/>
      <div className='title1'>
         <NavLink to='/Vehicle/roadtax'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
       <AllRoadtax/>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export const EachRoadTaxDetails = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Enter Each RoadTax Details</h3>
      </div>
          <div className='container' >
          <EachRoadTax/>
          </div>
          </div>
      </div>
      </div>
    )
  };

  export const EditRoadTaxDetails = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Edit RoadTax Details</h3>
      </div>
          <div className='container' >
           <EditRoadTax/>
          </div>
          </div>
      </div>
      </div>
    )
  };
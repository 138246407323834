
import React, { useEffect, useState } from 'react'
import axios from '../../../helper/axios';
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink ,useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
const ListViewLeave = () => {

  const history =useHistory();
    const[data,setData]= useState([]);
   
    const loadEmployeeLeave= async()=>{
      const token = sessionStorage.getItem('jwtToken');
    const res = await axios.get('/GetAllLeave', {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
        setData(res.data);
        console.log(res.data)
      }
      
        
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        loadEmployeeLeave();
    },[]);

 // Delete Employee Record

//  const deletetable = (value) =>
//       {
//         axios.post('/EmployeeDelete',{value})
        
//         .then((result)=>{
//          loadEmployee();
//         })
//         .catch(()=>{
//           alert('Error in the Code');
//         });
//       };
 

  return (
    <div>
       <Helmet> 
        <title>All Employee|BBS Inventory</title>
      </Helmet>
        <table id="leave">
            <thead>
                <tr>
                    <th>Employee Id</th>
                    <th>Employee Name</th>
                    <th>Employee Role</th>
                    <th>Leave Type</th>
                    <th>Leave From</th>
                    <th>Leave To</th>
                    <th>Leave Hour</th>
                    <th>Leave Applied Date</th>
                    <th>Time</th>
                    <th>Description</th>
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            data.map((value, key) => {
              return (
                <tr key={key}>
                   
                  <td>{value.employeeid}</td>
                  <td><NavLink to={`/employee/eachleave/${value.idemployleave}`} className="addlink">{value.employeename}</NavLink></td>
                  <td>{value.employeerole}</td>
                  <td>{value.leavetype}</td>
                  <td>{value.leavefrom}</td>
                  <td>{value.leaveto}</td>
                  <td>{value.leavehour}</td>
                  <td>{value.leavedate}</td>
                  <td>{value.currenttime}</td>
                  <td>{value.description}</td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
    </div>
  )
}

export default ListViewLeave ;
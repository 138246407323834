import React, { useState,useEffect } from "react";
import { useFormik } from "formik";
import { VendorSchema } from "../../../helper/schemas";
import axios from "../../../helper/axios"
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';
import { IoTrashOutline} from "react-icons/io5";
import { Link,useHistory } from "react-router-dom";

const initialValues = {
  companyname:"",
  supplierid:" ",
    name: "",
      email: "",
    phoneno: "",
    gstnumber:"",
    primarycontact:"",
    address: "",
  //  addresss:"",
  //  gst:"",
  //  sourceofsupply:"",
  //  currency:"",
  //  payment:"",
  //  price:"",
  //  tds:"",
   attention:"",
   country:"",
   city:"",
   state:"",
   zipcode:"",
   phonenos:"",
  //  attentions:"",
  //  countrys:"",
  //  citys:"",
  //  states:"",
  //  zipcodes:"",
  //  phonenos:"",
   remarks:"",
  
  };
  
  const NewSupplier = () => {
    const history = useHistory();
    const[showtab, setShowtab]= useState(1);

    const handletab = (e)=>{
     setShowtab(e);
       }
    
    
  const[datauser,setDatauser]= useState([{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);

    const adduser =()=>{
      setDatauser([...datauser,{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);
    }

     const onChange =(e, index)=>{
     const updateUser =datauser.map((user,i)=>
     index === i
     ? Object.assign(user,{[e.target.name]:e.target.value}):user
     );
     setDatauser(updateUser);
     }
    const removeUser =(index)=>{
    const filterUser =[...datauser];
    filterUser.splice(index,1);
    setDatauser(filterUser);
    }
    
 


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset} =
    useFormik({
      initialValues:initialValues,
      
      validationSchema: VendorSchema,
      
      onSubmit:async (values, action) => {
       
        try {
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("datauser",JSON.stringify(datauser));
          data.append("gettoken", JSON.stringify(gettoken));
         const response = await axios.post("/SupplierPost",data,{
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
          },);
  
          //await axios.post("/SupplierPost",values);
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/purechases/allsupplier');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
        console.log(
          values
        );
        action.resetForm();
        // alert("Updated successful.")
        setDatauser([{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);
      },
    });
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      loadAll();
      },[])
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const token = sessionStorage.getItem('jwtToken');
    const[gettoken, setToken] = useState()
    const loadAll = async () => {
      try {
        const response = await axios.post('/Login', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const UserLogedin = response.data.person[0][0].name;;
        setToken(UserLogedin);
        
      } catch (error) {
        console.log(error.response.data);
      }
    };

    return (
      <>
      <Helmet> 
        <title>New Supplier|BBS Inventory</title>
      </Helmet>
      
      <div className="">
       <form onSubmit={handleSubmit}>
       {success && <div className="form-erroremployees">Supplier details submitted successfully!</div>}
      {error && <div className="form-error">Error submitting Supplier details. Please try again later.</div>}
      
       <div className="input-container">        
       <div className="input-blockerror">
                    <label htmlFor="name" className="input-label">
                     Company Name
                    </label>
                   <div className="input-error">
                   <input
                      type="text"
                      autoComplete="off"
                      name="companyname"
                      id="companyname"
                      placeholder="Company Name"
                      value={values.companyname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    
                    {errors.companyname && touched.companyname ? (
                      <p className="form-error">{errors.companyname}</p>
                    ) : null}
                   </div>
                  </div>
                  
                  <div className="input-blockerror">
                    <label htmlFor="name" className="input-label leftone">
                    Supplier Name
                    </label>
                    <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className="form-error">{errors.name}</p>
                    ) : null}</div>
                  </div></div>
                  <div className="input-container">
                  <div className="input-blockerror">
                    <label htmlFor="id" className="input-label">
                    Supplier  Email
                    </label>
                    <div className="input-error">
                    <input
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p className="form-error">{errors.email}</p>
                    ) : null}</div>
                  </div>
                  <div className="input-blockerror">
                    <label htmlFor="phoneno" className="input-label">
                    Supplier Phone
                    </label>
                    <div className="input-error">
                    <input
                      type="number"
                      autoComplete="off"
                      name="phoneno"
                      id="phoneno"
                      placeholder="Phone"
                      value={values.phoneno}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phoneno && touched.phoneno ? (
                      <p className="form-error">{errors.phoneno}</p>
                    ) : null}</div>
                  </div></div>
                 
                  <div className="input-container">        
                   <div className="input-blockerror">
                  <label htmlFor="supplierid" className="input-label">
                  Gst Number
                    </label>
                    <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gstnumber"
                      id="gstnumber"
                      placeholder="Gst Number"
                     value={values.gstnumber}
                    
                      onChange={handleChange}
                      onBlur={handleBlur}
                  
                    />  </div>
                  </div>
                  <div className="input-block">
                  <label htmlFor="primarycontact" className="input-label">
                  Primary Contact Person
                    </label>
                    <div className="input-error">
                  <input
                 
                      type="text"
                      autoComplete="off"
                      name="primarycontact"
                      id="primarycontact"
                      placeholder="Primary Contact"
                      value={values.primarycontact}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  
                  </div>
                  </div>
                
                  <div className="navigation2">
                  {/* <button  type="button" className= {showtab===1? "button-link active": "button-link" }  onClick={()=>handletab(1)}>Other Details</button> */}
                  <button  type="button" className= {showtab===1? "button-link active": "button-link" }  onClick={()=>handletab(1)}>Address</button>
                  <button  type="button" className= {showtab===3? "button-link active": "button-link" }  onClick={()=>handletab(3)}>Contact Persons</button>
                  {/* <button  type="button" className= {showtab===4? "button-link active": "button-link" }  onClick={()=>handletab(4)}>Custom Fields</button>
                  <button  type="button" className={showtab===5? "button-link active": "button-link" }  onClick={()=>handletab(5)}>Reporting Tags</button> */}
                  <button  type="button" className={showtab===6? "button-link active": "button-link" }  onClick={()=>handletab(6)}>Additional Information</button>
                  </div>

                  {/* <div  className= { showtab===1 ? "tab-pane show active":"tab-pane fade "} > 
                  <div className="input-container">        
                  <div className="input-block">
                    <label htmlFor="name" className="input-label">
                    GST Treatment
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="gst"
                      id="gst"
                      value={values.gst}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Registered Business-Regular</option>
                      <option>Registered Business-Composition</option>
                       <option>Unregistered Business</option>
                       <option>Overseas</option>
                       <option>Special Economic Zone</option>
                       <option>Deemed Export</option>
              
                    </select>
                    
                    {errors.gst && touched.gst ? (
                      <p className="form-error">{errors.gst}</p>
                    ) : null}
                  </div>
                  
                  <div className="input-block">
                    <label htmlFor="name" className="input-label">
                    Source Of Supply
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="sourceofsupply"
                      id="sourceofsupply"
                      
                      value={values.sourceofsupply}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option></option>
                    <option>[AD] - Andhra Pradesh </option>
                   <option>[AR] - Arunachal Pradesh</option>
                   <option>[AS] - Assam</option>
                   <option>[BR] - Bihar</option>
                   <option>[CH] - Chandigarh</option>
                   <option>[CG] - Chhattisgarh</option>
                   <option>[DN] - Dadra and Nagar Haveli</option>
                   <option>[DD] - Daman and Diu</option>
                   <option>[DL] - Delhi</option>
                   <option>[GA] - Goa</option>
                   <option>[GJ] - Gujarat</option>
                   <option>[HR] - Haryana</option>
                   <option>[HP] - Himachal Pradesh </option>
                   <option>[JK] - Jammu and Kashmir</option>
                   <option>[JH] - Jharkhand</option>
                   <option>[KA] - Karnataka </option>
                   <option>[KL] - Kerala</option>
                   <option>[LA] - Ladakh</option>
                   <option>[LD] - Lakshadweep</option>
                   <option>[MP] - Madhya Pradesh</option>
                   <option>[MH] - Maharashtra</option>
                   <option>[MN] - Manipur</option>
                   <option>[ML] - Meghalaya</option>
                   <option>[MZ] - Mizoram</option>
                   <option>[NL] - Nagaland</option>
                   <option>[OD] - Odisha</option>
                   <option>[PY] - Puducherry</option>
                   <option>[PB] - Punjab</option>
                   <option>[RJ] - Rajasthan</option>
                   <option>[SK] - Sikkim</option>
                   <option>[TN] - Tamil Nadu</option>
                   <option>[TS] - Telangana</option>
                   <option>[TR] - Tripura</option>
                   <option>[UP] - Uttar Pradesh</option>
                   <option>[UK] - Uttarakhand </option>
                   <option>[WB] - West Bengal</option>
                   <option>[OT] - Other Territory</option>
                   
                         </select>
                    {errors.sourceofsupply && touched.sourceofsupply ? (
                      <p className="form-error">{errors.sourceofsupply}</p>
                    ) : null}
                  </div></div>
                  <div className="input-container">
                  <div className="input-block">
                    <label htmlFor="id" className="input-label">
                    Currency
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="currency"
                      id="currency"
                    
                      value={values.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option>Indian Rupee</option>
                    <option>Croatain Kuna</option>
                   <option>Lempira</option>
                   <option>Gourde</option>
                   <option>Forint</option>
                   <option>New Israeli Sheqel</option>
                   
                         </select>
                    {errors.currency && touched.currency ? (
                      <p className="form-error">{errors.currency}</p>
                    ) : null}
                  </div>
                  <div className="input-block">
                    <label htmlFor="payment" className="input-label">
                    Payment Terms
                    </label>
                    <select
                    className="list"
                      type="number"
                      autoComplete="off"
                      name="payment"
                      id="payment"
                      placeholder="payment"
                      value={values.payment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option>Net 45 </option>
                    <option>Net 15</option>
                   <option>Net 60</option>
                   <option>Due end of the month</option>
                   <option>Due on Receipt</option>
                   
                         </select>
                    {errors.payment && touched.payment ? (
                      <p className="form-error">{errors.payment}</p>
                    ) : null}
                  </div></div>
                
                  <div className="input-container">
                  <div className="input-block">
                    <label htmlFor="Price" className="input-label">
                    Price List
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="price"
                      id="price"
                      placeholder="price"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option></option>
                    <option>Pricebook2[82% Markup]</option>
                   <option>Pricebook3[76% Markup]</option>
                   <option>Pricebook4[92% Markup]</option>
                  
                         </select>
                    {errors.price && touched.price ? (
                      <p className="form-error">{errors.price}</p>
                    ) : null}
                  </div>
                  <div className="input-block">
                    <label htmlFor="tds" className="input-label">
                    TDS
                    </label>
                    <select
                    className="list"
                      type="number"
                      autoComplete="off"
                      name="tds"
                      id="tds"
                      placeholder="tds"
                      value={values.tds}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option>Select a Tax</option>
                    <option>sqqwq</option>
                   <option>adaF</option>
                   <option>adsz</option>
                   <option>sfdszdv</option>
                   <option>dhdx</option>
                   <option>gdg</option>
                         </select>
                    {errors.tds && touched.tds ? (
                      <p className="form-error">{errors.tds}</p>
                    ) : null}
                  </div>
                  </div>
                  </div> */}
                 <div className= { showtab===1 ? "tab-pane show active": "tab-pane fade"}>
               <div className="address">
              <div className="addr">
                 <p className="billing"> ADDRESS OF SUPPLIER</p>
               <div className="input-block">
                    <label htmlFor="attention" className="input-label">
                    Attention
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="attention"
                      id="attention"
                      placeholder="Attention"
                      value={values.attention}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="Country" className="input-label">
                    Country
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="country"
                      id="country"
                      placeholder="Country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="address" className="input-label">
                    Supplier Address
                    </label>
                    <textarea
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    
                    {errors.address && touched.address ? (
                      <p className="form-error">{errors.address}</p>
                    ) : null}
                  </div>
                    <div className="input-block">
                    <label htmlFor="city" className="input-label">
                    City
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="city"
                      id="city"
                      placeholder="City"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="state" className="input-label">
                    State
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="state"
                      id="state"
                      placeholder="State"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="zipcode" className="input-label">
                    Zip Code
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="zipcode"
                      id="zipcode"
                      placeholder="Zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="phoneno" className="input-label">
                   Phoneno
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="phonenos"
                      id="phonenos"
                      placeholder="Phonenos"
                      value={values.phonenos}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                   </div>
                    {/* <div className="addr">
                    <p className="billing">SHIPPING ADDRESS </p>
                    <div className="input-block">
                    <label htmlFor="attentions" className="input-label">
                    Attention
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="attentions"
                      id="attentions"
                      placeholder="Attention"
                      value={values.attentions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="Countrys" className="input-label">
                    Country
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="countrys"
                      id="countrys"
                      placeholder="Country"
                      value={values.countrys}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="address" className="input-label">
                    Supplier Address
                    </label>
                    <textarea
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="addresss"
                      id="addresss"
                      placeholder="Address"
                      value={values.addresss}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    
                    {errors.addresss && touched.addresss ? (
                      <p className="form-error">{errors.addresss}</p>
                    ) : null}
                  </div>
                    <div className="input-block">
                    <label htmlFor="city" className="input-label">
                    City
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="citys"
                      id="citys"
                      placeholder="City"
                      value={values.citys}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="state" className="input-label">
                    State
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="states"
                      id="states"
                      placeholder="State"
                      value={values.states}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="zipcode" className="input-label">
                    Zip Code
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="zipcodes"
                      id="zipcodes"
                      placeholder="Zipcode"
                      value={values.zipcodes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="phoneno" className="input-label">
                   Phoneno
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="phonenos"
                      id="phonenos"
                      placeholder="Phoneno"
                      value={values.phonenos}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    </div> */}
               </div>
          </div>
          <div className= { showtab===3 ? "tab-pane show active": "tab-pane fade"}>
           
          <table id="contacttable" onSubmit={handleSubmit}>
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Address</th>
                    <th>Work Phone</th>
                    <th>Mobile</th>
                    
                </tr>
            </thead>
            <tbody>
            {
            datauser.map((user, index) => {
              return (
                <tr key={index}>
                    <th>
                      <input
                    className="inputtable"
                    name="firstname"
                    onChange={(e)=> onChange(e, index)}
                    value={user.firstname}
                    />
                    </th>
                     <th><input
                    className="inputtable"
                    name="lastname"
                    onChange={(e)=> onChange(e, index)}
                    value={user.lastname}
                    /></th>
                    <th><input
                    className="inputtable"
                    name="useremail"
                    onChange={(e)=> onChange(e, index)}
                    value={user.useremail}
                    /></th>
                    <th><input
                    className="inputtable"
                    name="workphone"
                    onChange={(e)=> onChange(e, index)}
                    value={user.workphone}
                    /></th>
                 <th><input
                    className="inputtable"
                    name="mobilephone"
                    onChange={(e)=> onChange(e, index)}
                    value={user.mobilephone}
                    /></th>
                    <th><IoTrashOutline onClick={()=> removeUser(index)}/></th>
                </tr>
               )
            })
          }
             
            </tbody>
            
            </table>
           <div className="link"> <Link to="#" className="addlink"  onClick={adduser}>Add Contact Person</Link></div>
          </div>
          {/* <div className= { showtab===4 ? "tab-pane show active": "tab-pane fade"}>
               <p>Tab 3 Content</p>
          </div>
          <div className= { showtab===5 ? "tab-pane show active": "tab-pane fade"}>
               <p>Tab 4 Content</p>
          </div> */}
          <div className= { showtab===6 ? "tab-pane show active": "tab-pane fade"}>
         <div className="billing">
          <div className="input-block">
                    <label htmlFor="remarks" className="input-label">
                    Additional Information
                    </label>
                    <textarea
                    rows="3" cols="49"
                      type="text"
                      autoComplete="off"
                      name="remarks"
                      id="remarks"
                      placeholder="additional information"
                      value={values.remarks}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div></div>
          </div>
          <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       />     
                  <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="reset" onClick={handleReset}>
                   Cancel
                  </button></div>
                  </div>
                 
                </form>
                </div>   
      </>
    )
  }
  
  export default NewSupplier;

import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const EditPurchase = () => {
  const history = useHistory();
  //getting the id of purchase oprder
  const { purchaseorderid } = useParams();
  //what ever dta coming from backend it is updated here
  const [purchaseedit, setPurchaseedit] = useState({
    supplierid: "",
    picked: "organization",
    search: "",
    reference: "",
    purchaseorder: "",
    adress: "",
    purchasedate: "",
    expected: "",
    paymentterms: "",
    shipmentpreference: "",
    customernotes: "",
    termsconditions: "",
    status: "",
    insertedby: "",
    billNo: "",
    purchaseorderid: "",
    orderstage: "",
    requestedby: "",
    requisitiondate: "",
  });
  //file whatever we have uploaded will update here from backend
  const [file, setFile] = useState({ avatar: "" });

  const setimgfile = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  //whenever we load the page data will display
  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/");
    }
    getPurchases();
    getItems();
    getEmployee();
  }, []);
  //getting the data from backend according to the purchase id
  const getPurchases = async () => {
    const res = await axios.get(`/Purchase/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setPurchaseedit(newProduct);
  };
  //display all the employee
  const getEmployee = async () => {
    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee);
    //console.log(newusers)
  };
  //getting the dyanmic table price list data from backend according to the purchase id
  const getItems = async () => {
    const res = await axios.get(`/Price/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newItemData = await res.data;
    setDatas(newItemData);
    setAddInventory(newItemData);
    const newArr = res.data;
    const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);
    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 0;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));

    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
    //console.log(newusers)
  };

  //cancel the form onclick function
  const handleCancel = async (e) => {
    e.preventDefault();

    try {
      const confirmBox = window.confirm("Do you want to cancel it ?");
      if (confirmBox === true) {
        const response = await axios.post(
          `/DeletePurchase/${purchaseorderid}`,
          { status: "Inactive", purchaseorderid: purchaseorderid }
        );
        console.log(response.data); // Do something with the response data
        setStatus("Inactive");
        if (response.status === 200) {
          return history.push("/purechases/allpurchase");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (e) => {
    setPurchaseedit({ ...purchaseedit, [e.target.name]: e.target.value });
  };
  // send the data to backend to sumit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isAnyQuantityZero = addInventory.some(
        (user) => Number(user.purchasequantity) === 0
      );
      const isAnyQuantityEmpty = addInventory.some(
        (user) => user.purchasequantity === ""
      );
      if (isAnyQuantityZero || isAnyQuantityEmpty) {
        alert("Quantity	 field cannot be empty or zero.");
        return;
      }
      const isunit = addInventory.some(
        (user) => user.unitforpurchase === ""
      );
      if (isunit) {
        alert("Please select a purchase unit for all items.");
        return;
      }
      const data = new FormData();
      data.append("document", JSON.stringify(purchaseedit));
      data.append("datas", JSON.stringify(datas));
      data.append("addInventory", JSON.stringify(addInventory));
      data.append("avatar", file);
      data.append("gettoken", JSON.stringify(gettoken));
      data.append("totalAmount", JSON.stringify(totalAmount));
      data.append("gst", JSON.stringify(gst));
      data.append("totalGst", JSON.stringify(totalGst));
      data.append("subTotal", JSON.stringify(subTotal));
      console.log(JSON.stringify(addInventory))
      const respon = await axios.post(
        `/PurchaseorderUpdate/${purchaseorderid}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //const response = await axios.post(`/PurchaseorderUpdate/${purchaseorderid}`,purchaseedit);
      //const respon = await axios.post(`/PriceUpdate/${purchaseorderid}`,datas);

      if (respon.status === 200) {
        return history.push("/purechases/allpurchase");
      }
    } catch (err) {
      setSuccess(false);
      setError(true);
    }

    //setDatas([{Id:"",description:"",purchasequantity:"",rate:"",tax:"",amount:""}])
    setSupplier([]);
  };

  const itemTemp = {
    itemDetails: "",
    description: "",
    purchasequantity: "",
    rate: "",
    amount: "",
    unitforpurchase:"",
  };
  const [datas, setDatas] = useState([itemTemp]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [employee, setEmployee] = useState([]);
  const [addInventory, setAddInventory] = useState([]);

  // const addRow = () => {
  //   setDatas([...datas, itemTemp]);
  // };
  //add the new row to item dynamic table
  const addRow = () => {
    setAddInventory([...addInventory, addInventory]);
    //setDatas([...datas, itemTemp]);
  };

  // const removeUser = (index) => {
  //   const filterUser = [...datas];
  //   filterUser.splice(index, 1);
  //   setDatas(filterUser);
  // };
  //delete the new row from item dynamic table
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setDatas(updateUsers);
    setAddInventory(updateUsers);
    const total = updateUsers.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total);
    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 0;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));

    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
  };

  const calculateSubTotal = (total, newGst) => {
    const taxRate = 1 + newGst / 100;
    const gstAmount = total * (taxRate - 1);
    return total + gstAmount;
  };
  //onChange fuction to calculate the  amount in price list dynamic table
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...datas];
    updateUser[index] = { ...updateUser[index], [name]: value };
    const quantity = parseFloat(updateUser[index].purchasequantity) || 0;
    const rate = parseFloat(updateUser[index].rate) || 0;
    const amount = quantity * rate;
    updateUser[index].amount = amount.toString();
    setDatas(updateUser);
    // Create a copy of addInventory
  const updatedAddInventory = [...addInventory];

  // Copy the existing item and update the specified field
  const updatedItem = { ...updatedAddInventory[index], [name]: value };

  // Update the item in the array
  updatedAddInventory[index] = updatedItem;

  // Update the state
  setAddInventory(updatedAddInventory);
    // Calculate the total amount of all items
    const total = updateUser.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total);

    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 0;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));
    // const gstAmount = subTotal - total;
    // setTotalGst(gstAmount);
    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
  };
  //getting the default supplier id here
  let defaultSupplierId = purchaseedit.supplierid;
  //console.log(defaultSupplierId);
  const [item, setItem] = useState([]);
  const [supplierid, setSupplierid] = useState(defaultSupplierId);
  const [getrowdata, setGetrowdata] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  //display all the Supplier in table
  const loadSupplier = async (supplier) => {
    const res = await axios.get("/SupplierGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSupplier(res.data);
    console.log(res.data);
  };
  //if supplier id changed this call this function
  const handleEdits = async (e) => {
    try {
      const supplierid = e.target.value;
      //console.log(supplierid);
      setSupplierid(supplierid);

      const selectedOption = e.target.options[e.target.selectedIndex];
      const name = selectedOption.text;
      setSelectedName(name);

      setPurchaseedit((prevState) => ({
        ...prevState,
        supplierid: supplierid,
        name: name,
      }));

      const response = await axios.get(`/itemgetbySupplier/${supplierid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setItem(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    setSupplierid(defaultSupplierId);
    setAddInventory([]);
    setDatas([]);
    loadSupplier();
    loadAll();
    getItemdetail();
  }, [defaultSupplierId]);
  console.log({ supplierid });
  // useEffect(() => {

  //   getPurchase();
  //   setAddInventory([]);
  //   setDatas([]);
  //   loadSupplier();
  //   loadAll();
  //   getItemdetail();
  // }, [supplierid]);
  // const getPurchase = async () => {
  //   try {
  //     const response = await axios.get(`/itemgetbySupplier/${supplierid}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const data = response.data;
  //     console.log(data);
  //     setGetrowdata(data);
  //     //setItem(data);
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error here
  //   }
  // };
  //get all item according to supplier id
  const getItemdetail = async (data) => {
    const res = await axios.get(`/itemgetbySupplier/${defaultSupplierId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data);
    setItem(res.data);
  };

  const [status, setStatus] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const token = sessionStorage.getItem("jwtToken");
  const [gettoken, setToken] = useState();
  //got all user information who is loged in
  const loadAll = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;
      setToken(UserLogedin);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  //onchange function called when addInventory.length > 0
  const replaceData = async (e, index, type) => {
    //api to get the item details like Name,Quantity etc according to dynamic supplier id and targeted value id of item
    const res = await axios.get(
      `/itemgetbySupplier/${supplierid}/${e.target.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let newArr = [...addInventory];
    newArr[index] = {
      Id: res.data.Id,
      Name: res.data.Name,
      description: res.data.Descriptionone,
      purchasequantity: 0,
      rate: 0,
      amount: 0,
      itemmasterid: res.data.itemmasterid,
      unitforpurchase:"",
    };
    if (!(type == "Select Item")) {
      setAddInventory(newArr);
      setDatas(newArr);
    } else {
      setAddInventory([
        ...newArr,
        {
          Name: "Select Item",

          description: "",
          purchasequantity: "",
          rate: "",
          amount: "",
          unitforpurchase:"",
          itemmasterid: "",
        },
      ]);
      setDatas([
        ...newArr,
        {
          Name: "Select Item",
          // "hsncode": "",
          // "saccode": "",
          description: "",
          purchasequantity: "",
          rate: "",
          amount: "",
          unitforpurchase:"",
          itemmasterid: "",
        },
      ]);
    }
    const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);
    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 0;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));

    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
  };
  //onchange function called when addInventory.length == 0
  const getPriceList = async (e, b) => {
    // const res = await axios.get(`/Itemactive/${e.target.value}`);
    const res = await axios.get(
      `/itemgetbySupplier/${supplierid}/${e.target.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    setAddInventory([
      ...addInventory,
      {
        Id: res.data.Id,
        Name: res.data.Name,
        description: res.data.Descriptionone,
        purchasequantity: 0,
        rate: 0,
        amount: 0,
        unitforpurchase:"",
        itemmasterid: res.data.itemmasterid,
      },
    ]);
    setDatas([
      ...addInventory,
      {
        Id: res.data.Id,
        Name: res.data.Name,
        unitforpurchase:"",
        purchasequantity: 0,
        rate: 0,
        amount: 0,
        itemmasterid: res.data.itemmasterid,
      },
    ]);
  };

  return (
    <div>
      <Helmet>
        {" "}
        <title>Edit Purchase|BBS Inventory Management</title>
      </Helmet>

      <form onSubmit={handleSubmit}>
        {/* <div className="top-div1"> {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}</div>
      <div style={{padding:"12px", height:"70px"}}><h4 style={{color: "blue"}}>PO Stage:{purchaseedit.orderstage}</h4></div> */}
        <div className="top-div1">
          <div className="input-container-search">
            <label
              htmlFor="name"
              className="input-block_radio"
            >
              Supplier Name
            </label>
            <select
              className="list"
              type="text"
              autoComplete="off"
              name="supplierid"
              id="supplierid"
              // placeholder="Select a vendor"
              value={purchaseedit.supplierid}
              onChange={(e) => {
                handleEdits(e);
              }}
            >
              {supplier.map((supplier) => (
                <option
                  value={supplier.supplierid}
                  key={supplier.id}
                >
                  {supplier.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input-container1">
          <label
            htmlFor="name"
            className="input-label-radio"
          >
            Deliver To
          </label>
          <div className="input-block_radio">
            <div className="input-blockradio ">
              <div className="radio">
                <label
                  htmlFor="name"
                  className="input-label"
                >
                  Organization
                </label>
                <input
                  type="radio"
                  name="picked"
                  id="name"
                  value="organization"
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                  checked={purchaseedit.picked === "organization"}
                />
              </div>

              <div className="radio">
                <label
                  htmlFor="name"
                  className="input-label"
                >
                  Employee
                </label>
                <input
                  type="radio"
                  name="picked"
                  id="name"
                  value="Employee"
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                  checked={purchaseedit.picked === "Employee"}
                />
              </div>
            </div>
            {/* <div className="radiocontent">{purchaseedit.picked} </div> */}
          </div>
        </div>

        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="Reference"
              className="input-label"
            >
              Requisition No
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="reference"
                id="reference"
                placeholder="Reference"
                value={purchaseedit.reference}
                onChange={(e) => {
                  handleEdit(e);
                }}
              />
            </div>
          </div>
          <div className="input-block">
            <label
              htmlFor="requestedby"
              className="input-label"
            >
              Requested By
            </label>
            <div className="input-error">
              <select
                type="text"
                autoComplete="off"
                name="requestedby"
                id="requestedby"
                value={purchaseedit.requestedby}
                onChange={(e) => {
                  handleEdit(e);
                }}
              >
                <option>Select Employee</option>
                {employee.map((emp) => (
                  <option
                    value={emp.empId}
                    key={emp.id}
                  >
                    {emp.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="input-container">
          {/* added new field  Requisition Date by jyoti */}
          <div className="input-block">
            <label
              htmlFor="requisitiondate"
              className="input-label"
            >
              Requisition Date
            </label>
            <div className="input-error">
              <input
                type="date"
                autoComplete="off"
                name="requisitiondate"
                id="requisitiondate"
                value={purchaseedit.requisitiondate}
                onChange={(e) => {
                  handleEdit(e);
                }}
              />
            </div>
          </div>
          <div className="input-block">
            <label
              htmlFor="purchase order"
              className="input-label"
            >
              Purchase Order
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="purchaseorder"
                id="purchaseorder"
                placeholder="Purchase Order"
                value={"PO-" + purchaseedit.purchaseorderid}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="purchase date"
              className="input-label"
            >
              Purchase Date
            </label>
            <div className="input-error">
              <input
                type="date"
                autoComplete="off"
                name="purchasedate"
                id="purchasedate"
                placeholder="Purchase Date"
                value={purchaseedit.purchasedate}
                onChange={(e) => {
                  handleEdit(e);
                }}
              />
            </div>
          </div>
          <div className="input-block">
            <label
              htmlFor="expected date"
              className="input-label"
            >
              Expected Delivery Date
            </label>
            <div className="input-error">
              <input
                type="date"
                autoComplete="off"
                name="expected"
                id="expected"
                placeholder="Expected Date"
                value={purchaseedit.expected}
                onChange={(e) => {
                  handleEdit(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="insertedby"
              className="input-label"
            >
              Created By
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="insertedby"
                id="insertedby"
                placeholder=""
                value={purchaseedit.insertedby}
                disabled
              />
            </div>
          </div>
          <div className="input-block">
            <label
              htmlFor="billNo"
              className="input-label"
            >
              Bill No
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="billNo"
                id="billNo"
                placeholder=""
                value={purchaseedit.billNo}
                onChange={(e) => {
                  handleEdit(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="adress"
              className="input-label"
            >
              Delivery Address
            </label>
            <div className="input-error">
              <textarea
                rows="3"
                cols="42"
                type="text"
                autoComplete="off"
                name="adress"
                id="adress"
                placeholder="Address"
                value={purchaseedit.adress}
                onChange={(e) => {
                  handleEdit(e);
                }}
              />
            </div>
          </div>
        </div>

        <div className="input-container">
          {purchaseedit.status === "inactive" && (
            <div className="input-block">
              <label
                htmlFor="paymentterms"
                className="input-label"
              >
                Status
              </label>
              <div className="input-error">
                <select
                  className="list"
                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"
                  value={purchaseedit.status}
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                >
                  <option>active</option>
                  <option>inactive</option>
                </select>
              </div>
            </div>
          )}
        </div>

        <div>
          <div>Price List </div>
          <div>
            <table id="contacttable">
              <thead>
                <tr>
                  <th style={{ width: "60px" }}>Sl No</th>
                  <th style={{ width: "220px" }}>Item Name</th>
                  <th style={{ width: "280px" }}>Description</th>
                  <th>Quantity </th>
                  <th>Unit</th>
                  <th>Unit Price</th>
                  {/* <th>Tax %</th> */}
                  <th>Price</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {addInventory.length == 0 ? (
                  <tr>
                    <th></th>
                    <th>
                      <select
                        className="inputtable"
                        name="itemDetails"
                        onChange={(e) => getPriceList(e)}
                        // value={user.itemDetails}
                      >
                        <option value="">Select Item</option>

                        {item.map((values, index) => (
                          <option
                            value={values.Id}
                            key={values.Id}
                          >
                            {values.Name}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                ) : (
                  addInventory.map((user, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>
                          <select
                            className="inputtable"
                            name="itemDetails"
                            onChange={(e) => replaceData(e, index)}
                            value={user.itemDetails}
                          >
                            <option value="">{user.Name}</option>

                            {item.map((values, index) => (
                              <option
                                value={values.Id}
                                key={values.Id}
                              >
                                {values.Name}
                              </option>
                            ))}
                          </select>
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="description"
                            onChange={(e) => onChange(e, index)}
                            value={user.description}
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="purchasequantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.purchasequantity}
                          />
                        </th>
                        <th>
                            <select
                              className="inputtable"
                              name="unitforpurchase"
                              onChange={(e) => onChange(e, index)}
                              value={user.unitforpurchase}
                            >
                              <option>Select the unit</option>
                               <option>Pieces</option>
                               <option>Box</option> 
                               <option>Dozen</option>
                               <option>Meter</option>
                               <option>Kg</option> 
                               <option>Liter</option>
                              <option>Gallon</option>
                              
                            </select>
                          </th>
                        <th>
                          <input
                            className="inputtable"
                            name="rate"
                            onChange={(e) => onChange(e, index)}
                            value={user.rate}
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="amount"
                            onChange={(e) => onChange(e, index)}
                            value={user.amount}
                          />
                        </th>
                        <th>
                          <IoTrashOutline onClick={() => deleteRow(index)} />
                        </th>
                        <input
                            type="hidden"
                            className="inputtable"
                            name="itemmasterid"
                            onChange={(e) => onChange(e, index)}
                            value={user.itemmasterid}
                          />
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="link">
              {" "}
              <Link
                to="#"
                className="addlink"
                onClick={addRow}
              >
                Add New Line
              </Link>
            </div>
            <div className="link">
              {" "}
              <Link
                to="/inventory/additem"
                className="addlink"
              >
                Add New Item
              </Link>
            </div>
          </div>
          <div className="note">
            <div className="comment">
              <div className="input-block-text">
                <label
                  htmlFor="customernotes"
                  className="input-label"
                >
                  Customer Notes
                </label>
                <textarea
                  className="textareas"
                  rows="3"
                  cols="52"
                  type="text"
                  autoComplete="off"
                  name="customernotes"
                  id="customernotes"
                  placeholder="Customer notes"
                  value={purchaseedit.customernotes}
                />
              </div>
            </div>
            <div className="pricediscount">
              <div className="input-container">
                <div className="input-block">
                  <label className="input-label">Total Price</label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="totalAmount"
                      id="totalAmount"
                      value={totalAmount}
                      //value={purchaseedit.totalAmount}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              {/* <div className="input-container">
                <div className="input-block">
                  <label className="input-label">IGST @ %</label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gst"
                      id="gst"
                      //value={purchaseedit.gst}
                      value={gst}
                      onChange={(e) => {
                        const newGst = parseFloat(e.target.value) || 0;
                        setGst(newGst);
                        const subTotal = calculateSubTotal(
                          totalAmount,
                          newGst
                        );
                        setSubTotal(subTotal);
                        const gstAmount = subTotal - totalAmount;
                        setTotalGst(gstAmount);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-block">
                  <label className="input-label">GST Amount</label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gstamount"
                      id="gstamount"
                      value={totalGst}
                      // value={purchaseedit.totalGst}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-block">
                  <label className="input-label">
                    Final Price(Including GST)
                  </label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="subTotal"
                      id="subTotal"
                      value={subTotal}
                      // value={purchaseedit.subTotal}
                      readOnly
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <br />
          <div className="top-div2">
            <div className="note">
              <div>
                <div className="input-block-text">
                  <label
                    htmlFor="termsconditions"
                    className="input-label"
                  >
                    Terms & Conditions
                  </label>
                  <textarea
                    className="textareas"
                    rows="3"
                    cols="95"
                    type="text"
                    autoComplete="off"
                    name="termsconditions"
                    id="termsconditions"
                    placeholder="Terms and conditions"
                    value={purchaseedit.termsconditions}
                  />
                </div>
              </div>
              <div className="purchaseorderfile">
                <label htmlFor="Image">Attach File(s) to Purchase Order</label>

                <input
                  type="file"
                  name="avatar"
                  onChange={setimgfile}
                />
                {purchaseedit.avatar}
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="adjustedby"
            id="adjustedby"
            value={gettoken}
          />
          <input
            type="hidden"
            name="orderstage"
            value={purchaseedit.orderstage}
            onChange={(e) => {
              handleEdit(e);
            }}
          />
        </div>

        <div className="modal-buttons">
          <div className="save-button">
            <button
              className="input-button"
              type="submit"
              onClick={() => {
                handleEdit({ target: { name: "orderstage", value: "Draft" } });
              }}
            >
              Save As Draft
            </button>
            <button
              className="input-button"
              type="submit"
              onClick={() => {
                handleEdit({
                  target: { name: "orderstage", value: "Send Email" },
                });
              }}
            >
              Save And Send Email
            </button>
            <button
              className="input-button"
              type="button"
              onClick={handleCancel}
              // onClick={() => {
              //         const confirmBox = window.confirm(
              //           "Do you really want to delete "+ "PO-"+ purchaseedit.purchaseorderid
              //         )
              //         if (confirmBox === true) {
              //           deletepurchase(purchaseedit.purchaseorderid)
              //         }
              //       }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPurchase;

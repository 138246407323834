import React, { useEffect,useState } from 'react'
import "./formone.css"
import { IoTrashOutline} from "react-icons/io5";
import axios from '../../../helper/axios';
import { NavLink,useHistory } from 'react-router-dom';
import {TbFileDescription} from "react-icons/tb"
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";

   const AllInventoryPresent = () => {
    const [data,setData]=useState([]);
    const history = useHistory();
    
    const loadInventory=async(data)=>{
     
      const res = await axios.get("/inventGet", {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
      console.log(res.data)


    }
    const token = sessionStorage.getItem('jwtToken');
    useEffect(()=>{
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      loadInventory();
    },[]);
//     const deletetable = (value) =>
//  {
//    axios.post('/InDelete',{value})
//    .then((result)=>{
//     loadInventory();
//    })
//    .catch(()=>{
//      alert('Error in the Code');
//    });
//  };

    return (
      <div>
         <Helmet> <title>All Inventory|BBS Inventory Management</title>
  
  </Helmet>
           <table id="inventory-adjustments">
              <thead>
                  <tr>
                     
                      <th>DATE </th>
                      <th>REASON</th>
                      <th>DESCRIPTION</th>
                      <th>STATUS</th>
                      <th>REFERENCE NUMBER</th>
                      <th>ADJUSTED BY</th>
                      {/* <th>DELETE</th> */}
                     
  
                  </tr>
              </thead>
              <tbody>
              
            {
            data.map((value, key) => {
              return (
               
                <tr key={key}>
                    
                  <td>{value.date }</td>
                  <td>{value.reason}</td>
              
                  <td><NavLink to={`/inventory/items1/${value.referencenumber}`} className="addlink"><TbFileDescription></TbFileDescription>{value.description}</NavLink></td>
                  
                  <td>{value.status}</td>
                  <td>{value.referencenumber}</td>
                  <td>{value.adjustedby}</td>
                  {/* <td><IoTrashOutline onClick={()=>{
                    const confirmBox=window.confirm(
                      "Do you really want to delete"+value.Id
                    )
                    if (confirmBox===true){
                      deletetable(value.Id)

                    }
                  }}/>
                  </td> */}
                 
                </tr>
                
              )
            })
            
          }
          </tbody>

          <tbody>
            
            </tbody>
            
            </table>
        
        
    </div>
  )
}

export default AllInventoryPresent;
import React, { useState } from 'react'
import {useFormik} from "formik";
import axios from "../../helper/axios";
import {BsBuilding} from 'react-icons/bs';
import {HiOutlineMailOpen} from 'react-icons/hi';
import {RiLockPasswordLine} from 'react-icons/ri';
import {CiGlobe} from 'react-icons/ci';
import {SlLocationPin} from 'react-icons/sl';
import {BsTelephone} from 'react-icons/bs';
import {FcGoogle} from 'react-icons/fc';
import {BsFacebook} from 'react-icons/bs';
import {BsTwitter} from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { userSignin } from '../../helper/schemas';

const initialValues={
cname:"",
email:"",
password:"",
country:"",
state:"",
phoneno:"",

}

const Login = () => {

  const[selected, setSelected] = useState("");
  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };

  const state = [
   
    " Select State",
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Gujarat",
 "Haryana",
  "Himachal Pradesh",
 "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "kerala",
 "Lakshadweep",
  "Madhya Pradesh",
 "Maharashtra",
  "Manipur",
  "Meghalaya",
 "Mizoram",
  "Nagaland",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  ];
  
  let type = null;
  let options = null;
  if (selected === "India") {
    type = state;
  } else  {
    type = null;
  } 
  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }
  

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues,
    validationSchema: userSignin,
    onSubmit:async (values, action) => {
      await axios.post("/UserDynamic",values);
      console.log(values,
      );
     
      action.resetForm();
      alert("Created Account Successfully");
    },
  });
  
  return (
  

<div className='homemain'>
<div className='modal-containerlogin'>
<div className='containerfulllogin'>
<div className='topbox'>
<div className='headertopbox'>

</div>
<div className='signintopbox'>
<p className='float'>Already have a Account? 

<NavLink to='/Signin' className='float'><button className="none" >Sign in</button></NavLink></p>
</div>
</div>
<hr className='marginhead'></hr>



<div className='firstbox'>
<div className='secondbox'>




</div>


<div className='thirdbox'>
<div className='fon'>
<h4 >Create your Account</h4>
</div>
<div className='fontwo'>
<form onSubmit={handleSubmit}>
<div className='wrapper'>
<BsBuilding className='bs'/>
<div className='input-errormain'>
  <input
  type="text"
  className='inputicon'
  autoComplete="off"
  name="cname"
   id="cname"
  placeholder="Company Name"
  value={values.cname}
  onChange={handleChange}
  onBlur={handleBlur}/>
  {errors.cname && touched.cname ? (
  <p className="form-errormain">{errors.cname}</p>
                        ) : null}
 </div>
  
  </div>
  <br></br>
  <div className='wrapper'>
  
  <HiOutlineMailOpen className='bs'/>
  <div className='input-errormain'>
<input
  type="email"
  autoComplete="off"
  className='inputicon'
  name="email"
   id="email"
  placeholder="Email Address"
  value={values.email}
  onChange={handleChange}
  onBlur={handleBlur}/>
  {errors.email && touched.email ? (
  <p className="form-errormain">{errors.email}</p>
                        ) : null}
</div>
  </div>
  <br></br>
  <div className='wrapper'>
  <RiLockPasswordLine className='bs'/>
  <div className='input-errormain'>
<input
  type="password"
  autoComplete="off"
  className='inputicon'
  name="password"
   id="password"
  placeholder="Password"
  value={values.password}
  onChange={handleChange}
  onBlur={handleBlur}/>
  {errors.password && touched.password? (
  <p className="form-errormain">{errors.password}</p>
                        ) : null}
                        </div>
  </div>
  <br></br>
  <div className='wrapper'>
  <CiGlobe className='bs'/>
  <div className='input-errormain'>
 
       
        
          

 <select
  type="text"
  autoComplete="off"
  name="country"
  className='inputicon'
   id="country"
   onClick={changeSelectOptionHandler}
   onChange={handleChange}
  placeholder=""
  value={values.country}
  
  onBlur={handleBlur}>
  <option>Select Country</option>
  <option>Afghanistan</option>
  <option>Albania</option>
  <option>Algeria</option>
  <option>American Samoa</option>
  <option>Andorra</option>
  <option>Angola</option>
  <option>Anguilla</option>
  <option>Antigua and Barbuda</option>
  <option>Argentina</option>
  <option>Armenia</option>
  <option>Aruba</option>
  <option>Ascension</option>
  <option>Australia</option>
  <option>Australian External Territories</option>
  <option>Austria</option>
  <option>Azerbaijan</option>
  <option>Bahamas</option>
  <option>Bahrain</option>
  <option>Bangladesh</option>
  <option>Barbados</option>
  <option>Belarus</option>
  <option>Belgium</option>
  <option>Belize</option>
  <option>Benin</option>
  <option>Bermuda</option>
  <option>Bhutan</option>
  <option>Bolivia</option>
  <option>Bosnia and Herzegovina</option>
  <option>Botswana</option>
  <option>Brazil</option>
  <option>British Virgin Islands</option>
  <option>Brunei Darussalam</option>
  <option>Bulgaria</option>
  <option>Burkina Faso</option>
  <option>Burundi</option>
  <option>Cambodia</option>
  <option>Cameroon</option>
  <option>Canada</option>
  <option>Cape Verde</option>
  <option>Cayman Islands</option>
  <option>Central African Republic</option>
  <option>Chad</option>
  <option>Chile</option>
  <option>China</option>
  <option>Christmas Island</option>
  <option>Colombia</option>
  <option>Comoros</option>
  <option>Congo</option>
  <option>Cook Islands</option>
  <option>Costa Rica</option>
  <option>Code d'Ivoire</option>
  <option>Croatia</option>
  <option>Cuba</option>
  <option>Cyprus</option>
  <option>Czech Republic</option>
  <option>Democratic Republic of the Congo</option>
  <option>Denmark</option>
  <option>Diego Garcia</option>
  <option>Djibouti</option>
  <option>Dominica</option>
  <option>Dominican Republic</option>
  <option>East Timor</option>
  <option>Ecuador</option>
  <option>Egypt</option>
  <option>El Salvador</option>
  <option>Equatorial Guinea</option>
  <option>Eritrea</option>
  <option>Estonia</option>
  <option>Ethiopia</option>
  <option>Falkland Islands</option>
  <option>Faroe Islands</option>
  <option>Fiji</option>
  <option>Finland</option>
  <option>France</option>
  <option>French Guiana</option>
  <option>French Polynesia</option>
  <option>Gabon</option>
  <option>Gambia</option>
  <option>Georgia</option>
  <option>Germany</option>
  <option>Ghana</option>
  <option>Gibraltar</option>
  <option>Greece</option>
  <option>Greenland</option>
  <option>Grenada</option>
  <option>Guadeloupe</option>
  <option>Guam</option>
  <option>Guatemala</option>
  <option>Guinea</option>
  <option>Guinea-Bissau</option>
  <option>Guyana</option>
  <option>Haiti</option>
  <option>Honduras</option>
  <option>Hong Kong</option>
  <option>Hungary</option>
  <option>Iceland</option>
  <option>India</option>
  <option>Indonesia</option>
  <option>Iran</option>
  <option>Iraq</option>
  <option>Ireland</option>
  <option>Israel</option>
  <option>Italy</option>
  <option>Jamaica</option>
  <option>Japan</option>
  <option>Jersey</option>
  <option>Jordan</option>
  <option>Kazakhstan</option>
  <option>Kenya</option>
  <option>Kiribati</option>
  <option>Kosovo</option>
  <option>Kuwait</option>
  <option>Kyrgyzstan</option>
  <option>Laos</option>
  <option>Latvia</option>
  <option>Lebanon</option>
  <option>Lesotho</option>
  <option>Liberia</option>
  <option>Libya</option>
  <option>Liechtenstein</option>
  <option>Lithuania</option>
  <option>Luxembourg</option>
  <option>Macao</option>
  <option>Macedonia</option>
  <option>Madagascar</option>
  <option>Malawi</option>
  <option>Malaysia</option>
  <option>Maldives</option>
  <option>Mali</option>
  <option>Malta</option>
  <option>Marshall Islands</option>
  <option>Martinique</option>
  <option>Mauritania</option>
  <option>Mauritius</option>
  <option>Mexico</option>
  <option>Micronesia</option>
  <option>Moldova</option>
  <option>Monaco</option>
  <option>Mongolia</option>
  <option>Montenegro</option>
  <option>Montserrat</option>
  <option>Morocco</option>
  <option>Mozambique</option>
  <option>Mynanmar</option>
  <option>Namibia</option>
  <option>Nauru</option>
  <option>Nepal</option>
  <option>Netherlands</option>
  <option>Netherlands Antilles</option>
  <option>New Caledonia</option>
  <option>New Zealand</option>
  <option>Nicaragua</option>
  <option>Niger</option>
  <option>Nigeria</option>
  <option>Niue</option>
  <option>North Korea</option>
  <option>Northern Mariana Islands</option>
  <option>Norway</option>
  <option>Oman</option>
  <option>Pakistan</option>
  <option>Palau</option>
  <option>Palestine</option>
  <option>Panama</option>
  <option>Papua New Guinea</option>
  <option>Paraguay</option>
  <option>Peru</option>
  <option>Philippines</option>
  <option>Poland</option>
  <option>Portugal</option>
  <option>Puerto Rico</option>
  <option>Qatar</option>
  <option>Reunion</option>
  <option>Romania</option>
  <option>Russia</option>
  <option>Rwanda</option>
  <option>Saint Helena</option>
  <option>Saint Kitts and Nevis</option>
  <option>Saint Lucia</option>
  <option>Saint Pierre and Miquelon</option>
  <option>Samoa</option>
  <option>San Marino</option>
  <option>Sao Tome and Principe</option>
  <option>Saudi Arabia</option>
  <option>Senegal</option>
  <option>Serbia</option>
  <option>Seychelles</option>
  <option>Sierra Leone</option>
  <option>Singapore</option>
  <option>Sint Maarten</option>
  <option>Slovakia</option>
  <option>Slovenia</option>
  <option>Solomon Islands</option>
  <option>Somalia</option>
  <option>South Africa</option>
  <option>south Korea</option>
  <option>Spain</option>
  <option>Sri Lanka</option>
  <option>Sudan</option>
  <option>Suriname</option>
  <option>Swaziland</option>
  <option>Syria</option>
  <option>Taiwan</option>
  <option>Tajikistan</option>
  <option>Tanzania</option>
  <option>Thailand</option>
  <option>Togo</option>
  <option>Tokelau</option>
  <option>Tonga</option>
  <option>Trinidad and Tobago</option>
  <option>Tunisia</option>
  <option>Turkey</option>
  <option>turkmenistan</option>
  <option>Turks and Caicos Islands</option>
  <option>Tuvalu</option>
  <option>Uganda</option>
  <option>Ukraine</option>
  <option>United Arab Emirates</option>
  <option>United Kingdom</option>
  <option>United States</option>
  <option>Uruguay</option>
  <option>US Virgin Islands</option>
  <option>Uzbekistan</option>
  <option>Vanuatu</option>
  <option>Vatican City</option>
  <option>Venezuela</option>
  <option>Vietnam</option>
  <option>Wallis and Futuna</option>
  <option>Yemen</option>
  <option>Zambia</option>
  <option>Zimbabwe</option>
 
  
 </select> 
 </div>
  </div>
  <br></br>
  
  <div className='wrapper'>
  <SlLocationPin className='bs'/>
  <div className='input-errormain'>
  <select
   type="text"
  autoComplete="off"
  name="state"
   id="state"
   className='inputicon'
  placeholder=""
  value={values.state}
 
  onChange={handleChange}
  onBlur={handleBlur}
  >
            {
              options
            }
          </select>
  
  

  {/* </select> */}
 
</div>
  </div>
  
  <br></br>
  <div className='wrapper'>
 
  <BsTelephone className='bs'/>
  <div className='input-errormain'>
  <input
  type="number"
  className='inputicon'
  autoComplete="off"
  name="phoneno"
   id="phoneno"
  placeholder=" Phone Number"
  value={values.phoneno}
  onChange={handleChange}
  onBlur={handleBlur}/>
  {errors.phoneno && touched.phoneno ? (
  <p className="form-errormain">{errors.phoneno}</p>
                        ) : null}
 </div>
  </div>
  <br></br>
 <input type="checkbox" id='check'></input><label style={{ color:"#666767", fontSize:"12px"}}>I agree to the <span style={{color:"#007ac6"}}>Terms of Service</span> and <span style={{color:"#007ac6"}}>Private Policy</span></label>
 <br></br>
<div className='redbutton'>
  <button className="redbut"type="submit"onClick={handleSubmit}>CREATE YOUR FREE ACCOUNT</button>
  </div>
  <div className='paragraph'>
  <p>or sign in using 
  <a href="" alt="google"><FcGoogle  className='fc'/></a>
  <a href="https://www.facebook.com/login" alt="facebook"><BsFacebook className='fc'/></a>
  <a href="https://twitter.com/login" alt="twitter"><BsTwitter className="fc" /></a>
</p>
  </div>
</form>
</div>

</div>


</div>

</div>
</div>
</div>


  )
}
export default Login;
// import React from 'react'

// const Salesdropdown = () => {
//   return (
//     <div>Salesdropdown</div>
//   )
// }

// export default Salesdropdown
import React, { useState } from 'react'
import * as RiIcons from 'react-icons/ri';
import { Link } from "react-router-dom";
const Salesdropdown = () => {
    const [open, setOpen]=useState(false);
     const menus =[{
        title: 'All Supply',
        path: '/sales/sales',
        
      },
      {
        title: 'Active Supply',
        path: '/sales',
        
      },
      {
        title: 'Inactive Supply',
        path: '/sales/Inactivesales',
        
      },
      // {
      //   title: 'Sales By Date',
      //   path: '/sale/bydate',
        
      // },
      ]


  return (
    <div> 
        <div
        onClick={()=>setOpen(!open)}>
            <h3>All Supply<RiIcons.RiArrowDownSFill /></h3>
        </div>    
      {
        open && (
            <div 
            className='dropback'>
            <div>
            
              {
                 menus.map((menu)=>(
                   <ul
                     onClick={()=>setOpen(false)}
                      className='drop'  key={menu.title}> 
                     <Link to={menu.path} className='itemdrop'>{menu.title} </Link> 
                     </ul>
                 ))
               }
             
             </div>
            </div>
        )
      }
    </div>
  )
}

export default Salesdropdown
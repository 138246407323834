
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import {roadTax } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import  { useHistory } from 'react-router-dom'
const initialValues = {
  
   vehicleno: "",
   ownername:"",
   taxhead:"",
    taxmode:"",
    roadtaxfrom: "",
    taxupto:"",
    roadtaxamount:"",
     penalty:"",
     
    };
    
  
    const NewRoadTax= () => {
      const history = useHistory();
      
      const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
      useFormik({
        initialValues,
        validationSchema: roadTax,
        
        onSubmit: async(values, action,) => {
         
        try {
            const data = new FormData();
            data.append("document", JSON.stringify(values));
            data.append("avatar",file);
            
            const response = await axios.post("/RoadTaxPost",data,{
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              }
            });
  
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/Roadtax');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
        action.resetForm();
        },
        
      });
      const token = sessionStorage.getItem('jwtToken');
      const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
      
    const[file,setFile]=useState("");

       const setimgfile=(e)=>{
        setFile(e.target.files[0])
        console.log(e.target.files[0])
       }
       const[getVehicle,setGetVehicle]=useState([]);
       useEffect(()=>{
        loadVehicle()
         
         },[]);
        
         const loadVehicle = async ()=>{
             const res = await axios.get(`/RcGetvehicle`, {
               headers: { 
                 Authorization: `Bearer ${token}`,
               },
             });
             const newProduct = await res.data;
             console.log(newProduct)
             setGetVehicle(newProduct)
            
             }
      return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
  
        <div className="">
          
         <form onSubmit={handleSubmit}>
          
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
        {error && <div className="form-error">Error submitting form. Please try again later.</div>}
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="vehicleno" className="input-labelemployee leftone">
                      Vechicle Number
                      </label>
                      <div className="inputerroremployee">
                      <select
                        type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                       
                        placeholder="Registration Number"
                        value={values.vehicleno}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ><option>Select Vehcile No</option>
                      {getVehicle.map(vehicle => (
                    <option value={vehicle.vehicleno} key={vehicle.id} >{vehicle.vehicleno}</option>

                  ))
                  }
                        </select>
                       {errors.vehicleno && touched.vehicleno ? (
                        <p className="form-erroremployee">{errors.vehicleno}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="ownername" className="input-labelemployee">
                    Owner Name
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="ownername"
                        id="ownername"
                       
                        placeholder="Owner Name"
                        value={values.ownername}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.ownername && touched.ownername ? (
                        <p className="form-erroremployee">{errors.ownername}</p>
                      ) : null}
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="taxhead" className="input-labelemployee">
                      Road Tax Head
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="taxhead"
                      id="taxhead"
                      placeholder="Road Tax Head"
                      value={values.taxhead}
                      onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    {errors.taxhead && touched.taxhead ? (
                        <p className="form-erroremployee">{errors.taxhead}</p>
                      ) : null}  
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="taxmode" className="input-labelemployee">
                      Road Tax Mode
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="taxmode"
                        id="taxmode"
                        placeholder="Road Tax Mode"
                        value={values.taxmode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.taxmode && touched.taxmode ? (
                        <p className="form-erroremployee">{errors.taxmode}</p>
                      ) : null}
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="roadtaxfrom" className="input-labelemployee leftone">
                    Road Tax From
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="roadtaxfrom"
                        id="roadtaxfrom"
                        placeholder="Road Tax From"
                        value={values.roadtaxfrom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.roadtaxfrom && touched.roadtaxfrom ? (
                        <p className="form-erroremployee">{errors.roadtaxfrom}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="taxupto" className="input-labelemployee leftone">
                    Road Tax Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="taxupto"
                        id="taxupto"
                        placeholder="Road Tax Upto"
                        value={values.taxupto}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                     
                     {errors.taxupto && touched.taxupto ? (
                        <p className="form-erroremployee">{errors.taxupto}</p>
                      ) : null}
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="roadtaxamount" className="input-labelemployee">
                      Road Tax Amout
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="roadtaxamount"
                        id="roadtaxamount"
                        placeholder="Road Tax Amout"
                        value={values.roadtaxamount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.roadtaxamount && touched.roadtaxamount ? (
                        <p className="form-erroremployee">{errors.roadtaxamount}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="penalty" className="input-labelemployee">
                    Penalty
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                        type="text"
                        autoComplete="off"
                        name="penalty"
                        id="penalty"
                        placeholder="Penalty"
                        value={values.penalty}
                        onChange={handleChange}
                        onBlur={handleBlur}/>
        
        {errors.penalty && touched.penalty ? (
                        <p className="form-erroremployee">{errors.penalty}</p>
                      ) : null}
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="Image" className="input-labelemployee" >Road Tax Certificate</label>
                      <div className="inputerroremployee">
                      <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""
                          onChange={setimgfile}
                           />
                           </div>
                     </div> 
                      </div>          
                      <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>
                   
                  </form>
                  </div>     
        </>
      )
    }
    
    export default NewRoadTax ;
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Schema } from "../../../helper/schemas";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

// here I have initialised all the input values
const initialValues = {
  picked: "organization",
  search: "",
  reference: "",
  purchaseorder: "",
  adress: "",
  purchasedate: "",
  expected: "",
  customernotes: "",
  termsconditions: "",
  orderstage: "",
  requestedby: "",
  requisitiondate: "",
  billNo: "",
};
// this function will handel the form inputs,submitting form and all the operations.
const Purchaseorder = () => {
  //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.
  // //**the history object to navigate the user to a different route by calling  return history.push('/purechases/allpurchase'); */
  const history = useHistory();

  //created object for dynamic table
  const itemTemp = {
    itemDetails: "",
    description: "",
    purchasequantity: "",
    rate: "",
    amount: "",
    Quantity: "",
  };
  //state for dynamic table
  const [datas, setDatas] = useState([itemTemp]);
  //update totalprice, initial value is 0
  const [totalAmount, setTotalAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [purchaseId, setPurchaseId] = useState(null);
  
  // all the value of dynamic table field value will update here
  const [addInventory, setAddInventory] = useState([]);
  //add the new row to item dynamic table
  const addItemrow = () => {
    //using ... spread operator will get all the data which is already present in addInventory and addInventory will add new fields
    setAddInventory([...addInventory, addInventory]);
  };
  //delete row of dynamic table
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setDatas(updateUsers);
    setAddInventory(updateUsers);
    //here  we are again calculate the price gst etc because when will delete the row the price should also update.
    const total = updateUsers.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total);
    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 0;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));

    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
  };

  const calculateSubTotal = (total, newGst) => {
    const taxRate = 1 + newGst / 100;
    const gstAmount = total * (taxRate - 1);
    return total + gstAmount;
  };
  //onchange function for dynamic table
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...datas];
    updateUser[index] = { ...updateUser[index], [name]: value };
    const quantity = parseFloat(updateUser[index].purchasequantity) || 0;
    const rate = parseFloat(updateUser[index].rate) || 0;
    const amount = quantity * rate;
    updateUser[index].amount = amount.toString();
    setDatas(updateUser);
    setAddInventory(updateUser);
    // Calculate the total amount of all items
    const total = updateUser.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total);

    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 0;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));
    // const gstAmount = subTotal - total;
    // setTotalGst(gstAmount);
    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // this will validate all the input fields
    validationSchema: Schema,
    onSubmit: async (values, action) => {
      try {
        //checked the validation when submitting the form
        const isAnyQuantityZero = addInventory.some(
          (user) => Number(user.purchasequantity) === 0
        );
        const isAnyQuantityEmpty = addInventory.some(
          (user) => user.purchasequantity === ""
        );
        if (isAnyQuantityZero || isAnyQuantityEmpty) {
          alert("Quantity	 field cannot be empty or zero.");
          return;
        }
        const isunit = addInventory.some(
          (user) => user.unitforpurchase === ""
        );
        if (isunit) {
          alert("Please select a purchase unit for all items.");
          return;
        }
        //here we are passing file and data so we have created the new data object and then
        //we pass the file and different differnet vlaue inside it using JSON.stringify
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("avatar", file);
        data.append("datas", JSON.stringify(datas));
        data.append("addInventory", JSON.stringify(addInventory));
        data.append("gettoken", JSON.stringify(gettoken));
        data.append("totalAmount", JSON.stringify(totalAmount));
        data.append("subTotal", JSON.stringify(subTotal));
        data.append("totalGst", JSON.stringify(totalGst));
        data.append("gst", JSON.stringify(gst));
        data.append("getrowdata", JSON.stringify(getrowdata));
        data.append("expectedDate", values.expected); // append Expected Delivery Date
        data.append("purchaseDate", values.purchasedate); // append Purchase Date
        console.log(file)
        const response = await axios.post("/PurchaseorderPost", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          const purchaseId= "ZSS" + response.data[0].toString().padStart(4, "0");
          setPurchaseId(purchaseId);
          console.log(purchaseId)
          alert(`Purchase ID created: ${purchaseId}`);
          return history.push("/purechases/allpurchase");
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      action.resetForm();
      // alert("Updated successfuly.")
      setFile("");
      //  setDatas([{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);
    },
  });

  const [file, setFile] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [supplierid, setSupplierid] = useState("");
  const [getrowdata, setGetrowdata] = useState([]);
  const [item, setItem] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [employee, setEmployee] = useState([]);
  const setimgfile = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const [purchaseDate, setPurchaseDate] = useState(null);
  const [expectedDate, setExpectedDate] = useState(null);

  const handlePurchaseDateChange = (event) => {
    const newPurchaseDate = new Date(event.target.value);
    setPurchaseDate(newPurchaseDate);
    setFieldValue("purchasedate", newPurchaseDate.toISOString().substr(0, 10));

    // Reset the expected date if it's earlier than the new purchase date
    if (expectedDate && expectedDate < newPurchaseDate) {
      setExpectedDate(null);
    }
  };
  //onchage function for Expected Delivery Date field
  const handleExpectedDateChange = (event) => {
    const newExpectedDate = new Date(event.target.value);

    // Check if the new expected date is earlier than the purchase date
    if (purchaseDate && newExpectedDate < purchaseDate) {
      setExpectedDate(purchaseDate);
      setFieldValue("expected", newExpectedDate.toISOString().substr(0, 10));
    } else {
      setExpectedDate(newExpectedDate);
      setFieldValue("expected", newExpectedDate.toISOString().substr(0, 10));
    }
  };

  const expectedDateMin = purchaseDate
    ? purchaseDate.toISOString().substr(0, 10)
    : null;
  const expectedDateDisabled = !purchaseDate;
  
  //display all the employee
  const getEmployee = async () => {
    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee);
    //console.log(newusers)
  };
  //display all the Supplier in table
  const loadSupplier = async (supplier) => {
    const res = await axios.get("/SupplierGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSupplier(res.data);
    console.log(res.data);
  };
  // onChange function when anyone choose the supplier then the item will come according to that supplier
  const handleItem = async (event) => {
    try {
      const supplierid = event.target.value;
      setSupplierid(supplierid);
      const name = event.target.options[event.target.selectedIndex].text;
      setSelectedName(name);
      setFieldValue("search", name);

      const response = await axios.get(`/itemgetbySupplier/${supplierid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data)
      setItem(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  //when will refresh or load the page will display all the data
  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/inventory/items");
    }
    // get the items according to supplier id
    const loadItem = async () => {
      try {
        const response = await axios.get(`/itemgetbySupplier/${supplierid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const newdata = response.data[0].supplierid;
        const newdatas =response.data
        console.log(newdatas)
        setItem(newdatas);
        setGetrowdata(newdata);
        setAddInventory([]);
        setDatas([]);
      } catch (error) {
        console.error(error);
      }
    };
    getEmployee();
    loadItem();
  }, [supplierid]);
  useEffect(() => {
    loadSupplier();
    //loadPrice();
    loadAll();
  }, []);
  //will get the token from sessionStorage
  const token = sessionStorage.getItem("jwtToken");
  const [gettoken, setToken] = useState();
  //will get all the information about the user who is loged in
  const loadAll = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;
      setToken(UserLogedin);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  //to show current date
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  const [signatureData, setSignatureData] = useState("");
  console.log(signatureData);
  //onchange function called when addInventory.length > 0
  //will get all the item detais according to supplier id
  //input- here supplierid is dynamic and Itemid will come which will select from drop down
  const replaceData = async (e, index, type) => {
    const res = await axios.get(
      `/itemgetbySupplier/${supplierid}/${e.target.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //const res = await axios.get(`/itemgetbySupplier/${supplierid}`);
    //  It creates a new array called newArr using the spread operator ([...addInventory]), which makes a shallow copy of the addInventory array.
    //  It assigns a new object to the element at the specified index in newArr.
    //  This object contains properties such as itemId, itemName, Quantity, description, purchasequantity, rate, and amount, with values retrieved from res.data.

    let newArr = [...addInventory];
    newArr[index] = {
      itemId: res.data.Id,
      itemName: res.data.Name,
      Quantity: res.data.Quantity,
      description: res.data.Descriptionone,
      purchasequantity: "",
      rate: 0,
      amount: 0,
    itemmasterid: res.data.itemmasterid,
    unitforpurchase:"",
    };
    // It checks if the type is not equal to 'Select Item'.
    // If the condition is true, it updates the addInventory state with the modified newArr and sets the datas state to the same value as newArr.
    if (!(type == "Select Item")) {
      setAddInventory(newArr);
      setDatas(newArr);
    } else {
      setAddInventory([
        ...newArr,
        {
          itemName: "Select Item",
          description: "",
          Quantity: "",
          purchasequantity: "",
          rate: "",
          amount: "",
          itemmasterid:"",
          unitforpurchase:"",
        },
      ]);
      //It updates the addInventory state with the new array created in the previous step and sets the datas state to the same value.
      setDatas([
        ...newArr,
        {
          itemName: "Select Item",
          description: "",
          Quantity: "",
          purchasequantity: "",
          rate: "",
          amount: "",
          itemmasterid:"",
          unitforpurchase:"",
        },
      ]);
    }
    const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);
    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 18;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));

    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
  };
  //onchange function called when addInventory.length == 0
  //will get all the item detais according to supplier id
  //input- here supplierid is dynamic and Itemid will come which will select from drop down
  const getPriceList = async (e, b) => {
    // const res = await axios.get(`/Itemactive/${e.target.value}`);
    const res = await axios.get(
      `/itemgetbySupplier/${supplierid}/${e.target.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    // It updates the addInventory state by spreading the current addInventory array and adding a new object to it.
    //This object contains properties such as itemId, itemName, description, Quantity, purchasequantity, rate, and amount with values obtained from res.data.
    // It updates the datas state in a similar manner as addInventory.
    setAddInventory([
      ...addInventory,
      {
        itemId: res.data.Id,
        itemName: res.data.Name,
        description: res.data.Descriptionone,
        Quantity: res.data.Quantity,
        purchasequantity: "",
        rate: 0,
        amount: 0,
        itemmasterid: res.data.itemmasterid,
        unitforpurchase:"",
      },
    ]);
    setDatas([
      ...addInventory,
      {
        itemId: res.data.Id,
        itemName: res.data.Name,
        Quantity: res.data.Quantity,
        description: res.data.Descriptionone,
        purchasequantity: "",
        rate: 0,
        amount: 0,
        itemmasterid: res.data.itemmasterid,
        unitforpurchase:"",
      },
    ]);
  };

  return (
    <>
      <Helmet>
        <title>New Purchase|BBS Inventory</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>
          {success && (
            <div className="form-erroremployees">
              Purchase order submitted successfully!
            </div>
          )}
          {error && (
            <div className="form-error">
              Error submitting Purchase order. Please try again later.
            </div>
          )}
          <div className="top-div1">
            <div className="input-container-search">
              <label
                htmlFor="name"
                className="input-label leftone"
              >
                Supplier Name
              </label>
              <select
                className="list"
                type="text"
                autoComplete="off"
                name="search"
                id="search"
                // placeholder="Select a vendor"
                value={values.supplierid}
                //  onChange={(e)=>handleChange(e)}
                onChange={(e) => handleItem(e)}
                onBlur={handleBlur}
              >
                <option>Select Supplier Name</option>
                {supplier.map((supplier) => (
                  <option
                    value={supplier.supplierid}
                    key={supplier.id}
                  >
                    {supplier.name}
                  </option>
                ))}
              </select>
            </div>

            {errors.search && touched.search ? (
              <p className="form-error">{errors.search}</p>
            ) : null}
            <div className="input-container1">
              <div className="input-block">
                <NavLink
                  to="/purechases/supplier"
                  className="addlink"
                >
                  To add new supplier
                </NavLink>
              </div>
            </div>
          </div>
          <div className="input-container1">
            <label
              htmlFor="name"
              className="input-label-radio"
            >
              Deliver To
            </label>
            <div className="input-block_radio">
              <div className="input-blockradio ">
                <div className="radio">
                  <label
                    htmlFor="name"
                    className="input-label"
                  >
                    Organization
                  </label>
                  <input
                    type="radio"
                    name="picked"
                    id="name"
                    value="organization"
                    onChange={handleChange}
                    checked={values.picked === "organization"}
                  />
                </div>

                <div className="radio">
                  <label
                    htmlFor="name"
                    className="input-label"
                  >
                    Employee
                  </label>
                  <input
                    type="radio"
                    name="picked"
                    id="name"
                    value=" Employee"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* <div className="radiocontent">{values.picked} </div> */}
            </div>
          </div>
          {/* <div className="input-container">
                  <div className="input-block">
                    <label htmlFor="id" className="input-label">
                    Supplier  Email
                    </label>
                    <div className="input-error">
                    <input
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                    />
                    {errors.email && touched.email ? (
                      <p className="form-error">{errors.email}</p>
                    ) : null}
                  </div></div>
                  <div className="input-block">
                    <label htmlFor="phoneno" className="input-label">
                    Supplier Phoneno
                    </label>
                    <div className="input-error">
                    <input
                      type="number"
                      autoComplete="off"
                      name="phoneno"
                      id="phoneno"
                      placeholder="Phone"
                      value={values.phoneno}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phoneno && touched.phoneno ? (
                      <p className="form-error">{errors.phoneno}</p>
                    ) : null}
                  </div></div></div> */}
          <div className="input-container">
            <div className="input-block">
              <label
                htmlFor="Reference"
                className="input-label leftone"
              >
                Requisition No
              </label>
              <div className="input-error">
                <input
                  type="text"
                  autoComplete="off"
                  name="reference"
                  id="reference"
                  placeholder="Requisition No"
                  value={values.reference}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.reference && touched.reference ? (
                  <p className="form-error">{errors.reference}</p>
                ) : null}
              </div>
            </div>
            <div className="input-block">
              <label
                htmlFor="requestedby"
                className="input-label leftone"
              >
                Requested By
              </label>
              <div className="input-error">
                <select
                  type="text"
                  autoComplete="off"
                  name="requestedby"
                  id="requestedby"
                  value={values.requestedby}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select Employee</option>
                  {employee.map((emp) => (
                    <option
                      value={emp.empId}
                      key={emp.id}
                    >
                      {emp.name}
                    </option>
                  ))}
                </select>
                {errors.requestedby && touched.requestedby ? (
                  <p className="form-error">{errors.requestedby}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-container">
            <div className="input-block">
              <label
                htmlFor="purchase date"
                className="input-label leftone"
              >
                Purchase Date
              </label>
              <div className="input-error">
                <input
                  type="date"
                  id="purchaseDate"
                  name="purchaseDate"
                  onChange={handlePurchaseDateChange}
                  value={
                    purchaseDate ? purchaseDate.toISOString().substr(0, 10) : ""
                  }
                />

                {/* <input type="date" name="purchasedate" onChange={handlePurchaseDateChange} /> */}
                {errors.purchasedate && touched.purchasedate ? (
                  <p className="form-error">{errors.purchasedate}</p>
                ) : null}
              </div>
            </div>
            <div className="input-block">
              <label
                htmlFor="expected date"
                className="input-label"
              >
                Expected Delivery Date
              </label>
              <div className="input-error">
                <input
                  type="date"
                  id="expectedDate"
                  name="expectedDate"
                  onChange={handleExpectedDateChange}
                  value={
                    expectedDate ? expectedDate.toISOString().substr(0, 10) : ""
                  }
                  min={expectedDateMin}
                  disabled={expectedDateDisabled}
                />
                {/* <input type="date" onChange={handleExpectedDateChange} min={expectedDateMin} /> */}
                {errors.expected && touched.expected ? (
                  <p className="form-error">{errors.expected}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-container">
            {/* added new field  Requisition Date by jyoti */}
            <div className="input-block">
              <label
                htmlFor="requisitiondate"
                className="input-label"
              >
                Requisition Date
              </label>
              <div className="input-error">
                <input
                  type="date"
                  autoComplete="off"
                  name="requisitiondate"
                  id="requisitiondate"
                  value={values.requisitiondate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.requisitiondate && touched.requisitiondate ? (
                  <p className="form-error">{errors.requisitiondate}</p>
                ) : null}
              </div>
            </div>
            <div className="input-block">
              <label
                htmlFor="billNo"
                className="input-label"
              >
                Bill No
              </label>
              <div className="input-error">
                <input
                  type="text"
                  autoComplete="off"
                  name="billNo"
                  id="billNo"
                  value={values.billNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.billNo && touched.billNo ? (
                  <p className="form-error">{errors.billNo}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-container">
            <div className="input-block">
              <label
                htmlFor="adress"
                className="input-label"
              >
                Delivery Address
              </label>
              <div className="input-error">
                <textarea
                  // style={{ width: "210px", height: "50px" }}
                  rows="3"
                  cols="30"
                  type="text"
                  autoComplete="off"
                  name="adress"
                  id="adress"
                  placeholder="Address"
                  value={values.adress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.adress && touched.adress ? (
                  <p className="form-error">{errors.adress}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <div className="price-list-heading">Price List </div>
            <div>
              <table id="contacttable">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}>Sl No</th>
                    <th style={{ width: "220px" }}>Item Name</th>
                    <th style={{ width: "200px" }}>Description</th>
                    <th>Quantity In Stock </th>
                    <th>Quantity </th>
                    <th style={{ width: "180px" }}>Unit</th>
                    <th>Rate</th>
                    {/* <th>Tax %</th> */}
                    <th>Price</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {/* dynamic table */}

                  {addInventory.length == 0 ? (
                    <tr>
                      <th></th>
                      <th>
                        <select
                          className="inputtable"
                          name="itemDetails"
                          onChange={(e) => getPriceList(e)}
                          // value={user.itemDetails}
                        >
                          <option value="">Select Item</option>

                          {item.map((values, index) => (
                            <option
                              value={values.Id}
                              key={values.Id}
                            >
                              {values.Name}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  ) : (
                    addInventory.map((user, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <th>
                            <select
                              className="inputtable"
                              name="itemDetails"
                              onChange={(e) =>
                                replaceData(e, index, user.itemName)
                              }
                              value={user.itemDetails}
                            >
                              <option value="">{user.itemName}</option>

                              {item.map((values, index) => (
                                <option
                                  value={values.Id}
                                  key={values.Id}
                                >
                                  {values.Name}
                                </option>
                              ))}
                            </select>
                          </th>
                          <th>
                            {/* { setItem &&  <input
                    className="inputtable"
                    name="description"
                    onChange={(e)=> onChange(e, index)}
                 value={user.description}
                    
                    />} */}
                            <input
                              className="inputtable"
                              name="description"
                              onChange={(e) => onChange(e, index)}
                              value={user.description}
                            />
                          </th>
                          <th>
                          <input
                              className="inputtable"
                              name="quantityinstock"
                              onChange={(e) => onChange(e, index)}
                              value={user.Quantity}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="purchasequantity"
                              onChange={(e) => onChange(e, index)}
                              value={user.purchasequantity}
                            />
                          </th>
                          <th>
                            <select
                              className="inputtable"
                              name="unitforpurchase"
                              onChange={(e) => onChange(e, index)}
                              value={user.unitforpurchase}
                            >
                              <option>Select the unit</option>
                               <option>Pieces</option>
                               <option>Box</option> 
                               <option>Dozen</option>
                               <option>Meter</option>
                               <option>Kg</option> 
                               <option>Liter</option>
                              <option>Gallon</option>
                              
                            </select>
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="rate"
                              onChange={(e) => onChange(e, index)}
                              value={user.rate}
                            />
                          </th>
                          {/* <th><input
                    className="inputtable"
                    name="tax"
                    onChange={(e)=> onChange(e, index)}
                    value={user.tax}
                    />
                    </th> */}
                          <th>
                            <input
                              className="inputtable"
                              name="amount"
                              disabled
                              value={user.amount}
                            />
                          </th>
                          <th>
                            <IoTrashOutline onClick={() => deleteRow(index)} />
                          </th>
                          <input
                            type="hidden"
                            className="inputtable"
                            name="itemmasterid"
                            onChange={(e) => onChange(e, index)}
                            value={user.itemmasterid}
                          />
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="link">
                {" "}
                <Link
                  to="#"
                  className="addlink"
                  onClick={addItemrow}
                >
                  Add New Line
                </Link>
              </div>
              <div className="link">
                {" "}
                <Link
                  to="/inventory/additem"
                  className="addlink addlink2"
                >
                  Add New Item
                </Link>
              </div>
            </div>
            <div className="note">
              <div className="comment">
                <div className="input-block-text">
                  <label
                    htmlFor="customernotes"
                    className="input-label"
                  >
                    Customer Notes
                  </label>
                  <textarea
                    className="textareas"
                    rows="3"
                    cols="52"
                    type="text"
                    autoComplete="off"
                    name="customernotes"
                    id="customernotes"
                    placeholder="Customer notes"
                    value={values.customernotes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.customernotes && touched.customernotes ? (
                    <p className="form-error">{errors.customernotes}</p>
                  ) : null}
                </div>
              </div>
              <div className="pricediscount">
                <div className="input-container container-total-price">
                  <div className="input-block">
                    <label
                      className="input-label"
                      id="total-input-price"
                    >
                      Total Price
                    </label>
                    <div className="input-error display-total">
                      <input
                        type="text"
                        autoComplete="off"
                        name="totalAmount"
                        id="totalAmount"
                        value={totalAmount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">IGST @ %</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="gst"
                        id="gst"
                        value={gst}
                        onChange={(e) => {
                          const newGst = parseFloat(e.target.value) || 0;
                          setGst(newGst);
                          const subTotal = calculateSubTotal(
                            totalAmount,
                            newGst
                          );
                          setSubTotal(subTotal);
                          const gstAmount = subTotal - totalAmount;
                          setTotalGst(gstAmount);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">GST Amount</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="gstamount"
                        id="gstamount"
                        value={totalGst}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">
                      Final Price(Including GST)
                    </label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="subTotal"
                        id="subTotal"
                        value={subTotal}
                        readOnly
                      />
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
            <br />
            <div className="top-div2">
              <div className="note">
                <div>
                  <div className="input-block-text">
                    <label
                      htmlFor="termsconditions"
                      className="input-label"
                    >
                      Terms & Conditions
                    </label>
                    <textarea
                      className="textareas"
                      rows="3"
                      cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"
                      placeholder="Terms and conditions"
                      value={values.termsconditions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.termsconditions && touched.termsconditions ? (
                      <p className="form-error">{errors.termsconditions}</p>
                    ) : null}
                  </div>
                </div>
                <div className="purchaseorderfile">
                  <label htmlFor="Image">
                    Attach File(s) to Purchase Order
                  </label>
                  {/* <input
                         type="file"
                        name="avatar" 
                        onChange={(e)=>{
                          // let reader = new FileReader();
                          // reader.onload =() =>{
                          //   if(reader.readyState === 2){
                          //     setFieldValue("avatar",reader.result);
                          //   }
                          // }
                          // reader.readAsDataURL(e.target.files[0])
                          setFieldValue("avatar",e.target.files[0]);
                        }}
                          
                        onBlur={handleBlur}
                        /> */}
                  <input
                    className="attach-file"
                    type="file"
                    name="avatar"
                    onChange={setimgfile}
                  />
                </div>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="adjustedby"
            id="adjustedby"
            value={gettoken}
          />
          <input
            type="hidden"
            name="orderstage"
            value={values.orderstage}
            onChange={handleChange}
          />{" "}
          <br />
          {/* <Signature onSignatureSave={handleSignatureData} /> */}
          <br />
          <br />
          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
                onClick={() => {
                  handleChange({
                    target: { name: "orderstage", value: "Draft" },
                  });
                }}
              >
                Save As Draft
              </button>
              <button
                className="input-button"
                type="submit"
                onClick={() => {
                  handleChange({
                    target: { name: "orderstage", value: "Send Email" },
                  });
                }}
              >
                Save And Send Email
              </button>
              <button
                className="input-button"
                type="button"
                onClick={resetForm}
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Purchaseorder;


import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import {  } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams, useHistory } from 'react-router-dom' 
    
  
    const EditService= () => {
      const history = useHistory();
        const {vehicleserviceid}=useParams();
        const [service,setService] = useState({
  
            registrationnumber: "",
            lastservicedata:"",
             ownername:"",
             nextservicedate:"",
             servicecost: "",
            
              
             });
             const token = sessionStorage.getItem('jwtToken');
             useEffect(()=>{
              if (!sessionStorage.getItem('jwtToken')) {
                return history.push('/');
              }
                eachService()
                 
                 },[]);
         
                 const eachService = async ()=>{
                     const res = await axios.get(`/serviceGet/${vehicleserviceid}`, {
                      headers: { 
                        Authorization: `Bearer ${token}`,
                      },
                    });
                     const newProduct = await res.data[0];
                     setService(newProduct)
                    
                     }

          const handleEdit = (e)=>{
         setService({...service,[e.target.name]:e.target.value})
         } 
         
         const handleSubmit = async(e)=>{
            e.preventDefault();
            
     try {
        const response = await axios.post(`/servicePost/${vehicleserviceid}`,service,{
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        });
         
        if (response.status === 200) {
          setSuccess(true);
          setError(false);
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      setService({
  
        registrationnumber: "",
        lastservicedata:"",
         ownername:"",
         nextservicedate:"",
         servicecost: "",
         })
     }   
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState(false);
      return (
        <>
        <Helmet> 
          <title></title>
        </Helmet> 
        <div className="">
          
         <form onSubmit={handleSubmit}>
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
        {error && <div className="form-error">Error submitting form. Please try again later.</div>}
        
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="registrationnumber" className="input-labelemployee">
                      Vehicle Reg Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="registrationnumber"
                        id="registrationnumber"
                       
                        placeholder="Registration Number"
                        value={service.registrationnumber}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                       
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="lastservicedata" className="input-labelemployee">
                   Last Service Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="lastservicedata"
                        id="lastservicedata"
                       
                        placeholder="Registration Date"
                        value={service.lastservicedata}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="ownername" className="input-labelemployee">
                      Owner Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="ownername"
                      id="ownername"
                      placeholder="Owner Name"
                      value={service.ownername}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                   
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="nextservicedate" className="input-labelemployee">
                      Next Service Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="nextservicedate"
                        id="nextservicedate"
                        placeholder="REG/FC Upto"
                        value={service.nextservicedate}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="servicecost" className="input-labelemployee">
                   Last Service Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="servicecost"
                        id="servicecost"
                        placeholder="Service Cost"
                        value={service.servicecost}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      
                      </div>
                    </div>
                    {/* <div className="input-blockemployee">
                    <label htmlFor="taxupto" className="input-labelemployee">
                    Tax Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="taxupto"
                        id="taxupto"
                        placeholder="Tax Upto"
                        value={service.taxupto}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                     
                     {errors.taxupto && touched.taxupto ? (
                        <p className="form-erroremployee">{errors.taxupto}</p>
                      ) : null}
                    </div>
                    </div> */}
                    </div>
                    <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>         
                   
                   
                  </form>
                  </div>     
        </>
      )
    }
    
    export default EditService ;
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import {  } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams, useHistory } from 'react-router-dom'
import {GrEdit} from 'react-icons/gr';

const EachInsurances = () => {
  //**here will get the insuranceid from the url by using the useParams hook */
  const {insuranceid}=useParams();   
   // will update all the value for all input fields of this form here 
   const [insurance,setInsurance]=useState(
    {
  
      policyno: "",
    vehicleno:"",
    policyholder:"",
    effectivedate:"",
    expirydate: "",
    insurancecompany:"",
    lastcost:"",
    presentcost:"",
    status:"" 
     }
       );
         // willget the token from  sessionStorage
       const token = sessionStorage.getItem('jwtToken');
       //** when page will load or refresh will load specific insurance details will load */
       useEffect(()=>{
        eachinsurance()
  
        },[]);
 //** will get specific insurance details according to insuranceid  */
        const eachinsurance = async ()=>{
            const res = await axios.get(`/Vehicle/Insurance/${insuranceid}`, {
               //to authorize the api send the token header
              headers: { 
                Authorization: `Bearer ${token}`,
              },
            });
            const newProduct = await res.data[0];
            setInsurance(newProduct)
           
            }
            
  
    return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
  
       
        <div className='editbu'>
    <button className='input-bu'><NavLink to={`/Vehicle/Insurance/${insuranceid}/edit`}><GrEdit/></NavLink></button>
       </div>
       <div className="">
         <form  >
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="policyno" className="input-labelemployee">
                      Policy Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="policyno"
                        id="policyno"
                        placeholder="Policy Number"
                        value={insurance.policyno}
                        
                      />
                     
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="vehicleregno" className="input-labelemployee">
                    Vehicle No
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                        
                        placeholder="Registration number"
                        value={insurance.vehicleno}
                        
                      />
                      
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="policyholder" className="input-labelemployee">
                      Policy Holder
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="policyholder"
                      id="policyholder"
                      placeholder="Policy Holder Name"
                      value={insurance.policyholder}
                      
                    /> 
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor=" effectivedate" className="input-labelemployee">
                      Issue Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="effectivedate"
                        id="effectivedate"
                        placeholder="Start Of Cover"
                        value={insurance.effectivedate}
                        
                      />
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="expirydate" className="input-labelemployee">
                    Expiry Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="expirydate"
                        id="expirydate"
                        placeholder="End Of Cover"
                        value={insurance.expirydate}
                        
                      />
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="insurancecompany" className="input-labelemployee">
                    Insurance Company
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="insurancecompany"
                        id="insurancecompany"
                        placeholder="Insurance Taken From"
                        value={insurance.insurancecompany}
                        
                      />
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="lastcost" className="input-labelemployee">
                    Last Insurance Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="lastcost"
                        id="lastcost"
                        
                        value={insurance.lastcost}
                      />
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="presentcost" className="input-labelemployee">
                    Present Insurance Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="presentcost"
                        id="presentcost"
                        
                        value={insurance.presentcost}
                      />
                    </div>
                    </div>
                    </div>
                      {/* added a new field called status */}
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="status" className="input-labelemployee">
                   Status
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="status"
                        id="status"
                        
                        value={insurance.status}
                      />
                      </div>
                    </div>
                    </div>
                  </form>
                  </div>     
        </>
      )
}

export default EachInsurances



import axios from '../../../helper/axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import Cryptr from 'cryptr';

const cryptr = new Cryptr("AGM_EMPLOYEE")
const Editemployee = () => {
    const history=useHistory()
    const {Id}=useParams();
const [employeeedit,setEmployeeedit]=useState({Id:"", name:"", gender:"",email:"",date:"",password:"",cpassword:"", 
fathersname:"",maritalstatus:"",phoneno:"",address:"",state:"",pincode:"",jobtype:"",experience:"",department:"",joindate:"",role:""
});
const token = sessionStorage.getItem('jwtToken');
useEffect(()=>{
  // if(!Cookies.get('jwtToken')){
  //   return history.push("/");
  // }
  if (!sessionStorage.getItem('jwtToken')) {
    return history.push('/');
  }
    editUser();
},[]);

const editUser= async ()=>{
    const res=await axios.get(`/Employeeactive/${Id}`,{
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    const newEmployee = await res.data;
        setEmployeeedit(newEmployee)
}


const handleEdit = (e)=>{
        
    setEmployeeedit({...employeeedit,[e.target.name]:e.target.value})
  }
  // const handleSubmit = async(e)=>{
  //   e.preventDefault();
  //   try {
  //     employeeedit.password = cryptr.encrypt(employeeedit.password)
  //     employeeedit.cpassword = cryptr.encrypt(employeeedit.cpassword)
  //     const response = await axios.post(`/EmployeeUpdate/${Id}`,employeeedit,{
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         Role : "Supperadmin",
  //         "Content-Type": "multipart/form-data",
  //       }
  //     });
  //     if (response.status === 200) {
  //       setSuccess(true);
  //       setError(false);
  //       return history.push("/inventory/activeemployee");
  //     }
  //   } catch (err) {
  //     setSuccess(false);
  //     setError(true);
  //   }
  //   setEmployeeedit({Id:"", name:"", gender:"",email:"",date:"",password:"",cpassword:"", 
  //   fathersname:"",maritalstatus:"",phoneno:"",address:"",state:"",pincode:"",jobtype:"",experience:"",department:"",joindate:"",
  //   });
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responses = await axios.post('/Login', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedinId = responses.data.person[0][0].Id;
      const UserLogedinRole = responses.data.person[0][0].role;
    
      employeeedit.password = cryptr.encrypt(employeeedit.password);
      employeeedit.cpassword = cryptr.encrypt(employeeedit.cpassword);
      const response = await axios.post(`/EmployeeUpdate/${Id}`, employeeedit, {
        headers: {
          Authorization: `Bearer ${token}`,
          Role: UserLogedinRole,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        setSuccess(true);
        setError(false);
        return history.push("/inventory/activeemployee");
      }
      
    } catch (err) {
      setSuccess(false);
      setErrors(true);
    }
    setEmployeeedit({
      Id: "",
      name: "",
      gender: "",
      email: "",
      date: "",
      password: "",
      cpassword: "",
      fathersname: "",
      maritalstatus: "",
      phoneno: "",
      address: "",
      state: "",
      pincode: "",
      jobtype: "",
      experience: "",
      department: "",
      joindate: "",
    });
  };
  
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState(false);

  return (
    <>
    <div>
    <form onSubmit={handleSubmit}>
       
    {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {errors && <div className="form-error">Error submitting Employee details.Admin/Supperadmin can access. </div>}
      {error && <div className="form-error">Error submitting Employee details.Please try it latter </div>}
    <h4>Personal Information</h4>
       <div className="input-employeecontainer"> 
       
                  <div className="input-blockemployee">
                    <label htmlFor="name" className="input-labelemployee">
                     Full Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      value={employeeedit.name}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                  </div>
                  <div className="input-blockemployee">
                 
                    <label htmlFor="gender" className="input-labelradio">
                      Gender
                    </label>
                    <div className='input-erroremployee'>
                    <div className="radiotype">
                   
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="Male"
                      checked={employeeedit.gender==="Male"}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    {""}Male
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="Female"
                      checked={employeeedit.gender==="Female"}
                      onChange={(e)=>{handleEdit(e)}}
                      
                    />
                    {""}Female
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="others"
                      checked={employeeedit.gender==="others"}
                      onChange={(e)=>{handleEdit(e)}}
                      
                    />
                    {""}Others
                   </div>
                    </div>
                  </div></div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="email" className="input-labelemployee">
                      Email
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={employeeedit.email}
                      onChange={(e)=>{handleEdit(e)}}
                      
                    />
                    </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="date" className="input-labelemployee">
                      Date Of Birth
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="date"
                      autoComplete="off"
                      name="date"
                      id="date"
                      placeholder="Date of Birth"
                      value={employeeedit.date}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                    
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="password" className="input-labelemployee">
                      Password
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="password"
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={employeeedit.password}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                    
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="fathersname" className="input-labelemployee">
                      Father's Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="text"
                      autoComplete="off"
                      name="fathersname"
                      id="fathersname"
                      placeholder="Father's Name"
                      value={employeeedit.fathersname}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                    
                  </div>
                  
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="cpassword" className="input-labelemployee">
                       Confirm Password
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="password"
                      autoComplete="off"
                      name="cpassword"
                      id="cpassword"
                      placeholder="Confirm Password"
                      value={employeeedit.cpassword}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                    
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="maritalstatus" className="input-labelemployee">
                      Marital Status
                    </label>
                    <div className="inputerroremployee">
                    <select
                    
                      type="text"
                      autoComplete="off"
                      name="maritalstatus"
                      id="maritalstatus"
                      placeholder="maritalstatus"
                      value={employeeedit.maritalstatus}
                      onChange={(e)=>{handleEdit(e)}}>
                    
                    <option></option>
                   <option>Married</option>
                   <option>Unmarried</option>
                   <option>Divorced
                   </option>
                   <option>Widower</option>

                   </select>
                   </div>
                  </div>
                  
                  </div><br></br>
                  {/* <div className=""> */}
                    <h4>Contact Information</h4>
                    <div className="input-employeecontainer">
                    
                      <div className="input-blockemployee">
                      <label htmlFor="phoneno" className="input-labelemployee">
                      Phone Number
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="number"
                      autoComplete="off"
                      name="phoneno"
                      id="phoneno"
                      placeholder="Phone Number"
                      value={employeeedit.phoneno}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                   

                   
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="address" className="input-labelemployee">
                      Address
                    </label>
                    <div className="inputerroremployee">
                    <textarea
                     rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={employeeedit.address}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                    
                      </div>
                    </div>
                    <div className="input-employeecontainer">
                    
                    <div className="input-blockemployee">
                    <label htmlFor="state" className="input-labelemployee">
                    State
                  </label>
                  <div className="inputerroremployee">
                  <select
                    type="text"
                    autoComplete="off"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={employeeedit.state}
                    onChange={(e)=>{handleEdit(e)}}
                  >
                  <option>Select State</option>
  <option>Andaman and Nicobar Islands</option>
  <option>Andhra Pradesh</option>
  <option>Arunachal Pradesh</option>
  <option>Assam</option>
  <option>Bihar</option>
  <option>Chandigarh</option>
  <option>Chhattisgarh</option>
  <option>Dadra and Nagar Haveli</option>
  <option>Daman and Diu</option>
  <option>Delhi</option>
  <option>Goa</option>
  <option>Gujarat</option>
  <option>Haryana</option>
  <option>Himachal Pradesh</option>
  <option>Jammu and Kashmir</option>
  <option>Jharkhand</option>
  <option>Karnataka</option>
  <option>kerala</option>
  <option>Lakshadweep</option>
  <option>Madhya Pradesh</option>
  <option>Maharashtra</option>
  <option>Manipur</option>
  <option>Meghalaya</option>
  <option>Mizoram</option>
  <option>Nagaland</option>
  <option>Odisha</option>
  <option>Puducherry</option>
  <option>Punjab</option>
  <option>Rajasthan</option>
  <option>Sikkim</option>
  <option>Tamil Nadu</option>
  <option>Telangana</option>
  <option>Tripura</option>
  <option>Uttar Pradesh</option>
  <option>Uttarakhand</option>
  <option>West Bengal</option>
                  </select>
                  </div>
                 

                 
                </div>
                <div className="input-blockemployee">
                  <label htmlFor="pincode" className="input-labelemployee">
                    Pincode
                  </label>
                  <div className="inputerroremployee">
                  <input
                   
                    type="number"
                    autoComplete="off"
                    name="pincode"
                    id="pincode"
                    placeholder="Pin Code"
                    value={employeeedit.pincode}
                    onChange={(e)=>{handleEdit(e)}}
                  />
                  </div>
                  

                    </div>
                  </div><br></br>
                  {/* </div> */}
                  <div className="">
                  <h4>Professional Information</h4>
                    <div className="input-employeecontainer">
                    
                      <div className="input-blockemployee">
                      <label htmlFor="jobtype" className="input-labelemployee">
                      Job Type
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="jobtype"
                      id="jobtype"
                      placeholder="Job Type"
                      value={employeeedit.jobtype}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                  
                   
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="experience" className="input-labelemployee">
                    Experience
                    </label>
                    <div className="inputerroremployee">
                    <input
                     
                      type="number"
                      autoComplete="off"
                      name="experience"
                      id="experience"
                      placeholder="Experience"
                      value={employeeedit.experience}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                    

                      </div>
                    </div>
                    <div className="input-employeecontainer">
                    
                    <div className="input-blockemployee">
                    <label htmlFor="department" className="input-labelemployee">
                    Department
                  </label>
                  <div className="inputerroremployee">
                  <select
                    type="text"
                    autoComplete="off"
                    name="department"
                    id="department"
                    placeholder="Department"
                    value={employeeedit.department}
                    onChange={(e)=>{handleEdit(e)}}
                  >
                  <option>Select</option>
                  <option>Business management </option>
                  <option>Business Administration</option>
                  <option>Sales</option>
                  <option>Purchase</option>
                  <option>Marketing</option>
                  <option>Technical </option>
                  <option>Non Technical </option>
                  <option>Store</option>
                  <option>IT</option>
                  <option>Others</option>
                  </select>
                  
</div>
                 
                </div>
                <div className="input-blockemployee">
                  <label htmlFor="joindate" className="input-labelemployee">
                    Joining Date
                  </label>
                  <div className="inputerroremployee">
                  <input
                   
                    type="date"
                    autoComplete="off"
                    name="joindate"
                    id="joindate"
                    placeholder="Join Date"
                    value={employeeedit.joindate}
                    onChange={(e)=>{handleEdit(e)}}
                    />
                  </div>

                    </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="role" className="input-labelemployee">
                   Role
                  </label>
                  <div className="inputerroremployee">
                  <select
                    type="text"
                    autoComplete="off"
                    name="role"
                    id="role"
                    // placeholder="role"
                    value={employeeedit.role}
                    onChange={(e)=>{handleEdit(e)}}
                  >
                  <option>Supperadmin </option>
                  <option>Admin </option>
                  <option>Manager </option>
                  <option>Employee</option>
                  </select>
          
                </div>
                 
                </div>

                  </div>

                  <div className="modal-buttons">
                    
                    <button className="input-button" type="submit">
                    Update
                    </button>
                    <button className="input-button" type="reset">
                   Cancel
                  </button>
                  </div>


    </form>

    
    </div>


    </>
  )
}
export default Editemployee;
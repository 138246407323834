import React from 'react';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import { Navitems } from './Navitems';
import Dropdown from "./Dropdown";
import '../../../App.css'
import Integrations from './Integrations';


const Navbar = () => {
    const [dropdown, setDropdown]=useState(false);
    const [dropdowns, setDropdowns]=useState(false);
  return (
    <>
    <nav className='navitemsbar'>
    <ul className="nav-itemsbar">
          {Navitems.map((item) => {
            if (item.title === "Features") {
              return (
                <li
                  key={item.id}
                  className={item.className}
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link to={item.path}>{item.title}</Link>
                  {dropdown && <Dropdown />}
                </li>
              );
            }
            if (item.title === "Integrations") {
              return (
                <li
                  key={item.id}
                  className={item.className}
                  onMouseEnter={() => setDropdowns(true)}
                  onMouseLeave={() => setDropdowns(false)}
                >
                  <Link to={item.path}>{item.title}</Link>
                  {dropdowns && <Integrations />}
                </li>
              );
            }
            return (
              <li key={item.id} className={item.className}>
                <Link to={item.path}>{item.title}</Link>
              </li>
            );
          })}
        </ul>
    </nav>


</>
  )
        }
export default Navbar;

import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import {  } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams, useHistory } from 'react-router-dom'

const EditInsurance = () => {
  //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  // //**the history object to navigate the user to a different route by calling  return history.push('/Insurance'); */
  const history = useHistory();
  //**here will get the insuranceid from the url by using the useParams hook */
  const {insuranceid}=useParams();   
   // will update all the value for all input fields of this form here 
   const [insurance,setInsurance]=useState(
    {
      policyno: "",
    vehicleno:"",
    policyholder:"",
    effectivedate:"",
    expirydate: "",
    insurancecompany:"",
    lastcost:"",
    presentcost:"",
    status:"" 
     }
       );
    //  onchange function for all input fields 
       const handleEdit = (e)=>{
        setInsurance({...insurance,[e.target.name]:e.target.value})
      } 
      //This is onsubmit function for this form    
      const handleSubmit = async(e)=>{
        e.preventDefault();
        
 try {
   //here we are passing file and data so we have created the new data object and then 
      //we pass the values and file inside it using JSON.stringify
    const data = new FormData();
    data.append("document", JSON.stringify(insurance));
    data.append("avatar",file);
    
    const response = await axios.post(`/InsuranceUpdate/${insuranceid}`,data,{
      //to authorize the api send the token header
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      }
    }); 
    //**when the status is 200 will redirect to listview page */
    if (response.status === 200) {
      setSuccess(true);
      setError(false);
      return history.push('/Insurance');
    }
  } catch (err) {
    setSuccess(false);
    setError(true);
  }     
   //**it will set the input field value to empty field */
    setInsurance(
        {
          policyno: "",
          vehicleno:"",
          policyholder:"",
          effectivedate:"",
          expirydate: "",
          insurancecompany:"",
           });  
      }
     //**when we submit the form to get success or error message */
  const [success, setSuccess] = useState(false);
  //**to display input field error message */
  const [error, setError] = useState(false);
 // Update the vehicle no here
      const[getVehicle,setGetVehicle]=useState([]);
//** when page will load or refresh vehicle no will load and specific insurance details will load */
       useEffect(()=>{
        eachinsurance();
        loadVehicle();
        },[]);
  //** here will get the all vehicleno  */
        const loadVehicle = async ()=>{
          const res = await axios.get(`/RcGetvehicle`, {
            //to authorize the api send the token header
            headers: { 
              Authorization: `Bearer ${token}`,
            },
          });
          const newProduct = await res.data;
          console.log(newProduct)
          setGetVehicle(newProduct)
         
          }
          // willget the token from  sessionStorage
        const token = sessionStorage.getItem('jwtToken');
        //** will get specific insurance details according to insuranceid  */
        const eachinsurance = async ()=>{
            const res = await axios.get(`/Vehicle/Insurance/${insuranceid}`, {
              headers: { 
                Authorization: `Bearer ${token}`,
              },
            });
            const newProduct = await res.data[0];
            setInsurance(newProduct)
           
            }
       //to upload the file decleared the useState
       const[file,setFile]=useState("");
        const setimgfile=(e)=>{
             setFile(e.target.files[0])
             console.log(e.target.files[0])
            }

  
    return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
       <div className="">
         <form onSubmit={handleSubmit } >
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting insurance details. Please try again later.</div>}
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="policyno" className="input-labelemployee">
                      Policy Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="policyno"
                        id="policyno"
                        placeholder="Policy Number"
                        value={insurance.policyno}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                     
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="vehicleno" className="input-labelemployee">
                    Vehicle No
                      </label>
                      <div className="inputerroremployee">
                      <select
                        type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                        
                        placeholder="Registration number"
                        value={insurance.vehicleno}
                        onChange={(e)=>{handleEdit(e)}}
                      >
                       {getVehicle.map(vehicle => (
                    <option value={vehicle.vehicleno} key={vehicle.id} >{vehicle.vehicleno}</option>

                  ))
                  }
                  </select>
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="policyholder" className="input-labelemployee">
                      Policy Holder
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="policyholder"
                      id="policyholder"
                      placeholder="Policy Holder Name"
                      value={insurance.policyholder}
                      onChange={(e)=>{handleEdit(e)}}
                    /> 
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor=" effectivedate" className="input-labelemployee">
                      Issue Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="effectivedate"
                        id="effectivedate"
                        placeholder="Start Of Cover"
                        value={insurance.effectivedate}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="expirydate" className="input-labelemployee">
                    Expiry Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="expirydate"
                        id="expirydate"
                        placeholder="End Of Cover"
                        value={insurance.expirydate}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="insurancecompany" className="input-labelemployee">
                    Insurance Company
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="insurancecompany"
                        id="insurancecompany"
                        placeholder="Insurance Taken From"
                        value={insurance.insurancecompany}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="lastcost" className="input-labelemployee">
                    Last Insurance Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="lastcost"
                        id="lastcost"
                        placeholder="Last Insurance Cost"
                        value={insurance.lastcost}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="presentcost" className="input-labelemployee">
                    Present Insurance Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="presentcost"
                        id="presentcost"
                        placeholder="Present Insurance Cost"
                        value={insurance.presentcost}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                    </div>
                    </div>
                    </div>
                    {/* added a new field called status */}
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="status" className="input-labelemployee">
                   Status
                      </label>
                      <div className="inputerroremployee">
                      <select
                      
                      type="text"
                        autoComplete="off"
                        name="status"
                        id="status"
                        onChange={(e)=>{handleEdit(e)}}
                        value={insurance.status}
                      ><option>active</option>
                      <option>inactive</option>
                      </select>
                      </div>
                    </div>
                    
                    <div className="input-blockemployee">
                      <label htmlFor="Image" className="input-labelemployee" >Insurance Certificate</label>
                      <div className="inputerroremployee">
                      <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""
                          onChange={setimgfile}
                           />
                           </div>
                     </div> 
                      </div>   
                    <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>
                  </form>
                  </div>     
        </>
      )
}

export default EditInsurance



import React, { useState,useEffect } from 'react'
import { Link,NavLink, useParams, useHistory } from 'react-router-dom'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import {GrEdit} from 'react-icons/gr';
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";

const EachSupplier = () => {
    const history =useHistory();
    const {supplierid}=useParams();
    const {iduser}=useParams();
    

    const[supplieredit, setSupplieredit]=useState(
      { companyname:"", 
      name: "", 
      email: "",
      phoneno: "", 
      primarycontact:"",
      remarks:"",
      status:""
  });
  const[gstedit, setGstedit]=useState({
    gst:"",
    sourceofsupply:"",
    currency:"",
    payment:"",
    price:"",
    tds:"",
  })

  const[billingAddsressedit, setBillingAddsressedit]=useState({
    address: "",
    phoneno: "", 
    attention:"",
    country:"",
    city:"",
    state:"",
    zipcode:"",
  })

  const[shippindAddsressedit, setShippindAddsressedit]=useState({
    addresss:"",
    attentions:"",
    countrys:"",
    citys:"",
    states:"",
    zipcodes:"",
    phonenos:""
  })
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
    editUser()
     getGst()
    getBilling()
    getShipping()
    getUser()
    },[]);
    const token = sessionStorage.getItem('jwtToken');
const editUser = async ()=>{
    const res = await axios.get(`/SupplierGet/${supplierid}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data;
    console.log(newProduct)
    setSupplieredit(newProduct)
   
   //console.log(newProduct)
    }
    const getGst = async ()=>{
      const res = await axios.get(`/Gst/${supplierid}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      const newGst = await res.data;
     setGstedit(newGst)
     
     //console.log(newGst)
      }
      const  getBilling = async ()=>{
        const res = await axios.get(`/Billingaddress/${supplierid}`, {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        const newBilling = await res.data;
        setBillingAddsressedit(newBilling)
       
       //console.log(newBilling)
        }
       
        const  getShipping = async ()=>{
          const res = await axios.get(`/Shippingaddress/${supplierid}`, {
            headers: { 
              Authorization: `Bearer ${token}`,
            },
          });
          const newBilling = await res.data;
          setShippindAddsressedit(newBilling)
         
         //console.log(newBilling)
          }
         
          const  getUser = async ()=>{
            const res = await axios.get(`/User/${supplierid}`, {
              headers: { 
                Authorization: `Bearer ${token}`,
              },
            });
            const newuser = await res.data;
            setDatauser(newuser)
           //console.log(newuser)
            }

  // const handelEdit = (e)=>{
  //   setSupplieredit({...supplieredit,[e.target.name]:e.target.value})
  //   setGstedit({...gstedit,[e.target.name]:e.target.value})
  //   setBillingAddsressedit({...billingAddsressedit,[e.target.name]:e.target.value})
  //   setShippindAddsressedit({...shippindAddsressedit,[e.target.name]:e.target.value})
    
  // }

  
//   const handleSubmit = async(e)=>{
//     e.preventDefault();
//     const response = await axios.post(`/SupplierUpdate/${supplierid}`,supplieredit,);
//     const res = await axios.post(`/GstUpdate/${supplierid}`,gstedit);
//     const resp = await axios.post(`/BillingAddressUpdate/${supplierid}`,billingAddsressedit);
//     const respo = await axios.post(`/ShippingAddressUpdate/${supplierid}`,shippindAddsressedit);
   
//    const respon = await axios.post(`/UserUpdate/${supplierid}`,datauser);
// //    const data = new FormData();
// //    data.append("datauser",JSON.stringify(datauser));
// // const respon = await axios.post(`/UserUpdate/${supplierid}`,data,{
// // "Content-Type": "multipart/form-data",
// // },);
//    console.log(respon)
//    alert('Updated Successfully');
//   }


    const[showtab, setShowtab]= useState(1);

    const handletab = (e)=>{
     setShowtab(e);
       }
    
    
  const[datauser,setDatauser]= useState([{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);

    // const adduser =()=>{
    //  setDatauser([...datauser,{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);
    // }

    //  const onChange =(e, index)=>{
    //  const updateUser = datauser.map((user,i)=>
    //  index === i
    //  ? Object.assign(user,{[e.target.name]:e.target.value}):user
    //  );
    // setDatauser(updateUser);
    //  }
    // const removeUser =(index)=>{
    // const filterUser =[...datauser];
    // filterUser.splice(index,1);
    // setDatauser(filterUser);
    
    // }
      

  return (
    <div>
      <Helmet> 
        <title>Supplier Details|BBS Inventory Management</title>
      </Helmet>
 <div className='editbu'>
    <button className='input-bu'><NavLink to={`/purechases/eachsupplier/${supplierid}/edit`}><GrEdit/></NavLink></button>
       </div>
<form >
   
       <div className="input-container">        
       <div className="input-blockerror">
                    <label htmlFor="name" className="input-label">
                     Company Name
                    </label>
                   <div className="input-error">
                   <input
                      type="text"
                      autoComplete="off"
                      name="companyname"
                      id="companyname"
                      placeholder="Company Name"
                      value={supplieredit.companyname}
                    disabled
                    />
                   </div>
                  </div>
                  
                  <div className="input-blockerror">
                    <label htmlFor="name" className="input-label">
                    Supplier Name
                    </label>
                    <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={supplieredit.name}
                      disabled
                    />
                   </div>
                  </div></div>
                  <div className="input-container">
                  <div className="input-blockerror">
                    <label htmlFor="id" className="input-label">
                    Supplier  Email
                    </label>
                    <div className="input-error">
                    <input
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={supplieredit.email}
                      disabled
                    />
                   </div>
                  </div>
                  <div className="input-blockerror">
                    <label htmlFor="phoneno" className="input-label">
                    Supplier Phone
                    </label>
                    <div className="input-error">
                    <input
                      type="number"
                      autoComplete="off"
                      name="phoneno"
                      id="phoneno"
                      placeholder="Phone"
                      value={supplieredit.phoneno}
                      disabled
                    />
                   </div>
                  </div></div>
                 
                  <div className="input-container">
                 
                  <div className="input-block">
                  <label htmlFor="primarycontact" className="input-label">
                  Primary Contact
                    </label>
                    <div className="input-error">
                  <input
                 
                      type="text"
                      autoComplete="off"
                      name="primarycontact"
                      id="primarycontact"
                      placeholder="Primary Contact"
                      value={supplieredit.primarycontact}
                      disabled
                    />
                  </div>
                  </div>
                  <div className="input-block">
                  <label htmlFor="paymentterms" className="input-label">
                    Status
                    </label>
                    <input
                     className="list"
                      type="text"
                      autoComplete="off"
                      name="status"
                      id="status"
                      value={supplieredit.status}
                      disabled
                      
                    />
                      
              
                   
                  </div>
                  </div>

                
                
                  <div className="navigation2">
                  {/* <button  type="button" className= {showtab===1? "button-link active": "button-link" }  onClick={()=>handletab(1)}>Other Details</button> */}
                  <button  type="button" className= {showtab===1? "button-link active": "button-link" }  onClick={()=>handletab(1)}>Address</button>
                  <button  type="button" className= {showtab===3? "button-link active": "button-link" }  onClick={()=>handletab(3)}>Contact Persons</button>
                  {/* <button  type="button" className= {showtab===4? "button-link active": "button-link" }  onClick={()=>handletab(4)}>Custom Fields</button>
                  <button  type="button" className={showtab===5? "button-link active": "button-link" }  onClick={()=>handletab(5)}>Reporting Tags</button> */}
                  <button  type="button" className={showtab===6? "button-link active": "button-link" }  onClick={()=>handletab(6)}>Additional Information</button>
                  </div>

                  {/* <div  className= { showtab===1 ? "tab-pane show active":"tab-pane fade "} > 
                  <div className="input-container">        
                  <div className="input-block">
                    <label htmlFor="name" className="input-label">
                    GST Treatment
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="gst"
                      id="gst"
                      value={gstedit.gst}
                      onChange={(e)=>{handelEdit(e)}}
                    >
                      <option>Registered Business-Regular</option>
                      <option>Registered Business-Composition</option>
                       <option>Unregistered Business</option>
                       <option>Overseas</option>
                       <option>Special Economic Zone</option>
                       <option>Deemed Export</option>
              
                    </select>
                    
                   
                  </div>
                  
                  <div className="input-block">
                    <label htmlFor="name" className="input-label">
                    Source Of Supply
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="sourceofsupply"
                      id="sourceofsupply"
                      value={gstedit.sourceofsupply}
                      onChange={(e)=>{handelEdit(e)}}
                      
                    ><option></option>
                    <option>[AD] - Andhra Pradesh </option>
                   <option>[AR] - Arunachal Pradesh</option>
                   <option>[AS] - Assam</option>
                   <option>[BR] - Bihar</option>
                   <option>[CH] - Chandigarh</option>
                   <option>[CG] - Chhattisgarh</option>
                   <option>[DN] - Dadra and Nagar Haveli</option>
                   <option>[DD] - Daman and Diu</option>
                   <option>[DL] - Delhi</option>
                   <option>[GA] - Goa</option>
                   <option>[GJ] - Gujarat</option>
                   <option>[HR] - Haryana</option>
                   <option>[HP] - Himachal Pradesh </option>
                   <option>[JK] - Jammu and Kashmir</option>
                   <option>[JH] - Jharkhand</option>
                   <option>[KA] - Karnataka </option>
                   <option>[KL] - Kerala</option>
                   <option>[LA] - Ladakh</option>
                   <option>[LD] - Lakshadweep</option>
                   <option>[MP] - Madhya Pradesh</option>
                   <option>[MH] - Maharashtra</option>
                   <option>[MN] - Manipur</option>
                   <option>[ML] - Meghalaya</option>
                   <option>[MZ] - Mizoram</option>
                   <option>[NL] - Nagaland</option>
                   <option>[OD] - Odisha</option>
                   <option>[PY] - Puducherry</option>
                   <option>[PB] - Punjab</option>
                   <option>[RJ] - Rajasthan</option>
                   <option>[SK] - Sikkim</option>
                   <option>[TN] - Tamil Nadu</option>
                   <option>[TS] - Telangana</option>
                   <option>[TR] - Tripura</option>
                   <option>[UP] - Uttar Pradesh</option>
                   <option>[UK] - Uttarakhand </option>
                   <option>[WB] - West Bengal</option>
                   <option>[OT] - Other Territory</option>
                   
                         </select>
                   
                  </div></div>
                  <div className="input-container">
                  <div className="input-block">
                    <label htmlFor="id" className="input-label">
                    Currency
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="currency"
                      id="currency"
                      value={gstedit.currency}
                      onChange={(e)=>{handelEdit(e)}}
                      
                      
                    ><option>Indian Rupee</option>
                    <option>Croatain Kuna</option>
                   <option>Lempira</option>
                   <option>Gourde</option>
                   <option>Forint</option>
                   <option>New Israeli Sheqel</option>
                   
                         </select>
                   
                  </div>
                  <div className="input-block">
                    <label htmlFor="payment" className="input-label">
                    Payment Terms
                    </label>
                    <select
                    className="list"
                      type="number"
                      autoComplete="off"
                      name="payment"
                      id="payment"
                      placeholder="payment"
                      value={gstedit.payment}
                      onChange={(e)=>{handelEdit(e)}}
                    ><option>Net 45 </option>
                    <option>Net 15</option>
                   <option>Net 60</option>
                   <option>Due end of the month</option>
                   <option>Due on Receipt</option>
                   
                         </select>
                    
                  </div></div>
                
                  <div className="input-container">
                  <div className="input-block">
                    <label htmlFor="Price" className="input-label">
                    Price List
                    </label>
                    <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="price"
                      id="price"
                      placeholder="price"
                      value={gstedit.price}
                      onChange={(e)=>{handelEdit(e)}}
                    ><option></option>
                    <option>Pricebook2[82% Markup]</option>
                   <option>Pricebook3[76% Markup]</option>
                   <option>Pricebook4[92% Markup]</option>
                  
                         </select>
                    
                  </div>
                  <div className="input-block">
                    <label htmlFor="tds" className="input-label">
                    TDS
                    </label>
                    <select
                    className="list"
                      type="number"
                      autoComplete="off"
                      name="tds"
                      id="tds"
                      placeholder="tds"
                      value={gstedit.tds}
                      onChange={(e)=>{handelEdit(e)}}
                    ><option>Select a Tax</option>
                    <option>sqqwq</option>
                   <option>adaF</option>
                   <option>adsz</option>
                   <option>sfdszdv</option>
                   <option>dhdx</option>
                   <option>gdg</option>
                         </select>
                    
                  </div>
                  </div>
                  </div> */}
                 <div className= { showtab===1 ? "tab-pane show active": "tab-pane fade"}>
               <div className="address">
              <div className="addr">
                 <p className="billing">ADDRESS OF SUPPLIER</p>
               <div className="input-block">
                    <label htmlFor="attention" className="input-label">
                    Attention
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="attention"
                      id="attention"
                      placeholder="Attention"
                      value={billingAddsressedit.attention}
                      disabled
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="Country" className="input-label">
                    Country
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="country"
                      id="country"
                      placeholder="Country"
                      value={billingAddsressedit.country}
                      disabled
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="address" className="input-label">
                    Supplier Address
                    </label>
                    <textarea
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={billingAddsressedit.address}
                      disabled
                    />
                    
                    
                  </div>
                    <div className="input-block">
                    <label htmlFor="city" className="input-label">
                    City
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="city"
                      id="city"
                      placeholder="City"
                      value={billingAddsressedit.city}
                      disabled
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="state" className="input-label">
                    State
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="state"
                      id="state"
                      placeholder="State"
                      value={billingAddsressedit.state}
                      disabled
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="zipcode" className="input-label">
                    Zip Code
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="zipcode"
                      id="zipcode"
                      placeholder="Zipcode"
                      value={billingAddsressedit.zipcode}
                      disabled
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="phoneno" className="input-label">
                   Phoneno
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="phoneno"
                      id="phoneno"
                      placeholder="Phoneno"
                      value={billingAddsressedit.phoneno}
                      disabled
                    />
                    </div>
                   </div>
                    {/* <div className="addr">
                    <p className="billing">SHIPPING ADDRESS </p>
                    <div className="input-block">
                    <label htmlFor="attentions" className="input-label">
                    Attention
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="attentions"
                      id="attentions"
                      placeholder="Attention"
                      value={shippindAddsressedit.attentions}
                      onChange={(e)=>{handelEdit(e)}}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="Countrys" className="input-label">
                    Country
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="countrys"
                      id="countrys"
                      placeholder="Country"
                      value={shippindAddsressedit.countrys}
                      onChange={(e)=>{handelEdit(e)}}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="address" className="input-label">
                    Supplier Address
                    </label>
                    <textarea
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="addresss"
                      id="addresss"
                      placeholder="Address"
                      value={shippindAddsressedit.addresss}
                      onChange={(e)=>{handelEdit(e)}}
                    />
                    
                   
                  </div>
                    <div className="input-block">
                    <label htmlFor="city" className="input-label">
                    City
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="citys"
                      id="citys"
                      placeholder="City"
                      value={shippindAddsressedit.citys}
                      onChange={(e)=>{handelEdit(e)}}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="state" className="input-label">
                    State
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="states"
                      id="states"
                      placeholder="State"
                      value={shippindAddsressedit.states}
                      onChange={(e)=>{handelEdit(e)}}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="zipcode" className="input-label">
                    Zip Code
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="zipcodes"
                      id="zipcodes"
                      placeholder="Zipcode"
                      value={shippindAddsressedit.zipcodes}
                      onChange={(e)=>{handelEdit(e)}}
                    />
                    </div>
                    <div className="input-block">
                    <label htmlFor="phoneno" className="input-label">
                   Phoneno
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="phonenos"
                      id="phonenos"
                      placeholder="Phoneno"
                      value={shippindAddsressedit.phonenos}
                      onChange={(e)=>{handelEdit(e)}}
                    />
                    </div>
                    </div>
               */}
                </div>
          </div>
          <div className= { showtab===3 ? "tab-pane show active": "tab-pane fade"}>
           
          <table id="contacttable" >
            <thead>
                <tr>
                    {/* <th>iduser</th> */}
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Address</th>
                    <th>Work Phone</th>
                    <th>Mobile</th>
                    
                </tr>
            </thead>
            <tbody>
            {
            datauser.map((user, index) => {
              return (
                <tr key={index}>
                  {/* <th>
                      <input
                    className="iduser"
                    name="iduser"
                    //onChange={(e)=> onChange(e, index)}
                    value={user.iduser}
                    />
                    </th> */}
                    <th>
                      <input
                    className="inputtable"
                    name="firstname"
                    disabled
                    value={user.firstname}
                    />
                    </th>
                     <th><input
                    className="inputtable"
                    name="lastname"
                    disabled
                    value={user.lastname}
                    /></th>
                    <th><input
                    className="inputtable"
                    name="useremail"
                   
                    value={user.useremail}
                    /></th>
                    <th><input
                    className="inputtable"
                    name="workphone"
                    disabled
                    value={user.workphone}
                    /></th>
                 <th><input
                    className="inputtable"
                    name="mobilephone"
                    disabled
                    value={user.mobilephone}
                    /></th>
                   
                    {/* <th><IoTrashOutline onClick={()=> removeUser(index)}/></th> */}
                </tr>
               )
            })
          }
             
            </tbody>
            
            </table>
           {/* <div className="link"> <Link to="#" className="addlink"  onClick={adduser}>Add Contact Person</Link></div> */}
          </div>
          {/* <div className= { showtab===4 ? "tab-pane show active": "tab-pane fade"}>
               <p>Tab 3 Content</p>
          </div>
          <div className= { showtab===5 ? "tab-pane show active": "tab-pane fade"}>
               <p>Tab 4 Content</p>
          </div> */}
          <div className= { showtab===6 ? "tab-pane show active": "tab-pane fade"}>
         <div className="billing">
          <div className="input-block">
                    <label htmlFor="remarks" className="input-label">
                    Additional Information
                    </label>
                    <textarea
                    rows="4" cols="50"
                      type="text"
                      autoComplete="off"
                      name="remarks"
                      id="remarks"
                      placeholder="remarks"
                      disabled
                    />
                    </div></div>
          </div>
                  
                 
                </form>
    </div>
  )
}

export default EachSupplier
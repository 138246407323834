import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import {  } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams, useHistory } from 'react-router-dom'   
import {GrEdit} from 'react-icons/gr';
    const EachRc = () => {
        const {rcid}=useParams();
      
      const[getRc, setGetRc]= useState(
         {
  
            registrationnumber: "",
            registrationdate:"",
             ownername:"",
             regupto:"",
             fuel: "",
             taxupto:"",
              class:"",
              model:"",
              status:""
             }
      );

      useEffect(()=>{
       eachRc()
        
        },[]);
        const token = sessionStorage.getItem('jwtToken');
        const eachRc = async ()=>{
            const res = await axios.get(`/Vehicle/Rc/${rcid}`, {
              headers: { 
                Authorization: `Bearer ${token}`,
              },
            });
            const newProduct = await res.data[0];
            setGetRc(newProduct)
           
            }
       
      return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
        <div className='editbu'>
    <button className='input-bu'><NavLink to={`/Vehicle/Rc/${rcid}/edit`}><GrEdit/></NavLink></button>
       </div>
        <div className="">
          
         <form >
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="registrationnumber" className="input-labelemployee">
                      Registration Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="registrationnumber"
                        id="registrationnumber"
                       
                        placeholder="Registration Number"
                        value={getRc.registrationnumber}
                        disabled
                      />
                     
                      </div>
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="vehicleno" className="input-labelemployee">
                     Vehicle No
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                        placeholder="vehicleno"
                        value={getRc.vehicleno}
                       disabled
                      />
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer">
                    <div className="input-blockemployee">
                    <label htmlFor="registrationdate" className="input-labelemployee">
                    Registration Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="registrationdate"
                        id="registrationdate"
                       
                        placeholder="Registration Date"
                        value={getRc.registrationdate}
                        disabled
                      />
                      
                    </div>
                    </div> 
                    <div className="input-blockemployee">
                      <label htmlFor="ownername" className="input-labelemployee">
                      Owner Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="ownername"
                      id="ownername"
                      placeholder="Owner Name"
                      value={getRc.ownername}
                      disabled
                    /> 
                    </div>
  
                    </div>
                    {/* <div className="input-blockemployee">
                      <label htmlFor="regupto" className="input-labelemployee">
                      REG/FC Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="regupto"
                        id="regupto"
                        placeholder="REG/FC Upto"
                        value={getRc.regupto}
                        disabled
                      />
                      </div>
                    </div> */}
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="fuel" className="input-labelemployee">
                   Fuel
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="fuel"
                        id="fuel"
                        placeholder="Fuel"
                        value={getRc.fuel}
                        disabled
                      />
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="taxupto" className="input-labelemployee">
                    Tax Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="taxupto"
                        id="taxupto"
                        placeholder="Tax Upto"
                        value={getRc.taxupto}
                        disabled
                      />
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="class" className="input-labelemployee">
                      Type Of Vehicle
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name=""
                        id="class"
                        placeholder="Type Of Vehicle"
                        value={getRc.class}
                        disabled
                      />
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="model" className="input-labelemployee">
                     Model
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                        type="text"
                        autoComplete="off"
                        name="model"
                        id="model"
                        placeholder="Model"
                        value={getRc.model}
                        disabled
                        />
                    </div>
                    </div>
                    </div>         
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="status" className="input-labelemployee">
                     Status
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="status"
                        id="status"
                        placeholder="status"
                        value={getRc.status}
                       disabled
                      />
                      </div>
                    </div>
                    </div>
                   
                  </form>
                  </div>     
        </>
      )
    }
    
    export default EachRc ;
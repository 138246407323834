import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from './Homenavbar/Navbar';
import Customers from './HomeItems/Customers';
import Features from './HomeItems/Features';
import { NavLink } from 'react-router-dom';
import {AiOutlineSearch} from 'react-icons/ai'
import Integrations from './HomeItems/Integrations';
import More from './HomeItems/More';
import Pricing from './HomeItems/Pricing';
import Resources from './HomeItems/Resources';
import '../../App.css';
import bbslogo from '../../components/Images/BBS_LOGO.png';

import zapotec_logo from '../../components/Images/zapotec_logo.png';


const Mainhome = () => {
  return (

<>
<div className='homemainone'>
   
      <div className='modal-containerhome'>
      <div className="headerbox">
      <div className="navbuttons">

<div className="navone">
<AiOutlineSearch className='aisearch'/>
<NavLink to='/Signin' className='navbuttonone'><button className="navbuttonone" >SIGN IN</button></NavLink>
</div>
<div className="navtwo">

   <NavLink to='/Login' className='navbuttontwo'><button className="navbuttontwo" >SIGN UP NOW</button></NavLink>  
   </div>
   </div>
     </div>
     <div className="modal-containerhometwo">
      <div className="containerhome">
      <nav>

   
   <br></br>
   <div className="bbslogo">
   <img src={zapotec_logo}style={{width:"250px",height:"150px"}}/><p style={{fontSize:"20px",fontWeight:"600"}} className="inventory">Inventory</p>
  {/* <img src={bbslogo}style={{width:"40px",height:"24px"}}/><p style={{fontSize:"20px",fontWeight:"600"}} className="inventory">Inventory</p> */}
</div>
   
  
   <div className="navlinktop"></div>
      
        <Navbar />
        
          
          
        
     
      </nav>
   <div className="main-content">
    <p style={{fontSize:"45px"}}>Inventory management software </p><br/>
      <p style={{
        fontSize:"20px",
    width: "75%",
    margin: "auto",
    padding: "1% 0 2%",
    marginLeft:"39px"
}}>Manage orders. Track inventory. Handle GST billing. Oversee <br></br>warehouses.
 One inventory management software to run all<br></br>
 your inventory operations.</p>
 <div className="buttonsdemo">
 <button className="demo">SIGN UP </button>
 <button className="signbutton">EXPLORE DEMO ACCOUNT</button>
</div>
    
   </div>
   
    
    



</div>
    </div>
     </div>
     </div>

    </>

    
  )
}

export default Mainhome
import React from "react";
import { NavLink } from "react-router-dom";
import { FcMenu } from "react-icons/fc";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import GetSupply from "./GetSupply";
import PoReport from "./poReport";
import RequisitionReport from "./RequisitionReport";

export const Supply = () => {
  return (
    <div>
      {" "}
      <Sidebar />
      <div className="home">
        <Helmet>
          <title>All Supply|BBS Inventory</title>
        </Helmet>
        <div className="modal-container ">
          <div className="title">
            <h3> Supply </h3>
            <div className="title1"></div>
          </div>
          <div className="container">
            <GetSupply />
          </div>
        </div>
      </div>
    </div>
  );
};
export const Report = () => {
  return (
    <div>
      {" "}
      <Sidebar />
      <div className="home">
        <Helmet>
          <title>All PO Report|BBS Inventory</title>
        </Helmet>
        <div className="modal-container ">
          <div className="title">
            <h3> Purchase Order Report </h3>
            <div className="title1"></div>
          </div>
          <div className="container">
            <PoReport />
          </div>
        </div>
      </div>
    </div>
  );
};
export const RoReport = () => {
  return (
    <div>
      {" "}
      <Sidebar />
      <div className="home">
        <Helmet>
          <title>All RoO Report|BBS Inventory</title>
        </Helmet>
        <div className="modal-container ">
          <div className="title">
            <h3> Requisition Order Report </h3>
            <div className="title1"></div>
          </div>
          <div className="container">
           <RequisitionReport/>
          </div>
        </div>
      </div>
    </div>
  );
};
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Schema } from "../../../helper/schemas";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";


// this function will handel the form inputs,submitting form and all the operations.
const EditRequisation = () => {
  const history = useHistory();
  const { requisitionId } = useParams();
  const [requisitionedit, setRequisition] = useState(
    {
      empId: "",
      reference: "",
      requisitiondate: "",
      customernotes: "",
      termsconditions: "",
      requisitionstage: "",
    });
  //created object for dynamic table
    const itemTemp = {
      Id: "",    // Set to appropriate default value or empty string
      Name: "",  // Set to appropriate default value or empty string
      requestQuantity: "",
      vehicleno: "",
      others: "",
      unit: ""
    };
  const [emailSent, setEmailSent] = useState(false);
  //add the new row to item dynamic table
  const addItemrow = () => {
    setAddInventory([...addInventory, {...itemTemp}]);
  }
  

  //delete row of dynamic table
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    // setDatas(updateUsers);
    setAddInventory(updateUsers);
  };
 
  //onchange function
  const handleChange = (e) => {
    setRequisition({ ...requisitionedit, [e.target.name]: e.target.value })

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
      try {
        const isAnyQuantityZero = addInventory.some(
          (user) => Number(user.requestQuantity) === 0
        );
        const isAnyQuantityEmpty = addInventory.some(
          (user) => user.requestQuantity === ""
        );
        if (isAnyQuantityZero || isAnyQuantityEmpty) {
          alert("Quantity	 field cannot be empty or zero.");
          return;
        }
        const isunit = addInventory.some(
          (user) => user.unit === ""
        );
        if (isunit) {
          alert("Please select a Requested unit for all items.");
          return;
        }
        const allItemsHaveValues = addInventory.every(
          (user) => user.vehicleno !== "" || user.others !== ""
        );
        
        if (!allItemsHaveValues) {
          alert("Please select a vehicle No and provide 'Others' for all items.");
          return;
        }
        const data = new FormData();

        data.append("document", JSON.stringify(requisitionedit));
        data.append("addInventory", JSON.stringify(addInventory));

        data.append("avatar", file);
        const response = await axios.post(`/requisition/updaterequisition/${requisitionId}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          const requisitionId= requisitionedit.idforrequisition;
          alert(`Requisition ID updated: ${requisitionId}`);
          return history.push("/requisition");
        }
        
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
     
      setFile("");
      //  setDatas([{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);
    }
  const [file, setFile] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [getrowdata, setGetrowdata] = useState([]);
  // const [item, setItem] = useState([]);
  const [addInventory, setAddInventory] = useState([itemTemp]);
  const [selectedName, setSelectedName] = useState("");
  const [employee, setEmployee] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [quantity, setQuantity] = useState([0]);
  const setimgfile = (e) => {
    setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
  };
  const handleOrder = async () => {
    // ... (unchanged code)
    const data = new FormData();
    data.append("document", JSON.stringify(requisitionedit));
    data.append("addInventory", JSON.stringify(addInventory));
    console.log(JSON.stringify(requisitionedit))
    console.log(JSON.stringify(addInventory))
    // Access the requisitionId from the requisition state
    const orderId = requisitionId; 
    let dynamicTableContent = '';
    addInventory.forEach((user, index) => {
      dynamicTableContent += `
        <tr>
          <th>${index + 1}</th>
          <th>${user.Name}</th>
          <th>${user.requestQuantity}</th>
          <th>${user.vehicleno}</th>
        <tr>
      `;
    });
    const approveLink = `https://agminfra18.com/`;
    //Generate the approve and reject links with different query parameters
    const config = {
      //SecureToken:'568ed713-d3ef-4571-9f00-0d53a31083ff',
      SecureToken:'109e681e-7843-4090-ad32-c226d430dc19',
      //To: 'jyotiprava.rini@gmail.com',
      To: 'agminfra.angul@gmail.com',
      From:'agminfra.angul@gmail.com',
      Subject: 'Requisition for your approval',
      Body: `
      <h4>Please review the requisition with RequisitionID: ${orderId} </h4>
      <p style="color:green;">Waiting for your approval</p>
      <p>Login to the application to Approve 
      <button style="background-color: #FF3333; color: white; border-radius: 5px;">
        <a href="${approveLink}" style="text-decoration: none; color: white;">Login</a>
      </button>
      </p>
      <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
      <tr style="border: 1px solid black;">
       <th style="border: 1px solid black;">Requisitioner Name</th>
       <th style="border: 1px solid black;">Requisition Number</th>
       <th style="border: 1px solid black;">Requisition Date</th>
      </tr>
      <tr style="border: 1px solid black;">
        <th style="border: 1px solid black;">${requisitionedit.empId}</td>
        <th style="border: 1px solid black;">${requisitionedit.reference}</td>
        <th style="border: 1px solid black;">${requisitionedit.requisitiondate}</td>
      </tr>
    </table>
    <br>
    <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
    <tr style="border: 1px solid black;">
      <th style="border: 1px solid black;">Index</th>
      <th style="border: 1px solid black;">ItemName</th>
      <th style="border: 1px solid black;">Quantity Requested</th>
      <th style="border: 1px solid black;">Vehicle</th>
    </tr>
    ${dynamicTableContent}
  </table>
      `
    }; 
  
    try {
      // Send the email
      if (!emailSent && requisitionId !== null && window.Email){
        await window.Email.send(config);
        console.log('Email sent successfully');
       // alert('We will contact you shortly!');
      } else {
        throw new Error('Email service is not available.');
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  //get all employee details from backend 
  const loadEmployee = async (supplier) => {

    const res = await axios.get("/EmployeeGet", {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    setEmployee(res.data);

  }
  //getting the data from backend according to the purchase id
  const getRequisition = async () => {
    const res = await axios.get(`/requisition/${requisitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setRequisition(newProduct)

  }
  //getting the dyanmic table price list data from backend according to the purchase id      
  const getitemdetails = async () => {
    const res = await axios.get(`/requisitionitem/${requisitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newusers = await res.data;
    setAddInventory(newusers)
    //setItemList(newusers)
  }
  
  useEffect(() => {
    if (requisitionId !== null) {
      //handleOrder();
      getitemdetails();
      getRequisition();
      loadEmployee();
    }
  }, [requisitionId]);

  //display all the employee
  const getEmployee = async () => {
    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee);
    //console.log(newusers)
  };
  //display all the Supplier in table
  const loadSupplier = async (supplier) => {
    const res = await axios.get("/SupplierGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSupplier(res.data);
    // console.log(res.data);
  };

  //here rk has written
  const loadAllItems = async (data) => {
    const res = await axios.get("/itemwithsupplier", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
     console.log(res.data);
    setItemList(res.data);
  };

  //here rk has written
  const loadVehicleno = async (data) => {
    const res = await axios.get("/vehicleno", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    //console.log(res.data);
    setVehicleList(res.data);
  };

  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/inventory/items");
    }
  }, []);

 

  useEffect(() => {
    getEmployee();
    loadSupplier();
    loadAll();
    loadAllItems();
    loadVehicleno();
  }, []);

  const token = sessionStorage.getItem("jwtToken");
  const [gettoken, setToken] = useState();
  const loadAll = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;
      setToken(UserLogedin);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  ////////////////
  
 
  // const replacesupplier = async (e, index, type, supplierid) => {
  //   const res = await axios.get(`/SupplierGet/${e.target.value}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  
  //   let newArrs = [...addInventory];
  //   newArrs[index] = {
  //     ...newArrs[index],
  //     name: res.data.name,
  //     supplierid: res.data.supplierid,
  //   };
  
  //   if (!(type == "Select Item")) {
  //     setAddInventory(newArrs);
  //   } else {
  //     setAddInventory([
  //       ...newArrs,
  //       {
  //         name: "Select Item",
  //         supplierid: "",
  //       },
  //     ]);
  //   }
  
  //   // Call loadAllItems function with the supplierid
  //   loadAllItems(newArrs[index].supplierid);
  // };
  // const loadAllItems = async (supplierid) => {
  //   if (supplierid) {
  //     const res = await axios.get(`/itemgetbySupplier/${supplierid}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  
  //     console.log(res.data);
  //     setItemList(res.data);
  //   } else {
  //     setItemList([]);
  //   }
  // };
  
  const replaceData = async (e, index, type) => {
    const res = await axios.get(`/req/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
console.log(res.data)
    let newArr = [...addInventory];
    newArr[index] = {
      Id: res.data[0].Id,
      Name: res.data[0].Name,
      requestQuantity:0,
      vehicleno: "",
      others:"",
      unit:""
    };
    if (!(type == "Select Item")) {
      setAddInventory(newArr);
      // setDatas(newArr);
      console.log(addInventory);
    } else {
      setAddInventory([
        ...newArr,
        {
          Id:"",
          Name: "Select Item",
          requestQuantity:"",
          vehicleno: "",
          others:"",
          unit:""
        },
      ]);
    }
  };
    // Function to reset the form to its initial state
    const resetForm = () => {
      setRequisition({
        empId: "",
        reference: "",
        requisitiondate: "",
        customernotes: "",
        termsconditions: "",
        requisitionstage: "",
      });
      setAddInventory([itemTemp]);
      setFile("");
      setEmailSent(false);
      setSuccess(false);
      setError(false);
      // Reset any other state variables as needed
    };
     //onchange function for dynamic table
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...addInventory];
    console.log(updateUser)
    updateUser[index] = { ...updateUser[index], [name]: value };
    setAddInventory(updateUser);
  };
  return (
    <>
      <Helmet>
        <title>New Purchase|BBS Inventory</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>
          {success && (
            <div className="form-erroremployees">
              Purchase order submitted successfully!
            </div>
          )}
          {error && (
            <div className="form-error">
              Error submitting Purchase order. Please try again later.
            </div>
          )}
          
          {/* second one */}
          <div className="top-div1">
            <div className="input-container-search">
              <label
                htmlFor="requitionername"
                className="input-label leftone"
              >
                Employee Name
              </label>
              <select
                className="list"
                type="text"
                autoComplete="off"
                name="empId"
                id="empId"
                // placeholder="Select a vendor"
                value={requisitionedit.empId}
                onChange={(e) => { handleChange(e) }}
                
              >
                <option>Select Employee</option>
                {employee.map((emp) => (
                  <option
                    value={emp.empId}
                    key={emp.id}
                  >
                    {emp.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-container1">
              <div className="input-block">
                <NavLink
                  to="/employee"
                  className="addlink"
                >
                  To add new employee
                </NavLink>
              </div>
            </div>
          </div>
          {/* REQUISITION INPUT FIELD */}
          <div className="input-container">
            {/* <div className="input-block">
              <label
                htmlFor="requisitionnumber"
                className="input-label leftone"
              >
                Requisition Number
              </label>
              <div className="input-error">
                <input
                  type="text"
                  id="reference"
                  name="reference"
                  onChange={(e) => { handleChange(e) }}
                  value={requisitionedit.reference}
                />

               
              </div>
            </div> */}
          
          {/* DATE INPUT FIELD  */}
         
            <div className="input-block">
              <label
                htmlFor="purchase date"
                className="input-label leftone"
              >
                Requisition Date
              </label>
              <div className="input-error">
                <input
                  type="date"
                  id="requisitiondate"
                  name="requisitiondate"
                  onChange={(e) => { handleChange(e) }}
                  value={requisitionedit.requisitiondate}
                />

               
              </div>
            </div>
          </div>
          {/* HERE I HAVE CHANGED  */}
          <div>
            <div>Price List </div>
            <div>
              <table id="contacttable">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}>Sl No</th>
                    {/* <th>Supplier</th> */}
                    <th style={{ width: "220px" }}>Item Name</th>
                    {/* <th style={{ width: "280px" }}>Description</th>
                    <th>Quantity On Stock </th> */}
                   <th>Quantity </th>
                    <th>Unit </th>
                    <th>Vehicle Number</th>
                    <th>Others</th>
                  </tr>
                </thead>
                <tbody>
                  {/* dynamic table */}

                  {addInventory.map((user, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        {/* <th>
                          <select
                            className="inputtable"
                            name="supplier"
                            onChange={(e) => replacesupplier(e, index, user.name)}
                            value={user.supplier}
                          >
                            <option value=" ">{user.name}</option>

                            {supplier.map((values, index) => (
                              <option
                                value={values.supplierid}
                                key={values.Id}
                              >
                                {values.name}
                              </option>
                            ))}
                          </select>
                        </th> */}
                        <th>
                        <select
                            className="inputtable"
                            name="Name"
                            onChange={(e) => replaceData(e, index, user.Name)}
                            value={user.Id}
                          >
                            <option value="">{user.Name}</option>

                            {itemList.map((values, index) => (
                              <option value={values.Id} key={values.Id}>
                                {values.Name}
                              </option>
                            ))}
                          </select>
                        </th>
 {/* <th>
                          <input
                            className="inputtable"
                            name="itemDetails"
                            onChange={(e) => onChange(e, index)}
                            value={user.itemDetails}
                          />
                           
                        </th> */}
                        {/* TO INPUT DESCRIPTION */}
                        {/* <th>
                          <input
                            className="inputtable"
                            name="Description"
                            onChange={(e) => onChange(e, index)}
                            value={user.Description}
                          />
                        </th> */}

                        {/* TO INPUT QUANTITY */}
                        {/* <th>
                          <input
                            className="inputtable"
                            name="Quantity"
                            //onChange={(e) => onChange(e, index)}
                            value={user.Quantity}
                            disabled
                          />
                        </th> */}
                        {/* TO INPUT QUANTITY */}
                        <th>
                          <input
                            className="inputtable"
                            name="requestQuantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.requestQuantity}
                          />
                        </th>
                        <th>
                            <select
                              className="inputtable"
                              name="unit"
                              onChange={(e) => onChange(e, index)}
                              value={user.unit}
                              ><option>Select the unit</option>
                              <option>Pieces</option>
                              <option>Box</option> 
                              <option>Dozen</option>
                              <option>Meter</option>
                              <option>Kg</option> 
                              <option>Liter</option>
                             <option>Gallon</option> 
                              
                            </select>
                          </th>
                        {/* to input vehicle */}
                        <th>
                          <select
                            className="inputtable"
                            name="vehicleno"
                            onChange={(e) => onChange(e, index)}
                            value={user.vehicleno}
                          >
                            <option value=" ">Select vehicle</option>

                            {vehicleList.map((values, index) => (
                              <option
                                value={values.vehicleno}
                                key={values.Id}
                              >
                                {values.vehicleno}
                              </option>
                            ))}
                          </select>
                        </th>
                        <th><input
                            className="inputtable"
                            name="others"
                            onChange={(e) => onChange(e, index)}
                            value={user.others}
                          /></th>
                        {/* TO INPUT SUPPLIER */}
                        {/* <th>
                          <select
                            className="inputtable"
                            name="supplier"
                            onChange={(e) => onChange(e, index)}
                            value={user.supplier}
                          >
                            <option value=" ">{user.supplier}</option>

                            {supplier.map((values, index) => (
                              <option
                                value={values.supplierid}
                                key={values.Id}
                              >
                                {values.name}
                              </option>
                            ))}
                          </select>
                        </th> */}

                        {/* DELETE BUTTON */}
                        <th>
                            <IoTrashOutline onClick={() => deleteRow(index)} />
                          </th>
                          <input
                            type="hidden"
                            className="inputtable"
                            name="Quantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.Quantity}
                          />
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* TO ADD A NEW A ITEM */}
              <div className="link">
                <Link
                  to="#"
                  className="addlink"
                  onClick={addItemrow}
                >
                  Add New Line
                </Link>
              </div>
              {/* <div className="link">
                <Link
                  to="/purechases/supplier"
                  className="addlink"
                >
                  Add New supplier
                </Link>
              </div> */}
              <div className="link">
                <Link
                  to="/inventory/additem"
                  className="addlink"
                >
                  Add New Item
                </Link>
              </div>
            </div>

            {/* CUSTOMER NOTES */}
            <div className="note">
              <div className="comment">
                <div className="input-block-text">
                  <label
                    htmlFor="customernotes"
                    className="input-label"
                  >
                    Customer Notes
                  </label>
                  <textarea
                    className="textareas"
                    rows="3"
                    cols="52"
                    type="text"
                    autoComplete="off"
                    name="customernotes"
                    id="customernotes"
                    placeholder="Customer notes"
                    value={requisitionedit.customernotes}
                    onChange={(e) => { handleChange(e) }}
                  
                  />
                  
                </div>
              </div>
            </div>
            <br />

            {/* TERM AND CONDITIONS */}
            <div className="top-div2">
              <div className="note">
                {/* <div>
                  <div className="input-block-text">
                    <label
                      htmlFor="termsconditions"
                      className="input-label"
                    >
                      Terms & Conditions
                    </label>
                    <textarea
                      className="textareas"
                      rows="3"
                      cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"
                      placeholder="Terms and conditions"
                      value={requisitionedit.termsconditions}
                      onChange={(e) => { handleChange(e) }}
                      
                    />
                    
                  </div>
                </div> */}

                {/* attach files */}
                <div className="purchaseorderfile">
                  <label htmlFor="Image">
                    Attach File(s) to Requisition Order
                  </label>

                  <input
                    type="file"
                    name="avatar"
                    onChange={setimgfile}
                  />
                </div>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="adjustedby"
            id="adjustedby"
            value={gettoken}
          />
          <input
            type="hidden"
            name="requisitionstage"
            value={requisitionedit.requisitionstage}
            onChange={(e) => { handleChange(e) }}
          />
          <br />
          <br />
          <br />
          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
                onClick={() => {
                  handleChange({
                    target: { name: "requisitionstage", value: "Draft" },
                  });
                }}
              >
                Save As Draft
              </button>
              <button
                className="input-button"
                type="submit"
                onClick={async () => {
                  // First, update the state to "Pending Approval"
                  handleChange({
                    target: { name: "requisitionstage", value: "Pending Approval" },
                  });
              
                  try {
                    // Call the handleOrder function here
                    await handleOrder();
              
                    // handleOrder has completed successfully
                    // If needed, you can add additional logic after the handleOrder call here
                  } catch (error) {
                    console.error(error);
                    // Handle errors if necessary
                  }
                }}
              >
               Send For Approval
              </button>
              <button
                className="input-button"
                type="button"
                onClick={resetForm} // Call the resetForm function
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditRequisation;

import axios from '../../../helper/axios'
import React, { useEffect, useState } from 'react'
import {NavLink, useHistory, useParams } from 'react-router-dom';
import {GrEdit} from 'react-icons/gr';
import Cookies from 'js-cookie';

const Editemployee = () => {
  const history = useHistory();
  const { Id } = useParams();
  // console.log("id " + Id);
  const [employeeedit, setEmployeeedit] = useState({
    Id: "",
    name: "",
    gender: "",
    email: "",
    date: "",
    password: "",
    cpassword: "",
    fathersname: "",
    maritalstatus: "",
    phoneno: "",
    address: "",
    state: "",
    pincode: "",
    jobtype: "",
    experience: "",
    department: "",
    joindate: "",
    role:""
  });
  const [gettokenId, setTokenId] = useState();
  const [gettokenRole, setTokenRole] = useState();
  const token = sessionStorage.getItem('jwtToken');

  
  useEffect(() => {
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    loadAllDetails();
    editUser();
    console.log("Role get------" + gettokenRole);
    console.log(" get------" +gettokenId);
  }, [gettokenRole, gettokenId]); // Add dependencies here
  const loadAllDetails = async () => {
    try {
      const response = await axios.post('/Login', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedinId = response.data.person[0][0].Id;
      const UserLogedinRole = response.data.person[0][0].role;
      setTokenId(UserLogedinId);
      setTokenRole(UserLogedinRole);
      //console.log("Role get------" + UserLogedinRole);
    } catch (error) {
      if (error.response.status === 403) {
        alert('Token Expired. Please login again.');
        sessionStorage.removeItem('jwtToken');
        history.push('/');
      } else if (error.response.status === 401) {
        alert('Invalid token. Please login again.');
        sessionStorage.removeItem('jwtToken');
        history.push('/');
      } else {
        alert('Error: ' + error.response.data.error);
      }
    }
  };

  const editUser = async () => {
    const res = await axios.get(`/Employeeactive/${Id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newEmployee = await res.data;
    setEmployeeedit(newEmployee);
  };
  useEffect(() => {
    console.log("Role get------" + gettokenRole);
  }, [gettokenRole]);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
    <div>
    <div className='editbutt'>
       <button className='input-butt'><NavLink to={`/inventory/activeemployee/${Id}/edit`}><GrEdit/></NavLink></button><br></br>   
       </div>
       {/* {Number(Id) === gettokenId && (
        <div className='editbutt'>
          <button className='input-butt'>
            <NavLink to={`/inventory/activeemployee/${Id}/edit`}>
              <GrEdit />
            </NavLink>
          </button>
          <br />
        </div>
      )} */}
    {/* {(gettokenRole === 'Supperamin' || Number(Id) === gettokenId) && (
  <div className='editbutt'>
    <button className='input-butt'>
      <NavLink to={`/inventory/activeemployee/${Id}/edit`}>
        <GrEdit />
      </NavLink>
    </button>
    <br />
  </div>
)} */}

    <form>
       
    {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}
    <h4>Personal Information</h4>
       <div className="input-employeecontainer"> 
       
                  <div className="input-blockemployee">
                    <label htmlFor="name" className="input-labelemployee">
                     Full Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      value={employeeedit.name}
                     disabled
                    />
                    </div>
                  </div>
                  <div className="input-blockemployee">
                 
                    <label htmlFor="gender" className="input-labelradio">
                      Gender
                    </label>
                    <div className='input-erroremployee'>
                    <div className="radiotype">
                   
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="Male"
                      checked={employeeedit.gender==="Male"}
                     
                    />
                    {""}Male
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="Female"
                      checked={employeeedit.gender==="Female"}
                     
                      
                    />
                    {""}Female
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="others"
                      checked={employeeedit.gender==="others"}
                     
                      
                    />
                    {""}Others
                   </div>
                    </div>
                  </div></div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="email" className="input-labelemployee">
                      Email
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={employeeedit.email}
                      disabled
                      
                    />
                    </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="date" className="input-labelemployee">
                      Date Of Birth
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="date"
                      autoComplete="off"
                      name="date"
                      id="date"
                      placeholder="Date of Birth"
                      value={employeeedit.date}
                      disabled
                    />
                    </div>
                    
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="password" className="input-labelemployee">
                      Password
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="password"
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={employeeedit.password}
                      disabled
                    />
                    </div>
                    
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="fathersname" className="input-labelemployee">
                      Father's Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="text"
                      autoComplete="off"
                      name="fathersname"
                      id="fathersname"
                      placeholder="Father's Name"
                      value={employeeedit.fathersname}
                      disabled
                    />
                    </div>
                    
                  </div>
                  
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="cpassword" className="input-labelemployee">
                       Confirm Password
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="password"
                      autoComplete="off"
                      name="cpassword"
                      id="cpassword"
                      placeholder="Confirm Password"
                      value={employeeedit.cpassword}
                      disabled
                    />
                    </div>
                    
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="maritalstatus" className="input-labelemployee">
                      Marital Status
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="text"
                      autoComplete="off"
                      name="maritalstatus"
                      id="maritalstatus"
                      placeholder="maritalstatus"
                      value={employeeedit.maritalstatus}
                      disabled
                     />
                   </div>
                  </div>
                  
                  </div><br></br>
                  {/* <div className=""> */}
                    <h4>Contact Information</h4>
                    <div className="input-employeecontainer">
                    
                      <div className="input-blockemployee">
                      <label htmlFor="phoneno" className="input-labelemployee">
                      Phone Number
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="number"
                      autoComplete="off"
                      name="phoneno"
                      id="phoneno"
                      placeholder="Phone Number"
                      value={employeeedit.phoneno}
                      disabled
                    />
                    </div>
                   

                   
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="address" className="input-labelemployee">
                      Address
                    </label>
                    <div className="inputerroremployee">
                    <textarea
                     rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={employeeedit.address}
                      disabled
                    />
                    </div>
                    
                      </div>
                    </div>
                    <div className="input-employeecontainer">
                    
                    <div className="input-blockemployee">
                    <label htmlFor="state" className="input-labelemployee">
                    State
                  </label>
                  <div className="inputerroremployee">
                  <input
                    type="text"
                    autoComplete="off"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={employeeedit.state}
                    disabled
                  />

                  </div>
    
                </div>
                <div className="input-blockemployee">
                  <label htmlFor="pincode" className="input-labelemployee">
                    Pincode
                  </label>
                  <div className="inputerroremployee">
                  <input
                   
                    type="number"
                    autoComplete="off"
                    name="pincode"
                    id="pincode"
                    placeholder="Pin Code"
                    value={employeeedit.pincode}
                    disabled
                  />
                  </div>
                  

                    </div>
                  </div><br></br>
                  {/* </div> */}
                  <div className="">
                  <h4>Professional Information</h4>
                    <div className="input-employeecontainer">
                    
                      <div className="input-blockemployee">
                      <label htmlFor="jobtype" className="input-labelemployee">
                      Job Type
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="jobtype"
                      id="jobtype"
                      placeholder="Job Type"
                      value={employeeedit.jobtype}
                      disabled
                    />
                    </div>
                  
                   
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="experience" className="input-labelemployee">
                    Experience
                    </label>
                    <div className="inputerroremployee">
                    <input
                     
                      type="number"
                      autoComplete="off"
                      name="experience"
                      id="experience"
                      placeholder="Experience"
                      value={employeeedit.experience}
                      disabled
                    />
                    </div>
                    

                      </div>
                    </div>
                    <div className="input-employeecontainer">
                    
                    <div className="input-blockemployee">
                    <label htmlFor="department" className="input-labelemployee">
                    Department
                  </label>
                  <div className="inputerroremployee">
                  <input
                    type="text"
                    autoComplete="off"
                    name="department"
                    id="department"
                    placeholder="Department"
                    value={employeeedit.department}
                    disabled
                  />
          
                </div>
                 
                </div>
                <div className="input-blockemployee">
                  <label htmlFor="joindate" className="input-labelemployee">
                    Joining Date
                  </label>
                  <div className="inputerroremployee">
                  <input
                   
                    type="date"
                    autoComplete="off"
                    name="joindate"
                    id="joindate"
                    placeholder="Join Date"
                    value={employeeedit.joindate}
                    disabled
                    />
                  </div>

                    </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="role" className="input-labelemployee">
                   Role
                  </label>
                  <div className="inputerroremployee">
                  <input
                    type="text"
                    autoComplete="off"
                    name="role"
                    id="role"
                    // placeholder="role"
                    value={employeeedit.role}
                    disabled
                  />
          
                </div>
                 
                </div>

                  </div>


    </form>
  
    </div>


    </>
  )
}
export default Editemployee;
import React from 'react'

const Attendance = () => {
  return (
    <div>
        
    </div>
  )
}

export default Attendance
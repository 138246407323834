import React, { useEffect, useState } from "react";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

const InactiveReq = () => {
  //**initialize the value to update dynamic data in table what ever getting from backend*/
  const [datas, setDatas] = useState([]);
  //**for search operation update the Searchvalue
  const [searchValue, setSearchValue] = useState("");
  //** to get token from sessionStorage
  const token = sessionStorage.getItem("jwtToken");
  //**for pagination current page will be at 1 in initial stage
  const [currentPage, setCurrentPage] = useState(1);
  //** itemperpage will be 50 */
  //**1-50 1st page, 50-100 2nd page like that it will go on
  const [itemsPerPage, setItemsPerPage] = useState(50);
  //**lastIndex of currentpage
  const lastIndex = currentPage * itemsPerPage;
  //**FirstIndex of lastpage
  const firstIndex = lastIndex - itemsPerPage;
  //** find the data between firstIndex, lastIndex*/
  //**this records need to be map inside table
  const records = datas.slice(firstIndex, lastIndex);
  //**Calculate total number of pages
  const totalPages = Math.ceil(datas.length / itemsPerPage);
  //**get all the RC details from backend
  //**o/p- all RC details */
  const loadReq = async (data) => {
    const res = await axios.get("/requisition/inactive", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDatas(res.data);
    // console.log("req data" + res.data);
  };
  //**Load Rc details when page loads */
  useEffect(() => {
    loadReq();
  }, []);
  //**handel the search operation
  //**const response = await axios.get(/RcGet?q=${searchValue}, { ... })
  // This line sends a GET request to the /RcGet endpoint with a
  // query parameter q set to the value of the searchValue variable. */
  //**o/p- get the search result value and set the datas varible */
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`/requisition/inactive?q=${searchValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDatas(response.data);
      setSearchValue("");
    } catch (err) {
      console.log(err);
    }
  };
  //it will reset the page to 1st page
  const handleReset = () => {
    loadReq();
  };
  //**It will handel the pagination
  // setCurrentPage(page) This line calls the setCurrentPage function,passing the page parameter as an argument.
  // It implies that there is a setCurrentPage
  //  function defined elsewhere in the code, which will update the value of the currentPage state variable. */
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //Delete Rc Record
  //**The purpose of passing the rc value is to identify and specify which record should be deleted on the backend.
  // The backend code that handles the /pollutiondelete endpoint will receive the rc value
  //in the req.body object and use it to perform the appropriate deletion operation. */
  const deleteRc = (rc) => {
    axios
      .post("/rcdelete", { rc })
      .then((result) => {
        loadReq();
      })
      .catch(() => {
        alert("Error in the Code");
      });
  };

  return (
    <div>
      <Helmet>
        <title> </title>
      </Helmet>
      {/* search box */}
      <form
        className="Search-field"
        onSubmit={handleSearch}
      >
        <input
          type="text"
          placeholder="Search Item Name/Item code..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button
          className="allbutton"
          type="submit"
        >
          Search
        </button>
        <button
          className="allbutton"
          onClick={handleReset}
        >
          Reset
        </button>
        {/* table to display data from backend */}
      </form>
      <table id="requisation">
        <thead>
          <tr>
            {/* <th><input type="checkbox"/></th> */}
            {/* <th>Id </th> */}
            <th>Requisition</th>
            <th>Requisition Number</th>
            <th>Requested By</th>
            {/* <th>Requisition Id</th> */}
            <th>Requisition Date</th>
            <th>Status</th>
          </tr>
        </thead>
        {/* this is the dynamic data from data base */}
        <tbody>
          {records.map((req, keys) => {
            return (
              <tr key={keys}>
                {/* <td>{req.requisitionId}</td> */}
                <td><NavLink
                    to={`/requisitiondetails/${req.requisitionId}`}
                    className="addlink"
                  >
                   {req.idforrequisition}
                  </NavLink></td>
                <td>
                    {req.reference}
                  </td>
                <td>{req.employee_name}</td>
                {/* <td>{req.idforrequisition}</td> */}
                <td>{req.requisitiondate}</td>
                <td>{req.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination controls */}
      <div className="pagination">
        <button
          className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button
          className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default InactiveReq;

import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
// here we are providing the array which will give you the
// title path and all the icons present inside the Sidebar
export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Inventory",
    path: "#",
    icon: <MdIcons.MdOutlineInventory />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Items",
        path: "/inventory/items",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Adjustments",
        path: "/inventory/items1",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Supplier",
        path: "/purechases/allsupplier",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Manufacturer",
        path: "/inventory/allmanufacturer",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Requisition ",
        path: "/requisition",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "My Approval",
        path: "/myapprovallist",
        icon: <IoIcons.IoIosPaper />,
        //allowedRoles: ["Admin", "Supperadmin"],
      },
    
      {
        title: "All Purchase ",
        path: "/purechases/allpurchase",
        icon: <FaIcons.FaCartPlus />,
      },
      {
        title: "Supply Order",
        path: "/sales",
        icon: <FaIcons.FaCartPlus />,
      },
      {
        title: "RO Report",
        path: "/roreport",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "PO Report",
        path: "/poreport",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "SO Report",
        path: "/supply",

        icon: <IoIcons.IoIosPaper />,
      },
     
      // {
      //   title: "Invoice Record ",
      //   path: "/invoicerecord",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
 
  {
    title: "Staff",
    path: "#",
    icon: <AiIcons.AiOutlineUser />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "All Employee",
        path: "/inventory/activeemployee",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Attendance",
        // path: '/employee/attendance',
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Leave",
        path: "/employee/allleave",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Deduction",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Salary",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Performance",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  

  {
    title: "Vehicle Documentation ",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "RC ",
        path: "/Vehicle",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Insurance ",
        path: "/Insurance",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Fitness ",
        // path: '/Fitness',
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Permit ",
        path: "/Permit",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Road Tax ",
        path: "/Roadtax",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Pollution",
        path: "/Polution",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Vehicle Service History ",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Service History ",
        path: "/Service",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Ledger ",
    path: "#",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Staff ",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Vehicle ",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Machine ",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
 
];

export const SidebarDataEmployee = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiFillDashboard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Inventory",
    path: "#",
    icon: <MdIcons.MdOutlineInventory />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
     
      {
        title: "Requisition ",
        path: "/requisition",
        icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "SO Report",
      //   path: "/supply",

      //   icon: <IoIcons.IoIosPaper />,
      // },
      // {
      //   title: "PO Report",
      //   path: "/poreport",
      //   icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
 
  {
    title: "Staff",
    path: "#",
    icon: <AiIcons.AiOutlineUser />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Attendance",
        // path: '/employee/attendance',
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "ApplyLeave",
        path: "/employee/leave",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Deduction",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Salary",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Performance",
        path: "#",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
 
];
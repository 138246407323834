import React, { useState,useEffect} from 'react';
import './Manufacturer.css';
import {useFormik} from "formik";
import axios from "../../../helper/axios";
import { Manufacturer } from "../../../helper/schemas";
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';



const initialValues={
   
    manufacturername:"",

};

const Manufacturerform = () => {
  const history = useHistory();
  const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
  useFormik({
    initialValues,
     validationSchema: Manufacturer,
      
      onSubmit: async(values, action) => {
        
          
          try {
   
           // const response = await axios.post("/ManufacturerDynamic",values);
           const datas = new FormData();
        datas.append("document", JSON.stringify(values));
        datas.append("gettoken", JSON.stringify(gettoken));
        const response = await axios.post("/ManufacturerDynamic",datas,{
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        },); 
            if (response.status === 200) {
              setSuccess(true);
              setError(false);
              return history.push('/inventory/allmanufacturer');
            }
          } catch (err) {
            setSuccess(false);
            setError(true);
          }
      
        // action.resetForm();
        // alert("success");
      },
    });
 
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      loadAll();
      },[])
      const token = sessionStorage.getItem('jwtToken');
      const[gettoken, setToken] = useState()
      const loadAll = async () => {
        try {
          const response = await axios.post('/Login', null, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const UserLogedin = response.data.person[0][0].name;
          setToken(UserLogedin);
          console.log(UserLogedin)
          
        } catch (error) {
          // console.log(error.response.data);
        }
      };
  
    const [success, setSuccess] = useState(false);
      const [error, setError] = useState(false);
  return (


    <div>

<form onSubmit={handleSubmit}>

 {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}
       
      <div className="input-employeecontainer"> 
       
       <div className="input-blockemployee">
         <label htmlFor="manufacturername" className="input-label leftone">
         Manufacturer Name
         </label>
         <div >
         <input type="text"
          autoComplete="off"
          
          id="manufacturername"
          name="manufacturername"
          placeholder="Manufacturer Name"
          value={values.manufacturername} 
          onChange={handleChange}
            onBlur={handleBlur}
          /> 
          {errors.manufacturername && touched.manufacturername? (
                      <p className="form-error">{errors.manufacturername}</p>
                    ) : null}
         </div>
       </div>
       </div>
        <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       />
      
                    <button className="input-buttonone"   type="submit"> 
                   Save
                     </button>
                    <button className="input-buttononeone" type="reset" onClick={handleReset}  >
                      Cancel
                    </button>
                   

</form>

    </div>
  )
}

export default Manufacturerform
import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
const EditRoadTax = () => {
  // The useHistory hook is typically used in a functional component within
  //  a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  //**the history object to navigate the user to a different route by calling  return history.push('/Roadtax'); */
  const history = useHistory();
  //**here will get the roadtaxid from the url by using the useParams hook */
  const { roadtaxid } = useParams();
  // will update all the value for all input fields of this form here 
  const [roadtax, setRoadtax] = useState({
    vehicleno: "",
    ownername: "",
    taxhead: "",
    taxmode: "",
    roadtaxfrom: "",
    taxupto: "",
    roadtaxamount: "",
    penalty: "",
    status: ""
  })
  // will get the token from  sessionStorage
  const token = sessionStorage.getItem('jwtToken');
  // Update the vehicle no here
  const [getVehicle, setGetVehicle] = useState([]);
  //** here will get the all vehicleno  */   
  const loadVehicle = async () => {
    const res = await axios.get(`/RcGetvehicle`, {
      //to authorize the api send the token header
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data;
    console.log(newProduct)
    setGetVehicle(newProduct)

  }
  //** when page will load or refresh vehicle no will load and specific roadtax details will load */
  useEffect(() => {
    eachRoadTax()
    loadVehicle()
  }, []);
  //** will get specific Roadtax details according to roadtaxid  */
  const eachRoadTax = async () => {
    const res = await axios.get(`/RoadTaxGet/${roadtaxid}`, {
      //to authorize the api send the token header
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setRoadtax(newProduct)

  }
  //  onchange function for all input fields    
  const handleEdit = (e) => {
    setRoadtax({ ...roadtax, [e.target.name]: e.target.value })
  }
  //This is onsubmit function for this form           
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //here we are passing file and data so we have created the new data object and then 
      //we pass the values and file inside it using JSON.stringify
      const data = new FormData();
      data.append("document", JSON.stringify(roadtax));
      data.append("avatar", file);

      const response = await axios.post(`/RoadTaxUpdate/${roadtaxid}`, data, {
        //to authorize the api send the token header
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      });
      //**when the status is 200 will redirect to listview page */
      if (response.status === 200) {
        setSuccess(true);
        setError(false);
        return history.push('/Roadtax');
      }
    } catch (err) {
      setSuccess(false);
      setError(true);
    }
    //**it will set the input field value to empty field */
    setRoadtax({
      vehicleno: "",
      ownername: "",
      taxhead: "",
      taxmode: "",
      roadtaxfrom: "",
      taxupto: "",
      roadtaxamount: "",
      penalty: "",
    })
  }
  //**when we submit the form to get success or error message */
  const [success, setSuccess] = useState(false);
  //**to display input field error message */
  const [error, setError] = useState(false);
  //to upload the file decleared the useState       
  const [file, setFile] = useState("");
  const setimgfile = (e) => {
    setFile(e.target.files[0])
    console.log(e.target.files[0])
  }

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit} >
          {success && <div className="form-erroremployees">Form submitted successfully!</div>}
          {error && <div className="form-error">Error submitting form. Please try again later.</div>}
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="vehicleno" className="input-labelemployee leftone">
                Vechicle Reg Number
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="vehicleno"
                  id="vehicleno"
                  placeholder="Registration Number"
                  value={roadtax.vehicleno}
                  onChange={(e) => { handleEdit(e) }}
                ><option>Select Vehcile No</option>
                  {getVehicle.map(vehicle => (
                    <option value={vehicle.vehicleno} key={vehicle.id} >{vehicle.vehicleno}</option>
                  ))
                  }
                </select>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="ownername" className="input-labelemployee">
                Owner Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="ownername"
                  id="ownername"
                  placeholder="Owner Name"
                  value={roadtax.ownername}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div></div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="taxhead" className="input-labelemployee">
                Road Tax Head
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="taxhead"
                  id="taxhead"
                  placeholder="Road Tax Head"
                  value={roadtax.taxhead}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="taxmode" className="input-labelemployee">
                Road Tax Mode
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="taxmode"
                  id="taxmode"
                  placeholder="Road Tax Mode"
                  value={roadtax.taxmode}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="roadtaxfrom" className="input-labelemployee leftone">
                Road Tax From
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="roadtaxfrom"
                  id="roadtaxfrom"
                  placeholder="Road Tax From"
                  value={roadtax.roadtaxfrom}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="taxupto" className="input-labelemployee leftone">
                Road Tax Upto
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="taxupto"
                  id="taxupto"
                  placeholder="Road Tax Upto"
                  value={roadtax.taxupto}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="roadtaxamount" className="input-labelemployee">
                Road Tax Amout
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="roadtaxamount"
                  id="roadtaxamount"
                  placeholder="Road Tax Amout"
                  value={roadtax.roadtaxamount}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="penalty" className="input-labelemployee">
                Penalty
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="penalty"
                  id="penalty"
                  placeholder="Penalty"
                  value={roadtax.penalty}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          {/* added a field called status here */}
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="status" className="input-labelemployee">
                Status
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"
                  onChange={(e) => { handleEdit(e) }}
                  value={roadtax.status}
                ><option>active</option>
                  <option>inactive</option>
                </select>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="Image" className="input-labelemployee" >Chose Permit Certificate</label>
              <div className="inputerroremployee">
                <input type="file" name="avatar"
                  id="avatar"
                  placeholder=""
                  onChange={setimgfile}
                />
              </div>
            </div>
          </div>
          <div className="modal-buttons">
            <div className="save-button">
              <button className="input-button" type="submit">
                Save
              </button>
              <button className="input-button" type="button">
                Cancel
              </button></div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditRoadTax;
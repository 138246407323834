import React from 'react';
import Sidebar from '../components/Sidebar';

const Stock = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
      <div className='modal-container '>Team</div>
    </div>
    </div>
  );
};

export default Stock;

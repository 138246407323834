
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { invoice } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import {BsFillEyeFill} from 'react-icons/bs';
import axios from "../../../helper/axios";
import  { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';
const initialValues = {
  
  invoicenumber: "",
  invoicedate:"",
  suppliername:"",
  insertedby:"",
  withouttax: "",
  withtax:"",
   tax:"",
   purchaser:"",
   
  };
  

  const NewInvoiceRecord = () => {
    const history = useHistory();
    
    const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
    useFormik({
      initialValues,
      validationSchema: invoice,
      
      onSubmit: async(values, action,) => {
       
      try {
        //const response = await axios.post("/InvoicePost", values);
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("gettoken", JSON.stringify(gettoken));
        const response = await axios.post("/InvoicePost",data,{
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        },); 
        if (response.status === 200) {
          setSuccess(true);
          setError(false);
          return history.push('/invoicerecord');
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      // action.resetForm();
      },
      
    });
    const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
    
    const[supplier,setSupplier]= useState([]);
    //display all the Supplier in table
     const loadSupplier= async(supplier)=>{
         
         const res = await axios.get("/SupplierGet", {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
         setSupplier(res.data);
         console.log(res.data)
     }
     useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
         loadSupplier();
         loadAll();
     },[]);
     const token = sessionStorage.getItem('jwtToken');
     const[gettoken, setToken] = useState()
     const loadAll = async () => {
       try {
         const response = await axios.post('/Login', null, {
           headers: { Authorization: `Bearer ${token}` },
         });
         const UserLogedin = response.data.person[0][0].name;
         setToken(UserLogedin);
         
       } catch (error) {
         console.log(error.response.data);
       }
     };
    return (
      <>
      <Helmet> 
        <title>New Invoice|BBS Inventory</title>
      </Helmet>
      

      <div className="">
        
       <form onSubmit={handleSubmit}>
        
       {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}
      
       <div className="input-employeecontainer"> 
       
                  <div className="input-blockemployee">
                    <label htmlFor="invoicenumber" className="input-labelemployee">
                    Invoice Number
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="invoicenumber"
                      id="invoicenumber"
                     
                      placeholder="Invoice Number"
                      value={values.invoicenumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.invoicenumber && touched.invoicenumber ? (
                      <p className="form-erroremployee">{errors.invoicenumber}</p>
                    ) : null}
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="invoicenumber" className="input-labelemployee">
                    Invoice Date
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="date"
                      autoComplete="off"
                      name="invoicedate"
                      id="invoicedate"
                     
                      placeholder="Invoice Date"
                      value={values.invoicedate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.invoicedate && touched.invoicedate ? (
                      <p className="form-erroremployee">{errors.invoicedate}</p>
                    ) : null}
                  </div>
                  </div></div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="suppliername" className="input-labelemployee">
                    Supplier Name
                  </label>
                  <div className="inputerroremployee">
                  <select
                    type="text"
                    autoComplete="off"
                    name="suppliername"
                    id="suppliername"
                    placeholder="Supplier Name"
                    value={values.suppliername}
                    onChange={handleChange}
                      onBlur={handleBlur}
                  >
                   <option>Select Supplier Name</option>
                   {supplier.map(supplier => (
              <option value={supplier.supplierid} key={supplier.id}  >{supplier.name}</option>
    
              ))
              }
                  </select>

                  {errors.suppliername && touched.suppliername ? (
                      <p className="form-erroremployee">{errors.suppliername}</p>
                    ) : null}  
                  </div>

                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="purchaser" className="input-labelemployee">
                  Purchaser
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="text"
                      autoComplete="off"
                      name="purchaser"
                      id="purchaser"
                      placeholder="Purchaser"
                      value={values.purchaser}
                      onChange={handleChange}
                      onBlur={handleBlur}/>
      
      {errors.purchaser && touched.purchaser ? (
                      <p className="form-erroremployee">{errors.purchaser}</p>
                    ) : null}
                  </div>
                  
                </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                  <label htmlFor="withouttax" className="input-labelemployee">
                  Cost
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="number"
                      autoComplete="off"
                      name="withouttax"
                      id="withouttax"
                      placeholder="Cost"
                      value={values.withouttax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {errors.withouttax && touched.withouttax ? (
                      <p className="form-erroremployee">{errors.withouttax}</p>
                    ) : null}
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="withtax" className="input-labelemployee">
                  Total Cost
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="number"
                      autoComplete="off"
                      name="withtax"
                      id="withtax"
                      placeholder="Total Cost"
                      value={values.withtax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                   
                   {errors.withtax && touched.withtax ? (
                      <p className="form-erroremployee">{errors.withtax}</p>
                    ) : null}
                  </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="tax" className="input-labelemployee">
                    Tax
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="text"
                      autoComplete="off"
                      name="tax"
                      id="tax"
                      placeholder="Tax"
                      value={values.tax}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.tax && touched.tax ? (
                      <p className="form-erroremployee">{errors.tax}</p>
                    ) : null}
                    </div>
                  </div>

                  </div>
       <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       /> 
                  <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>
                 
                </form>
                </div>     
      </>
    )
  }
  
  export default NewInvoiceRecord

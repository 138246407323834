
import React from 'react'
import axios from '../../../helper/axios';
import { NavLink,useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IoTrashOutline} from "react-icons/io5";
import {GrEdit} from 'react-icons/gr'
import Cookies from 'js-cookie';

const Activeallmanufacturer = () => {
  const history = useHistory();
    const[data,setData]= useState([]);
    const token = sessionStorage.getItem('jwtToken');
    const loadAlldetails= async(data)=>{
        
        const res = await axios.get('/AllManufacturerActive', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      loadAlldetails();
    },[]);

    
      const deletedata = (value) =>
      {
        axios.post('/ManufacturerDelete',{value},{
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        
        .then((result)=>{
         loadAlldetails();
        })
        .catch(()=>{
          alert('Error in the Code');
        });
      };
    
  return (
    <div>

<table id="items">
              <thead>
                  <tr>
                  {/* <th><input type="checkbox"/></th> */}
                      <th>Id</th>
                     
                      <th>Manufacturer Name</th>
                      <th>Created By</th>
                      <th>Update By</th>
                      <th>Status</th>
                     <th>Edit</th>
                      <th>Delete</th>
                     
  
                  </tr>
              </thead>
              <tbody>
            {
            data.map((value, key) => {
              return (
                <tr key={key}>
                    {/* <td><input type="checkbox"/></td> */}
                  <td>{value.manufacturerid }</td>
                  <td>{value.manufacturername}</td>
                  <td>{value.insertedby}</td>
                  <td>{value.updateby}</td>
                 
                  <td>{value.status}</td>
                  <td><NavLink to={`/inventory/allmanufacturer/${value.manufacturerid}/edit`}><GrEdit/></NavLink></td>
                  <td><IoTrashOutline onClick={()=>{
                    const confirmBox=window.confirm(
                      "Do you want to delete "+value.manufacturerid+"?"
                    )
                    if (confirmBox===true){
                      deletedata(value.manufacturerid)

                    }
                  }}/>
                  </td>
                 
                </tr>
              )
            })
          }
            </tbody>
        </table>
    </div>
  )
}

export default Activeallmanufacturer
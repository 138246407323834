import React, { useEffect, useState } from 'react'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink,useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
  // this will take the dynamic data from data base table and show all the purchases
const AllInactivepurchases = () => {
  const history = useHistory();
  const[data,setData]= useState([]);
  //for search
  const [searchValue, setSearchValue] = useState('');
  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);
   //display all the employee in table
    const loadPurchase= async()=>{
        const res = await axios.get("/InactivePurchaseorderGet", {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            limit: itemsPerPage,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    const token = sessionStorage.getItem('jwtToken');
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        loadPurchase();
    },[currentPage, itemsPerPage]);
//display pages according to pagination
const handlePageChange = (page) => {
  setCurrentPage(page);
};
//hangel the search operation
const handleSearch = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.get(`/InactivePurchaseorderGet?q=${searchValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(response.data);
    setSearchValue('');
  } catch (err) {
    console.log(err);
  }
};
//it will reset the page to 1st page
const handleReset = () => {
  loadPurchase();
};
//  // Delete Employee Record
//  const deletepurchase = (value) =>
//  {
//    axios.post('/PurchaseorderDelete',{value})
//    .then((result)=>{
//     loadPurchase();
//    })
//    .catch(()=>{
//      alert('Error in the Code');
//    });
//  };
 

  return (
    <div>
      <Helmet> <title>All Inactive Purchases|BBS Inventory Management</title>
  
  </Helmet>
  <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Purchase Order..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button className="allbutton" onClick={handleReset}>Reset</button>
      </form>
      
        <table id="customers">
            <thead>
                <tr>
                <th>Purchase Order</th>
                <th>Purchase Date</th>
                    <th>Supplier Name</th>
                    <th>Requisition No</th>
                    <th>Requested By</th>
                    <th>Requisition Date</th>
                    <th>PO Stage</th>
                    <th>Deliver To</th>
                    <th>Supplier No</th>
                    <th>Dalivery Adress</th>
                    <th>Created By</th>
                    <th>Status</th>
                    {/* <th>Delete</th> */}
                    {/* <th>Delete</th> */}

                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
           records.map((value, key) => {
            return (
              <tr key={key}>
                  <td><NavLink to={`/purechases/eachpurchase/${value.purchaseorderid}`} className="addlink">{value.purchaseorder}</NavLink></td>
                  <td>{value.purchasedate}</td>
                <td>{value.name}</td>
                <td>{value.reference}</td>
                <td>{value.employee_name}</td>
                <td>{value.requisitiondate}</td>
                <td>{value.orderstage}</td>
                <td>{value.picked}</td>
                <td>{value.phoneno}</td>
                <td>{value.adress}</td>
                <td>{value.insertedby}</td>
                <td>{value.status}</td>
                  {/* <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you really want to delete "+ value.purchasedate
                          )
                          if (confirmBox === true) {
                            deletepurchase(value.purchaseorderid)
                          }
                        }}/> 
                        </td> */}
                </tr>
              )
            })
          }
            </tbody>
        </table>
        {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}
export default  AllInactivepurchases 
import React from "react";
import "./formone.css";
import {useFormik} from "formik";
import { Inventory } from "../../../helper/schemas";
import axios from "../../../helper/axios"
import { useState,useEffect } from "react";
import { IoTrashOutline} from "react-icons/io5";
import  { Redirect, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";

const initialValues={
      
        Id:"",
        date:"",
        account:"",
        reason:"",
        quantityavailable:"",
        newquantity:"",
        quantityadjusted:"",
        description:"",
        Quantity:" "
    };

    const Inventoryform=()=>{
      const history = useHistory();
       const [adjusted, setAdjusted]= useState(0);
      const [m1, setQuantity1]= useState();
      const [m2, setQuantity2]= useState();
      const [getItemid, setGetItemid]= useState('');
      const [getrowdata, setGetrowdata] = useState([{Quantity:" "}]);
      const[item,setItem]= useState([]);

      // const calculateResult = () => {
      //   const deduction = m2 - m1;
      //   setAdjusted(deduction);
      //   console.log(deduction)
      // };
    const loadAlldetails= async(data)=>{
        
        const res = await axios.get('/Itemactive', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setItem(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      loadAlldetails();
      loadAll();
    },[]);
    const token = sessionStorage.getItem('jwtToken');
    const[gettoken, setToken] = useState()
    const loadAll = async () => {
      try {
        const response = await axios.post('/Login', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const UserLogedin = response.data.person[0][0].name;;
        setToken(UserLogedin);
        
      } catch (error) {
        console.log(error.response.data);
      }
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: Inventory,
      onSubmit:async (values, action) => {
       
        try {
   
          const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("getrowdata",JSON.stringify(getrowdata));
        data.append("adjusted",JSON.stringify(adjusted));
        data.append("m2",JSON.stringify(m2));
        data.append("gettoken",JSON.stringify(gettoken));
        const respon = await axios.post("/newinventDynamic",data,{
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        },); 
      
          if (respon.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/inventory/items1');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
      },
    });

    const handleItem=(event)=>{
      const getItemid= event.target.value;   
      setGetItemid( getItemid);
    }
    
    useEffect(()=>{
      const editPurchases = async ()=>{
        const res = await axios.get(`/Itemactive/${getItemid}`, {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setGetrowdata(res.data)
       console.log(res.data)
       var resp =res.data.Quantity
       setQuantity1(resp);
        }
        editPurchases();
  },[getItemid]);
  useEffect(() => {
    // Check if both values are valid numbers before subtracting
    if (!isNaN(m1) && !isNaN(m2)) {
      const newAdjusted = parseInt(m2) - parseInt(m1);
      setAdjusted(newAdjusted.toString());
    }
  }, [m1,m2]);

  const [success, setSuccess] = useState(false);
   const [error, setError] = useState(false);
    return (
      <div className="top">
<Helmet> <title>New Inventory Adjustment|BBS Inventory Management</title>
  
  </Helmet>
    <form onSubmit={handleSubmit}>
    <div className="top-div1"> {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}</div>
      <div className="first-box">
      
      <div className="third-box">
      <div className="third-boxone">
    

      
      
        <div className="input-container">        
       <div className="input-blockerror">
<label  className="input-label leftone" >
  Item
  </label>
  <div className="input-error">

   <select 
       type="text"
         autoComplete="off"
         
         id="Id"
         name="Id"
         placeholder=""
        value={values.Name} 
         onChange={(e)=>handleItem(e)}
           onBlur={handleBlur}>
           <option></option>
{
           item.map(values => (
              <option value={values.Id} key={values.Id} >{values.Name}</option>
    
              ))
              }
              </select>
              {errors.Id && touched.Id ? (
                      <p className="form-errorinventory">{errors.Id}</p>
                    ) : null}
        
</div>
</div>
<div className="input-blockerror">
        <label className="input-label leftone">Date</label>
        <div className="input-error">
       
        <input type="date"
          autoComplete="off"
          id="date"
          name="date"
          placeholder="date"
          value={values.date} 
          onChange={handleChange}
            onBlur={handleBlur}
          /> 
          {errors.date && touched.date ? (
                      <p className="form-errorinventory">{errors.date}</p>
                    ) : null}
        
        </div>
        </div>
        </div>
        <div className="input-container">        
       <div className="input-blockerror">
        <label className="input-label">Account</label>
        <div className="input-error">
        <select
          className="inputselect"
            type="text"
                      
              autoComplete="off"
                      name="account"
                      id="account"
                      placeholder="Cost of Goods Sold"
                      value={values.account}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option></option>
                      <option>Uncategorized</option>
               <option>Contract Assests</option>
              <option>Merchandise</option>
              <option>Depriciation and Amortisation</option>
              <option>Raw materials and Consumables</option>
              
                    </select>
                    
        
        
        </div>
        </div>

        <div className="input-blockerror">
        <label className="input-label leftone">Reason</label>
        <div className="input-error">
       
        <select
          className="list"
            type="text"
                      
              autoComplete="off"
                      name="reason"
                      id="reason"
                      placeholder="Select a reason"
                      value={values.reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option></option>
                      <option>Stolen goods</option>
               <option>Stock on fire</option>
              <option>Damaged goods</option>
              <option>Inventory revaluation</option>
              <option>Stock written off</option>
              
                    </select>
                    {errors.reason && touched.reason ? (
                      <p className="form-errorinventory">{errors.reason}</p>
                    ) : null}
                    </div>
        </div>
        </div>
        <div className="input-container">  


        
       <div className="input-blockerror">
        <label className="input-label">Quantity Available</label>
        <div className="input-error">
        <input
               className="inputselect"       
              autoComplete="off"
              type="text"
                      name="Quantity"
                      id="Quantity"
                      placeholder="Quentity"
                     value={getrowdata.Quantity}
                     onChange={e =>setQuantity1(e.target.value)}
                     
                      >
                </input>  

        </div>
        </div>

        <div className="input-blockerror">
        <label className="input-label leftone">New Quantity</label>
        <div className="input-error">
       
        <input
          className="inputselect"
            type="number"
                      
              autoComplete="off"
                      name="newquantity"
                      id="newquantity"
                      placeholder=""
                      value={m2}
                   onChange={e =>setQuantity2(e.target.value)}
                       
                     onBlur={handleBlur}
                    />
                     {errors.newquantity && touched.newquantity ? (
                      <p className="form-errorinventory">{errors.newquantity}</p>
                    ) : null}
                    
                    
        
                    </div>
        </div>
        </div>
        <div className="input-container">
        <div className="input-blockerror">
        <label className="input-label">Quantity Adjusted</label>
        <div className="input-error">
        <input className="inputselect"
        type="number"
          autoComplete="off"
         
          id="quantityadjusted"
          name="quantityadjusted"
          placeholder=""
          value={adjusted}
         //onClick={calculateResult}
          /> 
          {errors.quantityadjusted && touched.quantityadjusted ? (
                      <p className="form-error">{errors.quantityadjusted}</p>
                    ) : null}
              </div>     
        </div>        
       <div className="input-blockerror">
        <label className="input-label">Description</label>
        <div className="input-error">
        <textarea className="inputselecttext"
         rows="3" cols="38"
          autoComplete="off"
          id="description"
          name="description"
          placeholder=""
          value={values.description} 
          onChange={handleChange}
            onBlur={handleBlur}
          /> 
          {errors.description && touched.description ? (
                      <p className="form-error">{errors.description}</p>
                    ) : null}
              </div>     
        </div>
        </div>
    </div>
      </div>
      <div className="fourth"></div>
      <br></br>
      {/* <div className="fourth-box">
        <table id="inventorytable">
            <thead>
                <tr>
                <th colSpan="2" className="thhead">Item Details</th>
                    <th>Current Value</th>
                    <th>Changed Value</th>
                    <th>Adjusted Value</th>
                </tr>
            </thead>
            <tbody>
            
            {
            datas.map((user, index) => {
              return (
                <tr key={index}>
                    <th className="th"><input
                    className="inputtable"
                    name="itemdetails"
                    onChange={(e)=> onChange(e, index)}
                    value={user.itemdetails}
                    /></th>
                     <th className="th"><input
                    className="inputtable"
                    name="itemdetails"
                    onChange={(e)=> onChange(e, index)}
                    value={user.itemdetails}>
                   

                   </input>
                    
                    
                    </th>
                    <th className="th"><input
                    className="inputtable"
                    name="currentvalue"
                    onChange={(e)=> onChange(e, index)}
                    value={user.currentvalue}
                    /></th>
                    <th className="th"><input
                    className="inputtable"
                    name="changedvalue"
                    onChange={(e)=> onChange(e, index)}
                    value={user.changedvalue}
                    /></th>
                    <th className="th"><input
                    className="inputtable"
                    name="adjustedvalue"
                    onChange={(e)=> onChange(e, index)}
                    value={user.adjustedvalue}
                    /></th>
                 
                    <th><IoTrashOutline onClick={()=> removetable(index)}/></th>
                </tr>
               )
            })
          }
             
            
            
            </tbody>
        </table>
        <br></br>
        <br></br>
        <div className="link"> <Link to="#" className="addlink"  onClick={addtable}>Add another line</Link></div>
          </div> */}

          <br></br>
          <br></br>
       <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       />
      
      </div>
      <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>
      
      </form>
      </div>
    )
    
    }
        
    ;
  
   
    export default Inventoryform;

import React, { useState,useEffect,useRef} from "react";
import axios from "../../helper/axios"
import { useParams,useHistory, NavLink} from "react-router-dom";
import { Helmet } from "react-helmet";
import {GrEdit,GrPrint} from 'react-icons/gr';
import {useReactToPrint} from 'react-to-print';
 import AGM_LOGO from '../../components/Images/AGM_LOGO.png';
 import API from '../../helper/axios';
// this function will handel the form inputs,submitting form and all the operations.
const EachSales = () => {
  const history = useHistory();
  const {salesid}=useParams();
 //dynamic table
  const[datas,setDatas]= useState([{itemDetails:"",salequantity:"",rate:"",description:"",amount:""}]);
  const[verifyitem,setVerifyitem]= useState([{itemDetails:"",salequantity:"",rate:"",description:"",amount:"",gst:"",vechileno:"",amount:""}]);
  const[sales,setSales]= useState({
    search:"",
    reference:"",
    salesdate:"",
    // expected:"",
    // shipmentdate:"",
    customernotes:"",
    termsconditions:"",
    orderstage: '' ,
   saletype:"",
   gst:"",
   subTotal:"",
   gstamount:"",
   servicedate:"",
   email:"",
 
   }
   );
   const[price,setPrice]= useState({});
   const[addressSales,setAddressSales]= useState({
    country:"",
   state:"",
   city:"",
   phonenos:"",
   address:"",
   }
   );
  //  const stages = sales.saletype;
   const stage = sales.orderstage;
   const componentRef= useRef();
   const handelPrint =useReactToPrint ({
     content:()=>componentRef.current,
     documentTitle:'purchase-data',
     
   })
   const saleItemOrder=async(data)=>{
     
    const res = await axios.get(`/saleGet/${salesid}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    setSales(res.data[0]);
 
  }
    const saleitem=async(data)=>{
     
      const res = await axios.get(`/saleitemget/${salesid}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setDatas(res.data);
   
    }
    const saleitemget=async(data)=>{
     
      const res = await axios.get(`/verifyitemget/${salesid}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setVerifyitem(res.data);
      setPrice(res.data[0])
   
    }
    const address=async(data)=>{
     
      const res = await axios.get(`/saleaddress/${salesid}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setAddressSales(res.data[0]);
   
    }
  useEffect(()=>{
    // if(!Cookies.get('jwtToken')){
    //   return history.push("/");
    // }
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    saleItemOrder();
      saleitem();
      loadAll();
      address();
      saleitemget();
  },[]);
  const token = sessionStorage.getItem('jwtToken');
  const[gettoken, setToken] = useState()
  
  const loadAll = async () => {
    try {
      const response = await axios.post('/Login', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;;
      setToken(UserLogedin);
      
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const [poStage, setPoStage] = useState("");
  const handleOrder = async(e) => {
    e.preventDefault();

   try {
    const confirmBox = window.confirm(
      "Do you want to invoice it ?"
    )
    if (confirmBox === true){
    const response = await axios.post(`/SoupdateItem/${salesid}`,{poStage:"Invoiced",salesid:salesid}); 
    console.log(response.data); // Do something with the response data
    setPoStage("Invoiced");
    window.location.reload();
    }
    
  } catch (error) {
    console.error(error);
  }
};
const handleOrders = async(e) => {
  e.preventDefault();

 try {
  const confirmBox = window.confirm(
    "Do you want to assign it"
  )
  if (confirmBox === true){
  const response = await axios.post(`/SoupdateItem/${salesid}`,{poStage:"Assigned",salesid:salesid}); 
  console.log(response.data); 
  setPoStage("Assigneded");
  window.location.reload();
  }
  
} catch (error) {
  console.error(error);
}
};
// const handleOrder = async (e) => {
//   e.preventDefault();

//   try {
//     const confirmBox = window.confirm("Do you want to invoice it");
//     if (confirmBox === true) {
//       const insufficientQuantityItem = datas.find((user) => user.Quantity < user.salequantity);
//       if (insufficientQuantityItem) {
//         alert(`Quantity is less in stock for "${insufficientQuantityItem.Name}"`);
//       } else {
//         const response = await axios.post(`/SoupdateItem/${salesid}`, { poStage: "Invoiced", salesid: salesid });
//         console.log(response.data); // Do something with the response data
//         setPoStage("Invoiced");
//         window.location.reload();
//       }
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };


    return (
      <>
      <Helmet> 
        <title>Sales Details|BBS Inventory Management</title>
      </Helmet>
      <div>
      <div className='editbu'>
      {/* <button className='input-bu' ><ImFilePdf/></button> */}
      <button className='input-bu' onClick={handelPrint}><GrPrint/></button>
      { ( stage === 'Draft') && (
  <button className='input-bu'>
    <NavLink to={`/sales/${salesid}/edit`}>
      <GrEdit />
    </NavLink>
  </button>
)}

    <div>
      {/* {stage === 'Invoiced' && (
        <NavLink to={`/sales/${salesid}/dispach`}><button className='input-button' >Dispatch</button></NavLink>
      )} */}
      {stage === 'Send to Employee' && (
        <NavLink to={`/sales/${salesid}/dispach`}><button className='input-button' >Verify Items</button></NavLink>
      )}
      {/* {(stage === 'Send to Employee') && (
        <button className='input-button' type="submit"  onClick={handleOrder} >Return/Verify Items </button>
      )} */}
     
      
    </div>
   
       </div>
       <div style={{padding:"10px", height:"50px"}}  ><h4 style={{color: "blue",}}>SO Stage:{sales.orderstage}</h4></div>
       <div ref={componentRef} style={{padding:"20px"}}>
       <form style={{border:"1px solid black"}}>
        
       <div className="headerdiv">
       <div className="element1">
       <img className="logo2" src={AGM_LOGO} alt="logo" /> 
       </div> 
       <div className="element2" >
       <p style={{marginTop:"4px"}}><h4>Company Address</h4>
         </p>
       </div>
       <div className="element2">
        <h3 >TAX INVOICE</h3>
       </div>
       </div>
       <hr/>
       <div className="parentdiv">
  <div className="element1">
  <div className="element11">
  Issued To :<br/><br/>
  Requisition :<br/><br/>
  
  {/* Expected Shipment Date : */}
  </div>
  
  <div className="element22">
  {sales.name}<br/><br/>
  {sales.reference}<br/><br/>
  
  {/* {sales.shipmentdate} */}
  </div>
  
  </div>
  <div className="element2">
  <div className="element11">
  Sale Order :<br/><br/>
  Date Of Sale :<br/><br/>
  {/* Expected Delivery Date : */}
  </div>
  
 
 
  <div className="element22">
  {sales.salesorder}<br/><br/>
  {sales.salesdate}<br/><br/>
  
  {/* {sales.expected} */}
  </div>
 
  </div>
</div>
{/* <div className="parentdiv">
<div className="element1">
  <p>
  <h4 style={{marginLeft:"0px"}}>Address</h4>
  {addressSales.address}<br/>
  
  {addressSales.city},
  {addressSales.state},
  {addressSales.country}-{addressSales.zipcode}
  </p>
</div>
<div className="element1">
  <div className="element11">
  <br/>
  
  Employee email :<br/>
  Contact No :<br/><br/>
  </div>

  <div className="element22">
  <br/>
   {sales.email} <br/>
 {addressSales.phonenos}

  </div>
  </div>  */}

{/* <div className="element1">
  <div className="element11">
  <h3 style={{padding:"5px"}}>Address</h3>
  Country :<br/><br/>
  State :<br/><br/>
  City :<br/><br/>
  Customer Phone No :<br/><br/>
  </div>

  <div className="element22">
  <br/><br/>
  {addressSales.country}<br/><br/>
  {addressSales.state}<br/><br/>
  {addressSales.city}<br/><br/>
  {addressSales.phonenos}
  
  </div>
  </div>
  <div className="element1">
  <div className="element11">
  <br/><br/>
  
  Customer email :<br/><br/>
  Customer Address :<br/><br/>
  </div>

  <div className="element22">
  <br/><br/>
   {sales.email} <br/><br/>
 {addressSales.address}<br/><br/>
  <br/><br/>
  <textarea style={{height:"100px",width:"220px",marginTop:"-35px",border:"none"}}
  // value={sales.name}
  />
  
  </div>
  </div> 
    </div> 
  */}


      
               
          <hr/><div> 
            <div style={{marginTop:"10px",marginLeft:"10px"}}>Price List </div>
          <div>    
          {(stage === 'Draft' || stage === 'Send to Employee') && ( <table id="contacttable">
            <thead>
                <tr>
                <th style={{width:"60px"}}>Sl No</th>
                      {/* <th>Item code</th> */}
                      <th style={{width:"220px"}}>Item Name</th>
                      {/* <th>Supplier</th> */}
                      {/* <th style={{width:"250px"}}>Description</th> */}
                      <th style={{width:"200px"}}>Quantity On Stock</th>
                      <th >Quantity </th>
                      <th style={{width:"180px"}}> Unit Price</th>
                      <th>Gst %</th>
                      <th style={{width:"150px"}}>Price</th>
                    
                </tr>
            </thead>
            <tbody>
            {/* dynamic table */}
        { datas.map((user, index) => {
              return (
                <tr key={index}>
                  <th>{index + 1}</th>
                    <th><input
                    className="inputtable"
                    name="itemDetails"
                   
                    value={user.Name}
                    />
                     </th>
                 
                     {/* <th>
                    <input
                    className="inputtable"
                    name="name"
                   
                    value={user.name}
                    />
                    </th>
                    <th><input
                    className="inputtable"
                    name="description"
                   
                    value={user.description}
                    /></th> */}
                    <th>
                            <input
                              className="inputtable"
                              name="Quantity"
                              // onChange={(e) => onChange(e, index)}
                              value={user.Quantity}
                              readOnly
                            />
                          </th>
                    
                    <th><input
                    className="inputtable"
                    name="salequantity"
                   
                    value={user.salequantity}
                    />
                    
                    
                    </th>
                    <th><input
                    className="inputtable"
                    name="rate"
                   
                    value={user.rate}
                    />
                    </th>
                    <th><input
                    className="inputtable"
                    name="gst"
                   
                    value={user.gst}
                    />
                    </th>
                 
                    <th><input
                    className="inputtable"
                    name="amount"
                   
                    value={user.amount}
                    /></th>
                   
                </tr>
               )
            })
          }
            </tbody>
            </table>)}
            {stage === 'Verified' && ( <table id="contacttable">
            <thead>
                <tr>
                <th style={{width:"60px"}}>Sl No</th>
                                {/* <th>Item code</th> */}
                                <th style={{width:"200px"}}>Item Name</th>
                                {/* <th>Supplier</th> */}
                                {/* <th style={{width:"200px"}}>Description</th> */}
                                <th style={{width:"200px"}}>Quantity On Stock</th>
                                <th style={{width:"110px"}}>Vehicle</th>
                                <th style={{width:"110px"}}>Others</th>
                                <th>Quantity </th>
                                <th>SO Unit</th>
                                <th style={{width:"110px"}}>Rate</th>
                                <th style={{width:"110px"}}>Gst %</th>
                                <th style={{width:"110px"}}>Price</th>
                    
                </tr>
            </thead>
            <tbody>
            {/* dynamic table */}
        { verifyitem.map((user, index) => {
              return (
                <tr key={index}>
                  <th>{index + 1}</th>
                    <th><input
                    className="inputtable"
                    name="itemDetails"
                   
                    value={user.itemDetails}
                    />
                     </th>
{/*                  
                     <th>
                    <input
                    className="inputtable"
                    name="name"
                   
                    value={user.supplier}
                    />
                    </th> */}
                    {/* <th><input
                    className="inputtable"
                    name="description"
                   
                    value={user.description}
                    /></th> */}
                    <th>
                            <input
                              className="inputtable"
                              name="Quantity"
                              // onChange={(e) => onChange(e, index)}
                              value={user.Quantity}
                              readOnly
                            />
                          </th>
                          <th><input
                    className="inputtable"
                    name="vehicleno"
                   
                    value={user.vehicleno}
                    />
                    </th>
                    <th><input
                    className="inputtable"
                    name="other"
                   
                    value={user.other}
                    />
                    </th>
                    <th><input
                    className="inputtable"
                    name="salequantity"
                   
                    value={user.salequantity}
                    />
                    </th>
                    <th>
                            <input
                              className="inputtable"
                              name="unit"
                              // onChange={(e) => onChange(e, index)}
                              value={user.unit}
                              readOnly
                            />
                          </th>
                    <th><input
                    className="inputtable"
                    name="rate"
                   
                    value={user.rate}
                    />
                    </th>
                    <th><input
                    className="inputtable"
                    name="gst"
                   
                    value={user.gst}
                    />
                    </th>
                 
                    <th><input
                    className="inputtable"
                    name="amount"
                   
                    value={user.amount}
                    /></th>
                   
                </tr>
               )
            })
          }
            </tbody>
            </table>)}
          </div> 
          <div className='notes'>
          <div className="comment">
          <div className="input-block-text">
                    <label htmlFor="customernotes" className="input-label">
                    Customer Notes
                    </label>
                    <div className="input-each">
                    <input
                    // className="textareas"
                    // rows="3" cols="52"
                      type="text"
                      autoComplete="off"
                      name="customernotes"
                      id="customernotes"
                     
                      value={sales.customernotes}
                     
                    />
                  </div>  
                  </div>
          </div>
                  <div >
                  <div className="input-container-total">
                  {(stage === 'Draft' || stage === 'Send to Employee' ) && ( <div className="input-block">
                 
                      <label  className="input-label2">Total Price</label>
                    <div className="input-gst">
                    <input
            type="text"
            autoComplete="off"
            name="totalAmount"
            id="totalAmount"
            value={sales.totalAmount}
            readOnly
          />
                    </div>
                  </div>)}
                  {stage === 'Verified'  && ( <div className="input-block">
                 
                 <label  className="input-label2">Total Price</label>
               <div className="input-gst">
               <input
       type="text"
       autoComplete="off"
       name="totalAmount"
       id="totalAmount"
       value={price.totalAmount}
       readOnly
     />
               </div>
             </div>)}
                  </div>
                  {/* <div className="input-container-total">
                  <div className="input-block">
                 
                      <label  className="input-label2">IGST @ %</label>
                    <div className="input-gst">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gst"
                      id="gst"
                      value={sales.gst}
                      readOnly
                    />
                    </div>
                  </div>
                  </div>
                  <div className="input-container-total">
                  <div className="input-block">
                 
                      <label  className="input-label2">IGST Amount</label>
                    <div className="input-gst">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gstamount"
                      id="gstamount"
                      value={sales.gstamount}
                      readOnly
                    />
                    </div>
                    </div>
                  </div>
                  <div className="input-container-total">
                  <div className="input-block">
                 
                      <label  className="input-label2">Final Price(Including GST)</label>
                    <div className="input-gst">
                    <input
            type="text"
            autoComplete="off"
            name="subTotal"
            id="subTotal"
            value={sales.subTotal}
            readOnly
          />
                    </div>
                  </div>
                  </div>*/}
                    
                  </div> 
          </div>
          <div >
          <div  className='notes'>
            <div><div className="input-block-text">
                    <label htmlFor="termscondition" className="input-label">
                    Terms & Conditions
                    </label>
                    <div className="input-each">
                    <input
                    // className="textareas"
                    // rows="3" cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"
                      
                      value={sales.termsconditions}
                      disabled
                    />
                   </div>
                  </div></div>
                  <div className='purchaseorderfile'> 
                     
                     {sales.saledocument}
                     <img src={`${API.defaults.baseURL}/uploadimage/uploads/${sales.saledocument}`} />
                     </div>
          </div>
          </div>
          </div>
          <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       /> 
        <input
       type="hidden"
            name="orderstage"
            value={sales.orderstage}
           
       /> 
         
                 
                </form>
                
                </div> </div>   
      </>
    )
}

export default EachSales;
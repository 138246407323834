import React from "react";

import { NavLink } from "react-router-dom";

import ReactHtmlTableToExcel from "react-html-table-to-excel";

import Sidebar from "../../components/Sidebar";
import NewRequisition from "./Requisitions/NewRequisition";
import ListViewRequisition from "./Requisitions/ListviewRequisition";
import InactiveReq from "./Requisitions/InactiveRequisition";
import ReqDropdown from "../../components/Dropdown/RequisitionDropdown";
import AllTheReuisation from "./Requisitions/allTheReuisation";
import EditRequisation from "./Requisitions/editRequisation";
import RequisationDetails from "./Requisitions/requisationDetails";
import GeneratePoFromRo from "./Requisitions/GeneratePoFromRo";
import PendingApproval from "./Requisitions/pendingApproval";


//requisition form
export const NewRequisitions = () => {
  return (
    <div>
      {" "}
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3> New Requisition Form</h3>
          </div>
          <div className="container">
            <NewRequisition/>
          </div>
        </div>
      </div>
    </div>
  );
};
//main page
export const RequisitionHomePage = () => {
  return (
    <div>
      {" "}
      <Sidebar />
      <div className="reports">
        <div className="modal-container ">
          <div className="title">
            {/* <dropDownReq /> */}
            <ReqDropdown/>
            <div className="title1">
              <NavLink to="/requisition/newrequisition">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="requisation"
                filename="Employeexls"
                sheet="Employeexls"
                buttonText="Export File"
              />
            </div>
          </div>

          <div className="container2">
            <ListViewRequisition/>
          </div>
        </div>
      </div>
    </div>
  );
};

export const allInactiveReq = () => {
  return (
    <div>
      <Sidebar />
      <div className="reports">
        <div className="modal-container ">
          <div className="title">
            {/* <dropDownReq /> */}
            <ReqDropdown/>
            <div className="title1">
              <NavLink to="/requisition/newrequisition">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="requisation"
                filename="Employeexls"
                sheet="Employeexls"
                buttonText="Export File"
              />
            </div>
          </div>

          <div className="container2">
            <InactiveReq/>
          </div>
        </div>
      </div>
    </div>
  );
};
export const allActiveInactiveReq = () => {
  return (
    <div>
      <Sidebar />
      <div className="reports">
        <div className="modal-container ">
          <div className="title">
            {/* <dropDownReq /> */}
            <ReqDropdown/>
            <div className="title1">
              <NavLink to="/requisition/newrequisition">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="requisation"
                filename="Employeexls"
                sheet="Employeexls"
                buttonText="Export File"
              />
            </div>
          </div>

          <div className="container2">
       <AllTheReuisation/>
          </div>
        </div>
      </div>
    </div>
  );
};

export const myapproval= () => {
  return (
    <div>
      <Sidebar />
      <div className="reports">
        <div className="modal-container ">
          <div className="title">
          <h3>Pending Approval</h3>
            <div className="title1">
              <NavLink to="/requisition/newrequisition">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="requisation"
                filename="Employeexls"
                sheet="Employeexls"
                buttonText="Export File"
              />
            </div>
          </div>

          <div className="container2">
       <PendingApproval/>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RequisitionDetails = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Requisition Details</h3>
          </div>
          <div className="container">
            <RequisationDetails/>
            
          </div>
        </div>
      </div>
    </div>
  );
};
export const EditRequisitionDetails = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Requisition Details</h3>
          </div>
          <div className="container">
            <EditRequisation/>
          </div>
        </div>
      </div>
    </div>
  );
};
export const RequisitionToPO = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Convert Requisition To Purchase Order</h3>
          </div>
          <div className="container">
            <GeneratePoFromRo/>
          </div>
        </div>
      </div>
    </div>
  );
};
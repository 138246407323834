import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { GrEdit, GrPrint } from "react-icons/gr";
import { ImFilePdf } from "react-icons/im";
import Cookies from "js-cookie";
import { useReactToPrint } from "react-to-print";
import AGM_LOGO from "../../../components/Images/AGM_LOGO.png";
import { Helmet } from "react-helmet";
import API from "../../../helper/axios";
const EachPurchase = () => {
  const history = useHistory();
  //getting the id of purchase oprder
  const { purchaseorderid } = useParams();
  console.log();
  //what ever dta coming from backend it is updated here
  const [purchaseedit, setPurchaseedit] = useState({
    picked: "organization",
    search: "",
    email: "",
    phoneno: "",
    reference: "",
    purchaseorder: "",
    adress: "",
    purchasedate: "",
    expected: "",
    paymentterms: "",
    shipmentpreference: "",
    customernotes: "",
    termsconditions: "",
    status: "",
    purchaseorderid: "",
    //  avatar:""
  });

  const [received, setReceived] = useState({ receivedate: "", receivedby: "" });
  //file whatever we have uploaded will update here from backend
  const [file, setFile] = useState({ avatar: "" });

  const setimgfile = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  //here getting the token stored in sessionStorage
  const token = sessionStorage.getItem("jwtToken");
  //whenever we load the page data will display
  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/");
    }
    getPurchases();
    getitemdetails();
    //receive()
  }, []);
  //getting the data from backend according to the purchase id
  const getPurchases = async () => {
    const res = await axios.get(`/Purchase/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setPurchaseedit(newProduct);
  };
  //getting the dyanmic table price list data from backend according to the purchase id
  const getitemdetails = async () => {
    const res = await axios.get(`/Price/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newusers = await res.data;
    setDatas(newusers);
    setDataitem(newusers);
    //console.log(newusers)
  };

  // const  receive = async ()=>{
  //   const res = await axios.get(`/Received/${purchaseorderid}`);
  //   const newreceive = await res.data;
  //   console.log(newreceive)
  //   // setPurchaseedit(newreceive)
  //   setReceived(await res.data[0])
  //   setDataitem(newreceive)
  //   }
  const itemtemps = {
    Id: "",
    description: "",
    purchasequantity: "",
    rate: "",
    tax: "",
    amount: "",
  };
  const [dataitem, setDataitem] = useState([itemtemps]);

  const itemtemp = {
    Id: "",
    description: "",
    purchasequantity: "",
    rate: "",
    tax: "",
    amount: "",
  };
  const [datas, setDatas] = useState([itemtemp]);
  //to print the page
  const componentRef = useRef();
  const handelPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "purchase-data",
  });
  //getting the po stage
  const stage = purchaseedit.orderstage;

  const [poStage, setPoStage] = useState("");
  //when wants to order this function will called
  const handleOrder = async (e) => {
    e.preventDefault();

    try {
      const confirmBox = window.confirm("Do you want to Order ?");
      if (confirmBox === true) {
        const response = await axios.post(`/Poupdate/${purchaseorderid}`, {
          poStage: "Ordered",
          purchaseorderid: purchaseorderid,
        });
        console.log(response.data); // Do something with the response data
        setPoStage("Ordered");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleOrders = async (e) => {
    e.preventDefault();

    try {
      const confirmBox = window.confirm("Do you want to Save and Send Email ?");
      if (confirmBox === true) {
        const response = await axios.post(`/Poupdate/${purchaseorderid}`, {
          poStage: "Send Email",
          purchaseorderid: purchaseorderid,
        });
        console.log(response.data); // Do something with the response data
        setPoStage("Send Email");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Helmet>
        {" "}
        <title>Purchase Details|BBS Inventory Management</title>
      </Helmet>
      <div className="editbu">
        <button
          className="input-bu"
          onClick={handelPrint}
        >
          <GrPrint />
        </button>
        {/* when the stage is "Send Email or Draft the only the edit button will displayed" */}

        {(stage === "Send Email" || stage === "Draft") && (
          <button className="input-bu">
            <NavLink to={`/purechases/eachpurchase/${purchaseorderid}/edit`}>
              <GrEdit />
            </NavLink>
          </button>
        )}
        {/* { (stage === 'Received') && (
  <button className='input-bu'>
    <NavLink to={`/purechases/eachpurchase/${purchaseorderid}/receive`}>
      <GrEdit />
    </NavLink>
  </button>
)} */}

        <div>
          {/* //when the stage is "Received" then so cteation button should display */}
          {stage === "Received" && (
            <NavLink to={`/purechases/sales/${purchaseorderid}`}>
              <button className="input-button"> Generate SO</button>
            </NavLink>
          )}
          {/* When the stage is "Ordered then Receive button will show" */}
          {stage === "Ordered" && (
            <NavLink to={`/purechases/eachpurchase/${purchaseorderid}/receive`}>
              <button className="input-button">Receive</button>
            </NavLink>
          )}
           {stage === "Draft" && (
            <div className="tooltips"><button
              className="input-button"
              type="submit"
              onClick={handleOrders}
            >
            Save And Send Email  {" "}
            </button>
            <span className="tooltiptext">If you don't want to change the data click the Button</span>
            </div>
          )}
          {stage === "Send Email" && (
            <button
              className="input-button"
              type="submit"
              onClick={handleOrder}
            >
              Order{" "}
            </button>
          )}
        </div>
      </div>
      <div style={{ padding: "10px", height: "50px" }}>
        <h4 style={{ color: "blue" }}>PO Stage:{purchaseedit.orderstage}</h4>
      </div>
      <div
        ref={componentRef}
        style={{ padding: "20px" }}
      >
        <form style={{ border: "1px solid black" }}>
          <div className="headerdiv">
            <div className="element1">
              <img
                className="logo2"
                src={AGM_LOGO}
                alt="logo"
              />
            </div>
            <div className="element2">
              <p style={{ marginTop: "4px" }}>
                <h4>Company Address</h4>
              </p>
            </div>
            <div className="element2">
              <h3 >
                TAX INVOICE
              </h3>
            </div>
          </div>
          <hr />
          <div className="parentdiv">
            <div className="element1">
              <div className="element11">
                Purchase Order : <br />
                <br />
                Supplier Name :<br />
                <br />
                Requisition By :<br />
                <br />
                Requisition No:
                <br />
                <br />
                Requisition Date:
              </div>

              <div className="element22">
                {purchaseedit.purchaseorder}
                <br />
                <br />
                {purchaseedit.name}
                <br />
                <br />
                {purchaseedit.employee_name}
                <br />
                <br />
                {purchaseedit.reference}
                <br />
                <br />
                {purchaseedit.requisitiondate}
              </div>
            </div>
            <div className="element2">
              <div className="element11">
                Purchase Date :<br />
                <br />
                Expected Delivery Date :<br />
                <br />
                Deliver To:
                <br />
                <br />
                Delivery Address :
              </div>

              <div className="element22">
                {purchaseedit.purchasedate}
                <br />
                <br />
                {purchaseedit.expected}
                <br />
                <br />
                {purchaseedit.picked}
                <br />
                <br />
                <textarea
                  style={{
                    width: "230px",
                    marginTop: "-5px",
                    border: "none",
                    padding: "0px",
                    fontSize: "16px",
                  }}
                  value={purchaseedit.adress}
                />
              </div>
            </div>
          </div>
          <hr />

          <div>
            <div>Price List</div>
            <div>
              {(stage === "Send Email" ||
                stage === "Draft" ||
                stage === "Ordered") && (
                <table id="contacttable">
                  <thead>
                    <tr>
                      {/* <th>Item Details</th>
                    <th>Account</th>
                    <th>Quantity oF Item Ordered</th>
                    {stage === 'Received' && (<th>Quantity oF Item Received</th>)}
                    <th>Rate</th>
                    <th>Tax</th>
                    <th>Amount</th> */}
                      <th style={{ width: "60px" }}>Sl No</th>
                      <th style={{ width: "220px" }}>Item Name</th>
                      <th style={{ width: "100px" }}>Description</th>
                      <th>Quantity </th>
                      <th>Purchase Unit</th>
                      <th>Unit Price</th>
                      {/* <th>Tax %</th> */}
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas.map((user, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <th>
                            <input
                              className="inputtable"
                              name="itemDetails"
                              value={user.Name}
                            />
                          </th>
                          <th>
                            {" "}
                            <input
                              className="inputtable"
                              name="description"
                              value={user.description}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="purchasequantity"
                              value={user.purchasequantity}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="unitforpurchase"
                              disabled
                              value={user.unitforpurchase}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="rate"
                              disabled
                              value={user.rate}
                            />
                          </th>

                          <th>
                            <input
                              className="inputtable"
                              name="amount"
                              disabled
                              value={user.amount}
                            />
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}

              {stage === "Received" && (
                <table id="contacttable">
                  <thead>
                    <tr>
                      {/* <th>Item Details</th>
                    <th>Account</th>
                    <th>Quantity oF Item Ordered</th>
                    {stage === 'Received' && (<th>Quantity oF Item Received</th>)}
                    <th>Rate</th>
                    <th>Tax</th>
                    <th>Amount</th> */}
                      <th style={{ width: "60px" }}>Sl No</th>
                      <th style={{ width: "220px" }}>Item Name</th>
                      <th style={{ width: "100px" }}>Description</th>
                      <th>Purchased Quantity </th>
                      <th>Purchase Unit</th>
                      <th>Received Quantity</th>
                      <th style={{ width: "100px" }}>Received Unit</th>
                      <th>Unit Price</th>

                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataitem.map((user, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <th>
                            <input
                              className="inputtable"
                              name="itemDetails"
                              disabled
                              value={user.Name}
                            />
                          </th>
                          <th>
                            {" "}
                            <input
                              className="inputtable"
                              name="description"
                              value={user.description}
                              disabled
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="purchasequantity"
                              disabled
                              value={user.purchasequantity}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="unitforpurchase"
                              disabled
                              value={user.unitforpurchase}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="quantityreceive"
                              disabled
                              value={user.receivedQuantity}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="unit"
                              disabled
                              value={user.unit}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="rate"
                              disabled
                              value={user.rate}
                            />
                          </th>
                          <th>
                            <input
                              className="inputtable"
                              name="amount"
                              disabled
                              value={user.amount}
                            />
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <div className="notes">
              <div className="comment">
                <div className="input-block-text">
                  <label
                    htmlFor="customernotes"
                    className="input-label"
                  >
                    Customer Notes
                  </label>
                  <div className="input-each">
                    <input
                      // className="textareas"
                      // rows="3" cols="52"
                      type="text"
                      autoComplete="off"
                      name="customernotes"
                      id="customernotes"
                      value={purchaseedit.customernotes}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="input-container-total">
                  <div className="input-block">
                    <label
                      className="input-label2"
                      id="inputtotalprice"
                    >
                      Total Price
                    </label>
                    <div className="input-gst">
                      <input
                        type="text"
                        autoComplete="off"
                        name="totalAmount"
                        id="totalAmount"
                        value={purchaseedit.totalAmount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="input-container-total">
                  <div className="input-block">
                 
                      <label  className="input-label2">IGST @ %</label>
                    <div className="input-gst">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gst"
                      id="gst"
                      value={purchaseedit.gst}
                      readOnly
                    />
                    </div>
                  </div>
                  </div>
                  <div className="input-container-total">
                  <div className="input-block">
                 
                      <label  className="input-label2">IGST Amount</label>
                    <div className="input-gst">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gstamount"
                      id="gstamount"
                      value={purchaseedit.gstamount}
                      readOnly
                    />
                    </div>
                    </div>
                  </div>
                  <div className="input-container-total">
                  <div className="input-block">
                 
                      <label  className="input-label2">Final Price(Including GST)</label>
                    <div className="input-gst">
                    <input
            type="text"
            autoComplete="off"
            name="subTotal"
            id="subTotal"
            value={purchaseedit.subTotal}
            readOnly
          />
                    </div>
                  </div>
                  </div> */}
              </div>
            </div>
            <div>
              <div className="notes">
                <div>
                  <div className="input-block-text">
                    <label
                      htmlFor="termscondition"
                      className="input-label"
                    >
                      Terms & Conditions
                    </label>
                    <div className="input-each">
                      <input
                        // className="textareas"
                        // rows="3" cols="95"
                        type="text"
                        autoComplete="off"
                        name="termsconditions"
                        id="termsconditions"
                        value={purchaseedit.termsconditions}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="purchaseorderfile">
                  {purchaseedit.avatar}
                  <img
                    src={`${API.defaults.baseURL}/uploadimage/uploads/${purchaseedit.avatar}`}
                   
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EachPurchase;

import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { Schema } from "../../helper/schemas";
import axios from "../../helper/axios"
import { IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
// this function will handel the form inputs,submitting form and all the operations.
const EditSales = () => {
  //get the history
  const history = useHistory();
  //get the sale id here
  const { salesid } = useParams();
// here updating the field values 
  const [sales, setSales] = useState({
    search: "",
    reference: "",
    salesdate: "",
    customernotes: "",
    termsconditions: "",
    insertedby: "",
    orderstage: "",
    issueto: "",
    assign: "",
    socreationdate: "",
    salesorder: "",
    billNo:"",
    requisitionby:"",
  }
  );
  //dynamic table object where we store the values
  const itemTemp = {
    itemDetails: "",
    salequantity: "",
    rate: "",
    description: "",
    amount: "",
    gst: "",
    addedValue: ""
  };
  const [item, setItem] = useState([]);
  const [datas, setDatas] = useState([itemTemp]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gstList, setGstList] = useState([]);
  const [rateList, setRateList] = useState([])
  //updating the dynamic table data here
  const [addInventory, setAddInventory] = useState([])
//this function will add the row in dynamic table
  const addRow = () => {
    setAddInventory([...addInventory, addInventory]);
    //setDatas([...datas, itemTemp]);
  };
  //this will delete the row
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setDatas(updateUsers);
    setAddInventory(updateUsers);
    const total = updateUsers.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);

  };

//Onchange function to calculate the price for dynamic table
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...datas];
    updateUser[index] = { ...updateUser[index], [name]: value };
    const quantity = parseFloat(updateUser[index].salequantity) || 0;
    const rate = parseFloat(updateUser[index].rate) || 0;
    const gst = parseFloat(updateUser[index].gst) || 0;
    const amount = quantity * rate * (1 + gst / 100);

    updateUser[index].amount = amount.toFixed(2);
    setDatas(updateUser);
    setAddInventory(updateUser);
    // Calculate the total amount of all items
    const total = updateUser.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);
    const currentRow = updateUser[index];
    const Quantity = parseFloat(currentRow.Quantity);
    const Salequantity = parseFloat(currentRow.salequantity);
    const addedValue = Quantity - Salequantity;
    currentRow.addedValue = addedValue;

  };

  useEffect(() => {
    async function calculateFields() {
      const res = await axios.get(`saleitemget/${salesid}`, {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      const updateUser = res.data;

      for (let i = 0; i < updateUser.length; i++) {
        const currentRow = updateUser[i];
        console.log(updateUser[i].Id)
        const res = await axios.get(`/allitemrateandgst/${updateUser[i].Id}`, {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        const Quantity = isNaN(parseFloat(currentRow.Quantity)) ? 0 : parseFloat(currentRow.Quantity);
        const Salequantity = isNaN(parseFloat(currentRow.salequantity)) ? 0 : parseFloat(currentRow.salequantity);
        const addedValue = Quantity - Salequantity;
        //console.log(`Row ${i}: Quantity=${Quantity}, Salequantity=${Salequantity}, addedValue=${addedValue}`);
        currentRow.addedValue = addedValue;
      }

      const rateArr = res.data.map(item => ({ value: item.rate, label: item.rate }));
      console.log(rateArr)
      setRateList(rateArr);

      const total = updateUser.reduce((sum, user) => sum + Number(user.amount), 0);
      setTotalAmount(total);
      setDatas(updateUser);
      setAddInventory(updateUser);
    }

    calculateFields();
  }, []);
//when addInventory.length > 0, then this function will be called

  const replaceData = async (e, index, type) => {
    const res = await axios.get(`/allitemrateandgst/${e.target.value}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    const gstArr = res.data.map((Id, key) => ({ value: Id.gst, label: Id.gst }));
    setGstList(gstArr);

    const rateArr = res.data.map((Id, key) => ({ value: Id.rate, label: Id.rate }));
    setRateList(rateArr);
//get all the previous data of addInventory using spreed operater
    let newArr = [...addInventory]
//updating the data according to response got from backend
    newArr[index] = {
      Id: res.data[0].Id,
      Name: res.data[0].Name,
      supplier: res.data[0].name,
      description: res.data[0].Descriptionone,
      Quantity: res.data[0].Quantity,
      salequantity: "",
      rate: "",
      gst: "",
      amount: 0,
      itemmasterid: res.data[0].itemmasterid,
      Unit: res.data[0].Unit,

    }

    if (!(type == 'Select Item')) {
      setAddInventory(newArr)
      setDatas(newArr);

    } else {

      setAddInventory([
        ...newArr,
        {
          Name: "Select Item",
          supplier: "",
          description: "",
          Quantity: "",
          salequantity: "",
          rate: "",
          gst: "",
          amount: "",
          itemmasterid: "",
          Unit:"" ,
        },
      ])
      setDatas([
        ...newArr,
        {
          Name: "Select Item",
          supplier: "",
          description: "",
          Quantity: "",
          salequantity: "",
          rate: "",
          gst: "",
          amount: "",
          itemmasterid: "",
          Unit:"" ,
        },
      ])
    }
    // console.log("final array");
    console.log(newArr)


  }
  //when addInventory.length == 0, then this function will be called

  const getPriceList = async (e, b) => {
    const res = await axios.get(`/allitemrateandgst/${e.target.value}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("selected1 "+e.target.value);
    setAddInventory([
      ...addInventory,
      {
        Id: res.data[0].Id,
        Name: res.data[0].Name,
        supplier: res.data[0].name,
        description: res.data[0].Descriptionone,
        Quantity: res.data[0].Quantity,
        salequantity: "",
        rate: "",
        gst: "",
        amount: 0,
        itemmasterid: res.data[0].itemmasterid,
        Unit: res.data[0].Unit,
      },

    ]);
    setDatas([
      ...addInventory,
      {
        Id: res.data[0].Id,
        Name: res.data[0].Name,
        supplier: res.data[0].name,
        description: res.data[0].Descriptionone,
        Quantity: res.data[0].Quantity,
        salequantity: "",
        rate: "",
        gst: "",
        amount: 0,
        itemmasterid: res.data[0].itemmasterid,
        Unit: res.data[0].Unit,
      },

    ]);

    const gstArr = res.data.map(item => ({ value: item.gst, label: item.gst }));
    setGstList(gstArr);

    const rateArr = res.data.map(item => ({ value: item.rate, label: item.rate }));
    setRateList(rateArr);

  }

  const [file, setFile] = useState({ avatar: "" });

  const setimgfile = (e) => {
    setFile(e.target.files[0])
    //console.log(e.target.files[0])
  }
  // const[supplier,setSupplier]= useState([]);
  const saleItemOrder = async (data) => {

    const res = await axios.get(`/saleGet/${salesid}`, {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    setSales(res.data[0]);

  }
  // const saleitem=async(data)=>{

  // const res = await axios.get(`/saleitemget/${salesid}`);
  // setDatas(res.data);
  // setAddInventory(res.data);
  // // console.log(res.data)
  // const idArr = res.data.map(item => item.Id);
  // // console.log(idArr);


  // const newArr=res.data;
  // const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
  // setTotalAmount(total);

  // }

  //get all employee details from backend 
  const loadEmployee = async (supplier) => {

    const res = await axios.get("/EmployeeGet", {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    setEmployee(res.data);

  }
  //got all item details from backend
  const loadItem = async (data) => {

    const res = await axios.get("/itemgetmaster", {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    setItem(res.data);


  }

  useEffect(() => {
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    saleItemOrder();
    //saleitem();
    loadAll();
    loadEmployee();
    getEmployee();
    loadItem();
    //setAddInventory([]);
  }, []);
  //get all employee details from backend
  const getEmployee = async () => {
    const res = await axios.get(`/EmployeeGet`, {
      headers: { 
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee)
    //console.log(newusers)
  }
  const [employee, setEmployee] = useState([]);
//get token
  const token = sessionStorage.getItem('jwtToken');
  const [gettoken, setToken] = useState();
  const [gettokenId, setTokenId] = useState()
//get all user details who is loged in
  const loadAll = async () => {
    try {
      const response = await axios.post('/Login', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;;
      const UserLogedinId = response.data.person[0][0].Id;
      setTokenId(UserLogedinId);
      setToken(UserLogedin);

    } catch (error) {
      //console.log(error.response.data);
    }
  };
  //onchange function
  const handleEdit = (e) => {
    setSales({ ...sales, [e.target.name]: e.target.value })
  }
  //send the form data to backend when we submit the form

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //check the quantity and salequantity
      const insufficientQuantityItem = addInventory.find((user) => user.Quantity < user.salequantity);
      const insufficientQuantityItems = addInventory.find((user) => user.salequantity);
      if (insufficientQuantityItem) {
        alert(`Quantity of "${insufficientQuantityItem.Name}" is more than available Quantity in store.Please reduce the quantity before submitting.`);
        return;
      }
      const isAnyQuantityZero = addInventory.some((user) => Number(user.salequantity) === 0);
      const isAnyQuantityEmpty = addInventory.some((user) => user.salequantity === '');
      if (isAnyQuantityZero || isAnyQuantityEmpty) {
        alert('Quantity	 field cannot be empty or zero.');
        return;
      }
      //check the rate field
      const isAnyRateZero = addInventory.some((user) => Number(user.rate) === 0);
      const isAnyRateEmpty = addInventory.some((user) => user.rate === '');
      if (isAnyRateZero || isAnyRateEmpty) {
        alert('Rate	field cannot be empty or zero.');
        return;
      }
      //check the gst field
      // const isAnyGSTZero = addInventory.some((user) => Number(user.gst) === 0);
      const isAnyGSTEmpty = addInventory.some((user) => user.gst === '');
      if (isAnyGSTEmpty) {
        alert('GST field cannot be empty .');
        return;
       
      }
      //submitting the data to backend
      const data = new FormData();
      data.append("document", JSON.stringify(sales));
      data.append("avatar", file);
      data.append("totalAmount", JSON.stringify(totalAmount));
      data.append("currentDate", JSON.stringify(currentDate));
      data.append("datas", JSON.stringify(datas));
      data.append("addInventory", JSON.stringify(addInventory));
      data.append("gettoken", JSON.stringify(gettoken));
      data.append("gettokenId", JSON.stringify(gettokenId));

console.log( JSON.stringify(addInventory))
      const respon = await axios.post(`/SalesorderUpdate/${salesid}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      },);

      //const respon = await axios.post(`//${salesid}`,datas);

      if (respon.status === 200) {
        return history.push("/sales");
      }
    } catch (err) {
      // setSuccess(false);
      // setError(true);
    }

  }
  const [status, setStatus] = useState("");
  //cancel the form
  const handleCancel = async (e) => {
    e.preventDefault();

    try {
      const confirmBox = window.confirm(
        "Do you want to cancel it ?"
      )
      if (confirmBox === true) {
        const response = await axios.post(`/SaleeorderDelete/${salesid}`, { status: "Inactive", salesid: salesid },{
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        });
        //console.log(response.data); // Do something with the response data
        setStatus("Inactive");
        if (response.status === 200) {
          return history.push("/sales");
        }
      }

    } catch (error) {
      console.error(error);
    }
  };
  let Showdate = new Date();
  let ShowTodaydate = Showdate.getFullYear() + '-' + (Showdate.getMonth() + 1) + '-' + Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  return (
    <>
      <Helmet>
        <title>Edit Sale|BBS Inventory  Management</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="top-div1">
            <div className="input-container-search">
              <label htmlFor="name" className="input-block_radio">
                Issued to
              </label>
              <select className="list" type="text" autoComplete="off" name="search" id="search"
                placeholder="Select a vendor" value={sales.Id} onChange={(e) => { handleEdit(e) }}
              >
                {/* <option>Select Employee Name</option> */}
                {employee.map((employee) => (
                  <option value={employee.Id} key={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-container1">
              <div className="input-block">
                <NavLink to="/employee" className="addlink">
                  Add new Employee
                </NavLink>
              </div>
            </div>
          </div>
          <div className="input-container">
            <div className="input-block">
              <label htmlFor="Reference" className="input-label">
                Reference#
              </label>
              <div className="input-error">
                <input type="text" autoComplete="off" name="reference" id="reference" placeholder="Reference" value={sales.reference} onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>

            <div className="input-block">
              <label htmlFor="issueto" className="input-label">
                Given by
              </label>
              <div className="input-error">
                <select className="list" type="text" autoComplete="off" name="issueto" id="issueto" onChange={(e) => { handleEdit(e) }}
                  value={sales.Id}
                >
                  <option>Select Employee</option>
                  <option value={gettokenId}>{gettoken}</option>
                  {employee.map(value => (
                    <option value={value.Id} key={value.id}>{value.name}</option>
                  ))
                  }
                </select>
                {/* <input className="list" type="text" autoComplete="off" name="issueto" id="issueto" onChange={handleChange} value={sales.issueto} /> */}
              </div>
            </div>
          </div>
          <div className="input-container">
            <div className="input-block">
              <label htmlFor="salesdate" className="input-label">
                Date Of Sale
              </label>
              <div className="input-error">
                <input type="date" autoComplete="off" name="salesdate" id="salesdate" value={sales.salesdate} // value={currentDate} onChange={(e)=>{handleEdit(e)}}
                />
              </div>
            </div>
            <div className="input-block">
              <label htmlFor="salesdate" className="input-label">
                Sale Order
              </label>
              <div className="input-error">
                <input type="text" autoComplete="off" name="salesorder" id="salesorder" value={sales.salesorder} // value={currentDate} onChange={(e)=>{handleEdit(e)}}
                />
              </div>
            </div>
          </div> 
          <div className="input-container">
            <div className="input-block">
              <label htmlFor="billNo" className="input-label">
                Bill No
              </label>
              <div className="input-error">
                <input
                 type="text" 
                autoComplete="off" 
                name="billNo"
                 id="billNo"
                  value={sales.billNo} // value={currentDate} onChange={(e)=>{handleEdit(e)}}
                />
              </div>
            </div>
          <div className="input-block">
                    <label htmlFor="requisitionby" className="input-label">
                        Requisition By
                    </label>
                    <div className="input-error">
                        <input type="text" autoComplete="off" name="requisitionby" id="requisitionby" value={sales.requisitionby} // value={currentDate} onChange={(e)=>{handleEdit(e)}}
                        />
                      </div>
                    </div>
                </div>
          <input type="hidden" autoComplete="off" name="socreationdate" id="socreationdate" value={ShowTodaydate} onChange={(e) => { handleEdit(e) }}
          />
          <br />
          <div>
            <div>Price List </div>
            <div>
              <table id="contacttable">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}>Sl No</th>
                    {/* <th>Item code</th> */}
                    <th style={{ width: "200px" }}>Item Name</th>
                    {/* <th>Supplier</th>
                    <th style={{ width: "200px" }}>Description</th> */}
                    <th style={{ width: "200px" }}>Quantity On Stock</th>
                    <th>Unit</th>
                    <th>Quantity </th>
                    <th style={{ width: "110px" }}>Rate</th>
                    <th style={{ width: "110px" }}>Gst %</th>
                    <th style={{ width: "110px" }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {/* dynamic table */}
                  {addInventory.length == 0 ?
                    <tr>
                      <th></th>
                      <th>
                        <select className="inputtable" name="itemDetails" onChange={(e) => getPriceList(e)}
                        // value={user.itemDetails}
                        >
                          <option value="">Select Item</option>

                          {item.map((Values, index) => (
                            <option value={Values.Id} key={Values.Id}>
                              {Values.Name}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      {/* <th></th>
                      <th></th> */}
                    </tr>
                    :
                    addInventory.map((user, index) => {

                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
{/* here in  replaceData(e, index, user.ItemId, user.ItemName) changed to replaceData(e, index, user.Id, user.Name) */}
                          <th>
                            <select className="inputtable" name="itemDetails" onChange={(e) => replaceData(e, index, user.Id, user.Name)}
                              value={user.Id}
                            >
                              <option value="">{user.Name}</option>

                              {item.map((Values, index) => (
                                <option value={Values.Id} key={Values.Id}>
                                  {Values.Name}
                                </option>
                              ))}
                            </select>
                          </th>

                          {/* <th>
                            <input className="inputtable" name="name" onChange={(e) => onChange(e, index)}
                              value={user.name}
                            />
                          </th>
                          <th>
                            <input className="inputtable" name="description" onChange={(e) => onChange(e, index)}
                              value={user.description}
                            />
                          </th> */}
                          <th>
                            <input className="inputtable" name="Quantity" // onChange={(e)=> onChange(e, index)}
                              value={user.Quantity}
                              readOnly
                            />
                          </th>
                          <th>
                            <input className="inputtable" name="Unit" // onChange={(e)=> onChange(e, index)}
                              value={user.Unit}
                              readOnly
                            />
                          </th>
                          <th>
                            <input className="inputtable" name="salequantity" onChange={(e) => onChange(e, index)}
                              value={user.salequantity}
                            />
                          </th>
                          <th>
                            <input className="inputtable" name="rate" onChange={(e) => onChange(e, index)}
                              value={user.rate}
                            />
                            {/* 
                                    <select className="inputtable" name="rate" onChange={(e)=> onChange(e, index)}
                                        value={user.rate}
                                        >
                                        <option value={user.rate}>{user.rate}</option>
                                        {rateList.map((ratevalue, index) => (
                                        <option value={ratevalue.value} key={index}>
                                            {ratevalue.label}
                                        </option>
                                        ))}

                                    </select> */}
                          </th>
                          <th>
                            <input className="inputtable" name="gst" onChange={(e)=> onChange(e, index)}
                                    value={user.gst}
                                    />
                            {/* <select className="inputtable" name="gst" onChange={(e) => onChange(e, index)}
                              value={user.gst}
                            >
                              <option value={user.gst}>{user.gst}</option>
                              {gstList.map((values, index) => (
                                <option value={values.value} key={index}>
                                  {values.label}
                                </option>
                              ))}
                            </select> */}
                          </th>
                          <th>
                            <input className="inputtable" name="amount" value={user.amount} disabled />
                          </th>
                          <th>
                            <IoTrashOutline onClick={() => deleteRow(index)} />
                          </th>
                          <input
                            type="hidden"
                            className="inputtable"
                            name="addedValue"
                            value={user.addedValue}
                          />
                        </tr>
                      );

                    })}
                </tbody>
              </table>


              <div className="link">
                {" "}
                <Link to="#" className="addlink" onClick={addRow}>
                  Add New Line
                </Link>
              </div>
              <div className="link">
                {" "}
                <Link to="/inventory/additem" className="addlink">
                  Add New Item
                </Link>
              </div>
            </div>
            <div className="note">
              <div className="comment">
                <div className="input-block-text">
                  <label htmlFor="customernotes" className="input-label">
                    Customer Notes
                  </label>
                  <textarea className="textareas" rows="3" cols="52" type="text" autoComplete="off" name="customernotes" id="customernotes" placeholder="Customer notes" value={sales.customernotes} onChange={(e) => { handleEdit(e) }}

                  />

                </div>
              </div>
              <div className="pricediscount">
                <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">Total Price</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="totalAmount"
                        id="totalAmount"
                        value={totalAmount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  {/* <div className="input-block">
                    <label className="input-label">IGST @ %</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="gst"
                        id="gst"
                        value={gst}
                        onChange={(e) => {
                          const newGst = parseFloat(e.target.value) || 0;
                          setGst(newGst);
                          const subTotal = calculateSubTotal(
                            totalAmount,
                            newGst
                          );
                          setSubTotal(subTotal);
                          const gstAmount = subTotal - totalAmount;
                          setTotalGst(gstAmount);
                        }}
                      />
                    </div>
                  </div> 
                </div>*/}
                  {/* <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">GST Amount</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="gstamount"
                        id="gstamount"
                        value={totalGst}
                        readOnly
                      />
                    </div>
                  </div> */}
                </div>
                {/* <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">
                      Final Price(Including GST)
                    </label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="subTotal"
                        id="subTotal"
                        value={subTotal}
                        readOnly
                      />
                    </div>
                  </div> 
                </div>*/}
              </div>
            </div>
            <br />
            <div className="top-div2">
              <div className="note">
                <div>
                  <div className="input-block-text">
                    <label htmlFor="termsconditions" className="input-label">
                      Terms & Conditions
                    </label>
                    <textarea
                      className="textareas"
                      rows="3"
                      cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"
                      placeholder="Terms and conditions"
                      value={sales.termsconditions}
                      onChange={(e) => { handleEdit(e) }}

                    />

                  </div>
                </div>
                <div className="purchaseorderfile">
                  <label htmlFor="Image">Attach File(s) to Sales Order</label>

                  <input type="file" name="avatar" onChange={setimgfile} />
                </div>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="insertedby"
            id="insertedby"
            value={gettoken}
            onChange={(e) => { handleEdit(e) }}
          />
          <input
            type="hidden"
            name="orderstage"
            value={sales.orderstage}
            onChange={(e) => { handleEdit(e) }}
          />
          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
                id="draft"
                onClick={() => {
                  handleEdit({
                    target: { name: "orderstage", value: "Draft" },
                  });
                }}
              >
                Save As Draft
              </button>
              <button className="input-button" type="submit" id="packaging" onClick={() => {
                handleEdit({ target: { name: 'orderstage', value: 'Send to Employee' } }

                );
              }}>
                Allocate
              </button>

              <button
                className="input-button"
                type="button"

              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditSales;
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams, useHistory } from 'react-router-dom'

const EditPucc = () => {
  //**here will get the pollutionid from the url by using the useParams hook */
  const { pollutionid } = useParams();
  //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  // //**the history object to navigate the user to a different route by calling  return history.push('/Polution'); */
  const history = useHistory();
  // will update all the value for all input fields of this form here 
  const [values, setValues] = useState({
    vehicleno: "",
    licencenumber: "",
    ownername: "",
    pucc: "",
    regyear: "",
    testdate: "",
    validdate: "",
    center: "",
    status: ""
  });
  // will get the token from  sessionStorage     
  const token = sessionStorage.getItem('jwtToken');
  //When will load the page all the details of pollution per pollutionid will load here
  useEffect(() => {
    eachPucc()
  }, []);
  //will get all the details of pollution per pollutionid here
  const eachPucc = async () => {
    const res = await axios.get(`/pollutiongetid/${pollutionid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    //What ever data we got from backend will set all the value for all input fields here 
    setValues(newProduct)
  }
  //  onchange function for all input fields            
  const handleEdit = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  //This is onsubmit function for this form              
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //here we are passing file and data so we have created the new data object and then 
      //we pass the values and file inside it using JSON.stringify
      const data = new FormData();
      data.append("document", JSON.stringify(values));
      data.append("avatar", file);

      const response = await axios.post(`/pollutionUpdate/${pollutionid}`, data, {
          //to authorize the api send the token header
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      });
         //**when the status is 200 will redirect to listview page */
      if (response.status === 200) {
        setSuccess(true);
        setError(false);
        return history.push('/Polution');
      }
    } catch (err) {
      setSuccess(false);
      setError(true);
    }
    //**it will set the input field value to empty field */
    setValues({

      vehicleno: "",
      licencenumber: "",
      ownername: "",
      pucc: "",
      regyear: "",
      testdate: "",
      validdate: "",
      center: "",

    })
  }
  //**when we submit the form to get success or error message */
  const [success, setSuccess] = useState(false);
  //**to display input field error message */
  const [error, setError] = useState(false);
  //to upload the file decleared the useState
  const [file, setFile] = useState("");
  const setimgfile = (e) => {
    setFile(e.target.files[0])
    console.log(e.target.files[0])
  }

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}
      <div className="">
        {/* here we called the handleSubmit to save the form  */}
        <form onSubmit={handleSubmit}>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="vehicleno" className="input-labelemployee leftone">
                Vehicle Regn Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="vehicleno"
                  id="vehicleno"
                  placeholder="Registration Number"
                  value={values.vehicleno}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="licencenumber" className="input-labelemployee">
                Licence Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="licencenumber"
                  id="licencenumber"
                  placeholder="Licence Number"
                  value={values.licencenumber}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div></div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="ownername" className="input-labelemployee">
                Owner Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="ownername"
                  id="ownername"
                  placeholder="Owner Name"
                  value={values.ownername}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="pucc" className="input-labelemployee">
                PUCC Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="pucc"
                  id="pucc"
                  placeholder="Pollution Under Control Certificate"
                  value={values.pucc}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="regyear" className="input-labelemployee leftone">
                Year Of Registration
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="regyear"
                  id="regyear"
                  placeholder="Year Of Registration"
                  value={values.regyear}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="testdate" className="input-labelemployee leftone">
                Test Date
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="testdate"
                  id="testdate"
                  value={values.testdate}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="validdate" className="input-labelemployee leftone">
                Valid Date
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="validdate"
                  id="validdate"
                  value={values.validdate}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="center" className="input-labelemployee">
                Center name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="center"
                  id="center"
                  placeholder="Center Name"
                  value={values.center}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          {/* Added a new field called status here */}
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="status" className="input-labelemployee">
                Status
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"
                  onChange={(e) => { handleEdit(e) }}
                  value={values.status}
                ><option>active</option>
                  <option>inactive</option>
                </select>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="Image" className="input-labelemployee" >Chose PUCC Picture</label>
              <div className="inputerroremployee">
                <input type="file" name="avatar"
                  id="avatar"
                  placeholder=""
                  onChange={setimgfile}
                />
              </div>
            </div>
          </div>
          <div className="modal-buttonsemployee">
            <button className="input-button" type="submit" >
              Save
            </button>
            <button className="input-button" type="reset">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditPucc;
import { useEffect, useRef, useState } from 'react'
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import axios from "./../../helper/axios";
import { useHistory } from 'react-router-dom';
//for date picker calender need to import all tha files
import { DateRangePicker } from 'react-date-range'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import { subDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { GrDocumentDownload } from "react-icons/gr";
import {FaFileDownload } from "react-icons/fa";

// import ReactHtmlTableToExcel from 'react-html-table-to-excel'
//Each property is assigned an empty string as its initial value for this form field value
const initialValues = {
    date: "",
    Name: "",
    idforrequisition: "",
    name:"",
  };
const RequisitionReport = () => {
    //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  // //**the history object to navigate the user to a different route by calling  return history.push('/'); */
  const history = useHistory();
  const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
    useFormik({
      initialValues,
      onSubmit: async (values, action,) => {
        try {
          // It extracts the startDate from an array called range and calculates the timezoneOffset in milliseconds based on the user's local time zone.
          // It creates a new Date object called adjustedDate, which is the original startDate adjusted by the timezoneOffset.
          // It logs the adjusted date in the format "YYYY-MM-DD" using toISOString() and slice() methods.
          // It creates a new FormData object called data.
          // It appends two key-value pairs to the data object: "document" with the JSON stringified value of values,
          // and "startDate" with the adjusted date in the format "YYYY-MM-DD".
          // It appends another key-value pair "endDate" with the endDate from range in the format "YYYY-MM-DD".
          const startDate = range[0].startDate;
          const endDate = range[0].endDate;
          const timezoneOffset = startDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
          const adjustedDate = new Date(startDate.getTime() - timezoneOffset);
          const adjustedDates = new Date(endDate.getTime() - timezoneOffset);
          console.log(adjustedDate.toISOString().slice(0, 10));
          //when i directlly used range[0].startDate.toISOString().slice(0, 10) the output was yesterday date so I used 
          //adjustedDate.toISOString().slice(0, 10) to get start date 
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("startDate", adjustedDate.toISOString().slice(0, 10)); // Add start date 
          data.append("endDate", adjustedDates.toISOString().slice(0, 10)); // Add end date 
          const response = await axios.post("/requisitionreportpost", data, {
            //send token header to authorize the api
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            }
          });
          if (response.data.length === 0) {
            // If the response data is an empty array, display a message
            alert("No data found");
            setDataSupply(response.data);
          } else {
            // Otherwise, set the dataSupply state with the response data
            console.log(response.data);
            setDataSupply(response.data);
          }
         } catch (err) {

        }
        action.resetForm();
      },

    });
  const [totalPrice, setTotalPrice] = useState(0);
  const [dataSupply, setDataSupply] = useState([]);
  //update vehicle no
  const [vehicle, setVehicle] = useState([]);
  //update supplier details
  const [datas, setDatas] = useState([]);
  //update the Item details
  const [item, setItem] = useState([]);
  const [employee, setEmployee] = useState([])
  //get the token from sessionStorage
  const token = sessionStorage.getItem('jwtToken');
  //will get all the item from itemorder  and Supplier by using the backend api
  const loadItem = async (data) => {
    const res = await axios.get("/itemgetmaster", {
      //send token header to authorize the api
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setItem(res.data);
  }
  //will get all the supplier details from supplier table using backend api
  const loadRequisition = async (data) => {
    const res = await axios.get("/requisition/activerequisition", {
      //send token header to authorize the api
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDatas(res.data);
    console.log(res.data)
  }
  const loadEmployee = async (data) => {
    const res = await axios.get("/EmployeeGet", {
      //send token header to authorize the api
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setEmployee(res.data);
    console.log(res.data)
  }
//   //will get all the Vehicle no from Rc table using the backend  api
//   const loadVehicle = async (data) => {
//     const res = await axios.get("/vehicleno", {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     setVehicle(res.data);
//   }
  //when will load the page or refresh then will get all the data of  loadItem();loadRequisition();loadVehicle();
  useEffect(() => {
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    loadItem();
   loadRequisition();
   loadEmployee ();
    //loadVehicle();
     // Calculate total price
// It will recalculate the total price whenever there's a change in the dataSupply array.
// The reduce method is called on the dataSupply array. The reduce method iterates over each item in the array and 
//accumulates the total price. The initial value for the accumulator is set to 0.
// For each item in the dataSupply array, the amount property is accessed and parsed as an float using parseFloat.
// The parsed value is then added to the accumulator.
// Finally, the totalPrice is set to the calculated value using the setTotalPrice function, which updates the state variable totalPrice.
    const totalPrice = dataSupply.reduce((total, item) => total + parseFloat(item.amount), 0);
    setTotalPrice(totalPrice);
  }, [dataSupply], []);

  // date state
  const [range, setRange] = useState([
    {
      endDate: new Date(),
      startDate: subDays(new Date(), 7),
      key: 'selection'
    }
  ])
  console.log(range)
  // open close
  const [open, setOpen] = useState(false)
  // get the target element to toggle 
  const refOne = useRef(null)
  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])
  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false)
    }
  }
  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false)
    }
  }
  //function is for downloding the table data 
  const handleDownload = (event) => {
    event.preventDefault();
    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(document.getElementById('supplyitem'));//need to provide the id of specific table here (id is supplyitem) .

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to an Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file using FileSaver.js
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'RO_report.xlsx');
  };

  return (
    <div>
<Helmet>
        <title>New Supply|BBS Inventory Management</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="input-employeecontainer">
          <div className="input-blockemployee">
              <label htmlFor="idforrequisition" className="input-labelemployee">
               Requisition No
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="idforrequisition"
                  id="idforrequisition"
                  value={values.idforrequisition}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select Requisition No</option>
                  {datas.map(itemorder => (
                    <option value={itemorder.idforrequisition} key={itemorder.Id} >{itemorder.idforrequisition}</option>
                  ))
                  }
                </select>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="Name" className="input-labelemployee">
               Item Name
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="Name"
                  id="Name"
                  value={values.Name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select Item Name </option>
                  {item.map(itemorder => (
                    <option value={itemorder.Id} key={itemorder.Id} >{itemorder.Name}</option>
                  ))
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="date" className="input-labelemployee">
                Date
              </label>
              <div className="calendarWrap">
                <input
                  type="text"
                  name="date"
                  id="date"
                  value={`${format(range[0].startDate, "dd/MM/yyyy")} to ${format(range[0].endDate, "dd/MM/yyyy")}`}
                  readOnly
                  onClick={() => setOpen(open => !open)}
                />
                <div ref={refOne}>
                  {open &&
                    <DateRangePicker
                      onChange={item => setRange([item.selection])}
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={false}
                      ranges={range}
                      months={1}
                      direction="vertical"
                      className="calendarElement"
                    />
                  }
                </div>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="Name" className="input-labelemployee">
               RO By
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select Employee Name </option>
                  {employee.map(itemorder => (
                    <option value={itemorder.empId} key={itemorder.Id} >{itemorder.name}</option>
                  ))
                  }
                </select>
              </div>
            </div>
          </div>
          
          <div className='buttonDisplay'>
            <button className="allbuttonget">Get Details</button>
            {/* <button className="allbutton" onClick={handleDownload}>Download Table Data</button> */}
            <div className="tooltip"> <FaFileDownload className='download' onClick={handleDownload}/>
             <span className="tooltiptext">Download Report</span>
            </div>
           

            {/* <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="supplyitem"
                   filename="tablexls"
                   sheet="tablexls"
                   buttonText="Download Table"/> */}
          </div>
          <br />
          <div className='container2'>
            <table id="supplyitem">
              <thead>
                <tr>
                  <th>RO No</th>
                  <th>RO Date</th>
                  <th style={{ width: "400px" }}>Item Name</th>
                  <th>RO By</th>
                  <th>Vehicle No</th>
                  <th>Other</th>
                  <th>Requested Quantity</th>
                  <th>Requested Unit</th>
                  <th>RO stage</th>
                  <th>Months</th>  
                  <th>Years</th> 
                </tr>
              </thead>

              <tbody>
                {
                  dataSupply.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td>{value.idforrequisition}</td>
                        <td>{value.requisitiondate}</td>
                        <td>{value.itemname}</td>
                        <td>{value.name}</td>
                        <td>{value.vehicleno}</td>
                        <td>{value.others}</td>
                        <td>{value.requestQuantity}</td>
                        <td>{value.unit}</td>
                        <td>{value.requisitionstage}</td>
                        <td>{format(new Date(value.requisitiondate), 'MMMM')}</td>    
                        <td>{format(new Date(value.requisitiondate), 'yyyy')}</td>   
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RequisitionReport
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Schema } from "../../../helper/schemas";
import axios from "../../../helper/axios"
import { IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
//validate the purchaseDate input field
const schema = Yup.object().shape({
  purchaseDate: Yup.date().required("Purchase date is required"),
});
const validatePurchaseDate = (inputValue) => {
  try {
    schema.validateSync({ purchaseDate: inputValue });
    return ""; // No error message if validation succeeds
  } catch (err) {
    return err.errors[0]; // Return the error message if validation fails
  }
};
const GeneratePoFromRo = () => {
  //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.
  const history = useHistory();
  //get the requisitionId using useParams hook
  const { requisitionId } = useParams();
  //create one object for dynamic table
  const itemTemp = { itemname: "", supplier: [], description: "", requestQuantity: "" };
  //pass the dynamic table 
  const [addInventory, setAddInventory] = useState([itemTemp])
  const [employee, setEmployee] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [requisitionedit, setRequisition] = useState(
    {
      requitionername: "",
      reference: "",
      requisitiondate: "",
      customernotes: "",
      termsconditions: "",
      requisitionstage: "",
      idforrequisition:""
    });
  const [receiveDateError, setReceiveDateError] = useState("");
  // Add a state variable to track form submission status
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // Add a state variable to store the error message
  const [errorMessage, setErrorMessage] = useState("");
  const [purchaseDate, setPurchaseDate] = useState({ purchaseDate: "" });
  const [supplier, setSupplier] = useState([]);
  const token = sessionStorage.getItem('jwtToken');
  const addItemrow = () => {
    //using ... spread operator will get all the data which is already present in addInventory and addInventory will add new fields
    setAddInventory([...addInventory, addInventory]);
  };
  //delete row of dynamic table
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setAddInventory(updateUsers);

  };
  //onchange function for dynamic table
  // const onChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updateUser = [...addInventory];
  //   updateUser[index] = { ...updateUser[index], [name]: value };
  //   setAddInventory(updateUser);
  //   // Set the corresponding supplier for the item
  //   if (name === "Name") {
  //     // Fetch the suppliers for the selected item
  //     loadAllSupplier(value, index);
  //   }
  // };
  const onChange = async (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...addInventory];
    updateUser[index] = { ...updateUser[index], [name]: value };
    setAddInventory(updateUser);

    if (name === "Name") {
      // Fetch the suppliers for the selected item
      loadAllSupplier(value, index);
    }

    if (name === "supplier") {
      // Fetch the quantity in stock for the selected supplier and update the "quantityInStock" field
      const supplierId = value;
      const item = addInventory[index];

      try {
        const res = await axios.get(`/getQuantity/${item.Name}/${supplierId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const quantityInStock = res.data[0].Quantity;
        const supplier = res.data[0].supplierid;
        const ItemId =res.data[0].Id;
        //console.log(supid)
        // Update the "quantityInStock" field for the selected item
        updateUser[index] = { ...updateUser[index], quantityInStock, supplier,ItemId };
        const updatedAddInventory = [...addInventory];
        updatedAddInventory[index] = { ...updatedAddInventory[index], quantityInStock, supplier,ItemId };
        setAddInventory(updatedAddInventory)

      } catch (error) {
        // Handle any error that may occur while fetching the quantity in stock
        console.error("Error fetching quantity in stock:", error);
      }
    }
  };

  //onchange function
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setPurchaseDate({ ...purchaseDate, [name]: value || null });
    setReceiveDateError("");
  };

  //onchange function
  const handleChange = (e) => {
    setRequisition({ ...requisitionedit, [e.target.name]: e.target.value })
  }
  //here rk has written
  const loadAllItems = async (data) => {
    const res = await axios.get("/itemget", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data);
    setItemList(res.data);
  };
  //get all employee details from backend 
  const loadEmployee = async (supplier) => {

    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setEmployee(res.data);

  }
  //getting the data from backend according to the purchase id
  const getRequisition = async () => {
    const res = await axios.get(`/requisition/${requisitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setRequisition(newProduct)

  }

  // Modify the getitemdetails function to fetch suppliers for each item
  const getitemdetails = async () => {
    const res = await axios.get(`/requisitionitem/${requisitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newusers = await res.data;
    setAddInventory(newusers);
    setItemList(newusers);
    console.log(newusers);
    // Call loadAllSupplier for each item in newusers
    newusers.forEach((user, index) => {
      loadAllSupplier(user.Name, index);
    });
  };

  useEffect(() => {
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    //loadSupplier()
    getRequisition()
    getitemdetails()
    loadAllSupplier()
    loadEmployee()
    loadAllItems()
    loadAll ()
  }, []);

  //wiil get the supplier according to Itemname
  const loadAllSupplier = async (Name, index) => {
    if (Name) {
      const res = await axios.get(`/itemgetname/${Name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const suppliersData = res.data;

      // Update the suppliersList array to store the suppliersData for the correct index
      setSupplier((prevSuppliersList) => {
        const updatedList = [...prevSuppliersList];
        updatedList[index] = suppliersData;
        return updatedList;
      });
      // console.log(addInventory)
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const purchaseDates = purchaseDate.purchaseDate;
      if (!purchaseDates) {
        setReceiveDateError("Purchase date is required");
        return;
      }

      const receiveDateErrorMessage = validatePurchaseDate(purchaseDates);
      if (receiveDateErrorMessage) {
        setReceiveDateError(receiveDateErrorMessage);
        return;
      }

      setReceiveDateError("");
      // Check if any of the rate field is empty
      const hasMissingPoQuuantity = addInventory.some((user) => !user.purchaseQuantity);
      const hasMissingSupplier = addInventory.some((user) => !user.unitforpurchase);
      const hasMissingunit = addInventory.some((user) =>!user.supplier );
      if (hasMissingunit) {
        // If any item has missing supplierid, set the error message
        //setErrorMessage("Please select a supplier for all items.");
        alert("Please select a supplier for all items.")
      }   
      else if (hasMissingPoQuuantity) {
        alert("Please select a purchase quantity for all items.")
      }
      else if (hasMissingSupplier) {
        alert("Please select a purchase unit for all items.")
      }
      else {
        // If all items have valid supplierid, proceed with form submission
        setErrorMessage(""); // Clear any previous error message
        setIsFormSubmitted(true);
        const data = new FormData();

        data.append("document", JSON.stringify(requisitionedit));
        data.append("addInventory", JSON.stringify(addInventory));
        data.append("purchaseDate", JSON.stringify(purchaseDate));
        console.log(JSON.stringify(requisitionedit))
        console.log(JSON.stringify(addInventory))
        console.log(JSON.stringify(purchaseDate))
        data.append("gettoken", JSON.stringify(gettoken));
        const response = await axios.post(`/requisitiontopurchase/${requisitionId}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {

          return history.push("/purechases/allpurchase");
        }
      }
    } catch (err) {
      // setSuccess(false);
      // setError(true);
    }
    //action.resetForm();
    // alert("Updated successfuly.")

    //  setDatas([{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);
  }
  //const token = sessionStorage.getItem("jwtToken");
  const [gettoken, setToken] = useState();
  //got all user information who is loged in
  const loadAll = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;
      setToken(UserLogedin);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  // New onChange function to handle changes in the item name input field

  return (
    <div>
      <Helmet>
        <title>New Purchase|BBS Inventory</title>
      </Helmet>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div className="input-block">
              <label htmlFor="Reference" className="input-label leftone">
                Requisition No
              </label>
              <div className="input-error">
                <input
                  type="text"
                  autoComplete="off"
                  name="idforrequisition"
                  id="idforrequisition"
                  placeholder="Requisition No"
                  value={requisitionedit.idforrequisition}
                //   onChange={handleChange}
                //   onBlur={handleBlur}

                />
                {/* {errors.reference && touched.reference ? (
                  <p className="form-error">{errors.reference}</p>
                ) : null} */}
              </div>
            </div>
            <div className="input-block">
              <label htmlFor="purchase date" className="input-label">
                  Employee Name
                </label>
                <div className="input-error">
                <select
                  type="text"
                  autoComplete="off"
                  name="requitionername"
                  id="requitionername"
                  // placeholder="Select a vendor"
                  value={requisitionedit.empId}
                  onChange={(e) => { handleChange(e) }}

                >
                  <option>Select Employee</option>
                  {employee.map((emp) => (
                    <option
                      value={emp.empId}
                      key={emp.id}
                    >
                      {emp.name}
                    </option>
                  ))}
                </select>
              </div>
              </div>
          </div>
          <div className="input-container">
            <div className="input-block">
              <label htmlFor="purchase date" className="input-label leftone">
                Requisition Date
              </label>
              <div className="input-error">
                <input type="date"
                  id="requisitiondate"
                  name="requisitiondate"
                  value={requisitionedit.requisitiondate}
                //  onChange={handlePurchaseDateChange}
                //   value={purchaseDate ? purchaseDate.toISOString().substr(0, 10) : ""}
                />

                {/* <input type="date" name="purchasedate" onChange={handlePurchaseDateChange} /> */}
                {/* {errors.purchasedate && touched.purchasedate ? (
                  <p className="form-error">{errors.purchasedate}</p>
                ) : null} */}
              </div>
            </div>
            <div className="input-block">
              <label htmlFor="purchase date" className="input-label leftone">
                Purchase Date
              </label>
              <div className="input-error">
                <input type="date"
                  id="purchaseDate"
                  name="purchaseDate"
                  value={purchaseDate.purchaseDate}
                  onChange={(e) => { handleChanges(e) }}
                />
                {receiveDateError && (
                  <p className="form-error">{receiveDateError}</p>
                )}
              </div>
            </div>
          </div>
          <div>
          <input
            type="hidden"
            name="adjustedby"
            id="adjustedby"
            value={gettoken}
          />
            <table id="contacttable">
              <thead>
                <tr>
                  <th style={{ width: "60px" }}>Sl No</th>
                  <th style={{ width: "220px" }}>Item Name</th>
                  <th>Supplier</th>
                  <th>Quantity In Stock</th>
                  <th>Requested Quantity </th>
                  <th>Requested Unit</th>
                  <th>Purchase Qunatity</th>
                  <th>Purchase Unit</th>
                  <th>Vehicle No</th>
                  <th>Delete</th>

                </tr>
              </thead>
              <tbody>
                {/* dynamic table */}

                {
                  addInventory.map((user, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>
                          <input
                            className="inputtable"
                            name="Name"
                            onChange={(e) => onChange(e, index)}
                            value={user.Name}
                          />
                          {/* <select
                            className="inputtable"
                            name="itemDetails"
                            onChange={(e) => replaceData(e, index, user.Name)}
                            value={user.Id}
                          >
                            <option value="">{user.Name}</option>

                            {itemList.map((values, index) => (
                              <option value={values.Id} key={values.Id}>
                                {values.Name}
                              </option>
                            ))}
                          </select> */}

                        </th>
                        <th>
                          <select
                            className="inputtable"
                            name="supplier"
                            onChange={(e) => onChange(e, index)}
                            value={user.supplierid}
                          >
                            <option value=" ">Select Supplier</option>
                            {/* Use the itemSuppliers state to populate the supplier options */}

                            {supplier[index]?.map((supplier) => (
                              <option value={supplier.supplierid} key={supplier.id}>
                                {supplier.name}
                              </option>
                            ))}
                          </select>
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="Quantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.quantityInStock}
                            readOnly
                          />
                        </th>
                         <th><input
                          className="inputtable"
                          name="requestQuantity"
                          //onChange={(e) => onChange(e, index)}
                          value={user.requestQuantity}
                          disabled
                        /></th>
                        <th><input
                          className="inputtable"
                          name="unit"
                          // onChange={(e) => onChange(e, index)}
                          value={user.unit}
                          disabled
                        /></th>
                        <th><input
                          className="inputtable"
                          name="purchaseQuantity"
                          onChange={(e) => onChange(e, index)}
                          value={user.purchaseQuantity}
                        /></th>
                         <th>
                            <select
                              className="inputtable"
                              name="unitforpurchase"
                              onChange={(e) => onChange(e, index)}
                              value={user.unitforpurchase}
                            >
                             <option>Select the unit</option>
                              <option>Pieces</option>
                              <option>Box</option> 
                              <option>Dozen</option>
                              <option>Meter</option>
                              <option>Kg</option> 
                              <option>Liter</option>
                             <option>Gallon</option>  
                            </select>
                          </th>
                        <th><input
                          className="inputtable"
                          name="vehicleno"
                          //onChange={(e) => onChange(e, index)}
                          value={user.vehicleno}
                        /></th>
                        <th><IoTrashOutline onClick={() => deleteRow(index)} /></th>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <div className="link">
              <Link to="#" className="addlink" onClick={addItemrow}>
                Add New Line
              </Link>
            </div>
            <div className="link">
              {" "}
              <Link to="/purechases/supplier" className="addlink">
                Add New Supplier
              </Link>
            </div>
          </div>
          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default GeneratePoFromRo
import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import axios from "../../../helper/axios"
import { GrEdit, GrPrint } from 'react-icons/gr';
import { useReactToPrint } from 'react-to-print';
import AGM_LOGO from '../../../components/Images/AGM_LOGO.png';
import { Helmet } from "react-helmet";
import API from '../../../helper/axios';
const RequisationDetails = () => {

  const history = useHistory();
  //getting the id of purchase oprder
  const { requisitionId } = useParams();
  //will store the loginId, role of login user
   const[loginId,setLoginId]=useState()
   const[role, setRole]=useState()
  //what ever data coming from backend it is updated here 
  const [requisitionedit, setRequisition] = useState(
    {
      requisitionId: "",
    });
  //file whatever we have uploaded will update here from backend
  const [file, setFile] = useState({ avatar: "" });

  const setimgfile = (e) => {
    setFile(e.target.files[0])
    console.log(e.target.files[0])
  }

  //here getting the token stored in sessionStorage
  const token = sessionStorage.getItem('jwtToken');
  //get all details of login user 
  const loadAll = async () => {
    try {
      const response = await axios.post('/Login', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].Id;
      const UserLogedinRole = response.data.person[0][0].role;
      setRole(UserLogedinRole)
     setLoginId(UserLogedin)

    } catch (error) {
      
    }
  };
  //whenever we load the page data will display
  useEffect(() => {
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    getRequisition()
    getitemdetails()
    loadAll ()
    //receive()
  }, []);
  //getting the data from backend according to the purchase id
  const getRequisition = async () => {
    const res = await axios.get(`/requisition/${requisitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setRequisition(newProduct)

  }
  //getting the dyanmic table price list data from backend according to the purchase id      
  const getitemdetails = async () => {
    const res = await axios.get(`/requisitionitem/${requisitionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newusers = await res.data;
    setDatas(newusers)
    setDataitem(newusers)
    //console.log(newusers)
  }
  const itemtemps = { Id: "", description: "", quantity: "", vehicleno: "", tax: "", supplier: "" }
  const [dataitem, setDataitem] = useState([itemtemps]);

  const itemtemp = { Id: "", description: "", quantity: "", vehicleno: "", tax: "", supplier: "" }
  const [datas, setDatas] = useState([itemtemp]);
  //to print the page
  const componentRef = useRef();
  const handelPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'request-data',

  })
  //getting the po stage
  const stage = requisitionedit.requisitionstage;

  const [poStage, setPoStage] = useState("");
  //when user will click on approve button this function will be called
  const handleApprove = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/requpdateapprovereject/${requisitionId}`, {
        requisitionstage: 'Approved',
        requisitionId: requisitionId
      }
        , {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        },);

      console.log(response.data); // Do something with the response data
      setPoStage('Approved');
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
     // Step 1: Add state variables for pop-up form
 
     const [showRejectPopup, setShowRejectPopup] = useState(false);
  // Step 2: Create the pop-up form component
  const RejectPopupForm = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [rejectReason, setRejectReason] = useState('');
    //onchange function for pup-up form
    const handleEdit = (e) => {
        setRejectReason(e.target.value)
        setErrorMessage('');
      };
      //when will click on reject button this function will be called to submit the data
      const handleRejectSubmit = async () => {
        try {
            if (!rejectReason) {
                setErrorMessage("Please enter a reason for rejection.");
                return;
              }
          const response = await axios.post(`/requpdateapprovereject/${requisitionId}`, {
            requisitionstage: 'Rejected',
            requisitionId: requisitionId,
            reason: rejectReason, // Include the reason in the request payload
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
    
          console.log(response.data); // Do something with the response data
          setPoStage('Rejected');
          window.location.reload();
        } catch (error) {
          console.error(error);
        }
      };
    
    return (
      <div className="reject-popup">
        <h3>Provide Reason for Rejection</h3>
        <textarea
        type="text"
        name="rejectReason"
          value={rejectReason}
          onChange={ handleEdit}
          placeholder="Enter reason for rejection..."
        />
         {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        <button onClick={handleRejectSubmit}>Submit</button><br/><br/>
        <button onClick={() => setShowRejectPopup(false)}>Cancel</button>
      </div>
    );
  };

  // Step 3: Show the pop-up form when "Reject" button is clicked
  const handleReject = () => {
    setShowRejectPopup(true);
  };

  
  return (
    <div>
      <Helmet> <title>Purchase Details|BBS Inventory Management</title>

      </Helmet>
      <div className='editbu'>
        <button className='input-bu' onClick={handelPrint}><GrPrint /></button>
        {/* when the stage is "Pending Approval  or Draft the only the edit button will displayed" */}

        {(stage === 'Pending Approval' || stage === 'Draft') && (
          <button className='input-bu'>
            <NavLink to={`/requisitiondetails/${requisitionId}/edit`}>
              <GrEdit />
            </NavLink>
          </button>
        )}
        {/* { (stage === 'Received') && (
  <button className='input-bu'>
    <NavLink to={`/purechases/eachpurchase/${requisitionId}/receive`}>
      <GrEdit />
    </NavLink>
  </button>
)} */}

        <div>
          {/* When the stage is "Approved and Admin Or Supperadmin then Generate PO button will show" */}
          {stage === 'Approved' && (role === 'Admin' || role === 'Supperadmin') && (
            <NavLink to={`/purechases/purchaseorders/${requisitionId}`}><button className='input-button' >Generate PO</button></NavLink>
          )}
          {/* When the stage is "Pending Approva then Approve button will show" */}
          {stage === 'Pending Approval' && (loginId === 1 || loginId === 10) && (
  <button className='input-button' type="submit" onClick={handleApprove}>Approve</button>
)}
{/* When the stage is "Pending Approva then Reject button will show" */}
          {stage === 'Pending Approval' && loginId === 1 && (
            <button className='input-button' type="submit" onClick={handleReject} >Reject</button>
          )}
        </div>
        
      </div>
      {/* When the stage is "Rejected" */}
      {stage === 'Rejected' &&  (
            <div style={{ padding: "10px", height: "50px" }}><h4 style={{ color: "Red", }}>Rejection Reason : {requisitionedit.approverComment}</h4>
            </div>
          )}
      {/* Show the RejectPopupForm component if showRejectPopup is true */}
      {showRejectPopup && <RejectPopupForm />}
      <div style={{ padding: "10px", height: "50px" }}  ><h4 style={{ color: "blue", }}>Requisition Stage : {requisitionedit.requisitionstage}</h4></div>
      <div ref={componentRef} style={{ padding: "20px" }}>
      
        <form style={{ border: "1px solid black" }}>
          <div className="headerdiv">
            <div className="element1">
              <img className="logo2" src={AGM_LOGO} alt="logo" />
            </div>
            <div className="element2" >
              <p style={{ marginTop: "4px" }}><h4>Company Address</h4>
              </p>
            </div>
            <div className="element2">
              <h3>TAX INVOICE</h3>
            </div>
          </div>
          <hr />
          <div className="parentdiv">
            <div className="element1">
              <div className="element11">
                Requisition Order : <br /><br />
                Requisition By :<br /><br />
              </div>

              <div className="element22">
                {requisitionedit.idforrequisition}<br /><br />
                {requisitionedit.employee_name}<br /><br />
              </div>

            </div>
            <div className="element2">
              <div className="element11">
                Requisition No:<br /><br />
                Requisition Date:
              </div>

              <div className="element22">
                {requisitionedit.reference}<br /><br />
                {requisitionedit.requisitiondate}
              </div>

            </div>
          </div><hr />

          <div>
            <div style={{ margin: "10px" }}>Item List </div>
            <div>
              <table id="contacttable">
                <thead>
                  <tr>
                    {/* <th>Item Details</th>
                    <th>Account</th>
                    <th>Quantity oF Item Ordered</th>
                    {stage === 'Received' && (<th>Quantity oF Item Received</th>)}
                    <th>vehicleno</th>
                    <th>Tax</th>*/}
                    <th style={{ width: "60px" }}>Sl No</th>
                    {/* <th>supplier</th> */}
                    <th style={{ width: "250px" }}>Item Name</th>
                    {/* <th style={{ width: "280px" }}>Description</th>
                    <th>Quantity On Stock </th> */}
                    <th>Quantity </th>
                    <th>Unit </th>
                    <th>Vehicle Number</th>
                    <th>Others</th>
                    {/* <th>Supplier</th> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    datas.map((user, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          {/* <th><input
                            className="inputtable"
                            name="supplier"
                            disabled
                            value={user.name}
                          /></th> */}
                          <th><input
                            className="inputtable"
                            name="itemname"

                            value={user.Name}
                          /></th>
                          {/* <th> <input
                            className="inputtable"
                            name="description"
                            value={user.description}

                          /></th>
                          <th><input
                            className="inputtable"
                            name="quantity"

                            value={user.quantity}
                          /></th> */}
                          <th><input
                            className="inputtable"
                            name="requestQuantity"

                            value={user.requestQuantity}
                          /></th>
                          <th><input
                            className="inputtable"
                            name="unit"
                            value={user.unit}
                          /></th>
                          <th><input
                            className="inputtable"
                            name="vehicleno"
                            disabled
                            value={user.vehicleno}
                          /></th>
                           <th><input
                            className="inputtable"
                            name="others"
                            disabled
                            value={user.others}
                          /></th>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className='notes'>
              <div className="comment">
                <div className="input-block-text">
                  <label htmlFor="customernotes" className="input-label">
                    Customer Notes
                  </label>
                  <div className="input-each">
                    <input
                      // className="textareas"
                      // rows="3" cols="52"
                      type="text"
                      autoComplete="off"
                      name="customernotes"
                      id="customernotes"

                      value={requisitionedit.customernotes}

                    />
                  </div>
                </div>
              </div>
              <div >
              </div>
            </div>
            <div >
              <div className='notes'>
                <div>
                  {/* <div className="input-block-text">
                  <label htmlFor="termscondition" className="input-label">
                    Terms & Conditions
                  </label>
                  <div className="input-each">
                    <input
                      // className="textareas"
                      // rows="3" cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"

                      value={requisitionedit.termsconditions}
                      disabled
                    />
                  </div>
                </div> */}
                </div>
                <div className='purchaseorderfile'>

                  {requisitionedit.avatar}
                  <img src={`${API.defaults.baseURL}/uploadimage/uploads/${requisitionedit.avatar}`} />
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  )
}

export default RequisationDetails
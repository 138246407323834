
import React, { useState,useEffect } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import axios from "../../../helper/axios"
import {GrEdit} from 'react-icons/gr';
import Cookies from 'js-cookie';
const EachInvoiceRecord = () => {

    const history =useHistory();
    const {invoiceid}=useParams();
    
    const[invoiceedit, setInvoiceedit]=useState(
        { 
            invoicenumber: "",
            invoicedate:"",
            suppliername:"",
            insertedby:"",
            withouttax: "",
            withtax:"",
             tax:"",
             purchaser:"",
    });
 
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        editInvoice()
        
        },[]);
        const token = sessionStorage.getItem('jwtToken');
        const editInvoice = async ()=>{
            const res = await axios.get(`/AllIinvoice/${invoiceid}`, {
              headers: { 
                Authorization: `Bearer ${token}`,
              },
            });
            const newProduct = await res.data[0];
            setInvoiceedit(newProduct)
           
            }
            
   
  return (
    <> <div className='editbu'>
    <button className='input-bu'><NavLink to={`/invoicerecord/${invoiceid}/edit`}><GrEdit/></NavLink></button>
       </div>
      <div className="">
      
       <form>
       <h4></h4>
       <div className="input-employeecontainer"> 
       
                  <div className="input-blockemployee">
                    <label htmlFor="invoicenumber" className="input-labelemployee">
                    Invoice Number
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="invoicenumber"
                      id="invoicenumber"
                      placeholder="Invoice Number"
                      value={invoiceedit.invoicenumber}
                      
                    />
                    
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="invoicenumber" className="input-labelemployee">
                    Invoice Date
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="date"
                      autoComplete="off"
                      name="invoicedate"
                      id="invoicedate"
                     
                      placeholder="Invoice Date"
                      value={invoiceedit.invoicedate}
                     
                    />
                    
                  </div>
                  </div></div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="suppliername" className="input-labelemployee">
                    Supplier Name
                  </label>
                  <div className="inputerroremployee">
                    
                  <input
                    type="text"
                    autoComplete="off"
                    name="suppliername"
                    id="suppliername"
                    placeholder="Supplier Name"
                    value={invoiceedit.name}
                   
                  />
                   {/* <option>Select Supplier Name</option>
                   {supplier.map(supplier => (
              <option value={supplier.supplierid} key={supplier.id} >{supplier.name}</option>
    
              )
              )
              } */}
                 
                  </div>

                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="insertedby" className="input-labelemployee">
                    Inserted By
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="insertedby"
                      id="insertedby"
                      placeholder=" Inserted By"
                      
                      value={invoiceedit.enterby}
                     
                    />
                   
                    </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                  <label htmlFor="withouttax" className="input-labelemployee">
                  Total invoice without Tax
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="text"
                      autoComplete="off"
                      name="withouttax"
                      id="withouttax"
                      placeholder="Without Tax"
                      value={invoiceedit.withouttax}
                     
                    />
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="withtax" className="input-labelemployee">
                  Total invoice with Tax
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="text"
                      autoComplete="off"
                      name="withtax"
                      id="withtax"
                      placeholder="With Tax"
                      value={invoiceedit.withtax}
                     
                    />
                   

                  </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="tax" className="input-labelemployee">
                    Tax
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="text"
                      autoComplete="off"
                      name="tax"
                      id="tax"
                      placeholder="Tax"
                      value={invoiceedit.tax}
                     
                    />
                    
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="purchaser" className="input-labelemployee">
                  Purchaser
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="text"
                      autoComplete="off"
                      name="purchaser"
                      id="purchaser"
                      placeholder="Purchaser"
                      value={invoiceedit.purchaser}
                     
                      />
      
              
                  </div>
                  
                
                  </div>

                  </div>

                 
                </form>
                </div>   
    </>
  )
}

export default EachInvoiceRecord
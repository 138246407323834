

import React from 'react'
import { useEffect, useState } from 'react';
import { useParams,useHistory} from 'react-router-dom';
import axios from '../../../helper/axios';
import { IoTrashOutline} from "react-icons/io5";
import Cookies from 'js-cookie';

const Description = () => {
  const history=useHistory()
    const {referencenumber}=useParams();
    
    
  
      const[eachitem, setEachitem]=useState({ Id:"",referencenumber:"",
      date:"",account:"",reason:"",quantityavailable:"",newquantity:"",
      quantityadjusted:"",description:"",adjustedby:" "
    });
    const token = sessionStorage.getItem('jwtToken');
      
      useEffect(()=>{
        // if(!Cookies.get('jwtToken')){
        //   return history.push("/");
        // }
   
          if (!sessionStorage.getItem('jwtToken')) {
            return history.push('/');
          }
          getInventory()
          
       
      },[]);

      
      const getInventory = async ()=>{
           const res = await axios.get(`/Iteminventory/${referencenumber}`, {
            headers: { 
              Authorization: `Bearer ${token}`,
            },
          });
         
           const newInventory = await res.data[0];
           setEachitem(newInventory);
          console.log(newInventory);
         
           }
        

                   
              return (
                <div>
                
            
            
    

      
      
        <div className="input-container">        
       <div className="input-blockerror">
<label  className="input-label" >
  Item
  </label>
  <div className="input-error">
 
  

   <input 
       type="text"
         autoComplete="off"
         
         id="Id"
         name="Id"
         placeholder=""
         value={eachitem.Name} />
       
        

          
        

</div>
</div>
<div className="input-blockerror">
        <label className="input-label">Date</label>
        <div className="input-error">
       
        <input type="text"
          autoComplete="off"
          id="date"
          name="date"
          placeholder="date"
          value={eachitem.date} 
        
          /> 
         
        
        </div>
        </div>
        </div>
        <div className="input-container">        
       <div className="input-blockerror">
        <label className="input-label">Account</label>
        <div className="input-error">
        <input
          className="inputselect"
            type="text"
                      
              autoComplete="off"
                      name="account"
                      id="account"
                      placeholder="Cost of Goods Sold"
                      value={eachitem.account}
                     
                   />
                     
              
                   
                    
        
        
        </div>
        </div>

        <div className="input-blockerror">
        <label className="input-label">Reason</label>
        <div className="input-error">
       
        <input
          className="inputselect"
            type="text"
                      
              autoComplete="off"
                      name="reason"
                      id="reason"
                      placeholder="Select a reason"
                      value={eachitem.reason}
                    
                   />
                      
              
                   
        
                    </div>
        </div>
        </div>
        <div className="input-container">        
       <div className="input-blockerror">
        <label className="input-label">Quantity Available</label>
        <div className="input-error">
        <input
               className="inputselect"       
              autoComplete="off"
              type="text"
                      name="quantityavailable"
                      id="quantityavailable"
                      placeholder=""
                     value={eachitem.quantityavailable}/>

        </div>
        </div>

        <div className="input-blockerror">
        <label className="input-label">New Quantity</label>
        <div className="input-error">
       
        <input
          className="inputselect"
            type="number"
                      
              autoComplete="off"
                      name="newquantity"
                      id="newquantity"
                      placeholder=""
                      value={eachitem.newquantity}
                      
                    />
                    
                    
                    
        
                    </div>
        </div>
        </div>
        <div className="input-container">
        <div className="input-blockerror">
        <label className="input-label">Quantity Adjusted</label>
        <div className="input-error">
        <input className="inputselect"
        type="number"
          autoComplete="off"
         
          id="quantityadjusted"
          name="quantityadjusted"
          placeholder=""
          value={eachitem.quantityadjusted} 
        
          /> 
         
              </div>     
        </div>        
        <div className="input-blockerror">
                    <label htmlFor="adjustedby" className="input-label">
                    Adjusted By
                    </label>
                    <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="adjustedby"
                      id="adjustedby"
                      placeholder="Inserted By"
                      value={eachitem.adjustedby}

                    />
        
       </div></div>
        </div>
                
        <div className="input-blockerror">
        <label className="input-label">Description</label>
        <div className="input-error">
        <textarea className="inputselecttext"
         rows="3" cols="38"
          autoComplete="off"
          id="description"
          name="description"
          placeholder=""
          value={eachitem.description} 
         
          /> 
          
              </div>     
        </div>

    </div>
    
    
                
              )
            }
            
            export default Description;
            
            
            
            
            
            
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { insurance } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import  { useHistory } from 'react-router-dom'

const initialValues = {
  
    policyno: "",
    vehicleno:"",
    policyholder:"",
    effectivedate:"",
    expirydate: "",
    insurancecompany:"",
    lastcost:"",
    presentcost:"" 
     };
     
const Insurance = () => {
  const history = useHistory();
    const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
      useFormik({
        initialValues,
        validationSchema: insurance,
        
        onSubmit: async(values, action,) => {
         
        try {

            //const response = await axios.post("/InsurancePost",values)
            const data = new FormData();
            data.append("document", JSON.stringify(values));
            data.append("avatar",file);
            
            const response = await axios.post("/InsurancePost",data,{
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              }
            });  
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/Insurance');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
        action.resetForm();
        },
        
      });
      const token = sessionStorage.getItem('jwtToken');
      const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const[file,setFile]=useState("");
    const[getVehicle,setGetVehicle]=useState([]);
    const setimgfile=(e)=>{
     setFile(e.target.files[0])
     console.log(e.target.files[0])
    }
    useEffect(()=>{
      loadVehicle()
       
       },[]);
      
       const loadVehicle = async ()=>{
           const res = await axios.get(`/RcGetvehicle`, {
             headers: { 
               Authorization: `Bearer ${token}`,
             },
           });
           const newProduct = await res.data;
           console.log(newProduct)
           setGetVehicle(newProduct)
          
           }
    
    return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
  
        <div className="">
          
         <form onSubmit={handleSubmit} >
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting Insurance details. Please try again later.</div>}
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="policyno" className="input-labelemployee leftone">
                      Policy Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="policyno"
                        id="policyno"
                        placeholder="Policy Number"
                        value={values.policyno}
                        onChange={handleChange}
                      onBlur={handleBlur}
                      />
                     {errors.policyno && touched.policyno ? (
                      <p className="form-erroremployee">{errors.policyno}</p>
                    ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="vehicleregno" className="input-labelemployee leftone">
                    Vehicle No
                      </label>
                      <div className="inputerroremployee">
                      <select
                        type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                        
                        placeholder="Vehicle No"
                        value={values.vehicleno}
                        onChange={handleChange}
                      onBlur={handleBlur}
                      >
                        <option>Select Vehicle</option>
                        {getVehicle.map(vehicle => (
                    <option value={vehicle.vehicleno} key={vehicle.id} >{vehicle.vehicleno}</option>

                  ))
                  }
                        </select>
                       {errors.vehicleno && touched.vehicleno ? (
                      <p className="form-erroremployee">{errors.vehicleno}</p>
                    ) : null}
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="policyholder" className="input-labelemployee">
                      Policy Holder
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="policyholder"
                      id="policyholder"
                      placeholder="Policy Holder Name"
                      value={values.policyholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    /> 
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor=" effectivedate" className="input-labelemployee leftone">
                      Issue Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="effectivedate"
                        id="effectivedate"
                        placeholder="Start Of Cover"
                        value={values.effectivedate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.effectivedate && touched.effectivedate ? (
                      <p className="form-erroremployee">{errors.effectivedate}</p>
                    ) : null}
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="expirydate" className="input-labelemployee leftone">
                    Expiry Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="expirydate"
                        id="expirydate"
                        placeholder="End Of Cover"
                        value={values.expirydate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.expirydate && touched.expirydate ? (
                      <p className="form-erroremployee">{errors.expirydate}</p>
                    ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="insurancecompany" className="input-labelemployee">
                    Insurance Company
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="insurancecompany"
                        id="insurancecompany"
                        placeholder="Insurance Taken From"
                        value={values.insurancecompany}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    </div>
                    </div>

                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="lastcost" className="input-labelemployee">
                    Last Insurance Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="lastcost"
                        id="lastcost"
                        placeholder="Last Insurance Cost"
                        value={values.lastcost}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="presentcost" className="input-labelemployee">
                    Present Insurance Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="presentcost"
                        id="presentcost"
                        placeholder="Present Insurance Cost"
                        value={values.presentcost}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="Image" className="input-labelemployee" >Insurance Certificate</label>
                      <div className="inputerroremployee">
                      <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""
                          onChange={setimgfile}
                           />
                           </div>
                     </div> 
                      </div>   
                    <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>
                  </form>
                  </div>     
        </>
      )
}

export default Insurance



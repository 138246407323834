import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams } from 'react-router-dom'
import { GrEdit } from 'react-icons/gr';

const EachRoadTax = () => {
  //**here will get the roadtaxid from the url by using the useParams hook */
  const { roadtaxid } = useParams();
  // will update all the value for all input fields of this form here
  const [roadtax, setRoadtax] = useState({
    vehicleno: "",
    ownername: "",
    taxhead: "",
    taxmode: "",
    roadtaxfrom: "",
    taxupto: "",
    roadtaxamount: "",
    penalty: "",
    status: ""
  })
  // will get the token from  sessionStorage
  const token = sessionStorage.getItem('jwtToken');
  //** when page will load or refresh  specific roadtax details will load */
  useEffect(() => {
    eachPucc()
  }, []);
  //** will get specific Roadtax details according to roadtaxid  */
  const eachPucc = async () => {
    const res = await axios.get(`/RoadTaxGet/${roadtaxid}`, {
        //to authorize the api send the token header
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setRoadtax(newProduct)
  }

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <div className='editbu'>
        <button className='input-bu'><NavLink to={`/Vehicle/roadtax/${roadtaxid}/edit`}><GrEdit /></NavLink></button>
      </div>
      <div className="">
        <form >
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="vehicleno" className="input-labelemployee">
                Vechicle Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="vehicleno"
                  id="vehicleno"
                  placeholder="Registration Number"
                  value={roadtax.vehicleno}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="ownername" className="input-labelemployee">
                Owner Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="ownername"
                  id="ownername"
                  placeholder="Owner Name"
                  value={roadtax.ownername}
                />
              </div>
            </div></div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="taxhead" className="input-labelemployee">
                Road Tax Head
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="taxhead"
                  id="taxhead"
                  placeholder="Road Tax Head"
                  value={roadtax.taxhead}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="taxmode" className="input-labelemployee">
                Road Tax Mode
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="taxmode"
                  id="taxmode"
                  placeholder="Road Tax Mode"
                  value={roadtax.taxmode}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="roadtaxfrom" className="input-labelemployee">
                Road Tax From
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="roadtaxfrom"
                  id="roadtaxfrom"
                  placeholder="Road Tax From"
                  value={roadtax.roadtaxfrom}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="taxupto" className="input-labelemployee">
                Road Tax Upto
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="taxupto"
                  id="taxupto"
                  placeholder="Road Tax Upto"
                  value={roadtax.taxupto}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="roadtaxamount" className="input-labelemployee">
                Road Tax Amout
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="roadtaxamount"
                  id="roadtaxamount"
                  placeholder="Road Tax Amout"
                  value={roadtax.roadtaxamount}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="penalty" className="input-labelemployee">
                Penalty
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="penalty"
                  id="penalty"
                  placeholder="Penalty"
                  value={roadtax.penalty}
                />
              </div>
            </div>
          </div>
          {/* added a field called sataus here */}
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="status" className="input-labelemployee">
                Status
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"
                  value={roadtax.status}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EachRoadTax;
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { permit } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import  { useHistory } from 'react-router-dom'
const initialValues = {
  
   vehicleno: "",
   chassisno:"",
    ownername:"",
    npauth:"",
    state: "",
    permittype:"",
    permitfrom:"",
     permitupto:"",
     vechileclass:"",
     cost:""
    };
    
  
    const NewPermitDetails= () => {
      //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  // //**the history object to navigate the user to a different route by calling  return history.push('/Permit'); */
      const history = useHistory();
      const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
      useFormik({
        initialValues,
        validationSchema: permit,
        
        onSubmit: async(values, action,) => {
         
        try {
            const data = new FormData();
            data.append("document", JSON.stringify(values));
            data.append("avatar",file);
            
            const response = await axios.post("/PermitPost",data,{
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              }
            });
  
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/Permit');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
        action.resetForm();
        },
        
      });
      const token = sessionStorage.getItem('jwtToken');
      const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
      
    const[file,setFile]=useState("");

       const setimgfile=(e)=>{
        setFile(e.target.files[0])
        console.log(e.target.files[0])
       }
       const[getVehicle,setGetVehicle]=useState([]);
       useEffect(()=>{
        loadVehicle()
         
         },[]);
        
         const loadVehicle = async ()=>{
             const res = await axios.get(`/RcGetvehicle`, {
               headers: { 
                 Authorization: `Bearer ${token}`,
               },
             });
             const newProduct = await res.data;
             console.log(newProduct)
             setGetVehicle(newProduct)
            
             }
      return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
  
        <div className="">
          
         <form onSubmit={handleSubmit}>
          
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
        {error && <div className="form-error">Error submitting permit Details. Please try again later.</div>}
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="vehicleno" className="input-labelemployee leftone">
                      Vehcile No
                      </label>
                      <div className="inputerroremployee">
                      <select
                        type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                       
                        placeholder="Registration Number"
                        value={values.vehicleno}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ><option>Select Vehcile No</option>
                      {getVehicle.map(vehicle => (
                    <option value={vehicle.vehicleno} key={vehicle.id} >{vehicle.vehicleno}</option>

                  ))
                  }
                        </select>
                       {errors.vehicleno && touched.vehicleno ? (
                        <p className="form-erroremployee">{errors.vehicleno}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="chassisno" className="input-labelemployee">
                    Chassis Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="chassisno"
                        id="chassisno"
                       
                        placeholder="Chassis Number"
                        value={values.chassisno}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.chassisno && touched.chassisno ? (
                        <p className="form-erroremployee">{errors.chassisno}</p>
                      ) : null}
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="ownername" className="input-labelemployee">
                      Owner Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="ownername"
                      id="ownername"
                      placeholder="Owner Name"
                      value={values.ownername}
                      onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    {errors.ownername && touched.ownername ? (
                        <p className="form-erroremployee">{errors.ownername}</p>
                      ) : null}  
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="npauth" className="input-labelemployee">
                      Np Auth Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="npauth"
                        id="npauth"
                        placeholder="Np Auth Number"
                        value={values.npauth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.npauth && touched.npauth ? (
                        <p className="form-erroremployee">{errors.npauth}</p>
                      ) : null}
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="state" className="input-labelemployee leftone">
                  Permit For State
                      </label>
                      <div className="inputerroremployee">
                      <select
                      
                      type="text"
                        autoComplete="off"
                        name="state"
                        id="state"
                        placeholder="state"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select State</option>
                        <option>All Over India</option>
                        <option> Andaman and Nicobar Islands</option>
                        <option>Andhra Pradesh</option>
                        <option>Arunachal Pradesh</option>
                        <option>Assam</option>
                        <option>Bihar</option>
                        <option>Chandigarh</option>
                        <option>Chhattisgarh</option>
                        <option>Dadra and Nagar Haveli</option>
                        <option>Daman and Diu</option>
                        <option>Delhi</option>
                        <option>Gujarat</option>
                        <option>Haryana</option>
                        <option>Himachal Pradesh</option>
                        <option>Jammu and Kashmir</option>
                        <option>Jharkhand</option>
                        <option>Karnataka</option>
                        <option>kerala</option>
                        <option>Lakshadweep</option>
                        <option>Madhya Pradesh</option>
                        <option>Maharashtra</option>
                        <option>Manipur</option>
                        <option>Meghalaya</option>
                        <option>Mizoram</option>
                        <option>Nagaland</option>
                        <option>Puducherry</option>
                        <option>Punjab</option>
                        <option>Rajasthan</option>
                        <option>Sikkim</option>
                        <option>Tamil Nadu</option>
                        <option>Telangana</option>
                        <option>Tripura</option>
                        <option>Uttar Pradesh</option>
                        <option>Uttarakhand</option>
                        <option>West Bengal</option>

                      </select>
                       {errors.state && touched.state ? (
                        <p className="form-erroremployee">{errors.state}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="permittype" className="input-labelemployee">
                    Permit Type
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="permittype"
                        id="permittype"
                        placeholder="Tax Upto"
                        value={values.permittype}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                     
                     {errors.permittype && touched.permittype ? (
                        <p className="form-erroremployee">{errors.permittype}</p>
                      ) : null}
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="permitfrom" className="input-labelemployee leftone">
                      Permit From
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="permitfrom"
                        id="permitfrom"
                        placeholder="Class"
                        value={values.permitfrom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.permitfrom && touched.permitfrom ? (
                        <p className="form-erroremployee">{errors.permitfrom}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="permitupto" className="input-labelemployee leftone">
                    Permit Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                        type="date"
                        autoComplete="off"
                        name="permitupto"
                        id="permitupto"
                        placeholder="permitupto"
                        value={values.permitupto}
                        onChange={handleChange}
                        onBlur={handleBlur}/>
        
        {errors.permitupto && touched.permitupto ? (
                        <p className="form-erroremployee">{errors.permitupto}</p>
                      ) : null}
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    {/* <label htmlFor="vechileclass" className="input-labelemployee">
                    Class Of Vehicle
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                        type="text"
                        autoComplete="off"
                        name="vechileclass"
                        id="vechileclass"
                        placeholder="Class Of Vehicle"
                        value={values.vechileclass}
                        onChange={handleChange}
                        onBlur={handleBlur}/> */}
                         <label htmlFor="vechileclass" className="input-labelemployee">
                      Type Of Vehicle
                      </label>
                      <div className="inputerroremployee">
                      <select
                      
                      type="text"
                        autoComplete="off"
                        name="vechileclass"
                        id="vechileclass"
                        // placeholder="Class"
                        value={values.vechileclass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select type</option>
                        <option>TATA HYVA TIPPER</option>
                        <option>PICK-UP</option>
                        <option>TATA PRIMA TIPPER</option>
                        <option>DOZZER</option>
                        <option>LIGOUNG</option>
                        <option>CAT-330</option>
                        <option>TATA HITACHI</option>
                        <option>VOLVO MACHINE</option>
                        <option>JCB BREAKER</option>
                        <option>BOLERO</option>
                        <option>TATA LEYLAND TIPPER</option>
                        <option>LOADER</option>
                        <option>THAR</option>
                        <option>TIPPER</option>
                        <option>OTHERS</option>
                      </select>
        
        {errors.vechileclass && touched.vechileclass ? (
                        <p className="form-erroremployee">{errors.vechileclass}</p>
                      ) : null}
                    </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="cost" className="input-labelemployee">
                    Permit Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                        type="text"
                        autoComplete="off"
                        name="cost"
                        id="cost"
                        placeholder="Permit Cost"
                        value={values.cost}
                        onChange={handleChange}
                        onBlur={handleBlur}/>
        
        {errors.cost && touched.cost ? (
                        <p className="form-erroremployee">{errors.cost}</p>
                      ) : null}
                    </div>
                    </div>
                      </div>
                      <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="Image" className="input-labelemployee" >Chose Permit Certificate</label>
                      <div className="inputerroremployee">
                      <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""
                          onChange={setimgfile}
                           />
                           </div>
                     </div> 
                      </div>                   
                      <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>
                   
                  </form>
                  </div>     
        </>
      )
    }
    
    export default NewPermitDetails;
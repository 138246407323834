import React from 'react'
import Sidebar from '../../components/Sidebar';
import { NavLink } from 'react-router-dom';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import Pollution from './Document/Pollution';
import ListViewPollution from './Document/ListViewPollution';
import EachPollutionCertificate from './Document/EachPollutionCertificate';
import EditPucc from './Document/EditPucc';
import PollutionDropdown from '../../components/Dropdown/PollutionDropdown';
import ListviewAllPollution from './Document/ListviewAllPollution';
import InactiveListviewPollution from './Document/InactiveListviewPollution';

//will display the new form to enter polution details
export const PollutionDetails = () => {
    return (
      //implemeted side bar here
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>New Pollution Details</h3>
      </div>
          <div className='container' >
            {/* render the new pollution form */}
           <Pollution/>
          </div>
          </div>
      </div>
      </div>
    )
  };
  //will display the listview page for active Pollution detail
  export const VehiclePucc = () => {
    return (
      //implemented the side bar here
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
          {/* Implemented the filter dropdown for pollution  */}
          <PollutionDropdown/>
      <div className='title1'>
        {/* when will click on this new button will navigate to the new pollution page */}
         <NavLink to='/Vehicle/polution'><button className="allbutton" >New</button></NavLink> 
         {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
            {/* searchbar, dynamic table and pagination for active Pollution is inside  <ListViewPollution/> */}
  <ListViewPollution/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  //will display the listview page for inactive Pollution detail
  export const VehicleInactivePucc = () => {
    return (
      //implemented the side bar here
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
          {/* Implemented the filter dropdown for pollution  */}
          <PollutionDropdown/>
      <div className='title1'>
         {/* when will click on this new button will navigate to the new pollution page */}
         <NavLink to='/Vehicle/polution'><button className="allbutton" >New</button></NavLink> 
         {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
             {/* searchbar, dynamic table and pagination for inactive Pollution is inside  <InactiveListviewPollution/> */}
  <InactiveListviewPollution/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  //will display the listview page for active and inactive Pollution detail
  export const AllVehiclePucc = () => {
    return (
      //implemented the side bar here
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
          {/* Implemented the filter dropdown for pollution  */}
          <PollutionDropdown/>
      <div className='title1'>
         {/* when will click on this new button will navigate to the new pollution page */}
         <NavLink to='/Vehicle/polution'><button className="allbutton" >New</button></NavLink> 
         {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
             {/* searchbar, dynamic table and pagination for active, inactive Pollution is inside  <ListviewAllPollution/> */}
  <ListviewAllPollution/>
          </div>
        </div>
      </div>
      </div>
    );
  };
//will display the indivisual Pollution detail page
  export const EachPollutionDetails = () => {
    return (
      //implemented sidebar here
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Enter Each Pollution Details</h3>
      </div>
          <div className='container' >
      {/* render indivisual Pollution detail form here */}
           <EachPollutionCertificate/>
          </div>
          </div>
      </div>
      </div>
    )
  };
//will display the indivisual Pollution detail edit page
  export const EditPollutionDetails = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Enter New Pollution Details</h3>
      </div>
          <div className='container' >
             {/* render indivisual Pollution detail edit form here */}
           <EditPucc/>
          </div>
          </div>
      </div>
      </div>
    )
  };
import React from "react";

import { NavLink } from "react-router-dom";

import ReactHtmlTableToExcel from "react-html-table-to-excel";

import Purchaseorder from "./Purchases/Purchaseorder";
import Allpurchase from "./Purchases/Allpurchase";
import NewSupplier from "./Supplier/NewSupplier";
import Allsupplier from "./Supplier/Allsupplier";
import Supplierdropdown from "../../components/Dropdown/Supplierdropdown";
import EachSupplier from "./Supplier/EachSupplier";
import EditSupplier from "./Supplier/EditSupplier";
import EachPurchase from "./Purchases/EachPurchase";
import EditPurchase from "./Purchases/EditPurchase";
import Sidebar from "../../components/Sidebar";
import AllInactiveSupplier from "./Supplier/AllInactiveSupplier";
import SupplierInData from "./Supplier/SupplierInData";
import Purchasedropdown from "../../components/Dropdown/Purchasedropdown";
import AllInactivepurchases from "./Purchases/AllInactivePurchase";
import AllthePurchaseOrder from "./Purchases/AllthePurchaseOrder";
import ReceivedItem from "./Purchases/ReceivedItem";
import GenerateSo from "../Sales/GenerateSo";


export const Purchases = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">Purchases</div>
      </div>
    </div>
  );
};
// this will show the content for creating new Supplier
export const Supplier = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>New Supplier </h3>
          </div>
          <div className="container">
            <NewSupplier />
          </div>
        </div>
      </div>
    </div>
  );
};
// this will show the content for creating new Purchaseorder
export const Purchaseorders = () => {
  return (
    <div>
       {/*implemented sidebar component */}
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>New Purchase </h3>
          </div>
          <div className="container">
            {/* implemented the new purchase order form using  <Purchaseorder /> component*/}
            <Purchaseorder />
          
          </div>
        </div>
      </div>
    </div>
  );
};

//when we will click on any specific supplier it will show the content
export const SupplierName = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Supplier details</h3>
          </div>
          <div className="container">
            <EachSupplier />
          </div>
        </div>
      </div>
    </div>
  );
};
//this will show the edit content for supplier
export const SupplierNameEdit = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Edit Supplier details</h3>
          </div>
          <div className="container">
            <EditSupplier />
          </div>
        </div>
      </div>
    </div>
  );
};
//this will show the user details
export const userName = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>user details</h3>
          </div>
          <div className="container"></div>
        </div>
      </div>
    </div>
  );
};
//when we will click on any specific purchase order it will show the content
export const PurchaseName = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Purchase details</h3>
          </div>
          <div className="container">
            <EachPurchase />
          </div>
        </div>
      </div>
    </div>
  );
};
//this will show the edit details of purchase
export const PurchaseNameEdit = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Edit Purchase details</h3>
          </div>
          <div className="container">
            <EditPurchase />
          </div>
        </div>
      </div>
    </div>
  );
};
// this will show the content for All active Purchase
export const Allpurchaseorder = () => {
  return (
    <div>
       {/*implemented sidebar component */}
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
         {/* implemented filter dropdown for Purchase here */}
            <Purchasedropdown />
            <div className="title1">
              {/* given the path for new button when will click the new button will open new Purchase form */}
              <NavLink to="/purechases/purchaseorders">
                <button className="allbutton">New</button>
              </NavLink>
               {/* to export the table in to excel file given the button */}
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="customers"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export File"
              />
            </div>
          </div>
  {/* searchbar, dynamic table and pagination for active purchase is inside  <Allpurchase /> */}
          <div className="container2">
            <Allpurchase />
          </div>
        </div>
      </div>
    </div>
  );
};
// this will show the content for All inactive Purchase
export const AllInactivepurchaseorder = () => {
  return (
    <div>
      {/*implemented sidebar component */}
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
   {/* implemented filter dropdown for Purchase here */}
            <Purchasedropdown />
            <div className="title1">
                {/* given the path for new button when will click the new button will open new Purchase form */}
              <NavLink to="/purechases/purchaseorders">
                <button className="allbutton">New</button>
              </NavLink>
               {/* to export the table in to excel file given the button */}
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="customers"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export File"
              />
            </div>
          </div>
          <div className="container2">
             {/* searchbar, dynamic table and pagination for inactive purchase is inside  <Allpurchase /> */}
            <AllInactivepurchases />
          </div>
        </div>
      </div>
    </div>
  );
};
// this will show the content for All Purchase
export const Allthepurchaseorders = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
             {/* implemented filter dropdown for Purchase here */}
            <Purchasedropdown />
            <div className="title1">
              {/* given the path for new button when will click the new button will open new Purchase form */}
              <NavLink to="/purechases/purchaseorders">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="customers"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export File"
              />
            </div>
          </div>
          <div className="container2">
             {/* searchbar, dynamic table and pagination for active and inactive purchase is inside  <Allpurchase /> */}
            <AllthePurchaseOrder />
          </div>
        </div>
      </div>
    </div>
  );
};

// this will show the content for creating new Allsuppliers
export const Allsuppliers = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <Supplierdropdown />

            <div className="title1">
              <NavLink to="/purechases/supplier">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="Supplier"
                filename="supplierxls"
                sheet="supplierxls"
                buttonText="Export File"
              />
            </div>
          </div>

          <div className="container2">
            <Allsupplier />
          </div>
        </div>
      </div>
    </div>
  );
};
// this will show the content for All inactive supplier
export const AllInactiveSuppliers = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <Supplierdropdown />

            <div className="title1">
              <NavLink to="/purechases/supplier">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="Supplier"
                filename="supplierxls"
                sheet="supplierxls"
                buttonText="Export File"
              />
            </div>
          </div>

          <div className="container2">
            <AllInactiveSupplier />
          </div>
        </div>
      </div>
    </div>
  );
};
// this will show the content for All active supplier
export const SupliersPresent = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <Supplierdropdown />

            <div className="title1">
              <NavLink to="/purechases/supplier">
                <button className="allbutton">New</button>
              </NavLink>
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="allbutton"
                table="Supplier"
                filename="supplierxls"
                sheet="supplierxls"
                buttonText="Export File"
              />
            </div>
          </div>

          <div className="container2">
            <SupplierInData />
          </div>
        </div>
      </div>
    </div>
  );
};

// this will show the content for All received Purchase
export const Received = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Received Purchase Item</h3>
          </div>
          <div className="container">
            <ReceivedItem />
          </div>
        </div>
      </div>
    </div>
  );
};

// this will show the content for to create one Sales Order according to the purchase order
export const GenerateNewSo = () => {
  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="modal-container ">
          <div className="title">
            <h3>Generate Supply Order</h3>
          </div>
          <div className="container">
            <GenerateSo />
          </div>
        </div>
      </div>
    </div>
  );
};

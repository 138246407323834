import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { Link,useHistory } from 'react-router-dom';
import * as IoIcons from 'react-icons/io';
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from 'react-icons/fa';
import { SidebarData } from './SidebarData';
import { SidebarDataEmployee } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import BBS_LOGO from './Images/BBS_LOGO.png';
import AGM_LOGO from './Images/AGM_LOGO.png';

import Cookies from 'js-cookie';
import axios from '../helper/axios';


// Here we are using react styled component for styling
//we are not using any css file for styling
// Here we have styled the navigation bar bsckground
// const Nav = styled.div`
//   background: #e6e5e5;
//   height: 48px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
// `;
const Nav = styled.div`
  background: #e6e5e5;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media (max-width: 480px) {
    height: 74px;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
  }
`;

// Here we have styled the navigation bar icons size and positions
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 1rem;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 480px) {
    margin-left: 1rem;
    font-size: 1.2rem;
  }
  
`;
const Navbar = styled.div`
border: 1px solid #d4dce4;
box-sizing: border-box;
height: 50px;
margin-left:40px;
width:88.5vw;
background: #ececec;

@media (max-width: 480px) {
  margin-left: 10px;
  width: 100vw;
  background: #e6e5e5;
  border: 1px solid #e6e5e5;
}
`;
// Here we have styled the  icons size and position present in rightside of navigation bar
const Navicon = styled.div`
width:25vw;
margin-left:55vw;
margin-right:0px;
cursor: pointer;
font-size: 1.5rem;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 10px;
position:relative;

@media (max-width: 480px) {
  width:100px;
  margin-left:50px;
  margin-right:0px;
  justify-content: space-around;
}
`;

// Here we have styled the navigation bar heading
const NavHeading =styled.h2`
color: #000;
  margin-left: 12px;
  width: 13vw;

  @media (max-width: 1500px) {
    width: 12.5vw;
  }

  @media (max-width:480px) {
    width: auto;
  }
`;
// Here we have styled the background and size of sidebar navigation
// left keyword is used open and close the side bar when we click on cross icon
const SidebarNav = styled.nav`
  background: #2d373c;
  width:16vw;
  height: 94vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 10;
  transition: 350ms;
  z-index: 1000;
  overflow-y: scroll;
  height: 94vh;
  @media screen and (max-width: 480px) {
    width: 70%;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  }
`;
// Here we have styled the  sidebarwrap
const SidebarWrap = styled.div`
  width: 100%;
  
`;

const Sidebar = () => {
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const [open, setOpen]=useState(false);
  const token = sessionStorage.getItem('jwtToken')
const[gettoken, setToken] = useState()
const [userRole, setRole] = useState();
const loadAllDetails = async () => {
  try {
    const response = await axios.post('/Login', null, {
      headers: { Authorization: `Bearer ${token}` },
      // headers: { Authorization: `Bearer ${sessionStorage.getItem('jwtToken') }`},
    });
    const UserLogedin = response.data.person[0][0].name;
    const UserRoles = response.data.person[0][0].role;
    setToken(UserLogedin);
    setRole(UserRoles)
    //console.log("------"+UserLogedinId);
  
  } catch (error) {
    if (error.response.status === 403) {
      alert('Token Expired. Please login again.');
       sessionStorage.removeItem('jwtToken');
       //Cookies.remove('jwtToken');
      history.push('/');
    } else if (error.response.status === 401) {
      alert('Invalid token. Please login again.');
      sessionStorage.removeItem('jwtToken');
       //Cookies.remove('jwtToken');
      history.push('/');
    } else {
      alert('Error: ' + error.response.data.error);
    }
  }
};
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const handleResize = () => {
  setWindowWidth(window.innerWidth);
};
const isMobile = windowWidth <= 480;
useEffect(() => {
  loadAllDetails();
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
// useEffect(() => {
//   loadAllDetails();
// }, []);
const logout = async () => {
  try {
    const response = await axios.post('/logout', null, {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(response.status);
    if (response.status === 200) {
      sessionStorage.removeItem('jwtToken');
      alert("Logout");
      history.push("/");
    }
  } catch (error) {
    if (error.response.status === 403) {
      alert('Token Expired. Please login again.');
      sessionStorage.removeItem('jwtToken');
      history.push('/');
    } else if (error.response.status === 401) {
      alert('Invalid token. Please login again.');
      sessionStorage.removeItem('jwtToken');
      history.push('/');
    } else {
      alert('Error: ' + error.response.data.error);
    }
  }
};



// const logout=()=>{
//   sessionStorage.removeItem('jwtToken');
//   alert("logout")
//   return history.push("/");
//   } 

  return (
    <>
    {/* will provide the color for icons where ever it is used */}
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
        {isMobile && (
          <NavIcon to='#'>
            <FaIcons.FaBars style={{ color:'#000000' }} onClick={showSidebar} />
          </NavIcon>
        )}
          <NavHeading>
          <img className="logo" src={BBS_LOGO} alt="logo" /> 
          </NavHeading>
          <Navbar>
         
           {/* will provide the color for icons used in navigation bar */}
          <IconContext.Provider value={{ color: '#000' }}>
         <Navicon> 
         <p className='para'>{gettoken}</p>
         <img className="logo1" src={AGM_LOGO} alt="logo" /> 
        {/* <MdIcons.MdOutlineGroup  onClick={()=>setOpen(!open)} />   
      {
        open && (
          <div className="dropdown">
          <p>Username:{gettoken} </p>
          <p>Email: </p>
          <button onClick={logout}>Logout </button>
        </div>
        )
      }
          <MdIcons.MdOutlineNotificationsActive/>
          <AiIcons.AiFillSetting/> */}
          <button className='logoutbutton' onClick={logout}>Logout </button>
         </Navicon> 
         </IconContext.Provider>
         </Navbar>
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            {/* <NavIcon to='#'>
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon> */}
            {/* {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })} */}
            
            {/* if the role will be Admin or Supperadmin then pass this SidebarData array to show the dropdown */}
              {/* if the role will be Employee then pass this SidebarDataEmployee array to show the dropdown */}
            {(userRole === 'Admin') || (userRole === 'Supperadmin') ? (
            // If user is Admin or Supperadmin, display SidebarData
            SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })
          ) : (
            // If user is Employee, display SidebarDataEmployee
            SidebarDataEmployee.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })
          )}
             
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;


import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import {  } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams } from 'react-router-dom'   
import  { useHistory } from 'react-router-dom'
    const EditRcDetails= () => {
      const history = useHistory();
        const {rcid}=useParams();
      const[editRc,setEditRc]=useState(
        {
            registrationnumber: "",
            registrationdate:"",
             ownername:"",
             regupto:"",
             fuel: "",
             taxupto:"",
              class:"",
              model:"",
              vehicleno:"",
              status:""
             }
      );
      
      const handleEdit = (e)=>{
        setEditRc({...editRc,[e.target.name]:e.target.value})
      } 
      
      const handleSubmit = async(e)=>{
        e.preventDefault();
        
 try {
    //const response = await axios.post(`/InsuranceUpdate/${insuranceid}`,insurance);
    const data = new FormData();
    data.append("document", JSON.stringify(editRc));
    data.append("avatar",file);
    
    const response = await axios.post(`/Vehicle/Rcedit/${rcid}`,data,{
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      }
    }); 
    if (response.status === 200) {
      setSuccess(true);
      setError(false);
      return history.push('/Vehicle');
    }
  } catch (err) {
    setSuccess(false);
    setError(true);
  }     
    // alert("Updated successfuly.")
    setEditRc(
        {
            registrationnumber: "",
            registrationdate:"",
             ownername:"",
             regupto:"",
             fuel: "",
             taxupto:"",
              class:"",
              model:"",
              vehicleno:"",
              status:""
             })
      }
     
       useEffect(()=>{
        eachinsurance()
  
        },[]);
        const token = sessionStorage.getItem('jwtToken');
        const eachinsurance = async ()=>{
            const res = await axios.get(`/Vehicle/Rc/${rcid}`, {
              headers: { 
                Authorization: `Bearer ${token}`,
              },
            });
            const newProduct = await res.data[0];
            setEditRc(newProduct)
           
            }
     
      const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
      
    const[file,setFile]=useState("");

       const setimgfile=(e)=>{
        setFile(e.target.files[0])
        console.log(e.target.files[0])
       }
       
      return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
  
        <div className="">
          
         <form onSubmit={handleSubmit}>
          
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
        {error && <div className="form-error">Error submitting RC details. Please try again later.</div>}
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="registrationnumber" className="input-labelemployee leftone">
                      Registration Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="registrationnumber"
                        id="registrationnumber"
                       
                        placeholder="Registration Number"
                        value={editRc.registrationnumber}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      
                      </div>
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="vehicleno" className="input-labelemployee leftone">
                     Vehicle No
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                        placeholder="vehicleno"
                        value={editRc.vehicleno}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      </div>
                    </div>
                   </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="registrationdate" className="input-labelemployee leftone">
                    Registration Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="registrationdate"
                        id="registrationdate"
                       
                        placeholder="Registration Date"
                        value={editRc.registrationdate}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                      
                    </div>
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="ownername" className="input-labelemployee">
                      Owner Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="ownername"
                      id="ownername"
                      placeholder="Owner Name"
                      value={editRc.ownername}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                     
                    </div>
  
                    </div>
                    {/* <div className="input-blockemployee">
                      <label htmlFor="regupto" className="input-labelemployee">
                      REG/FC Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="regupto"
                        id="regupto"
                        placeholder="REG/FC Upto"
                        value={editRc.regupto}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                     
                      </div>
                    </div> */}
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="fuel" className="input-labelemployee">
                   Fuel
                      </label>
                      <div className="inputerroremployee">
                      <select
                      
                      type="text"
                        autoComplete="off"
                        name="fuel"
                        id="fuel"
                        placeholder="Fuel"
                        value={editRc.fuel}
                        onChange={(e)=>{handleEdit(e)}}
                      >
                       <option>Select fuel</option>
                        <option>Diesel</option>
                        <option>Petrol</option>
                        <option>Gas</option>
                        <option>Electric</option>
                      </select>
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="taxupto" className="input-labelemployee">
                    Tax Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="taxupto"
                        id="taxupto"
                        placeholder="Tax Upto"
                        value={editRc.taxupto}
                        onChange={(e)=>{handleEdit(e)}}
                      />
                     
                     
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="class" className="input-labelemployee">
                      Type Of Vehicle
                      </label>
                      <div className="inputerroremployee">
                      <select
                      
                      type="text"
                        autoComplete="off"
                        name=""
                        id="class"
                        placeholder="Class"
                        value={editRc.class}
                        onChange={(e)=>{handleEdit(e)}}
                      >
                     <option>Select type</option>
                        <option>TATA HYVA TIPPER</option>
                        <option>PICK-UP</option>
                        <option>TATA PRIMA TIPPER</option>
                        <option>DOZZER</option>
                        <option>LIGOUNG</option>
                        <option>CAT-330</option>
                        <option>TATA HITACHI</option>
                        <option>VOLVO MACHINE</option>
                        <option>JCB BREAKER</option>
                        <option>BOLERO</option>
                        <option>TATA LEYLAND TIPPER</option>
                        <option>LOADER</option>
                        <option>THAR</option>
                        <option>TIPPER</option>
                        <option>OTHERS</option>
                      </select>
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="model" className="input-labelemployee">
                     Model
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                        type="text"
                        autoComplete="off"
                        name="model"
                        id="model"
                        placeholder="Model"
                        value={editRc.model}
                        onChange={(e)=>{handleEdit(e)}}
                        />
        
        
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="status" className="input-labelemployee">
                    Status
                      </label>
                      <div className="inputerroremployee">
                        {/* {editRc.status === "inactive" && ( */}
                      <select
                      
                      type="text"
                        autoComplete="off"
                        name="status"
                        id="status"
                        placeholder="status"
                        value={editRc.status}
                        onChange={(e)=>{handleEdit(e)}}
                      >
                        <option>active</option>
                        <option>inactive</option>
                      </select>
                      {/* )} */}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="Image" className="input-labelemployee" >Chose RC Picture</label>
                      <div className="inputerroremployee">
                      <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""
                          onChange={setimgfile}
                           />
                           </div>
                     </div> 
                      </div>          
                    <div className="modal-buttonsemployee">
                      
                      <button className="input-button" type="submit" >
                       Save
                      </button>   
                       
                      <button className="input-button" type="reset">
                     Cancel
                    </button>
                    </div>
                   
                  </form>
                  </div>     
        </>
      )
    }
    
    export default EditRcDetails ;
import React from 'react'
import Sidebar from '../../components/Sidebar';
import ListViewRc from './Vehicle/ListViewRc';
import RC from './Vehicle/RC';
import { NavLink } from 'react-router-dom';
import EachRc from './Vehicle/EachRc';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import ListInsurance from './Vehicle/ListInsurance';
import Insurance from './Vehicle/Insurance';
import EachInsurances from './Vehicle/EachInsurances';
import EditInsurance from './Vehicle/EditInsurance';
import EditRcDetails from './Vehicle/EditRcDetails';
import RcDropdown from '../../components/Dropdown/RcDropdown';
import InactiveRc from './Vehicle/InactiveRc';
import AllRc from './Vehicle/AllRc';
import InsuranceDropdown from '../../components/Dropdown/InsuranceDropdown';
import InactiveInsurance from './Vehicle/InactiveInsurance';
import AllInsurances from './Vehicle/AllInsurance';

export const VehicleRc = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Add New Rc Details</h3>
      </div>
          <div className='container' >
           <RC/>
          </div>
          </div>
      </div>
      </div>
    )
  };
  
  export const Rcget = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Rc Details</h3>
      </div>
          <div className='container' >
           <EachRc/>
          </div>
          </div>
      </div>
      </div>
    )
  };
  export const EachRcEdit = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Edit RC Details</h3>
      </div>
          <div className='container' >
        <EditRcDetails/>
          </div>
          </div>
      </div>
      </div>
    )
  };

  export const Vehicle = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
        <RcDropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/Rc'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="rc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
  <ListViewRc/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  export const InactiveVehicle = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
        <RcDropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/Rc'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="rc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
  <InactiveRc/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  export const AllVehicle = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
        <RcDropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/Rc'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="rc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
  <AllRc/>
          </div>
        </div>
      </div>
      </div>
    );
  };
//will display all active Insurance lisview page here
  export const AllInsurance = () => {
    return (
       //implemented sidebar
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
          {/* implemented filter dropdown for Insurance here */}
        <InsuranceDropdown/>
      <div className='title1'>
        {/* will display the new form when will click on new button */}
         <NavLink to='/Vehicle/insurance'><button className="allbutton" >New</button></NavLink> 
         {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="rc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
            {/* searchbar, dynamic table and pagination for active, inactive Pollution is inside   <ListInsurance/> */}
          <ListInsurance/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  //will display all inactive Insurance lisview page here
  export const AllInactiveInsurance = () => {
    return (
       //implemented sidebar
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
          {/* implemented filter dropdown for Insurance here */}
        <InsuranceDropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/insurance'><button className="allbutton" >New</button></NavLink> 
         {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="rc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
            {/* searchbar, dynamic table and pagination for active, inactive Pollution is inside  <InactiveInsurance/> */}
          <InactiveInsurance/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  //will display all active and inactive Insurance lisview page here
  export const AllTheInsurance = () => {
    return (
       //implemented sidebar
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
          {/* implemented filter dropdown for Insurance here */}
        <InsuranceDropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/insurance'><button className="allbutton" >New</button></NavLink> 
         {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="rc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
            {/* searchbar, dynamic table and pagination for active, inactive Pollution is inside  <AllInsurances/> */}
          <AllInsurances/>
          </div>
        </div>
      </div>
      </div>
    );
  };
  //will display the new insurance form page
  export const Insuranceget = () => {
    return (
      //implemented sidebar
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>New Insurance Details</h3>
      </div>
          <div className='container' >
              {/* rander the new Insurance form */}
          <Insurance/>
          </div>
          </div>
      </div>
      </div>
    )
  };
//will get indivisual Insurance detail page
  export const EachInsurance = () => {
    return (
      //implemented the sidebar
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Insurance Details</h3>
      </div>
          <div className='container' >
            {/* rander the Insurance detail form */}
        <EachInsurances/>
          </div>
          </div>
      </div>
      </div>
    )
  };
  export const EachInsuranceEdit = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Edit Insurance Details</h3>
      </div>
          <div className='container' >
        <EditInsurance/>
          </div>
          </div>
      </div>
      </div>
    )
  };
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { IoTrashOutline } from "react-icons/io5";
import axios from "../../helper/axios";

const DynamicTable = () => {
    //here I have initialised all the input values for dynamictable
    const ItemTemp = {
        itemName: "",
        supplier: "",
        Quantity: "",
        salequantity: "",
        rate: "",
        description: "",
        amount: "",
        gst: "",
        Id:""
    };
    //here I store all the values of ItemTemp in a useState.    
    const [addInventory, setAddInventory] = useState([ItemTemp]);

    //here adding new row in dynamic table
    const addNewRow = () => {
        setAddInventory([...addInventory, addInventory]);

    };
    // function to handle deleting a row
    const deleteRow = (index) => {
        const newRows = [...addInventory];
        newRows.splice(index, 1);
        setAddInventory(newRows);
    };
    // This is the onchage function for all input
    const onChange = (e, index) => {
        const { name, value } = e.target;
        const newRows = [...addInventory];
        newRows[index].Id = parseInt(e.target.value);
        newRows[index] = { ...newRows[index], [name]: value };
        setAddInventory(newRows);

    };
    //this is the useState wher we Store all the response came from /itemget.
    const [getItem, setGetItem] = useState([]);
    //Get all the item list from Itemorder Table
    const loadItems = async (data) => {
        const response = await axios.get("/itemget", {
            headers: { 
              Authorization: `Bearer ${token}`,
            },
          });
        setGetItem(response.data);
    };
    const token = sessionStorage.getItem('jwtToken');
    //when the page will load what ever data present inside useEffect will display 1st
    useEffect(() => {
        loadItems();
    }, []);
    const [rateList, setRateList] = useState([])
    //when i select the item then this onChage function we called
    const getItemDetails = async (e, index) => {

        console.log("index no " + index);
        const itemIdget = e.target.value;
        console.log("selected Id " + itemIdget);
        const getResponse = await axios.get(`/allitemrateandgst/${e.target.value}`, {
            headers: { 
              Authorization: `Bearer ${token}`,
            },
          });
        console.log("get all response ");
        console.log(getResponse.data);
        const rateArr = getResponse.data.map((item, key) => ({ value: item.rate, label: item.rate, Id: item.Id }));
        setRateList(rateArr);

        console.log(rateArr)
        let newArray = [...addInventory]

        newArray[index] = {
            "Id": getResponse.data[0].Id,
            "Name": getResponse.data[0].Name,
            "supplier": getResponse.data[0].name,
            "description": getResponse.data[0].Descriptionone,
            "Quantity": getResponse.data[0].Quantity,
            "salequantity": "",
            "rate": "",
            "gst": "",
            "amount": 0

        }

        setAddInventory(newArray);

    }
    const renderOptionDropdown = (index) => {
        return (
          <select
            value={addInventory[index].Id}
            onChange={(event) => onChange(event, index)}
          >
            {rateList.map((option) => (
              <option key={option.Id} value={option.Id}>
                {option.label}
              </option>
            ))}
          </select>
        );
      };

    return (
        <div>
            <table id="contacttable">
                <thead>
                    <tr>
                        <th style={{ width: "60px" }}>Sl No</th>
                        <th style={{ width: "200px" }}>Item Name</th>
                        <th>Supplier</th>
                        <th style={{ width: "200px" }}>Description</th>
                        <th style={{ width: "110px" }}>Quantity On Stock</th>
                        <th>Quantity </th>
                        <th style={{ width: "110px" }}>Rate</th>
                        <th style={{ width: "110px" }}>Gst %</th>
                        <th style={{ width: "110px" }}>Price</th>
                    </tr>
                </thead>
                <tbody>

                    {addInventory.map((Item, index) => {

                        return (
                            <tr key={index}>
                                <th>{index + 1}</th>
                                <th>
                                    <select
                                        className="inputtable"
                                        name="itemName"
                                        onChange={(e) => getItemDetails(e, index, Item.Name)}
                                        //onChange={(e) => onChange(e, index)}
                                        value={Item.itemName}
                                    >
                                        <option value="">{Item.Name}</option>
                                        {getItem.map((values, index) => (
                                            <option value={values.Id} key={values.Id}>
                                                {values.Name}
                                            </option>
                                        ))}
                                    </select>
                                </th>
                                <th>
                                    <input
                                        className="inputtable"
                                        name="supplier"
                                        onChange={(e) => onChange(e, index)}
                                        value={Item.supplier}
                                    />
                                </th>
                                <th>
                                    <input
                                        className="inputtable"
                                        name="description"
                                        onChange={(e) => onChange(e, index)}
                                        value={Item.description}
                                    />
                                </th>

                                <th>
                                    <input
                                        className="inputtable"
                                        name="Quantity"
                                        onChange={(e) => onChange(e, index)}
                                        value={Item.Quantity}
                                        readOnly
                                    />
                                </th>
                                <th>
                                    <input
                                        className="inputtable"
                                        name="salequantity"
                                        onChange={(e) => onChange(e, index)}
                                        value={Item.salequantity}
                                    />
                                </th>
                                <th>
                              
                                    <select
                                        className="inputtable"
                                        name="rate"
                                        onChange={(e) => onChange(e, index)}
                                        value={Item.rate}
                                    >
                                        <option value={Item.rate}>{Item.rate}</option>
                              {rateList.map((ratevalue, index) => (
                                <option value={ratevalue.value} key={index}>
                                  {ratevalue.label}
                                </option>
                              ))}
                                    </select>
                                </th>
                                <th>
                                    <select
                                        className="inputtable"
                                        name="gst"
                                        onChange={(e) => onChange(e, index)}
                                        value={Item.gst}
                                    >
                                        <option></option>
                                    </select>
                                </th>
                                <th>
                                    <input
                                        className="inputtable"
                                        name="amount"
                                        value={Item.amount}
                                        disabled
                                    />
                                </th>
                                <th>
                                    <IoTrashOutline onClick={() => deleteRow(index)} />
                                </th>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="link">
                {" "}
                <Link to="#" className="addlink" onClick={addNewRow}>
                    Add New Line
                </Link>
            </div>
        </div>
    )
}

export default DynamicTable
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
//import {  } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';

  const EachEmployeeLeave = () => {

    const history =useHistory();
    const {idemployleave}=useParams();
    
    const[eachLeave,setEachLeave ]=useState(
        { 
            employeeid:"",
            employeerole:"",
            employeename:"",
            leavetype:"",
            leavefrom:"",
            leaveto:"",
            leavehour:"",
            leavedate:"",
            description:" ",
            currenttime:" "
    });
    const token = sessionStorage.getItem('jwtToken');
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
       leave()
        
        },[]);

        const leave = async ()=>{
            const res = await axios.get(`/GetLeave/${idemployleave}`,{
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            const newProduct = await res.data[0];
            setEachLeave(newProduct)
           
            }
    
    return (
      <>
      <Helmet> 
        <title>New Invoice|BBS Inventory</title>
      </Helmet>
      

      <div className="">
        
       <form >
       <div className="input-employeecontainer"> 
       
                  <div className="input-blockemployee">
                    <label htmlFor="" className="input-labelemployee">
                   Employee Id
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="employeeid"
                      id="employeeid"
                      placeholder="Employee Id"
                     value={eachLeave.employeeid}
                    
                    />
                    
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="" className="input-labelemployee">
                  Employee Role
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="employeerole"
                      id="employeerole"
                     
                      placeholder="Employee Role"
                     value={eachLeave.employeerole}
                      
                    />
                    
                  </div>
                  </div></div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="" className="input-labelemployee">
                    Employee Name
                  </label>
                  <div className="inputerroremployee">
                  <input
                    type="text"
                    autoComplete="off"
                    name="employeename"
                    id="employeename"
                    placeholder="Employee Name"
                   value={eachLeave.employeename}
                    
                  />
                   
                  </div>

                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="" className="input-labelemployee">
                    Leave Type
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="leavetype"
                      id="leavetype"
                   value={eachLeave.leavetype}  
                    />
                    </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                  <label htmlFor="" className="input-labelemployee">
                  Leave From
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="date"
                      autoComplete="off"
                      name="leavefrom"
                      id="leavefrom"
                      placeholder="Leave From"
                     value={eachLeave.leavefrom}
                     
                    />
                    
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="" className="input-labelemployee">
                  Leave To
                    </label>
                    <div className="inputerroremployee">
                    <input 
                    type="date"
                      autoComplete="off"
                      name="leaveto"
                      id="leaveto"
                      placeholder="Leave To"
                    value={eachLeave.leaveto}
                      
                    />
                  </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="tax" className="input-labelemployee">
                    Leave Hour
                    </label>
                    <div className="inputerroremployee">
                    <input
                    type="number"
                      autoComplete="off"
                      name="leavehour"
                      id="leavehour"
                      placeholder="Leave Hour"
                    value={eachLeave.leavehour}
                      
                    />
                   
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="" className="input-labelemployee">
                  Applied On
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    // type="date"
                    //   autoComplete="off"
                    className='time'
                      name="leavedate"
                      id="leavedate"
                      placeholder="Leave To"
                   value={eachLeave.leavedate}
                    />
                  </div>
                  </div>

                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                  <label htmlFor="" className="input-labelemployee">
                  Description
                    </label>
                    <div className="inputerroremployee">
                    <textarea
                    rows="4" cols="30"
                      type="text"
                      autoComplete="off"
                      name="description"
                      id="description"
                      placeholder="Description"
                     value={eachLeave.description}
                     />
                  </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="" className="input-labelemployee">
                  Current Time
                    </label>
                    <div className="inputerroremployee">
                    <input
                       className='time'
                      name="time"
                      id="time"
                    value={eachLeave.currenttime}
                    
                    />
                  </div>
                  </div>
                  </div>
                </form>
                </div>     
      </>
    )
  }
  
  export default EachEmployeeLeave;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { RCdoc } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { useHistory } from "react-router-dom";
const initialValues = {
  registrationnumber: "",
  registrationdate: "",
  ownername: "",
  regupto: "",
  fuel: "",
  taxupto: "",
  class: "",
  model: "",
  vehicleno: "",
};

const RC = () => {
  const history = useHistory();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: RCdoc,

    onSubmit: async (values, action) => {
      try {
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("avatar", file);

        const response = await axios.post("/RcPost", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          setSuccess(true);
          setError(false);
          return history.push("/Vehicle");
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      action.resetForm();
    },
  });
  const token = sessionStorage.getItem("jwtToken");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [file, setFile] = useState("");

  const setimgfile = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>

      <div className="">
        <form onSubmit={handleSubmit}>
          {success && (
            <div className="form-erroremployees">
              Form submitted successfully!
            </div>
          )}
          {error && (
            <div className="form-errors">
              Error submitting RC details. Please try again later.
            </div>
          )}

          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor="registrationnumber"
                className="input-labelemployee leftone"
              >
                Registration Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="registrationnumber"
                  id="registrationnumber"
                  placeholder="Registration Number"
                  value={values.registrationnumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.registrationnumber && touched.registrationnumber ? (
                  <p className="form-erroremployee">
                    {errors.registrationnumber}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor="vehicleno"
                className="input-labelemployee leftone"
              >
                Vehicle No
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="vehicleno"
                  id="vehicleno"
                  placeholder="vehicleno"
                  value={values.vehicleno}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.vehicleno && touched.vehicleno ? (
                  <p className="form-erroremployee">{errors.vehicleno}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor="registrationdate"
                className="input-labelemployee leftone"
              >
                Registration Date
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="registrationdate"
                  id="registrationdate"
                  placeholder="Registration Date"
                  value={values.registrationdate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.registrationdate && touched.registrationdate ? (
                  <p className="form-erroremployee">
                    {errors.registrationdate}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor="ownername"
                className="input-labelemployee"
              >
                Owner Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="ownername"
                  id="ownername"
                  placeholder="Owner Name"
                  value={values.ownername}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.ownername && touched.ownername ? (
                  <p className="form-erroremployee">{errors.ownername}</p>
                ) : null}
              </div>
            </div>
            {/* <div className="input-blockemployee">
                      <label htmlFor="regupto" className="input-labelemployee">
                      REG/FC Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="regupto"
                        id="regupto"
                        placeholder="REG/FC Upto"
                        value={values.regupto}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.regupto && touched.regupto ? (
                        <p className="form-erroremployee">{errors.regupto}</p>
                      ) : null}
                      </div>
                    </div>
                     */}
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor="fuel"
                className="input-labelemployee"
              >
                Fuel
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="fuel"
                  id="fuel"
                  placeholder="Fuel"
                  value={values.fuel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select fuel</option>
                  <option>Diesel</option>
                  <option>Petrol</option>
                  <option>Gas</option>
                  <option>Electric</option>
                </select>
                {errors.fuel && touched.fuel ? (
                  <p className="form-erroremployee">{errors.fuel}</p>
                ) : null}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor="taxupto"
                className="input-labelemployee"
              >
                Tax Upto
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="taxupto"
                  id="taxupto"
                  placeholder="Tax Upto"
                  value={values.taxupto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.taxupto && touched.taxupto ? (
                  <p className="form-erroremployee">{errors.taxupto}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor="class"
                className="input-labelemployee"
              >
                Type Of Vehicle
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name=""
                  id="class"
                  placeholder="Class"
                  value={values.class}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select type</option>
                  <option>TATA HYVA TIPPER</option>
                  <option>PICK-UP</option>
                  <option>TATA PRIMA TIPPER</option>
                  <option>DOZZER</option>
                  <option>LIGOUNG</option>
                  <option>CAT-330</option>
                  <option>TATA HITACHI</option>
                  <option>VOLVO MACHINE</option>
                  <option>JCB BREAKER</option>
                  <option>BOLERO</option>
                  <option>TATA LEYLAND TIPPER</option>
                  <option>LOADER</option>
                  <option>THAR</option>
                  <option>TIPPER</option>
                  <option>OTHERS</option>
                </select>
                {errors.class && touched.class ? (
                  <p className="form-erroremployee">{errors.class}</p>
                ) : null}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor="model"
                className="input-labelemployee"
              >
                Model
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="model"
                  id="model"
                  placeholder="Model"
                  value={values.model}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.model && touched.model ? (
                  <p className="form-erroremployee">{errors.model}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            {/* <div className="input-blockemployee">
                      <label htmlFor="ownername" className="input-labelemployee">
                      Driver Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="ownername"
                      id="ownername"
                      placeholder="Driver Name"
                      value={values.ownername}
                      onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.ownername && touched.ownername ? (
                        <p className="form-erroremployee">{errors.ownername}</p>
                      ) : null}  
                    </div>
  
                    </div> */}
            <div className="input-blockemployee">
              <label
                htmlFor="Image"
                className="input-labelemployee"
              >
                Chose RC Certificate
              </label>
              <div className="inputerroremployee">
                <input
                  type="file"
                  name="avatar"
                  id="avatar"
                  placeholder=""
                  onChange={setimgfile}
                />
              </div>
            </div>
          </div>
          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
              >
                Save
              </button>
              <button
                className="input-button"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RC;

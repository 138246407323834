
import React, {useEffect,useState}from 'react'
import "./formone.css"
import { NavLink,useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from '../../../helper/axios';
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";

   const Allitems = () => {

    const history = useHistory();
    const[data,setData]= useState([]);
    const token = sessionStorage.getItem('jwtToken');
     //for search
  const [searchValue, setSearchValue] = useState('');
  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);
   //display all the items in table
    const loadAllitems= async(data)=>{
        
        const res = await axios.get('/itemgetmaster', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            limit: itemsPerPage,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
        if (!sessionStorage.getItem('jwtToken')) {
          return history.push('/');
        }
      loadAllitems();
    },[currentPage, itemsPerPage]);

    //display pages according to pagination
 const handlePageChange = (page) => {
  setCurrentPage(page);
};
//hangel the search operation
const handleSearch = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.get(`/itemgetmaster?q=${searchValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(response.data);
    setSearchValue('');
  } catch (err) {
    console.log(err);
  }
};
//it will reset the page to 1st page
const handleReset = () => {
  loadAllitems();
};
      const deletetable = (value) =>
      {
        axios.post('/ItemDelete',{value})
        
        .then((result)=>{
         loadAllitems();
        })
        .catch(()=>{
          alert('Error in the Code');
        });
      };
    
    return (
      <div>
          <Helmet> <title>All Active Item|BBS Inventory Management</title>
  
  </Helmet>
  <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Item Name/Item code..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button className="allbutton" onClick={handleReset}>Reset</button>
      </form>
           <table id="items">
              <thead>
                  <tr>
                  {/* <th><input type="checkbox"/></th> */}
                      {/* <th>Item Id</th> */}
                      <th>Item Code</th>
                      <th>Item Name</th>
                      {/* <th>Supplier Name</th> */}
                      <th>Stock On Hand</th>
                      {/* <th>Rate Per Unit(Rs)</th> */}
                      <th>Created By</th>
                      <th>Status</th>
                      <th>Delete</th>
                  </tr>
              </thead>
              <tbody>
            {
            records.map((value, key) => {
              return (
                <tr key={key}>
                    {/* <td><input type="checkbox"/></td> */}
                    {/* <td>{value.Id }</td> */}
                  <td>{value.itemcode }</td>
                  <td><NavLink to={`/inventory/items/${value.Id}`} className="addlink">{value.Name}</NavLink></td>
                  {/* <td>{value.name}</td> */}
                  <td>{value.Quantity}</td>
                  {/* <td>{value.Costprice}</td> */}
                  <td>{value.insertedby}</td>
                  <td>{value.status}</td>
                 <td><IoTrashOutline onClick={()=>{
                    const confirmBox=window.confirm(
                      "Do you  want to delete " + value.Name+"?"
                    )
                    if (confirmBox===true){
                      deletetable(value.Id)

                    }
                  }}/>
                  </td>
                 
                </tr>
              )
            })
          }
            </tbody>
        </table>
        {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default Allitems;

import React, { useEffect, useState } from 'react'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';



const AllRc = () => {

    const[datas,setDatas]= useState([]);
    const token = sessionStorage.getItem('jwtToken');
    const [searchValue, setSearchValue] = useState('');
     //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const records = datas.slice(firstIndex, lastIndex);
  // Calculate total number of pages
  const totalPages = Math.ceil(datas.length / itemsPerPage);
   //display all the Supplier in table
    const loadRc= async(data)=>{
        const res = await axios.get('/alltherc', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setDatas(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
      loadRc();
    },[]);
//hangel the search operation
const handleSearch = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.get(`/api/alltherc?q=${searchValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDatas(response.data);
    setSearchValue('');
  } catch (err) {
    console.log(err);
  }
};
//it will reset the page to 1st page
const handleReset = () => {
  loadRc();
};
const handlePageChange = (page) => {
  setCurrentPage(page);
};
 //Delete Rc Record
 const deleteRc= (rc) =>
 {
   axios.post('/rcdelete',{rc})
   .then((result)=>{
    loadRc();
   })
   .catch(()=>{
     alert('Error in the Code');
   });
 };

  return (
    <div>
      <Helmet> 
        <title> </title>
      </Helmet>
      <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Item Name/Item code..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button className="allbutton" onClick={handleReset}>Reset</button>
      </form>
        <table id="rc">
            <thead>
                <tr>
                    {/* <th><input type="checkbox"/></th> */}
                    <th>Registration Number</th>
                    <th>Registration Date</th>
                    <th>Owner Name</th>
                    <th>Vehicle No</th>
                    <th>REG/FC Upto</th>
                    <th>Fuel</th>
                    <th>Tax Upto</th>
                    <th>Type Of Vehicle</th>
                    <th>Model</th>
                    <th>Status</th>
                    <th>Delete</th>
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
           records.map((rc, keys) => {
              return (
                <tr key={keys}>
                    {/* <td><input type="checkbox"/></td> */}
                  
                  <td><NavLink to={`/Vehicle/Rc/${rc.rcid}`} className="addlink">{rc.registrationnumber}</NavLink></td>
                  <td>{rc.registrationdate}</td>
                  <td>{rc.ownername}</td>
                  <td>{rc.vehicleno}</td>
                  <td>{rc.regupto}</td>
                  <td>{rc.fuel}</td>
                  <td>{rc.taxupto}</td>
                  <td>{rc.class}</td>
                  <td>{rc.model}</td>
                  <td>{rc.status}</td>

                  <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you want to delete "+ rc.vehicleno+"?"
                          )
                          if (confirmBox === true) {
                            deleteRc(rc.rcid)
                          }
                        }}/> 
                        </td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
        {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default AllRc
import React from 'react'
import Sidebar from '../../components/Sidebar';
import { NavLink } from 'react-router-dom';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import NewServiceEntry from './VehicleService/NewServiceEntry';
import EachService from './VehicleService/eachService';
import ListViewService from './VehicleService/ListViewService';
import EditService from './VehicleService/editService';
import Servicedropdown from '../../components/Dropdown/Servicedropdown';

import AllService from './VehicleService/AllService';
import Inactivelistview from './VehicleService/Inactivelistview';


export const ServiceDetails = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Enter New Service Details</h3>
      </div>
          <div className='container' >
           <NewServiceEntry/>
          </div>
          </div>
      </div>
      </div>
    )
  };
  export const VehicleService = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
        <Servicedropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/service'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
  <ListViewService/>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export const InactiveVehicleService = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
        <Servicedropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/service'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
  <Inactivelistview/>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export const AllVehicleService = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
        <Servicedropdown/>
      <div className='title1'>
         <NavLink to='/Vehicle/service'><button className="allbutton" >New</button></NavLink> 
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
  <AllService/>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export const EachServiceDetails = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Enter each Service Details</h3>
      </div>
          <div className='container' >
          <EachService/>
          </div>
          </div>
      </div>
      </div>
    )
  };

  export const EditServiceDetails = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Edit Service Details</h3>
      </div>
          <div className='container' >
          <EditService/>
          </div>
          </div>
      </div>
      </div>
    )
  };
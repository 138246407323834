
import React, { useState,useEffect } from 'react'
import { NavLink, useParams , useHistory} from 'react-router-dom';
import axios from "../../../helper/axios"
import "./formone.css";
import Cookies from 'js-cookie';
import { GrEdit } from 'react-icons/gr';
import API from '../../../helper/axios';


const Edititem = () => {

const history=useHistory()
const {Id}=useParams();

const[itemedit, setItemedit]=useState({
        Name:"",
        Unit:"",
       isAgree:"false",
       taxpreference:"Taxable",
       isAgree:"false",
       Costprice:"",
       Quantity:"",
       Accountone:"",
       Descriptionone:"",
       supplierid:"",
       status:""
});
const[file,setFile]=useState({avatar:""});

    const setimgfile=(e)=>{
     setFile(e.target.files[0])
     console.log(e.target.files[0])
    } 
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
    editUser();
    loadManufacturer();
    loadAll();
    },[]);
    const token = sessionStorage.getItem('jwtToken');
    const[gettoken, setToken] = useState()
    const loadAll = async () => {
      try {
        const response = await axios.post('/Login', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const UserLogedin = response.data.person[0][0].name;;
        setToken(UserLogedin);
        
      } catch (error) {
        console.log(error.response.data);
      }
    };

    const editUser = async ()=>{
        const res = await axios.get(`/Itemactive/${Id}`, {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        const newItem = await res.data;
        setItemedit(newItem)
       
      //  console.log(newItem)
        }
       
        const[data,setData]= useState([]);
  const loadsupplier= async(data)=>{
      
      const res = await axios.get('/SupplierGet', {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
      console.log(res.data)
  }
const[datas,setDatas]= useState([]);
  const loadManufacturer= async(data)=>{
      
      const res = await axios.get('/AllManufacturer', {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setDatas(res.data);
      console.log(res.data)
  }
  useEffect(()=>{
    loadManufacturer();
    loadsupplier();
  },[]);
  const [status, setStatus] = useState("");
  const handleCancel = async(e) => {
    e.preventDefault();

   try {
    const confirmBox = window.confirm(
      "Do you want to cancel it ?"
    )
    if (confirmBox === true){
    const response = await axios.post(`/ItemDelete/${Id}`,{status:"Inactive",Id:Id}); 
    //console.log(response.data); // Do something with the response data
    setStatus("Inactive");
    if (response.status === 200) {
      return history.push("/inventory/items");
    }
    }
    
  } catch (error) {
    console.error(error);
  }
};
       
      const handleSubmit = async(e)=>{
        e.preventDefault();

      try {
        const data = new FormData();
      data.append("document", JSON.stringify(itemedit));
      data.append("avatar",file);
      data.append("gettoken",JSON.stringify(gettoken));
     const respon = await axios.post(`/ItemUpdate/${Id}`,data,{
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      }
      },);
    
        if (respon.status === 200) {
          setSuccess(true);
          setError(false);
          return history.push("/inventory/items");
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      }
      
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState(false);
   const stage =itemedit.isgood;
  return (

    <div>
      <div className='editbu'>
   <button className='input-bu'><NavLink to={`/inventory/items/${Id}/edit`}><GrEdit/></NavLink></button><br></br>
   </div> 
    <form onSubmit={handleSubmit}>
       
    
     <div className="top-div">
          <div className="first-div">
                        <div className="row">
                        <div className="col-25">
                       
                        <label htmlFor="Name"  className="leftones">
                          Item Name
                        </label>
                        
                       </div>
                       <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="text"
                          autoComplete="off"
                          name="Name"
                          id="Name"
                          placeholder=""
                          value={itemedit.Name}
                          disabled
                          
                        />
                        
                        </div>
                       
                        <br></br>
                     </div>
                      
                         <div className="row">
                         <div className="col-25">
                      <label htmlFor="Unit" className="leftones">
                      Quantity
                          </label>
                        </div>
                        <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="number"
                          autoComplete="off"
                          name="Quantity"
                          id="Quantity"
                          placeholder=""
                          value={itemedit.Quantity}
                          disabled
                          
                        />
                       
                        </div>
                        
                        </div>
                     
                      
                        <div className="row">
                     <div className="col-25">
                      <label htmlFor="Unit" className="leftones">
                          Unit
                          </label>
                        </div>
                        <div className="col-75">

                        <input
                    className="inoneone"
                      type="text"
                      autoComplete="off"
                      name="Unit"
                      id="Unit"
                      value={itemedit.Unit}
                      disabled
                      
                    />
                  
                        
                        <br></br>
                      </div>
                      </div>
                      {/* <div className="row">
                     <div className="col-25">
                      
                        <label htmlFor="Costprice" id="tooltip" >
                        Rate
                          
                        </label>
                        
                        </div>
                        <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="number"
                          autoComplete="off"
                          name="Costprice"
                          id="Costprice"
                          placeholder=""
                          value={itemedit.Costprice}
                          disabled
                          
                        />
                      
                        </div>
                        
                        </div> */}
                   
<div className="row">
            <div className="col-25radio">
              <label className="leftones">Tax Preference</label>
              </div>
              <div className="col-75">
              <input
           
            type="radio"
            name="taxpreference"
            className="radio"
            value="Taxable"
            checked={itemedit.taxpreference==="Taxable"}
           />
            {""}Taxable
    
        
            <input
          
            type="radio"
            className="radiotwo"
            name="taxpreference"
            value="Non-Taxable"
            checked={itemedit.taxpreference==="Non-Taxable"}
            />
            {""}Non-Taxable
            
            
            </div>
            </div>
            </div>
           
                     <br></br>
                      <div className="second-div">
                     <div className="fileattach">
                     <div>
                     <img src={`${API.defaults.baseURL}/uploadimage/images/${itemedit.avatar}`} style={{ width: "200px", height: "150px" }}/>

{/* 
                    <img src={`http://localhost:3002/api/${itemedit.avatar}`} style={{width:"200px",height:"150px"}}/> */}
      
                   </div>
                   </div>
            </div>
            
</div>

            <br></br>
             <br></br>

      
 <div className="input-container">
 <div className="input-blockerror">                   
                        <label htmlFor="Manufacturer" className="input-label">
                          Manufacturer
                        </label>
                        
                        <div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Manufacturer"
                      id="Manufacturer"
                      value={itemedit.Manufacturer}
                      disabled
                      
                    ><option></option>
                      
                   {datas.map(itemedit => (
              <option value={itemedit.manufacturerid} key={itemedit.id} >{itemedit.manufacturername}</option>
    
              ))
              }
              
                    </select>

</div>
</div>
 {/* <div className="input-blockerror">
  <label htmlFor="supplier" className="input-label">
                          Supplier
                        </label>
                        
<div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="supplierid"
                      id="supplierid"
                      value={itemedit.supplierid}
                      disabled
                      
                    ><option></option>
                      
                   {data.map(values => (
              <option value={values.supplierid} key={values.id} >{values.name}</option>
    
              ))
              }
              
                    </select>
</div>
</div> */}
<div className="input-blockerror">
                    <label className="input-label">
        
        Model{""} 
        </label>
       <div className="input-error">
        <input
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Model"
                      id="Model"
                      value={itemedit.Model}
                      disabled
                      
                    />
                
                  </div>
                  </div>



                    </div>
         
                    <div className="input-container">
                    {/* <div className="input-blockerror">                   
                        <label htmlFor="Manufacturer" className="input-label">
                          Manufacturer
                        </label>
                        
                        <div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Manufacturer"
                      id="Manufacturer"
                      value={itemedit.Manufacturer}
                      disabled
                      
                    ><option></option>
                      
                   {datas.map(itemedit => (
              <option value={itemedit.manufacturerid} key={itemedit.id} >{itemedit.manufacturername}</option>
    
              ))
              }
              
                    </select>

</div>
</div> */}
        <div className="input-blockerror">
       
        <label className="input-label">Description</label>
        
        <div className="input-error">
        <textarea
                   className="" 
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="Descriptionone"
                      id="Descriptionone"
                      placeholder=""
                      value={itemedit.Descriptionone}
                      disabled
                      
                    />
                    
                    </div>
                    </div> 
        
          </div>   
    <br></br>
    <hr className="hr"></hr>
         
         <br></br>
         <br></br>
           
                   
          </form>


     </div> 
  )
}

      
export default Edititem  
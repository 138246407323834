import { useEffect, useState } from "react";

import React from 'react'
import { Link,NavLink } from 'react-router-dom';
import Sidebar from "../../components/Sidebar";
import Dashboarditems from "./Dashboard/Dashboarditems";



 export const Dashboard = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
      <div className='modal-container '>
      <div className="title">
      
<h3>Dashboard</h3>

 </div>
 <div className="container2">
        <Dashboarditems/>
      </div>

</div>
    </div>

</div>

  )
};







import React, { useEffect, useState } from 'react';
import axios from '../../helper/axios';
import { IoTrashOutline } from 'react-icons/io5';
import { Helmet } from 'react-helmet';
import { NavLink, useHistory } from 'react-router-dom';

const ListviewSales = () => {
  const history = useHistory();
  const token = sessionStorage.getItem('jwtToken');
  const [data, setData] = useState([]);
  //for search
  const [searchValue, setSearchValue] = useState('');
  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);
  // const numbers =[...Array(totalPages + 1).keys()].slice

  const loadSales = async () => {
    const res = await axios.get('/saleGet', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: currentPage,
        limit: itemsPerPage,
      },
    });
    setData(res.data);
    console.log(res.data);
  };
  
  
  useEffect(() => {
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    loadSales();
  }, [currentPage, itemsPerPage]);
  

  // Delete Sales Order record
  const deleteSalesOrder = (value) => {
    axios
      .post('/SaleeorderDelete', { value }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        loadSales();
      })
      .catch(() => {
        alert('Error in the Code');
      });
  };
  //display pages according to pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //hangel the search operation
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`/saleGet?q=${searchValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setSearchValue('');
    } catch (err) {
      console.log(err);
    }
  };
  //it will reset the page to 1st page
  const handleReset = () => {
    loadSales();
  };
  return (
    <div>
      <Helmet>
        <title>All Active Supply Item | BBS Inventory Management</title>
      </Helmet>
      <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Ssupply Order..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button  className="allbutton"onClick={handleReset}>Reset</button>
      </form>
        <table id="Sales">
            <thead>
                <tr>
                    {/* <th></th> */}
                    
                    <th>Supply Order</th>
                    <th>Supply Date </th>
                    <th>Reference#</th>
                    <th>Issued To</th>
                    <th>Employee Phone No</th>
                    {/* <th>Vehicle No</th> */}
                    <th>Total Price</th>
                    <th>Supply Status</th>
                    <th>Given By</th>
                    <th>Status</th>
                    <th>Delete</th>
                    
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            records.map((value, key) => {
              return (
                <tr key={key}>
                    {/* <td><input type="checkbox"/></td> */}
                   
                  <td><NavLink to={`/sales/${value.salesid}`} className="addlink">{value.salesorder}</NavLink></td>
                  <td>{value.salesdate}</td>
                  <td>{value.reference}</td>
                  <td>{value.name}</td>
                  <td>{value.phoneno}</td>
                  {/* <td>{value.vehicleno}</td> */}
                  <td>{value.totalAmount}</td>
                  <td>{value.orderstage}</td>
                  <td>{value.soldby}</td>
                  <td>{value.status}</td>
                  <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you  want to delete salesorder "+ value.salesorder+"?"
                          )
                          if (confirmBox === true) {
                            deleteSalesOrder(value.salesid)
                          }
                        }}/> 
                        </td>
                </tr>
              )
            })
          }
            </tbody>
        </table>

        {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    
    </div>
  )
}

export default  ListviewSales
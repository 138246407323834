import React, { useState } from 'react';
import { useParams,useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import axios from '../../../helper/axios';
import Cookies from 'js-cookie';

const EditManufacturer = () => {
  const history = useHistory();
  const[edit,setEdit]=useState({manufacturername:"", status:"",})
    const {manufacturerid}=useParams();

    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      loadAll();
      editUser();
      },[]);
      const token = sessionStorage.getItem('jwtToken');
      const[gettoken, setToken] = useState()
      const loadAll = async () => {
        try {
          const response = await axios.post('/Login', null, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const UserLogedin = response.data.person[0][0].name;
          setToken(UserLogedin);
          
        } catch (error) {
          console.log(error.response.data);
        }
      };
      const editUser = async ()=>{
          const res = await axios.get(`/Allmanufacturer/${manufacturerid}`, {
            headers: { 
              Authorization: `Bearer ${token}`,
            },
          });
          const newManufacturer = await res.data[0];
          setEdit(newManufacturer)
      }
      const handleEdit = (e)=>{
        
        setEdit({...edit,[e.target.name]:e.target.value})
      }
      const [status, setStatus] = useState("");
  const handleCancel = async(e) => {
    e.preventDefault();

   try {
    const confirmBox = window.confirm(
      "Do you want to cancel it ?"
    )
    if (confirmBox === true){
    const response = await axios.post(`/ManufacturerDelete/${manufacturerid}`,{status:"Inactive",manufacturerid:manufacturerid},{
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      }
    }); 
    //console.log(response.data); // Do something with the response data
    setStatus("Inactive");
    if (response.status === 200) {
      return history.push("/sales");
    }
    }
    
  } catch (error) {
    console.error(error);
  }
};
      const handleSubmit = async(e)=>{
        e.preventDefault();
       // const response = await axios.post(`/ManufacturerUpdate/${manufacturerid}`,edit);
        try {
   
          //const response = await axios.post(`/ManufacturerUpdate/${manufacturerid}`,edit);
          const data = new FormData();
           data.append("document", JSON.stringify(edit));
           data.append("gettoken", JSON.stringify(gettoken));
           const response = await axios.post(`/ManufacturerUpdate/${manufacturerid}`,data,{
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            }
           },); 
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/inventory/allmanufacturer');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
        setEdit({manufacturername:"", status:"", insertedby:""})
    
   
    
      }
      const [success, setSuccess] = useState(false);
      const [error, setError] = useState(false);
       
  return (
    <div>

    <form onSubmit={handleSubmit}>
       {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}
       
      <div className="input-employeecontainer"> 
       
       <div className="input-blockemployee">
       <label htmlFor="name" className="input-labelemployee">
                     Manufacturer Name
                    </label>
                   
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="manufacturername"
                      className='inputmanufacturer'
                      id="manufacturername"
                      placeholder=""
                      value={edit.manufacturername}
                      onChange={(e)=>{handleEdit(e)}}
                    />
         
       </div></div>
       <div className="input-blockemployee">
       <label htmlFor="name" className="input-labelemployee">
                     Status Of Manufacturer
                    </label>
                   
                    <div className="inputerroremployee">
                    <select
                      type="text"
                      autoComplete="off"
                      name="status"
                      className='inputmanufacturer'
                      id="status"
                      placeholder=""
                      value={edit.status}
                      onChange={(e)=>{handleEdit(e)}}>
<option>active</option>
<option>Inactive</option>
                      </select>

      
       </div></div></div>
       <div className="input-employeecontainer"> 
       
       <div className="input-blockemployee">
                    <label htmlFor="insertedby" className="input-label">
                  Created By
                    </label>
                    <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="insertedby"
                      id="insertedby"
                      placeholder="Inserted By"
                      value={edit.insertedby}

                    />
        
       </div></div></div>
    
                    <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       />
                    <button className="input-button" type="submit">
                    Update
                    </button>
                    <button className="input-button" type="button" onClick={handleCancel}>
                   Cancel
                  </button>
    </form>

    </div>
  )
}

export default EditManufacturer;
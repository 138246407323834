import Sidebar from "../../components/Sidebar";
import AllManufacturers from "./Manufacturer/AllManufacturers";
import Manufacturerform from "./Manufacturer/Manufacturerform";
import { NavLink } from "react-router-dom";
import EditManufacturer from "./Manufacturer/EditManufacturer";
import ManufacturerDropdown from "../../components/Dropdown/ManufacturerDropdown";
import Activeallmanufacturer from "./Manufacturer/Activeallmanufacturer";
import Inactivemanufacturer from "./Manufacturer/Inactivemanufacturer";


export const Manufacturer = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>New Manufacturer Registration</h3>
      </div>
          <div className='container' >
            <Manufacturerform/>
          </div>
          </div>
      </div>
      </div>
    )
  }

  export const Manufacturertotal = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
     <ManufacturerDropdown/>
      <NavLink to='/inventory/addmanufacturer'><button className="allbutton" >New</button></NavLink> 
      </div>
          <div className='container2' >
            <AllManufacturers/>
          </div>
          </div>
      </div>
      </div>
    )
  }

  export const ManufacturerActive = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
     <ManufacturerDropdown/>
      <NavLink to='/inventory/addmanufacturer'><button className="allbutton" >New</button></NavLink> 
      </div>
          <div className='container2' >
            <Activeallmanufacturer/>
          </div>
          </div>
      </div>
      </div>
    )
  }

  export const ManufacturerInactive = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
     <ManufacturerDropdown/>
      <NavLink to='/inventory/addmanufacturer'><button className="allbutton" >New</button></NavLink> 
      </div>
          <div className='container2' >
            <Inactivemanufacturer/>
          </div>
          </div>
      </div>
      </div>
    )
  }

  export const Manufacturerone  = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
       <div className=' modal-container'>
  
        <div className='title'><h3>Edit Manufacturer details</h3>
      
        </div>
        <div className='container' ><EditManufacturer/>
        </div>
        </div>
      </div>
      </div>
    );
  };
 
import React from 'react'
import Allemployee from './NewEmploy/Allemployee';
import EmployRegistration from './NewEmploy/EmployRegistration'
import { NavLink } from 'react-router-dom';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import EachEmployee from './NewEmploy/Eachemployee';
import Editemployee from './NewEmploy/Editemployee';
import Sidebar from '../../components/Sidebar';
import Employeedropdown from '../../components/Dropdown/Employeedropdown';
import AllInactiveEmployee from './NewEmploy/AllInactiveEmployee';
import AllTheEmployees from './NewEmploy/AllTheEmployee';
import EmployeeLeave from './NewEmploy/EmployeeLeave';
import ListViewLeave from './NewEmploy/ListViewLeave';
import EachEmployeeLeave from './NewEmploy/EachEmployeeLeave';
import Attendance from './Attendance/Attendance';


export const Employee = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
<div className='modal-container '>
  <div className='title'>
    <h3>New Employee Registration</h3>
    </div>
        <div className='container' >
          <EmployRegistration/>
        </div>
        </div>
    </div>
    </div>
  )
}

export const ActiveEmployee = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
      <div className='modal-container '>
      <div className='title'>
    <Employeedropdown/>
    <div className='title1'>
       <NavLink to='/employee'><button className="allbutton" >New</button></NavLink> 
       <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="customers"
                   filename="Employeexls"
                   sheet="Employeexls"
                   buttonText="Export File"/> 
       </div>
    </div>

        <div className='container2'>
<Allemployee/>
        </div>
      </div>
    </div>
    </div>
  );
};

export const AllTheEmployee = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
      <div className='modal-container '>
      <div className='title'>
    <Employeedropdown/>
    <div className='title1'>
       <NavLink to='/employee'><button className="allbutton" >New</button></NavLink> 
       <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="customers"
                   filename="Employeexls"
                   sheet="Employeexls"
                   buttonText="Export File"/> 
       </div>
    </div>

        <div className='container2'>
<AllTheEmployees/>
        </div>
      </div>
    </div>
    </div>
  );
};

export const InactiveEmployee = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
      <div className='modal-container '>
      <div className='title'>
    <Employeedropdown/>
    <div className='title1'>
       <NavLink to='/employee'><button className="allbutton" >New</button></NavLink> 
       <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="customers"
                   filename="Employeexls"
                   sheet="Employeexls"
                   buttonText="Export File"/> 
       </div>
    </div>

        <div className='container2'>
        <AllInactiveEmployee/>
        </div>
      </div>
    </div>
    </div>
  );
};

export const Employeeone = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
<div className='modal-container '>
  <div className='title'>
    <h3>Employee Details</h3>
    </div>
        <div className='container' >
         <EachEmployee/>
        </div>
        </div>
    </div>
    </div>
  )
};

export const Employeeedit = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
<div className='modal-container '>
  <div className='title'>
    <h3>Edit Employee Details</h3>
    </div>
        <div className='container' >
         <Editemployee/>
        </div>
        </div>
    </div>
    </div>
  )
};
export const Employeeleave = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
<div className='modal-container '>
  <div className='title'>
    <h3> Employee Leave Details</h3>
    </div>
        <div className='container' >
         <EmployeeLeave/>
        </div>
        </div>
    </div>
    </div>
  )
};
export const AllEmployeeleave = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
      <div className='modal-container '>
      <div className='title'>
      <h3>Employee Leave Details</h3>
    <div className='title1'>
       <NavLink to='/employee/leave'><button className="leave-button" >Apply Leave</button></NavLink> 
       <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="leave-button"
                   table="leave"
                   filename="Employeexls"
                   sheet="Employeexls"
                   buttonText="Export File"/> 
       </div>
    </div>

        <div className='container2'>
<ListViewLeave/>
        </div>
      </div>
    </div>
    </div>
  );
};
export const EachLeave = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
<div className='modal-container '>
  <div className='title'>
    <h3>Edit Employee Details</h3>
    </div>
        <div className='container' >
         <EachEmployeeLeave/>
        </div>
        </div>
    </div>
    </div>
  )
};
export const EmployeeAttendance = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
<div className='modal-container '>
  <div className='title'>
    <h3> Employee Attendance Details</h3>
    </div>
        <div className='container' >
         <Attendance/>
        </div>
        </div>
    </div>
    </div>
  )
};
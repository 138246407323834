

import React, { useState,useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import Cookies from 'js-cookie';
const EditInvoiceRecord = () => {

    const history =useHistory();
    const {invoiceid}=useParams();
    
    const[invoiceedit, setInvoiceedit]=useState(
        { 
            invoicenumber: "",
            invoicedate:"",
            name:"",
            insertedby:"",
            withouttax: "",
            withtax:"",
             tax:"",
             purchaser:"",
             supplierid:"",
             insertedby:" "
    });

    
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        editInvoice()
        loadAll();
        loadSupplier();
        },[]);
        const token = sessionStorage.getItem('jwtToken');
        const[gettoken, setToken] = useState()
        const loadAll = async () => {
          try {
            const response = await axios.post('/Login', null, {
              headers: { Authorization: `Bearer ${token}` },
            });
            const UserLogedin = response.data.person[0][0].name;
            setToken(UserLogedin);
            
          } catch (error) {
            console.log(error.response.data);
          }
        };
    
        const editInvoice = async ()=>{
            const res = await axios.get(`/AllIinvoice/${invoiceid}`, {
              headers: { 
                Authorization: `Bearer ${token}`,
              },
            });
            const newProduct = await res.data[0];
            setInvoiceedit(newProduct)
            console.log(newProduct)
            }

          
  const handleEdit = (e)=>{
    setInvoiceedit({...invoiceedit,[e.target.name]:e.target.value})
  }        
  const[supplier,setSupplier]= useState([]);
  //display all the Supplier in table
   const loadSupplier= async(supplier)=>{
       
       const res = await axios.get("/SupplierGet", {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
        const newdata = res.data;
       setSupplier(newdata);
     // console.log(newdata)
   }
 
      
 const handleSubmit = async(e)=>{
 e.preventDefault();
 
 
 try {
  //const response = await axios.post(`/AllIinvoiceUpdate/${invoiceid}`,invoiceedit);
  const data = new FormData();
  data.append("document", JSON.stringify(invoiceedit));
  data.append("gettoken", JSON.stringify(gettoken));
  const response = await axios.post(`/AllIinvoiceUpdate/${invoiceid}`,data,{
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    }
  },); 
  if (response.status === 200) {
    setSuccess(true);
    setError(false);
    return history.push('/invoicerecord');
  }
} catch (err) {
  setSuccess(false);
  setError(true);
}     
  // alert("Updated successfuly.")
  setInvoiceedit(
    { 
        invoicenumber: "",
        invoicedate:"",
        name:"",
        insertedby:"",
        withouttax: "",
        withtax:"",
         tax:"",
         purchaser:"",
         supplierid:""
});  
setSupplier([]);             
  }
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
    
            
    // const [selectedOption, setSelectedOption] = useState('');
  return (
    <> 
      <div className="">
       <form onSubmit={handleSubmit}>
       {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}
       <div className="input-employeecontainer"> 
       
                  <div className="input-blockemployee">
                    <label htmlFor="invoicenumber" className="input-labelemployee">
                    Invoice Number
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="invoicenumber"
                      id="invoicenumber"
                      placeholder="Invoice Number"
                      value={invoiceedit.invoicenumber}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="invoicenumber" className="input-labelemployee">
                    Invoice Date
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="date"
                      autoComplete="off"
                      name="invoicedate"
                      id="invoicedate"
                     
                      placeholder="Invoice Date"
                      value={invoiceedit.invoicedate}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    
                  </div>
                  </div></div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="suppliername" className="input-labelemployee">
                    Supplier Name
                  </label>
                  <div className="inputerroremployee">

                  <select
                  className="list"
                  type="text"
                  autoComplete="off"
                  name="supplierid"
                  id="supplierid"
                 
                  value={invoiceedit.supplierid}
                  onChange={(e)=>{handleEdit(e)}}
                  
                  >
                 
               {supplier.map(supplier => {return(<>
          <option value={supplier.supplierid} key={supplier.id} >{supplier.name}</option>
          
          </>  ) })
          }
                  </select>
                  </div>

                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="purchaser" className="input-labelemployee">
                  Purchaser
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="text"
                      autoComplete="off"
                      name="purchaser"
                      id="purchaser"
                      placeholder="Purchaser"
                      value={invoiceedit.purchaser}
                      onChange={(e)=>{handleEdit(e)}}
                      />
                  </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                  <label htmlFor="withouttax" className="input-labelemployee">
                  Total invoice without Tax
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="text"
                      autoComplete="off"
                      name="withouttax"
                      id="withouttax"
                      placeholder="Without Tax"
                      value={invoiceedit.withouttax}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    </div>
                  </div>
                  <div className="input-blockemployee">
                  <label htmlFor="withtax" className="input-labelemployee">
                  Total invoice with Tax
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="text"
                      autoComplete="off"
                      name="withtax"
                      id="withtax"
                      placeholder="With Tax"
                      value={invoiceedit.withtax}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                   

                  </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="tax" className="input-labelemployee">
                    Tax
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                    type="text"
                      autoComplete="off"
                      name="tax"
                      id="tax"
                      placeholder="Tax"
                      value={invoiceedit.tax}
                      onChange={(e)=>{handleEdit(e)}}
                    />
                    
                    </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
       
       <div className="input-blockemployee">
                    <label htmlFor="insertedby" className="input-label">
                   Inserted By
                    </label>
                    <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="insertedby"
                      id="insertedby"
                      placeholder="Inserted By"
                      value={invoiceedit.enterby}

                    />
        
       </div></div></div>

                  <div className="modal-buttonsemployee">
                    
                    <button className="input-button" type="submit">
                     Save
                    </button>
                    <button className="input-button" type="reset">
                   Cancel
                  </button>
                  </div>
                </form>
                </div>   
    </>
  )
}

export default EditInvoiceRecord
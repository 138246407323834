
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { Schema } from "../../helper/schemas";
import axios from "../../helper/axios"
import { IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';
import { GrEdit, GrPrint } from 'react-icons/gr';
import { useReactToPrint } from 'react-to-print';
// this function will handel the form inputs,submitting form and all the operations.
const Dispach = () => {
  //get the history
  const history = useHistory();
  //get the sale id 
  const { salesid } = useParams();
  //stote updated filed value here
  const [sales, setSales] = useState({
    issuedto: "",
    reference: "",
    salesdate: "",
    customernotes: "",
    termsconditions: "",
    insertedby: "",
    orderstage: "",
    soldby: "",
    assign: "",
    billNo: "",
    socreationdate: "",
    salesorder: "",
    requisitionby: ""
  }
  );
  //dynamic table
  const userTemp = {
    itemDetails: "",
    salequantity: "",
    rate: "",
    description: "",
    amount: "",
    gst: "",
    Unit: ""
  };


  const [vehicle, setVehicle] = useState([]);
  const [item, setItem] = useState([]);
  const [datas, setDatas] = useState([userTemp]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gstList, setGstList] = useState([]);
  const [rateList, setRateList] = useState([])
  const [addInventory, setAddInventory] = useState([])
  //add row to the dynamic table
  const addItem = () => {
    setAddInventory([...addInventory, addInventory]);
    //setDatas([...datas, userTemp]);
  };
  //delete the row from dynamic table
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setDatas(updateUsers);
    setAddInventory(updateUsers);
    const total = updateUsers.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);

  };
  //onchange function for dynamic table to calculate the price
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...datas];
    updateUser[index] = { ...updateUser[index], [name]: value };
    const quantity = parseFloat(updateUser[index].salequantity) || 0;
    console.log(quantity)
    const rate = parseFloat(updateUser[index].rate) || 0;
    const gst = parseFloat(updateUser[index].gst) || 0;
    const amount = quantity * rate * (1 + gst / 100);
    updateUser[index].amount = amount.toFixed(2);

    // Calculate the total quantity of the same item
    const itemId = updateUser[index].Id;
    const totalQuantity = updateUser.reduce((sum, user) =>
      user.Id === itemId ? sum + Number(user.salequantity) : sum, 0);

    // Check if the total quantity of the same item exceeds the initial sale quantity
    if (totalQuantity > parseFloat(initialSaleQuantities[itemId])) {
      alert('Total quantity cannot exceed the initial salequantity');
      return;
    }

    setDatas(updateUser);
    setAddInventory(updateUser);

    // Calculate the total amount of all items
    const totalAmount = updateUser.reduce(
      (sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(totalAmount.toFixed(0));
  };
  //when addInventory.length > 0, then this function will be called
  const replaceData = async (e, index, type) => {
    const res = await axios.get(`/allitemrateandgst/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const gstArr = res.data.map((Id, key) => ({ value: Id.gst, label: Id.gst }));
    setGstList(gstArr);

    const rateArr = res.data.map((Id, key) => ({ value: Id.rate, label: Id.rate }));
    setRateList(rateArr);

    let newArr = [...addInventory]

    newArr[index] = {
      "Id": res.data[0].Id,
      "Name": res.data[0].Name,
      // "name": res.data[0].name,
      // "description": res.data[0].Descriptionone,
      "Quantity": res.data[0].Quantity,
      "salequantity": 0,
      "rate": "",
      "gst": "",
      "amount": 0,
      "Unit": res.data[0].Unit,

    }

    console.log(newArr);

    if (!(type == 'Select Item')) {
      setAddInventory(newArr)
      setDatas(newArr);

    } else {

      setAddInventory([
        ...newArr,
        {
          "Name": "Select Item",
          "name": "",
          "description": "",
          "Quantity": "",
          "salequantity": "",
          "rate": "",
          "gst": "",
          "amount": "",
          "Unit": "",
        },
      ])
      setDatas([
        ...newArr,
        {
          "Name": "Select Item",
          "description": "",
          "name": "",
          "Quantity": "",
          "salequantity": "",
          "rate": "",
          "gst": "",
          "amount": "", "Unit": "",
          "Unit": "",
        },
      ])
    }
    console.log("final array");
    console.log(addInventory)


  }
  //when addInventory.length ==  0, then this function will be called
  const getPriceList = async (e, b) => {
    const res = await axios.get(`/allitemrateandgst/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("selected1 " + e.target.value);
    //here will set the value what ever we got from backend
    setAddInventory([
      ...addInventory,
      {
        "Id": res.data[0].Id,
        "Name": res.data[0].Name,
        "name": res.data[0].name,
        "description": res.data[0].Descriptionone,
        "Quantity": res.data[0].Quantity,
        "salequantity": 0,
        "rate": "",
        "gst": "",
        "amount": 0,
        "Unit": res.data[0].Unit,
      },

    ]);
    setDatas([
      ...addInventory,
      {
        "Id": res.data[0].Id,
        "Name": res.data[0].Name,
        "name": res.data[0].name,
        "description": res.data[0].Descriptionone,
        "Quantity": res.data[0].Quantity,
        "salequantity": 0,
        "rate": "",
        "gst": "",
        "amount": 0,
        "Unit": res.data[0].Unit
      },

    ]);

    const gstArr = res.data.map(item => ({ value: item.gst, label: item.gst }));
    setGstList(gstArr);

    const rateArr = res.data.map(item => ({ value: item.rate, label: item.rate }));
    setRateList(rateArr);

  }
  const [initialSaleQuantities, setInitialSaleQuantities] = useState([]);

  const [file, setFile] = useState({ avatar: "" });

  const setimgfile = (e) => {
    setFile(e.target.files[0])
    //console.log(e.target.files[0])
  }
  // const[supplier,setSupplier]= useState([]);
  const saleItemOrder = async (data) => {

    const res = await axios.get(`/saleGet/${salesid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSales(res.data[0]);

  }
  //get the item according to sale id
  const saleitem = async (data) => {
    const res = await axios.get(`/saleitemget/${salesid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const updateUsers = res.data;
    setDatas(updateUsers);
    setAddInventory(updateUsers);

    const newArr = res.data;
    const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);

    // Set the initial sale quantity for each item
    const initialQuantities = {};
    updateUsers.forEach((user) => {
      initialQuantities[user.Id] = user.salequantity;
    });
    setInitialSaleQuantities(initialQuantities);
  };
  //get all employee details
  const loadEmployee = async (supplier) => {

    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setEmployee(res.data);

  }
  //get all item from backend
  const loadItems = async (data) => {

    const res = await axios.get("/itemgetmaster", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setItem(res.data);
  }
  //get all vehicle no from backend
  const loadVehicle = async (data) => {

    const res = await axios.get("/vehicleno", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setVehicle(res.data);


  }

  useEffect(() => {
    if (!sessionStorage.getItem('jwtToken')) {
      return history.push('/');
    }
    saleItemOrder();
    saleitem();
    loadAll();
    loadEmployee();
    getEmployee();
    loadItems();
    setAddInventory([]);
    loadVehicle();
  }, []);

  //get all employee details
  const getEmployee = async () => {
    const res = await axios.get(`/EmployeeGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee)
    //console.log(newusers)
  }
  const [employee, setEmployee] = useState([]);
  const token = sessionStorage.getItem('jwtToken');
  const [gettoken, setToken] = useState();
  const [gettokenId, setTokenId] = useState()
  //get user information who is loged in
  const loadAll = async () => {
    try {
      const response = await axios.post('/Login', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;;
      const UserLogedinId = response.data.person[0][0].Id;
      setTokenId(UserLogedinId);
      setToken(UserLogedin);

    } catch (error) {
      console.log(error.response.data);
    }
  };
  //onChange function 
  const handleEdit = (e) => {
    setSales({ ...sales, [e.target.name]: e.target.value })
  }
  // send all form data to backend to submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // //check the quantity and salequantity
      // const insufficientQuantityItem = addInventory.find((user) => user.Quantity < user.salequantity);
      // const insufficientQuantityItems = addInventory.find((user) => user.salequantity);
      // if (insufficientQuantityItem) {
      //   alert(`Quantity of "${insufficientQuantityItem.Name}" is more than available Quantity in store.Please reduce the quantity before submitting.`);
      //   return;
      // }
      // Check if any of the salequantity field is empty or 0 or -ve
      const isAnyQuantityZero = addInventory.some((user) => Number(user.salequantity) === 0);
      const isAnyQuantityEmpty = addInventory.some((user) => user.salequantity === '');
      const isAnyQuantityNegative = addInventory.some((user) => user.salequantity < 0);

      if (isAnyQuantityZero || isAnyQuantityEmpty || isAnyQuantityNegative) {
        alert('Quantity field cannot be empty, zero, or negative.');
        return;
      }
      // Check if any of the rate field 
      const isAnyRateZero = addInventory.some((user) => Number(user.rate) === 0);
      const isAnyRateEmpty = addInventory.some((user) => user.rate === '');
      if (isAnyRateZero || isAnyRateEmpty) {
        alert('Rate	field cannot be empty or zero.');
        return;
      }
      // const isAnyGSTZero = addInventory.some((user) => Number(user.gst) === 0);
      const isAnyGSTEmpty = addInventory.some((user) => user.gst === '');
      if (isAnyGSTEmpty) {
        alert('GST field cannot be empty .');
        return;
      }
      const hasMissingInfo = addInventory.every((user) => !user.vehicleno && !user.other);

      if (hasMissingInfo) {
        alert("Please fill in Vehicle Number or Others value.");
        return;
      }
      // const isAnyVechicleNoEmpty = addInventory.some((user) => !user.vehicleno);
      // if (isAnyVechicleNoEmpty) {
      //   alert('Please fill in all the vehicle number fields.');
      //   return;
      // }
      //submitting the data to backend
      const data = new FormData();
      data.append("document", JSON.stringify(sales));
      data.append("avatar", file);
      data.append("totalAmount", JSON.stringify(totalAmount));
      data.append("currentDate", JSON.stringify(currentDate));
      data.append("datas", JSON.stringify(datas));
      data.append("addInventory", JSON.stringify(addInventory));
      data.append("gettoken", JSON.stringify(gettoken));
      data.append("gettokenId", JSON.stringify(gettokenId));


      const respon = await axios.post(`/verifyitemupdate/${salesid}`, data, {
        "Content-Type": "multipart/form-data",
      },);

      //const respon = await axios.post(`//${salesid}`,datas);

      if (respon.status === 200) {
        return history.push("/sales");
      }
    } catch (err) {
      // setSuccess(false);
      // setError(true);
    }

  }
  const [status, setStatus] = useState("");
  //cancel form
  const handleCancel = async (e) => {
    e.preventDefault();

    try {
      const confirmBox = window.confirm(
        "Do you want to cancel it ?"
      )
      if (confirmBox === true) {
        const response = await axios.post(`/SaleeorderDelete/${salesid}`, { status: "Inactive", salesid: salesid });
        //console.log(response.data); // Do something with the response data
        setStatus("Inactive");
        if (response.status === 200) {
          return history.push("/sales");
        }
      }

    } catch (error) {
      console.error(error);
    }
  };
  //to show recent date
  let Showdate = new Date();
  let ShowTodaydate = Showdate.getFullYear() + '-' + (Showdate.getMonth() + 1) + '-' + Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  return (
    <>
      <Helmet>
        <title>Verifyitem Sale|BBS Inventory Management</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>

          <div className="top-div1">
            <div className="input-container-search">
              <label htmlFor="name" className="input-block_radio">
                Issued to
              </label>
              <select className="list" type="text" autoComplete="off" name="issuedto" id="issuedto"
                placeholder="Select a vendor" value={sales.Id} onChange={(e) => { handleEdit(e) }}

              >
                <option>Select Employee Name</option>
                {employee.map((employee) => (
                  <option value={employee.Id} key={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>


            <div className="input-container1">
              <div className="input-block">
                <NavLink to="/employee" className="addlink">
                  Add new Employee
                </NavLink>
              </div>
            </div>
          </div>

          <div className="input-container">
            <div className="input-block">
              <label htmlFor="Reference" className="input-label">
                Reference#
              </label>
              <div className="input-error">
                <input type="text" autoComplete="off" name="reference" id="reference" placeholder="Reference" value={sales.reference} onChange={(e) => { handleEdit(e) }}

                />

              </div>
            </div>

            <div className="input-block">
              <label htmlFor="soldby" className="input-label">
                Given by
              </label>
              <div className="input-error">
                <select className="list" type="text" autoComplete="off" name="soldby" id="soldby" onChange={(e) => { handleEdit(e) }}
                  value={sales.Id}
                >

                  <option>Select Employee</option>
                  <option value={gettokenId}>{gettoken}</option>
                  {employee.map(value => (
                    <option value={value.Id} key={value.id}>{value.name}</option>

                  ))
                  }
                </select>
                {/* <input className="list" type="text" autoComplete="off" name="soldby" id="soldby" onChange={handleChange} value={sales.soldby} /> */}
              </div>
            </div>
          </div>
          <div className="input-container">


            <div className="input-block">
              <label htmlFor="salesdate" className="input-label">
                Date Of Sale
              </label>
              <div className="input-error">
                <input type="date" autoComplete="off" name="salesdate" id="salesdate" value={sales.salesdate} // value={currentDate} onChange={(e)=>{handleEdit(e)}}

                />

              </div>
            </div>


            <div className="input-block">
              <label htmlFor="salesdate" className="input-label">
                Sale Order
              </label>
              <div className="input-error">
                <input type="text" autoComplete="off" name="salesorder" id="salesorder" value={sales.salesorder} // value={currentDate} onChange={(e)=>{handleEdit(e)}}

                />

              </div>
            </div>
          </div>
          <div className="input-container">
            <div className="input-block">
              <label htmlFor="billNo" className="input-label">
                Bill No
              </label>
              <div className="input-error">
                <input
                  type="text"
                  autoComplete="off"
                  name="billNo"
                  id="billNo"
                  value={sales.billNo} // value={currentDate} onChange={(e)=>{handleEdit(e)}}
                />
              </div>
            </div>
            <div className="input-block">
              <label htmlFor="requisitionby" className="input-label">
                Requisition By
              </label>
              <div className="input-error">
                <input type="text" autoComplete="off" name="requisitionby" id="requisitionby" value={sales.requisitionby} // value={currentDate} onChange={(e)=>{handleEdit(e)}}
                />
              </div>
            </div>
          </div>
          <input type="hidden" autoComplete="off" name="socreationdate" id="socreationdate" value={ShowTodaydate} onChange={(e) => { handleEdit(e) }}

          />
          <br />

          <div>
            <div>Price List </div>
            <div>

              <table id="contacttable">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}>Sl No</th>
                    {/* <th>Item code</th> */}
                    <th style={{ width: "200px" }}>Item Name</th>
                    {/* <th>Supplier</th> */}
                    {/* <th style={{ width: "200px" }}>Description</th> */}
                    <th style={{ width: "100px" }}>Quantity On Stock</th>
                    <th>Unit</th>
                    <th style={{ width: "150px" }}>Vehicle</th>
                    <th style={{ width: "200px" }}>Others</th>
                    <th>Quantity </th>
                    <th style={{ width: "110px" }}>Rate</th>
                    <th style={{ width: "110px" }}>Gst %</th>
                    <th style={{ width: "110px" }}>Price</th>

                  </tr>
                </thead>
                <tbody>
                  {/* dynamic table */}
                  {addInventory.length == 0 ?
                    <tr>
                      <th></th>
                      <th>
                        <select className="inputtable" name="itemDetails" onChange={(e) => getPriceList(e)}
                        // value={user.itemDetails}
                        >
                          <option value="">Select Item</option>

                          {item.map((Values, index) => (
                            <option value={Values.Id} key={Values.Id}>
                              {Values.Name}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th></th>
                      {/* <th></th>
                      <th></th> */}
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    :
                    addInventory.map((user, index) => {

                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>

                          <th>
                            <select className="inputtable" name="itemDetails" onChange={(e) => replaceData(e, index, user.Id, user.Name)}
                              value={user.Id}
                            >
                              <option value="">{user.Name}</option>

                              {item.map((Values, index) => (
                                <option value={Values.Id} key={Values.Id}>
                                  {Values.Name}
                                </option>
                              ))}
                            </select>
                          </th>

                          {/* <th>
                            

                            <input className="inputtable" name="name" onChange={(e) => onChange(e, index)}
                              value={user.name}
                            />
                          </th>
                          <th>
                            <input className="inputtable" name="description" onChange={(e) => onChange(e, index)}
                              value={user.description}
                            />
                          </th> */}
                          <th>
                            <input className="inputtable" name="Quantity" // onChange={(e)=> onChange(e, index)}
                              value={user.Quantity}
                              readOnly
                            />
                          </th>
                          <th>
                            <input className="inputtable" name="Unit" // onChange={(e)=> onChange(e, index)}
                              value={user.Unit}
                              readOnly
                            />
                          </th>

                          <th>
                            <select className="inputtable" name="vehicleno" onChange={(e) => onChange(e, index)}
                              value={user.vehicleno} >
                              <option>Select Vechicleno</option>
                              <option>Other</option>
                              <option>All Vehicle</option>
                              {vehicle.map((vehiclevalue, index) => (
                                <option value={vehiclevalue.vehicleno} key={index}>
                                  {vehiclevalue.vehicleno}
                                </option>))}
                            </select>
                          </th>
                          <th>
                            {user.vehicleno === "Other" ? (
                              <input
                                className="inputtable"
                                name="other"
                                value={user.other}
                                onChange={(e) => onChange(e, index)}
                              />
                            ) : null}
                          </th>
                          <th>
                            <input className="inputtable" name="salequantity" onChange={(e) => onChange(e, index)}
                              value={user.salequantity}
                            />
                          </th>
                          <th>
                            <input className="inputtable" name="rate" onChange={(e) => onChange(e, index)}
                              value={user.rate}
                            />

                            {/* <select className="inputtable" name="rate" onChange={(e)=> onChange(e, index)}
                                        value={user.rate}
                                        >
                                        <option value={user.rate}>{user.rate}</option>
                                        {rateList.map((ratevalue, index) => (
                                        <option value={ratevalue.value} key={index}>
                                            {ratevalue.label}
                                        </option>
                                        ))}

                                    </select> */}
                          </th>
                          <th>
                            <input className="inputtable" name="gst" onChange={(e) => onChange(e, index)}
                              value={user.gst}
                            />
                            {/* <select className="inputtable" name="gst" onChange={(e)=> onChange(e, index)}
                                        value={user.gst}
                                        >
                                        <option value={user.gst}>{user.gst}</option>
                                        {gstList.map((values, index) => (
                                        <option value={values.value} key={index}>
                                            {values.label}
                                        </option>
                                        ))}
                                    </select> */}
                          </th>
                          <th>
                            <input className="inputtable" name="amount" value={user.amount} disabled />
                          </th>
                          <th>
                            <IoTrashOutline onClick={() => deleteRow(index)} />
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>


              <div className="link">
                {" "}
                <Link to="#" className="addlink" onClick={addItem}>
                  Add New Line
                </Link>
              </div>
              <div className="link">
                {" "}
                <Link to="/inventory/additem" className="addlink">
                  Add New Item
                </Link>
              </div>
            </div>
            <div className="note">
              <div className="comment">
                <div className="input-block-text">
                  <label htmlFor="customernotes" className="input-label">
                    Customer Notes
                  </label>
                  <textarea className="textareas" rows="3" cols="52" type="text" autoComplete="off" name="customernotes" id="customernotes" placeholder="Customer notes" value={sales.customernotes} onChange={(e) => { handleEdit(e) }}

                  />

                </div>
              </div>
              <div className="pricediscount">
                <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">Total Price</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="totalAmount"
                        id="totalAmount"
                        value={totalAmount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="input-container">
                </div>
              </div>
            </div>
            <br />
            <div className="top-div2">
              <div className="note">
                <div>
                  <div className="input-block-text">
                    <label htmlFor="termsconditions" className="input-label">
                      Terms & Conditions
                    </label>
                    <textarea
                      className="textareas"
                      rows="3"
                      cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"
                      placeholder="Terms and conditions"
                      value={sales.termsconditions}
                      onChange={(e) => { handleEdit(e) }}

                    />

                  </div>
                </div>
                <div className="purchaseorderfile">
                  <label htmlFor="Image">Attach File(s) to Sales Order</label>

                  <input type="file" name="avatar" onChange={setimgfile} />
                </div>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="insertedby"
            id="insertedby"
            value={gettoken}
            onChange={(e) => { handleEdit(e) }}
          />
          <input
            type="hidden"
            name="orderstage"
            value={sales.orderstage}
            onChange={(e) => { handleEdit(e) }}
          />
          <div className="modal-buttons">

            <div className="save-button">
              <button className="input-button" type="submit" onClick={handleSubmit}>
                Save
              </button>
              <button className="input-button" type="reset" >
                Cancel
              </button></div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Dispach;
import React from "react";
import "./formone.css";
import { useState,useEffect } from "react";
import {useFormik} from "formik";
import { GoodsSchema } from "../../../helper/schemas";
import axios from "../../../helper/axios"
import {BsHandIndex} from "react-icons/bs"
import { Helmet } from "react-helmet";
// import {AiOutlineClose} from "react-icons/ai";
// import {AiOutlineQuestionCircle} from 'react-icons/ai';
import  { useHistory } from 'react-router-dom';

//created an object 
const initialValues={
      //  isgood:"Goods",
        Name:"",
        Unit:"",
       isAgree:"false",
       taxpreference:"Taxable",
       isAgree:"false",
       Costprice:0,
       Quantity:0,
       Descriptionone:"",
       supplier:"",
       Manufacturer:"",
       Model:""
    };
    
 const Formone = () => {
        //to get the history
        const history = useHistory();

        const[data,setData]= useState([]);
//get supplier from backend
    const loadsupplier= async(data)=>{
        
        const res = await axios.get('/SupplierGet', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    const[datas,setDatas]= useState([]);
    //get manufacturer from backend
    const load= async(data)=>{
        
        const res = await axios.get('/AllManufacturer', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setDatas(res.data);
        console.log(res.data)
    }
    //when load the page data will display
    useEffect(()=>{
   
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      load();
      loadAll();
      loadsupplier();
    },[]);
    //get the token here
    const token = sessionStorage.getItem('jwtToken');
    const[gettoken, setToken] = useState()
    const loadAll = async () => {
      try {
        const response = await axios.post('/Login', null, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const UserLogedin = response.data.person[0][0].name;;
        setToken(UserLogedin);
        
      } catch (error) {
        console.log(error.response.data);
      }
    };
        const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm} =

     //here sending the data to backend to submit the form
    useFormik({
      initialValues,
     validationSchema: GoodsSchema,
      onSubmit:async (values, action) => {

      try {
        const data = new FormData();

      data.append("document", JSON.stringify(values));
      data.append("avatar",file);
      data.append("gettoken",JSON.stringify(gettoken));
      const respon = await axios.post("/PostDynamic",data,{
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      });
    console.log(data);
        if (respon.status === 200) {
          setSuccess(true);
          setError(false);
          return history.push("/inventory/items");
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      console.log(data)
     
        console.log(values,
        ); 
           action.resetForm();
           
        }
    });
   
    const[file,setFile]=useState("");

 const setimgfile=(e)=>{
  setFile(e.target.files[0])
  console.log(e.target.files[0])
 }
 const [success, setSuccess] = useState(false);
 const [error, setError] = useState(false);
//  const selectedValue = values.isgood;
        return (
          <>
          <Helmet> <title>New Item|BBS Inventory</title>
  
          </Helmet>
       <form onSubmit={handleSubmit}>
       <div className="top-div1"> {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting Item details. Please try again later.</div>}</div>   
    
     <div className="top-div">
          <div className="first-div">
                        <div className="row">
                        <div className="col-25">
                       
                        <label htmlFor="Name"  className="leftone">
                          Item Name
                        </label>
                        
                       </div>
                       <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="text"
                          autoComplete="off"
                          name="Name"
                          id="Name"
                          placeholder=""
                          value={values.Name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Name && touched.Name ? (
                          <p className="form-errorinventory">{errors.Name}</p>
                        ) : null}
                        </div>
                       
                        <br></br>
                     </div>
                      
                         <div className="row">
                         <div className="col-25">
                      <label htmlFor="Unit" className="leftones">
                      Quantity
                          </label>
                        </div>
                        <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="number"
                          autoComplete="off"
                          name="Quantity"
                          id="Quantity"
                          placeholder=""
                          value={values.Quantity}
                          disabled
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                        />

                        {errors.Quantity && touched.Quantity ? (
                          <p className="form-errorinventory">{errors.Quantity}</p>
                        ) : null}
                        </div>
                        
                        </div>
                     
                      
                        <div className="row">
                     <div className="col-25">
                      <label htmlFor="Unit" className="leftones">
                          Unit
                          </label>
                        </div>
                        <div className="col-75">

                        <select
                    className="inoneone"
                      type="text"
                      autoComplete="off"
                      name="Unit"
                      id="Unit"
                      value={values.Unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                  <option>Select Unit</option>  
                  <option>Liter</option>
               <option>BOX</option>
              <option>PIECE</option>
              
                    </select>
                        
                        <br></br>
                      </div>
                      </div>
                      <div className="row">
                     {/* <div className="col-25">
                      
                        <label htmlFor="Costprice" id="tooltip" >
                        Rate
                          
                        </label>
                        
                        </div>
                        <div className="col-75">
                       
                        <input
                        className="inoneone"
                          type="number"
                          autoComplete="off"
                          name="Costprice"
                          id="Costprice"
                          placeholder=""
                          value={values.Costprice}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.Costprice && touched.Costprice ? (
                          <p className="form-errorinventory">{errors.Costprice}</p>
                        ) : null}
                        </div> */}
                        
                        </div>
                   
<div className="row">
            <div className="col-25radio">
              <label className="leftones">Tax Preference</label>
              </div>
              <div className="col-75">
              <input
           
            type="radio"
            name="taxpreference"
            className="radio"
            value="Taxable"
            checked={values.taxpreference==="Taxable"}
            onChange={handleChange}/>
            {""}Taxable
    
        
            <input
          
            type="radio"
            className="radiotwo"
            name="taxpreference"
            value="Non-Taxable"
            checked={values.taxpreference==="Non-Taxable"}
            onChange={handleChange}/>
            {""}Non-Taxable
            
            
            </div>
            </div>
            </div>
           
                     <br></br>
                     <div className="second-div">
                     <div className="fileattach">
                      <div>
                      <label htmlFor="Image" ><BsHandIndex className="BsHandIndex"/>
                      
                        </label>
                        
            <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""                        
                          onChange={setimgfile}

                        />

                      </div>
                     </div>
            </div>
            
</div>

            <br></br>
             <br></br>

      
 <div className="input-container">
 {/* <div className="input-blockerror">
  <label htmlFor="supplier" className="input-label">
                          Supplier
                        </label>
                        
<div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="supplier"
                      id="supplier"
                      value={values.supplier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option></option>
                      
                   {data.map(values => (
              <option value={values.supplierid} key={values.id} >{values.name}</option>
    
              ))
              }
              
                    </select>
                    {errors.supplier && touched.supplier ? (
                      <p className="form-error">{errors.supplier}</p>
                    ) : null} 
</div>
</div> */}
 <div className="input-blockerror">                   
                        <label htmlFor="Manufacturer" className="input-label">
                          Manufacturer
                        </label>
                        
                        <div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Manufacturer"
                      id="Manufacturer"
                      value={values.Manufacturer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option></option>
                      
                   {datas.map(values => (
              <option value={values.manufacturerid} key={values.id} >{values.manufacturername}</option>
    
              ))
              }
              
                    </select>

</div>
</div>
<div className="input-blockerror">
                    <label className="input-label">
        
        Model{""} 
        </label>
       <div className="input-error">
        <input
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Model"
                      id="Model"
                      value={values.Model}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                 {errors.Model&& touched.Model ? (
                      <p className="form-error">{errors.Model}</p>
                    ) : null}   
                   
                  </div>
                  </div>



                    </div>
         
                    <div className="input-container">
                    {/* <div className="input-blockerror">                   
                        <label htmlFor="Manufacturer" className="input-label">
                          Manufacturer
                        </label>
                        
                        <div className="input-error">
                        <select
                    className=""
                      type="text"
                      autoComplete="off"
                      name="Manufacturer"
                      id="Manufacturer"
                      value={values.Manufacturer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ><option></option>
                      
                   {datas.map(values => (
              <option value={values.manufacturerid} key={values.id} >{values.manufacturername}</option>
    
              ))
              }
              
                    </select>

</div>
</div> */}
        <div className="input-blockerror">
       
        <label className="input-label">Description</label>
        
        <div className="input-error">
        <textarea
                   className="" 
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="Descriptionone"
                      id="Descriptionone"
                      placeholder=""
                      value={values.Descriptionone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.Descriptionone && touched.Descriptionone ? (
                      <p className="form-error">{errors.Descriptionone}</p>
                    ) : null}
                    </div>
                    </div> 
        
          </div>   
    <br></br>
    <hr className="hr"></hr>
         
         <br></br>
         <br></br>
           
                   <input
       type="hidden"
       name="adjustedby"
       id="adjustedby"
       value={gettoken}
       />
      
                    <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button" onClick={resetForm}>
                   Reset
                  </button></div>
                  </div>
                
          </form>
          </>
     
        )
        }
      
      export default Formone;




import React, { useEffect, useState } from 'react'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink,useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';


const AllInvoiceRecord = () => {
  const history = useHistory();
    const[datas,setDatas]= useState([]);
    const token = sessionStorage.getItem('jwtToken');
   //display all the Supplier in table
    const loadInvoice= async(data)=>{
        
        const res = await axios.get('/Invoice', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setDatas(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      loadInvoice();
    },[]);
   
 // Delete Employee Record
 const deleteinvoice = (invoice) =>
 {
   axios.post('/InvoiceDelete',{invoice},{
    headers: {
      Authorization: `Bearer ${token}`
    }
   })
   .then((result)=>{
    loadInvoice();
   })
   .catch(()=>{
     alert('Error in the Code');
   });
 };

  return (
    <div>
      <Helmet> 
        <title>All Invoice|BBS Inventory</title>
      </Helmet>
        <table id="invoice">
            <thead>
                <tr>
                    <th><input type="checkbox"/></th>
                    <th>Invoice Number</th>
                    <th> Invoice Date</th>
                    <th>Supplier Name</th>
                    <th>Inserted By</th>
                    <th>Cost</th>
                    <th>Total Cost</th>
                    <th>Tax</th>
                    <th>Purchaser</th>
                    <th>Status</th>
                    <th>Delete</th>
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            datas.map((invoice, keys) => {
              return (
                <tr key={keys}>
                    <td><input type="checkbox"/></td>
                  
                  <td><NavLink to={`/invoicerecord/${invoice.invoiceid}`} className="addlink">{invoice.invoicenumber}</NavLink></td>
                  <td>{invoice.invoicedate}</td>
                  <td>{invoice.name}</td>
                  <td>{invoice.enterby}</td>
                  <td>{invoice.withouttax}</td>
                  <td>{invoice.withtax}</td>
                  <td>{invoice.tax}</td>
                  <td>{invoice.purchaser}</td>
                  <td>{invoice.status}</td>
                  <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you really want to delete "+ invoice.invoicenumber
                          )
                          if (confirmBox === true) {
                            deleteinvoice(invoice.invoiceid)
                          }
                        }}/> 
                        </td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
    </div>
  )
}

export default AllInvoiceRecord
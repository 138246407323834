import React, { useEffect, useState } from 'react'
import axios from '../../../helper/axios';
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const AllTheEmployees = () => {
  const history = useHistory();
    const[data,setData]= useState([]);
   //display all the employee in table
    const loadEmployee= async()=>{
    const res = await axios.get('/EmployeeGet',{
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
        setData(res.data);
        console.log(res.data)
      }
      
      const token = sessionStorage.getItem('jwtToken');
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        loadEmployee();
    },[]);

 // Delete Employee Record

 const deletetable = (value) =>
      {
        axios.post('/EmployeeDelete',{value})
        
        .then((result)=>{
         loadEmployee();
        })
        .catch(()=>{
          alert('Error in the Code');
        });
      };
 

  return (
    <div>
       <Helmet> 
        <title>All Employee|BBS Inventory</title>
      </Helmet>
        <table id="customers">
            <thead>
                <tr>
                    <th><input type="checkbox"/></th>
                    
                    <th>Employee Id</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    <th>Status</th>
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            data.map((value, key) => {
              return (
                <tr key={key}>
                    <td><input type="checkbox"/></td>
                  <td>{value.Id}</td>
                  <td><NavLink to={`/inventory/activeemployee/${value.Id}`} className="addlink">{value.name}</NavLink></td>
                  {/* <td>{value.name}</td> */}
                  <td>{value.phoneno}</td>
                  <td>{value.address}</td>
                  <td>{value.status}</td>
                  {/* <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you really want to delete "+ value.name
                          )
                          if (confirmBox === true) {
                            deletetable(value.Id)
                          }
                        }}/> 
                        </td> */}
                </tr>
              )
            })
          }
            </tbody>
        </table>
    </div>
  )
}

export default AllTheEmployees
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams } from 'react-router-dom'
import { GrEdit } from 'react-icons/gr';

const EachPermit = () => {
   //**here will get the permitid from the url by using the useParams hook */
   const { permitid } = useParams();
   // will update all the value for all input fields of this form here 
  const [permit, setPermit] = useState(
    {
      vehicleno: "",
      chassisno: "",
      ownername: "",
      npauth: "",
      state: "",
      permittype: "",
      permitfrom: "",
      permitupto: "",
      vechileclass: "",
      cost: "",
      status: ""
    }
  )
   // will get the token from  sessionStorage
  const token = sessionStorage.getItem('jwtToken');
   //** when page will load or refresh specific permit details will load */
  useEffect(() => {
    loadPermit()
  }, []);
 //** will get specific Roadtax details according to permitid  */
  const loadPermit = async () => {
    const res = await axios.get(`/PermitGet/${permitid}`, {
       //to authorize the api send the token header
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setPermit(newProduct)

  }

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <div className='editbu'>
        <button className='input-bu'><NavLink to={`/Vehicle/permit/${permitid}/edit`}><GrEdit /></NavLink></button>
      </div>

      <div className="">

        <form >
          <div className="input-employeecontainer">

            <div className="input-blockemployee">
              <label htmlFor="vehicleno" className="input-labelemployee">
                Vehcile Reg Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="vehicleno"
                  id="vehicleno"

                  placeholder="Registration Number"
                  value={permit.vehicleno}

                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="chassisno" className="input-labelemployee">
                Chassis Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="chassisno"
                  id="chassisno"

                  placeholder="Chassis Number"
                  value={permit.chassisno}

                />

              </div>
            </div></div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="ownername" className="input-labelemployee">
                Owner Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="ownername"
                  id="ownername"
                  placeholder="Owner Name"
                  value={permit.ownername}

                />
              </div>

            </div>
            <div className="input-blockemployee">
              <label htmlFor="npauth" className="input-labelemployee">
                Np Auth Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="npauth"
                  id="npauth"
                  placeholder="Np Auth Number"
                  value={permit.npauth}

                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="state" className="input-labelemployee">
                Permit For State
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="state"
                  id="state"
                  placeholder="state"
                  value={permit.state}
                />

              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="permittype" className="input-labelemployee">
                Permit Type
              </label>
              <div className="inputerroremployee">
                <input

                  type="text"
                  autoComplete="off"
                  name="permittype"
                  id="permittype"
                  placeholder="Tax Upto"
                  value={permit.permittype}

                />

              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="permitfrom" className="input-labelemployee">
                Permit From
              </label>
              <div className="inputerroremployee">
                <input

                  type="text"
                  autoComplete="off"
                  name="permitfrom"
                  id="permitfrom"
                  placeholder="Class"
                  value={permit.permitfrom}

                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="permitupto" className="input-labelemployee">
                Permit Upto
              </label>
              <div className="inputerroremployee">
                <input

                  type="text"
                  autoComplete="off"
                  name="permitupto"
                  id="permitupto"
                  placeholder="permitupto"
                  value={permit.permitupto}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="vechileclass" className="input-labelemployee">
                Class Of Vehicle
              </label>
              <div className="inputerroremployee">
                <input

                  type="text"
                  autoComplete="off"
                  name="vechileclass"
                  id="vechileclass"
                  placeholder="Class Of Vehicle"
                  value={permit.vechileclass}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="cost" className="input-labelemployee">
                Permit Cost
              </label>
              <div className="inputerroremployee">
                <input

                  type="text"
                  autoComplete="off"
                  name="cost"
                  id="cost"
                  placeholder="Permit Cost"
                  value={permit.cost}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
             {/* added a new field status here */}
            <div className="input-blockemployee">
              <label htmlFor="status" className="input-labelemployee">
                Status
              </label>
              <div className="inputerroremployee">
                <input

                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"

                  value={permit.status}
                />
              </div>
            </div>
          </div>

        </form>
      </div>
    </>
  )
}

export default EachPermit;
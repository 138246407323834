
export const Navitems = [

    {
        id: 1,
        title: "Features",
        path: "./Features",
        className: "homedropdown",
      },

      {
        id: 2,
        title: "Pricing",
        path: "./Pricing",
        className: "homedropdown",
      },

      {
        id: 2,
        title: "Customers",
        path: "./Customers",
        className: "homedropdown",
      },

      {
        id: 2,
        title: "Integrations",
        path: "./Integrations",
        className: "homedropdown",
      },

      {
        id: 2,
        title: "Resources",
        path: "./Resources",
        className: "homedropdown",
      },

      {
        id: 2,
        title: "More",
        path: "./More",
        className: "homedropdown",
      },

]

export const FeaturesDropdown = [
    {
        id: 1,
        title: "Inventory Solutions",
        path: "./Inventory Solutions",
        className: "featuredropdown",

},

{
    id: 2,
    title: "Item Management",
    path: "./Item Management",
    className: "featuredropdown",

},

{
    id: 3,
    title: "Customer Life Cycle",
    path: "./Customer Life Cycle",
    className: "featuredropdown",

},

{
    id: 4,
    title: "Supplier Relations",
    path: "./Supplier Relations",
    className: "featuredropdown",

},

{
    id: 5,
    title: "Integrations",
    path: "./Integrations",
    className: "featuredropdown",

},

{
    id: 1,
    title: "Automation",
    path: "./Automation",
    className: "featuredropdown",

},

{
    id: 1,
    title: "Smart Features",
    path: "./Smart Features",
    className: "featuredropdown",

},



]

export const IntegrationsDropdown=[
  {

    id: 1,
    title: "Marketplace",
    path: "./Marketplace",
    className: "integrationdropdown",

  },
  {

    id: 2,
    title: "Shopping Cart",
    path: "./Cart",
    className: "integrationdropdown",

  },
  {

    id: 3,
    title: "Sales and Marketing",
    path: "./Sales",
    className: "integrationdropdown",

  },
  {

    id: 4,
    title: "Payment Services",
    path: "./Payment",
    className: "integrationdropdown",

  },
  {

    id: 5,
    title: "Accounting",
    path: "./Accounting",
    className: "integrationdropdown",

  }
 
]
  



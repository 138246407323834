import "./App.css";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Dashboard } from "./pages/Dashboardfolder/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component
import RoleLoader from "./components/RoleLoader";
import React, { useState, useEffect } from "react";
import axios from "./helper/axios";
import {
  AllPresentItem,
  InactiveItems,
  Additem,
  Descriptionone,
  eachOne,
  InEdit,
  Inventory,
  Inventoryadjustments,
  ItemNameEdit,
  Items,
  Items1,
  InventoryInactive,
  InventoryAll,

} from "./pages/Inventoryfolder/Inventory.js";
import Stock from "./pages/Stock";
import {
  Employee,
  Employeeedit,
  Employeeone,
  ActiveEmployee,
  InactiveEmployee,
  AllTheEmployee,
  Employeeleave,
  AllEmployeeleave,
  EachLeave,
  EmployeeAttendance,
} from "./pages/Employeefolder/Employee";
import {
  Purchaseorders,
  Purchases,
  Supplier,
  Allpurchaseorder,
  Allsuppliers,
  SupplierName,
  SupplierNameEdit,
  userName,
  PurchaseName,
  PurchaseNameEdit,
  AllInactiveSuppliers,
  SupliersPresent,
  AllInactivepurchaseorder,
  Allthepurchaseorders,
  Received,
  GenerateNewSo,
} from "./pages/Purchasesfolder/Purchases";
import Description from "./pages/Inventoryfolder/Items/Description";

import Login from "./pages/Login/Login";
import Mainhome from "./pages/Homefolder/Mainhome";
import Signin from "./pages/Login/Signup/Signin";
import Pricing from "./pages/Homefolder/HomeItems/Pricing";
import {
  Invoice,
  ActiveInvoice,
  InvoiceNameEach,
  InvoiceNameEdit,
  AllinactiveInvoice,
  AllInvoicesPresent,
} from "./pages/InvoiceRecord/InvoiceRecord";
// import { Manufacturer, Manufacturerone, Manufacturertotal } from './pages/Manufacturerfolder/Manufacturer';
import {
  Manufacturer,
  ManufacturerActive,
  ManufacturerInactive,
  Manufacturerone,
  Manufacturertotal,
} from "./pages/Manufacturerfolder/Manufacturer";
import {
  VehicleRc,
  Vehicle,
  Rcget,
  AllInsurance,
  Insuranceget,
  EachInsurance,
  EachInsuranceEdit,
  EachRcEdit,
  InactiveVehicle,
  AllVehicle,
  AllTheInsurance,
  AllInactiveInsurance,
} from "./pages/VehicleDocumentation/VehicleDocumentation";
import {
  PollutionDetails,
  VehiclePucc,
  EditPollutionDetails,
  EachPollutionDetails,
  VehicleInactivePucc,
  AllVehiclePucc,
} from "./pages/VehicleDocumentation/document";
import {
  AllVehicleService,
  EachServiceDetails,
  EditServiceDetails,
  InactiveVehicleService,
  ServiceDetails,
  VehicleService,
} from "./pages/VehicleServiceHistory/VehicleServiceHistory";
import {
  AllVehiclePermit,
  EachPermitDetails,
  EditPermitDetails,
  PermitDetails,
  VehicleInactivePermit,
  VehiclePermit,
} from "./pages/VehicleDocumentation/Permit";
import {
  AllVehicleRoadTax,
  EachRoadTaxDetails,
  EditRoadTaxDetails,
  RoadTaxDetails,
  VehicleRoadTax,
  VehicleinactiveRoadTax,
} from "./pages/VehicleDocumentation/RoadTax";
import { Supply, Report, RoReport } from "./pages/Supply/supply";
import {
  AllInactiveSales,
  AllSales,
  AllSalesByDate,
  Dispached,
  Dynamic,
  EachSalesorders,
  EditSalesorders,
  Sales,
  Salesorders,
} from "./pages/Sales/Sales";
import { EditRequisitionDetails, NewRequisitions, RequisitionDetails, RequisitionHomePage, RequisitionToPO, allActiveInactiveReq, allInactiveReq, myapproval } from "./pages/Requisitionfolder/RequisitionComponent";


//here we have given all the path to redirect.

function App() {
  const [userRoles, setRole] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isRolesFetched, setRolesFetched] = useState(false);

  const token = sessionStorage.getItem('jwtToken');

  const loadAll = async () => {
    try {
      const response = await axios.post('/Login', null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userRole = response.data.person[0][0].role;
      setRole(userRole);
      setRolesFetched(true);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data);
      setRolesFetched(true);
      setLoading(false);
    }
  };
  console.log(userRoles)
  useEffect(() => {
    loadAll()
      .then(() => {
        setRolesFetched(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  }, []);

  if (isLoading || !isRolesFetched) {
    return <div>Loading...</div>;
  }
  return (
    <Router>
      {/* <Sidebar /> */}
      <Switch>
        <Route
          path="/Login"
          exact
          component={Login}
         
        />
        <Route
          path="/"
          exact
          component={Signin}
        />
        <Route
          path="/home"
          exact
          component={Mainhome}
        />
        <Route
          path="/Pricing"
          component={Pricing}
        />
        <Route
          path="/dashboard"
          exact
          component={Dashboard}
        />
        <Route
          path="/inventory"
          exact
          component={Inventory}
        />
        <Route
          path="/inventory/items"
          exact
          component={Items}
        />
        {/* <ProtectedRoute
          path="/inventory/items"
          exact
          component={Items}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/allInactiveitems"
          exact
          component={InactiveItems}
        />
        {/* <ProtectedRoute
          path="/inventory/allInactiveitems"
          exact
          component={InactiveItems}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/allitems"
          exact
          component={AllPresentItem}
        />
        {/* <ProtectedRoute
          path="/inventory/allitems"
          exact
          component={AllPresentItem}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/additem"
          exact
          component={Additem}
        />
        {/* <ProtectedRoute
          path="/inventory/additem"
          exact
          component={Additem}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/items1"
          exact
          component={Items1}
        />
        {/* <ProtectedRoute
          path="/inventory/items1"
          exact
          component={Items1}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/inactiveinventory"
          exact
          component={InventoryInactive}
        />
        {/* <ProtectedRoute
          path="/inventory/inactiveinventory"
          exact
          component={InventoryInactive}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/allinventory"
          exact
          component={InventoryAll}
        />
        {/* <ProtectedRoute
          path="/inventory/allinventory"
          exact
          component={InventoryAll}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/items/:Id"
          exact
          component={eachOne}
        />
        {/* <ProtectedRoute
          path="/inventory/items/:Id"
          exact
          component={eachOne}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/items/:Id/edit"
          exact
          component={ItemNameEdit}
        />
        {/* <ProtectedRoute
          path="/inventory/items/:Id/edit"
          exact
          component={ItemNameEdit}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/Inventoryadjustments"
          exact
          component={Inventoryadjustments}
        />
        <Route
          path="/purechases/eachpurchase/:purchaseorderid"
          exact
          component={PurchaseName}
        />
        {/* <ProtectedRoute
          path="/purechases/eachpurchase/:purchaseorderid"
          exact
          component={PurchaseName}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        {/* this route will lead to generate SO, all details given in purchase order will be prefilled(pricelist table) in Sales form if Id is there */}
        <Route
          path="/purechases/sales/:purchaseorderid"
          exact
          component={GenerateNewSo}
        />
        {/* <ProtectedRoute
          path="/purechases/sales/:purchaseorderid"
          exact
          component={GenerateNewSo}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/requisition"
          exact
          component={RequisitionHomePage}
        />
        <Route
          path="/requisition/newrequisition"
          exact
          component={NewRequisitions}
        />
        <Route
          path="/requisitiondetails/:requisitionId"
          exact
          component={RequisitionDetails}
        />
        <Route
          path="/requisitiondetails/:requisitionId/edit"
          exact
          component={EditRequisitionDetails}
        />
        <Route
          path="/requisition/allrequistion"
          exact
          component={allActiveInactiveReq}
        />
        <Route
          path="/requisition/inactive"
          exact
          component={allInactiveReq}
        />
        <Route
          path="/purechases/eachpurchase/:purchaseorderid/receive"
          exact
          component={Received}
        />
        {/* <ProtectedRoute
          path="/purechases/eachpurchase/:purchaseorderid/receive"
          exact
          component={Received}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/eachpurchase/:purchaseorderid/edit"
          exact
          component={PurchaseNameEdit}
        />
        {/* <ProtectedRoute
          path="/purechases/eachpurchase/:purchaseorderid/edit"
          exact
          component={PurchaseNameEdit}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/activeemployee"
          exact
          component={ActiveEmployee}
        />
        {/* <ProtectedRoute
          path="/inventory/activeemployee"
          exact
          component={ActiveEmployee}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/inactiveemployee"
          exact
          component={InactiveEmployee}
        />
        {/* <ProtectedRoute
          path="/inventory/inactiveemployee"
          exact
          component={InactiveEmployee}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/allemployee"
          exact
          component={AllTheEmployee}
        />
        {/* <ProtectedRoute
          path="/inventory/allemployee"
          exact
          component={AllTheEmployee}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/items1/:referencenumber"
          exact
          component={Descriptionone}
        />
        {/* <ProtectedRoute
          path="/inventory/items1/:referencenumber"
          exact
          component={Descriptionone}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        {/* <Route path='/inventory/items1/:Id/edit'exact component={InEdit} /> */}
        <Route
          path="/purechases"
          exact
          component={Purchases}
        />
        {/* <ProtectedRoute
          path="/purechases"
          exact
          component={Purchases}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/supplier"
          exact
          component={Supplier}
        />
        {/* <ProtectedRoute
          path="/purechases/supplier"
          exact
          component={Supplier}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/eachsupplier/user/:iduser"
          exact
          component={userName}
        />
        {/* <ProtectedRoute
          path="/purechases/eachsupplier/user/:iduser"
          exact
          component={userName}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/eachsupplier/:supplierid"
          exact
          component={SupplierName}
        />
        {/* <ProtectedRoute
          path="/purechases/eachsupplier/:supplierid"
          exact
          component={SupplierName}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/eachsupplier/:supplierid/edit"
          exact
          component={SupplierNameEdit}
        />
        {/* <ProtectedRoute
          path="/purechases/eachsupplier/:supplierid/edit"
          exact
          component={SupplierNameEdit}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/allsupplier"
          exact
          component={Allsuppliers}
        />
        {/* <ProtectedRoute
          path="/purechases/allsupplier"
          exact
          component={Allsuppliers}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/inactivesupplier"
          exact
          component={AllInactiveSuppliers}
        />
        {/* <ProtectedRoute
          path="/purechases/inactivesupplier"
          exact
          component={AllInactiveSuppliers}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/allsuppliers"
          exact
          component={SupliersPresent}
        />
        {/* <ProtectedRoute
          path="/purechases/allsuppliers"
          exact
          component={SupliersPresent}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/purchaseorders"
          exact
          component={Purchaseorders}
        />
        {/* <ProtectedRoute
          path="/purechases/purchaseorders"
          exact
          component={Purchaseorders}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/purchaseorders/:requisitionId"
          exact
          component={RequisitionToPO}
        />
        {/* <ProtectedRoute
          path="/purechases/purchaseorders/:requisitionId"
          exact
          component={RequisitionToPO}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/myapprovallist"
          exact
          component={myapproval}
        />
        {/* <ProtectedRoute
          path="/myapprovallist"
          exact
          component={myapproval}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/allpurchase"
          exact
          component={Allpurchaseorder}
        />
        {/* <ProtectedRoute
          path="/purechases/allpurchase"
          exact
          component={Allpurchaseorder}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/allInactivepurchase"
          exact
          component={AllInactivepurchaseorder}
        />
        {/* <ProtectedRoute
          path="/purechases/allInactivepurchase"
          exact
          component={AllInactivepurchaseorder}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/purechases/allthepurchase"
          exact
          component={Allthepurchaseorders}
        />
        {/* <ProtectedRoute
          path="/purechases/allthepurchase"
          exact
          component={Allthepurchaseorders}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/supply"
          exact
          component={Supply}
        />
        {/* <ProtectedRoute
          path="/supply"
          exact
          component={Supply}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/roreport"
          exact
          component={RoReport}
        />
        <Route
          path="/poreport"
          exact
          component={Report}
        />
        <Route
          path="/stock"
          exact
          component={Stock}
        />
        <Route
          path="/employee"
          exact
          component={Employee}
        />
        {/* <ProtectedRoute
          path="/employee"
          exact
          component={Employee}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/activeemployee/:Id"
          exact
          component={Employeeone}
        />
        {/* <ProtectedRoute
          path="/inventory/activeemployee/:Id"
          exact
          component={Employeeone}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/activeemployee/:Id/edit"
          exact
          component={Employeeedit}
        />
        {/* <ProtectedRoute
          path="/inventory/activeemployee/:Id/edit"
          exact
          component={Employeeedit}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/inventoryadjustments"
          exact
          component={Description}
        />
        {/* <Route path='/inventory/addmanufacturer' exact component ={Manufacturer}/>

   <Route path='/inventory/allmanufacturer' exact component={Manufacturertotal}/>
   <Route path='/inventory/allmanufacturer/:manufacturerid/edit' exact component={Manufacturerone}/> */}
        <Route
          path="/inventory/addmanufacturer"
          exact
          component={Manufacturer}
        />
        {/* <ProtectedRoute
          path="/inventory/addmanufacturer"
          exact
          component={Manufacturer}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/allmanufacturer"
          exact
          component={Manufacturertotal}
        />
        {/* <ProtectedRoute
          path="/inventory/allmanufacturer"
          exact
          component={Manufacturertotal}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/activemanufacturer"
          exact
          component={ManufacturerActive}
        />
        {/* <ProtectedRoute
          path="/activemanufacturer"
          exact
          component={ManufacturerActive}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inactivemanufacturer"
          exact
          component={ManufacturerInactive}
        />
        {/* <ProtectedRoute
          path="/inactivemanufacturer"
          exact
          component={ManufacturerInactive}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/inventory/allmanufacturer/:manufacturerid/edit"
          exact
          component={Manufacturerone}
        />
        {/* <ProtectedRoute
          path="/inventory/allmanufacturer/:manufacturerid/edit"
          exact
          component={Manufacturerone}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        {/* jyoti */}
        <Route
          path="/invoicerecord/newinvoice"
          exact
          component={Invoice}
        />
        {/* <ProtectedRoute
         path="/invoicerecord/newinvoice"
         exact
         component={Invoice}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/invoicerecord"
          exact
          component={ActiveInvoice}
        />
        {/* <ProtectedRoute
          path="/invoicerecord"
          exact
          component={ActiveInvoice}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/invoicerecord/:invoiceid"
          exact
          component={InvoiceNameEach}
        />
        {/* <ProtectedRoute
           path="/invoicerecord/:invoiceid"
           exact
           component={InvoiceNameEach}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/invoicerecord/:invoiceid/edit"
          exact
          component={InvoiceNameEdit}
        />
        {/* <ProtectedRoute
           path="/invoicerecord/:invoiceid/edit"
           exact
           component={InvoiceNameEdit}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/invoicerecordinactive"
          exact
          component={AllinactiveInvoice}
        />
         {/* <ProtectedRoute
           path="/invoicerecordinactive"
           exact
           component={AllinactiveInvoice}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/invoicerecordall"
          exact
          component={AllInvoicesPresent}
        />
        {/* <ProtectedRoute
           path="/invoicerecordall"
           exact
           component={AllInvoicesPresent}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/employee/attendance"
          exact
          component={EmployeeAttendance}
        />
        <Route
          path="/employee/leave"
          exact
          component={Employeeleave}
        />
        <Route
          path="/employee/allleave"
          exact
          component={AllEmployeeleave}
        />
        {/* <ProtectedRoute
          path="/employee/allleave"
          exact
          component={AllEmployeeleave}
          allowedRoles={["Supperadmin", "Admin"]} // Roles allowed to access this route
          userRole={userRoles}
        /> */}
        <Route
          path="/employee/eachleave/:idemployleave"
          exact
          component={EachLeave}
        />

        <Route
          path="/Vehicle/Rc"
          exact
          component={VehicleRc}
        />
        <Route
          path="/Vehicle"
          exact
          component={Vehicle}
        />
        <Route
          path="/Vehicle/inactive"
          exact
          component={InactiveVehicle}
        />
        <Route
          path="/Vehicle/allrc"
          exact
          component={AllVehicle}
        />
        <Route
          path="/Vehicle/Rc/:rcid"
          exact
          component={Rcget}
        />
        <Route
          path="/Vehicle/Rc/:rcid/edit"
          exact
          component={EachRcEdit}
        />

        <Route
          path="/alltheinsurance"
          exact
          component={AllTheInsurance}
        />
        <Route
          path="/allinactiveinsurance"
          exact
          component={AllInactiveInsurance}
        />
        <Route
          path="/Insurance"
          exact
          component={AllInsurance}
        />
        <Route
          path="/Vehicle/insurance"
          exact
          component={Insuranceget}
        />
        <Route
          path="/Vehicle/insurance/:insuranceid"
          exact
          component={EachInsurance}
        />
        <Route
          path="/Vehicle/insurance/:insuranceid/edit"
          exact
          component={EachInsuranceEdit}
        />

        <Route
          path="/Vehicle/polution"
          exact
          component={PollutionDetails}
        />
        <Route
          path="/Polution"
          exact
          component={VehiclePucc}
        />
        <Route
          path="/allinactivepollution"
          exact
          component={VehicleInactivePucc}
        />
        <Route
          path="/allpollution"
          exact
          component={AllVehiclePucc}
        />
        <Route
          path="/Vehicle/polution/:pollutionid"
          exact
          component={EachPollutionDetails}
        />
        <Route
          path="/Vehicle/polution/:pollutionid/edit"
          exact
          component={EditPollutionDetails}
        />

        <Route
          path="/Vehicle/service"
          exact
          component={ServiceDetails}
        />
        <Route
          path="/Service"
          exact
          component={VehicleService}
        />
        <Route
          path="/Vehicle/service/:vehicleserviceid"
          exact
          component={EachServiceDetails}
        />
        <Route
          path="/inactiveservice"
          exact
          component={InactiveVehicleService}
        />
        <Route
          path="/allservice"
          exact
          component={AllVehicleService}
        />
        <Route
          path="/Vehicle/service/:vehicleserviceid/edit"
          exact
          component={EditServiceDetails}
        />

        <Route
          path="/Vehicle/permit"
          exact
          component={PermitDetails}
        />
        <Route
          path="/Permit"
          exact
          component={VehiclePermit}
        />
        <Route
          path="/allthepermit"
          exact
          component={AllVehiclePermit}
        />
        <Route
          path="/allinactivepermit"
          exact
          component={VehicleInactivePermit}
        />
        <Route
          path="/Vehicle/permit/:permitid"
          exact
          component={EachPermitDetails}
        />
        <Route
          path="/Vehicle/permit/:permitid/edit"
          exact
          component={EditPermitDetails}
        />

        <Route
          path="/Vehicle/roadtax"
          exact
          component={RoadTaxDetails}
        />
        <Route
          path="/Roadtax"
          exact
          component={VehicleRoadTax}
        />
        <Route
          path="/inactiveroadtax"
          exact
          component={VehicleinactiveRoadTax}
        />
        <Route
          path="/allroadtax"
          exact
          component={AllVehicleRoadTax}
        />
        <Route
          path="/Vehicle/roadtax/:roadtaxid"
          exact
          component={EachRoadTaxDetails}
        />
        <Route
          path="/Vehicle/roadtax/:roadtaxid/edit"
          exact
          component={EditRoadTaxDetails}
        />

        <Route
          path="/sales"
          exact
          component={Sales}
        />
        <Route
          path="/sales/sales"
          exact
          component={AllSales}
        />
        <Route
          path="/sales/Inactivesales"
          exact
          component={AllInactiveSales}
        />
        {/* <Route path="/sales/newsales" exact component={Salesorders}/> */}
        <Route
          path="/sales/:salesid"
          exact
          component={EachSalesorders}
        />
        <Route
          path="/sales/:salesid/edit"
          exact
          component={EditSalesorders}
        />
        <Route
          path="/sales/:salesid/dispach"
          exact
          component={Dispached}
        />
        <Route
          path="/dynamictable"
          exact
          component={Dynamic}
        />
        <Route
          path="/sale/bydate"
          exact
          component={AllSalesByDate}
        />
        <Route path="/unauthorized" component={() => <h1>Unauthorized Access!</h1>} />
        <Route path="*" component={() => <h1>Error 404 page not found !!</h1>} />
      </Switch>
    </Router>
  );
}

export default App;

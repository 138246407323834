import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { goodsSchema } from "../../helper/schemas";
import { servicesSchema } from "../../helper/schemas";
//import { validationSchema } from "../../helper/schemas";
import axios from "../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

//here i have made some changes

// here I have initialised all the input values
const initialValues = {
  search: "",
  //reference: "",
  salesdate: "",
  customernotes: "",
  termsconditions: "",
  insertedby: "",
  orderstage: "",
  soldby: "",
  assign: "",
  billNo:"",
};
//this is the main function
const GenerateSo = () => {
  const history = useHistory();
  //getting the id of purchase oprder
  const { purchaseorderid } = useParams();
  const inputValue = purchaseorderid === '0' ? ':purchaseorderid' : purchaseorderid;
  const [selectedValue, setSelectedValue] = useState("Goods");
  const [salesId, setSalesId] = useState(null);
  const validationSchema =
    selectedValue === "Goods" ? goodsSchema : servicesSchema;
  //dynamic table object
  const itemTemp = {
    itemDetails: "",
    salequantity: "",
    supplier: "",
    rate: "",
    description: "",
    amount: "",
    gst: "",
  };
  // const rateTemp = {
  //   rate: "",
  // };
  // const gstTemp = {
  //   gst: "",
  // };

  //here i used useFormik to submit the form
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    //field validation
    validationSchema: validationSchema,
    //here we are submiting the data to backend
    onSubmit: async (values, action) => {
      try {
        //check the quantity should gratter than salesquantity
        const insufficientQuantityItem = addInventory.find(
          (user) => user.Quantity < user.salequantity
        );
        if (insufficientQuantityItem) {
          alert(
            `Quantity of "${insufficientQuantityItem.Name}" is more than available Quantity in store.Please reduce the quantity before submitting.`
          );
          return;
        }
        //check the  salesquantity should not be zero, -ve
        const isAnyQuantityZero = addInventory.some(
          (user) => Number(user.salequantity) === 0
        );
        const isAnyQuantityEmpty = addInventory.some(
          (user) => user.salequantity === ""
        );
        const isAnyQuantityNegative = addInventory.some(
          (user) => user.salequantity < 0
        );

        if (isAnyQuantityZero || isAnyQuantityEmpty || isAnyQuantityNegative) {
          alert("Quantity field cannot be empty, zero, or negative.");
          return;
        }

        // check the rate field

        const isAnyRateZero = addInventory.some(
          (user) => Number(user.rate) === 0
        );
        const isAnyRateEmpty = addInventory.some((user) => user.rate === "");
        if (isAnyRateZero || isAnyRateEmpty) {
          alert("Rate	field cannot be empty or zero.");
          return;
        }
        // const isAnyGSTZero = addInventory.some((user) => Number(user.gst) === 0);
        const isAnyGSTEmpty = addInventory.some((user) => user.gst === "");
        if (isAnyGSTEmpty) {
          alert("GST field cannot be empty .");
          return;
        }
        const confirmBox = window.confirm(
          "Do you want to " +
            (orderStage === "Draft" ? "save as draft" : "allocate") +
            "?"
        );
        // const confirmBox = window.confirm(
        //   "Do you want to Allocate ?"
        // )
        //submit the data
        if (confirmBox === true) {
          const data = new FormData();
          data.append("document", JSON.stringify(values));
          data.append("reference", JSON.stringify(reference));
          data.append("selectedValue", JSON.stringify(selectedValue));
          data.append("currentDate", JSON.stringify(currentDate));
          data.append("totalAmount", JSON.stringify(totalAmount));
          data.append("subTotal", JSON.stringify(subTotal));
          data.append("totalGst", JSON.stringify(totalGst));
          data.append("gst", JSON.stringify(gst));
          data.append("avatar", file);
          data.append("addInventory", JSON.stringify(addInventory));
          console.log(JSON.stringify(addInventory));
          data.append("gettoken", JSON.stringify(gettoken));
          data.append("gettokenId", JSON.stringify(gettokenId));
          const purchaseIdToSend = purchaseorderid === ":purchaseorderid" ? "0" : purchaseorderid;
          data.append("purchaseorderid", purchaseIdToSend);
          const response = await axios.post("/salePost", data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
//when the status is 200 will push it to sales page.
          if (response.status === 200) {
            const salesId= "ZSO" + response.data[0].toString().padStart(4, "0");
            setSalesId(salesId);
            console.log(salesId)
            alert(`Sales ID created: ${salesId}`);
            return history.push("/sales");
          }
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      action.resetForm();
      // alert("Updated successfuly.")
      setFile("");
      //  setAddInventory([{firstname:"",lastname:"",useremail:"",workphone:"",mobilephone:""}]);
    },
  });
  //All the useStates
  const [reference, setReference] = useState({ reference: "",requestedby:"",requisitionby:"" });
  const token = sessionStorage.getItem("jwtToken");
  const [gettoken, setToken] = useState();
  const [gettokenId, setTokenId] = useState();
  const [file, setFile] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [addInventory, setAddInventory] = useState([itemTemp]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gst, setGst] = useState(18);
  const [totalGst, setTotalGst] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [item, setItem] = useState([]);
  const [orderStage, setOrderStage] = useState("Draft");
 // const [rateList, setRateList] = useState([rateTemp]);
 // const [gstList, setGstList] = useState([gstTemp]);
  const [employee, setEmployee] = useState([]);
  const [supplier, setSupplier] = useState([]);

  //here adding new line in dynamic table
  const addNewRow = () => {
    setAddInventory([...addInventory, addInventory]);
    //setRateList([rateTemp]);
   // setGstList([gstTemp]);
  };
  //here delete row of dynamic table
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setAddInventory(updateUsers);
    // setAddInventory(updateUsers);
    const total = updateUsers.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total.toFixed(0));
    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 18;
    setGst(newGst);
  };

  //This is the onChangefuction for input filed of Dynamic table
  //all the calculaton happened here
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...addInventory];
    updateUser[index] = { ...updateUser[index], [name]: value };
    const quantity = parseFloat(updateUser[index].salequantity) || 0;
    const rate = parseFloat(updateUser[index].rate) || 0;
    const gst = parseFloat(updateUser[index].gst) || 0;
    let amount, newRate;
    if (name === "salequantity" || name === "rate" || name === "gst") {
      amount = quantity * rate * (1 + gst / 100);
      updateUser[index].amount = amount.toFixed(2);
    } else if (name === "amount") {
      amount = parseFloat(updateUser[index].amount) || 0;
      newRate = amount / (quantity * (1 + gst / 100));
      updateUser[index].rate = newRate.toFixed(2);
    }
    //const amount = parseFloat(updateUser[index].amount) || 0;
    // const amount = quantity * rate * (1 + gst / 100);
    // updateUser[index].amount = amount.toFixed(2);
    
    setAddInventory(updateUser);
    // Calculate the total amount of all items
    const total = updateUser.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total.toFixed(0));
    const currentRow = updateUser[index];
    const Quantity = parseFloat(currentRow.Quantity);
    const salequantity = parseFloat(currentRow.salequantity);
    const addedValue = Quantity - salequantity;
    currentRow.addedValue = addedValue;
  };

  const setimgfile = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  //display all the Supplier in table
  const loadSupplier = async (supplier) => {
    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSupplier(res.data);
  };
  //display all the item list
  const loadItems = async (data) => {
    const res = await axios.get("/itemgetmaster", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setItem(res.data);
  };
  //display all the employee
  const getEmployee = async () => {
    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee);
    //console.log(newusers)
  };
  //get Requisition Number from purchaseorder table
  const getrequisition = async () => {
    //here we have written an api that will call GetRequisitionNo() function(purchase.js)
    //GetRequisitionNo() function will send a response and it is  stored in res variable.
    const res = await axios.get(`/generatesoreference/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(res.data);
    //here we are storing the required data in neweref that is comming from res variable
    const neweref = await res.data[0];
    //setReference will display all data that is stored in neweref
    setReference(neweref);
    //console.log(newusers)
  };

  //get all pricelist table data  from purchaseorder table
  const loadItemDetails = async () => {
    //here we have written an api that will call GetAllPurchaseDetails() function(purchase.js)
    //GetAllPurchaseDetails() function will send a response and it is  stored in res variable.
    const res = await axios.get(`/generateso/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
   
    //in newitemdetails we are storing the res.data
    const newemitemdetails = await res.data;
    //setAddInventory function is used to display all data that is stored in newitemdetails.
    setAddInventory(newemitemdetails);

    //need to calculate the totalprice here according to the item came from purchaseorder.
    const newArr = res.data;
    const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);
  };
  const handleEdit = (e) => {
    setReference({ ...reference, [e.target.name]: e.target.value });
  };
  //this is the onload when we load the site what ever the data stored in it will display
  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/");
    }
    loadSupplier();
    loadItems();
    getLoginUser();
    getEmployee();
    loadItemDetails();
    getrequisition();
  }, []);
  //get all information about the user who is loged in
  const getLoginUser = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;
      const UserLogedinId = response.data.person[0][0].empId;
      setTokenId(UserLogedinId);
      setToken(UserLogedin);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);

  //when i select the item then this onChage function we called
  const replaceData = async (e, index, type) => {
    //setRateList("");
    console.log("index no " + index);
    const itemIdget = e.target.value;
    console.log("selected Id " + itemIdget);
    //will get all the item
    //this is the api to display all details of item according to id changes dynamically
    const res2 = await axios.get(`/allitemrateandgst/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // const gstArr = res2.data.map((item, key) => ({
    //   value: item.gst,
    //   label: item.gst,
    // }));
    // setGstList(gstArr);

    // const rateArr = res2.data.map((item, key) => ({
    //   value: item.rate,
    //   label: item.rate,
    //   Id: item.Id,
    // }));
    // setRateList(rateArr);

    let newArr = [...addInventory];
    newArr[index] = {
      Id: res2.data[0].Id,
      Name: res2.data[0].Name,
      supplier: res2.data[0].name,
      description: res2.data[0].Descriptionone,
      Quantity: res2.data[0].Quantity,
      salequantity: "",
      rate: "",
      gst: "",
      amount: 0,
      itemmasterid: res2.data[0].itemmasterid,
      Unit:res2.data[0].Unit,
    };

    console.log(newArr);
    if (!(type == "Select Item")) {
      setAddInventory(newArr);
    } else {
      setAddInventory([
        ...newArr,
        {
          Name: "Select Item",
          supplier: "",
          description: "",
          Quantity: "",
          salequantity: "",
          rate: "",
          gst: "",
          amount: "",
          itemmasterid: "",
          Unit:""
        },
      ]);
    }
  };

  return (
    <>
      <Helmet>
        <title>New Sale|BBS Inventory Management</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>
          {success && (
            <div className="form-erroremployees">
              Sales order submitted successfully!
            </div>
          )}
          {error && (
            <div className="form-error">
              Error submitting Sales order. Please try again later.
            </div>
          )}
          <div className="top-div1">
            <div className="input-container-search">
              <label htmlFor="name" className="input-label leftone">
                Issued to
              </label>
              <select
                className="list"
                type="text"
                autoComplete="off"
                name="search"
                id="search"
                // placeholder="Select a vendor"
                value={values.search}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select Employee Name</option>
                {supplier.map((supplier) => (
                  <option value={supplier.Id} key={supplier.id}>
                    {supplier.name}
                  </option>
                ))}
              </select>
            </div>

            {errors.search && touched.search ? (
              <p className="form-error">{errors.search}</p>
            ) : null}
            <div className="input-container1">
              <div className="input-block">
                <NavLink to="/employee" className="addlink">
                  Add new Employee
                </NavLink>
              </div>
            </div>
          </div>

          <div className="input-container">
            <div className="input-block">
              <label htmlFor="Reference" className="input-label">
                Requisition No
              </label>
              <div className="input-error">
              {purchaseorderid === ":purchaseorderid" ? (
                <input
                  type="text"
                  autoComplete="off"
                  name="reference"
                  id="reference"
                  placeholder="Requisition No"
                  value={reference.reference}
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                />
                ) : (
                  <input
                  type="text"
                  autoComplete="off"
                  name="reference"
                  id="reference"
                  placeholder="Requisition No"
                  value={reference.reference}
                  disabled
                /> 
                )}
              </div>
            </div>
            {/* {(
              <div className="input-block">
              <label htmlFor="assign" className="input-label">
                Issued To
              </label>
              <div className="input-error">
                
                <input
                  className="list"
                  type="text"
                  autoComplete="off"
                  name="assign"
                  id="assign"
                  onChange={handleChange}
                  value={values.assign}
                />
              </div>
            </div>
            )} */}
            <div className="input-block">
              <label htmlFor="soldby" className="input-label">
                Given by
              </label>
              <div className="input-error">
                {/* <select
                    className="list"
                      type="text"
                      autoComplete="off"
                      name="soldby"
                      id="soldby"
                      onChange={handleChange}
                      value={values.soldby}
                    > 
                    
                    <option>Select Employee</option>
                    <option value={gettokenId} selected>{gettoken}</option>
                     {employee.map(value => (
                     <option value={value.empId} key={value.id} >{value.name}</option>
    
                        ))
                      }
                    </select> */}
                <select
                  className="list"
                  type="text"
                  autoComplete="off"
                  name="soldby"
                  id="soldby"
                  onChange={handleChange}
                  value={values.soldby || gettokenId}
                >
                  <option>Select Employee</option>
                  <option value={gettokenId} selected>
                    {gettoken}
                  </option>
                  {employee.map((value) => (
                    <option value={value.empId} key={value.id}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="input-container">
            <div className="input-block">
              <label htmlFor="salesdate" className="input-label leftone">
                Date Of Sale
              </label>
              <div className="input-error">
                <input
                  type="date"
                  autoComplete="off"
                  name="salesdate"
                  id="salesdate"
                  value={values.salesdate}
                  // value={currentDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.salesdate && touched.salesdate ? (
                  <p className="form-error">{errors.salesdate}</p>
                ) : null}
              </div>
            </div>
            <div className="input-block">
              <label htmlFor="billNo" className="input-label ">
               Bill No
              </label>
              <div className="input-error">
                <input
                  type="text"
                  autoComplete="off"
                  name="billNo"
                  id="billNo"
                  value={reference.billNo}
                  // value={currentDate}
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                  onBlur={handleBlur}
                />
                {errors.billNo && touched.billNo ? (
                  <p className="form-error">{errors.billNo}</p>
                ) : null}
              </div>
            </div>

                <input
                  type="hidden"
                  autoComplete="off"
                  name="purchaseorderid"
                  id="purchaseorderid"
                  value={purchaseorderid === ":purchaseorderid" ? "0" : purchaseorderid}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
          </div>
          {/* <div className="input-container">
            <div className="input-block">
              <label htmlFor="requisitionby" className="input-label">
               Requisition By
              </label>
              <div className="input-error">
                <select
                  type="text"
                  autoComplete="off"
                  name="requisitionby"
                  id="requisitionby"
                  value={reference.requisitionby}
                 onChange={(e) => {
                    handleEdit(e);
                  }}
                >
                  <option value={reference.requisitionby} selected>
                {reference.requisitionby}
              </option>
                   {employee.map((emp) => (
                    <option
                      value={emp.name}
                      key={emp.id}
                    >
                      {emp.name}
                    </option>
                  ))}
                  </select>
              </div>
            </div> */}
             <div className="input-container">
      <div className="input-block">
        <label htmlFor="requisitionby" className="input-label">
          Requisition By
        </label>
        <div className="input-error">
          {purchaseorderid === ":purchaseorderid" ? (
             <select
             type="text"
             autoComplete="off"
             name="requisitionby"
             id="requisitionby"
             value={reference.requisitionby}
             onChange={(e) => {
               handleEdit(e);
             }}
           >
            <option>Select Employee</option>
             {employee.map((emp) => (
               <option value={emp.name} key={emp.id}>
                 {emp.name}
               </option>
             ))}
           </select>
           
          ) : (
            <input
            type="text"
            autoComplete="off"
            name="requisitionby"
            id="requisitionby"
            value={reference.requisitionby}
            disabled
          />
          )}
        </div>
      </div>
    </div>
            <input
                  type="hidden"
                  autoComplete="off"
                  name="salesdate"
                  id="salesdate"
                  value={reference.requestedby}
                  
                />
           
          <input
            type="hidden"
            autoComplete="off"
            name="socreationdate"
            id="socreationdate"
            value={ShowTodaydate}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <br />

          <div>
            <div>Price List </div>
            <div>
              <table id="contacttable">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}>Sl No</th>
                    {/* <th>Item code</th> */}
                    <th style={{ width: "200px" }}>Item Name</th>
                    {/* <th>Supplier</th> */}
                    {/* <th style={{ width: "200px" }}>Description</th> */}
                    <th style={{ width: "200px" }}>Quantity On Stock</th>
                    <th>Unit</th>
                    <th>Quantity </th>
                    <th style={{ width: "110px" }}>Rate</th>
                    <th style={{ width: "110px" }}>Gst %</th>
                    <th style={{ width: "110px" }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {/* dynamic table */}
                  {/* {addInventory.length==0?
                  <tr><th></th>
                      <th>
                    <select
                      className="inputtable"
                      name="itemDetails"
                      onChange={(e) => getPriceList(e)}
                      // value={user.itemDetails}
                    >
                      <option value="">Select Item</option>

                      {item.map((values, index) => (
                        <option value={values.Id} key={values.Id}>
                          {values.Name}
                        </option>
                      ))}
                    </select>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  </tr>
                    : */}
                  {addInventory.map((user, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>

                        <th>
                          <select
                            className="inputtable"
                            name="itemDetails"
                            onChange={(e) => replaceData(e, index, user.Name)}
                            value={user.itemDetails}
                          >
                            <option value="">{user.Name}</option>

                            {item.map((values, index) => (
                              <option value={values.Id} key={values.Id}>
                                {values.Name}
                              </option>
                            ))}
                          </select>
                        </th>

                        {/* <th>
                          <input
                            className="inputtable"
                            name="supplier"
                            onChange={(e) => onChange(e, index)}
                            value={user.supplier}
                          />
                        </th> */}
                        {/* <th>
                          <input
                            className="inputtable"
                            name="description"
                            onChange={(e) => onChange(e, index)}
                            value={user.description}
                          />
                        </th> */}

                        <th>
                          <input
                            className="inputtable"
                            name="Quantity"
                            // onChange={(e) => onChange(e, index)}
                            value={user.Quantity}
                            readOnly
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="Unit"
                            // onChange={(e) => onChange(e, index)}
                            value={user.Unit}
                            readOnly
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="salequantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.salequantity}
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="rate"
                            onChange={(e) => onChange(e, index)}
                            value={user.rate}
                          />

                          {/* <select
                            className="inputtable"
                            name="rate"
                            onChange={(e) => onChange(e, index)}
                            value={user.rate}
                          >
                           <option value={user.rate}>{user.rate}</option>
                              {rateList.map((ratevalue, index) => (
                                <option value={ratevalue.value} key={index}>
                                  {ratevalue.label}
                                </option>
                              ))}
                          </select> */}
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="gst"
                            onChange={(e) => onChange(e, index)}
                            value={user.gst}
                            />
                          {/* <select
                            className="inputtable"
                            name="gst"
                            onChange={(e) => onChange(e, index)}
                            value={user.gst}
                          >
                            {gstList.map((values, index) => (
                              <option value={values.value} key={index}>
                                {values.label}
                              </option>
                            ))}
                          </select> */}
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="amount"
                            value={user.amount}
                            onChange={(e) => onChange(e, index)}
                          />
                        </th>
                        <th>
                          <IoTrashOutline onClick={() => deleteRow(index)} />
                        </th>
                        <input
                            type="hidden"
                            className="inputtable"
                            name="itemmasterid"
                            onChange={(e) => onChange(e, index)}
                            value={user.itemmasterid}
                          />
                        <input
                          type="hidden"
                          className="inputtable"
                          name="addedValue"
                          value={user.addedValue}
                        />
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="link">
                {" "}
                <Link to="#" className="addlink" onClick={addNewRow}>
                  Add New Line
                </Link>
              </div>
              <div className="link">
                {" "}
                <Link to="/inventory/additem" className="addlink">
                  Add New Item
                </Link>
              </div>
            </div>
            <div className="note">
              <div className="comment">
                <div className="input-block-text">
                  <label htmlFor="customernotes" className="input-label">
                    Customer Notes
                  </label>
                  <textarea
                    className="textareas"
                    rows="3"
                    cols="52"
                    type="text"
                    autoComplete="off"
                    name="customernotes"
                    id="customernotes"
                    placeholder="Customer notes"
                    value={values.customernotes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.customernotes && touched.customernotes ? (
                    <p className="form-error">{errors.customernotes}</p>
                  ) : null}
                </div>
              </div>
              <div className="pricediscount">
                <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">Total Price</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="totalAmount"
                        id="totalAmount"
                        value={totalAmount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  {/* <div className="input-block">
                    <label className="input-label">IGST @ %</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="gst"
                        id="gst"
                        value={gst}
                        onChange={(e) => {
                          const newGst = parseFloat(e.target.value) || 0;
                          setGst(newGst);
                          const subTotal = calculateSubTotal(
                            totalAmount,
                            newGst
                          );
                          setSubTotal(subTotal);
                          const gstAmount = subTotal - totalAmount;
                          setTotalGst(gstAmount);
                        }}
                      />
                    </div>
                  </div> 
                </div>*/}
                  {/* <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">GST Amount</label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="gstamount"
                        id="gstamount"
                        value={totalGst}
                        readOnly
                      />
                    </div>
                  </div> */}
                </div>
                {/* <div className="input-container">
                  <div className="input-block">
                    <label className="input-label">
                      Final Price(Including GST)
                    </label>
                    <div className="input-error">
                      <input
                        type="text"
                        autoComplete="off"
                        name="subTotal"
                        id="subTotal"
                        value={subTotal}
                        readOnly
                      />
                    </div>
                  </div> 
                </div>*/}
              </div>
            </div>
            <br />
            <div className="top-div2">
              <div className="note">
                <div>
                  <div className="input-block-text">
                    <label htmlFor="termsconditions" className="input-label">
                      Terms & Conditions
                    </label>
                    <textarea
                      className="textareas"
                      rows="3"
                      cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"
                      placeholder="Terms and conditions"
                      value={values.termsconditions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.termsconditions && touched.termsconditions ? (
                      <p className="form-error">{errors.termsconditions}</p>
                    ) : null}
                  </div>
                </div>
                <div className="purchaseorderfile">
                  <label htmlFor="Image">Attach File(s) to Sales Order</label>
                  <input type="file" name="avatar" onChange={setimgfile} />
                </div>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="insertedby"
            id="insertedby"
            value={gettoken}
            onChange={handleChange}
          />
          <input
            type="hidden"
            name="orderstage"
            value={values.orderstage}
            onChange={handleChange}
          />
          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
                id="draft"
                onClick={() => {
                  setOrderStage("Draft");
                  handleChange({
                    target: { name: "orderstage", value: "Draft" },
                  });
                }}
              >
                Save As Draft
              </button>
              <button
                className="input-button"
                type="submit"
                id="packaging"
                onClick={() => {
                  setOrderStage("Send to Employee");
                  handleChange({
                    target: { name: "orderstage", value: "Send to Employee" },
                  });
                }}
              >
                Allocate
              </button>
              <button
                className="input-button"
                type="button"
                onClick={resetForm}
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default GenerateSo;

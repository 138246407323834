import React, { useEffect, useState } from 'react'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';



const ListviewPermit = () => {
//**initialize the value to update dynamic data in table what ever getting from backend*/
const[datas,setDatas]= useState([]);
//**for search operation update the Searchvalue
const [searchValue, setSearchValue] = useState('');
//** to get token from sessionStorage
const token = sessionStorage.getItem('jwtToken');
 //**for pagination current page will be at 1 in initial stage
const [currentPage, setCurrentPage] = useState(1);
//** itemperpage will be 50 */
//**1-50 1st page, 50-100 2nd page like that it will go on
const [itemsPerPage, setItemsPerPage] = useState(50);
//**lastIndex of currentpage
const lastIndex = currentPage * itemsPerPage;
//**FirstIndex of lastpage
const firstIndex = lastIndex - itemsPerPage;
//** find the data between firstIndex, lastIndex*/
//**this records need to be map inside table
const records = datas.slice(firstIndex, lastIndex);
//**Calculate total number of pages
const totalPages = Math.ceil(datas.length / itemsPerPage);
//**get all the active Permit details from backend
   //**o/p- all Permit details */
    const loadpermit= async(data)=>{
        
        const res = await axios.get('/PermitGet', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setDatas(res.data);
        console.log(res.data)
    }
     //**Load permit details when page loads */
    useEffect(()=>{
      loadpermit();
    },[]);
//**handel the search operation
//**const response = await axios.get(/PermitGet?q=${searchValue}, { ... })
// This line sends a GET request to the /PermitGet endpoint with a 
// query parameter q set to the value of the searchValue variable. */
//**o/p- get the search result value and set the datas varible */
const handleSearch = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.get(`/PermitGet?q=${searchValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDatas(response.data);
    setSearchValue('');
  } catch (err) {
    console.log(err);
  }
};
//it will reset the page to 1st page
const handleReset = () => {
  loadpermit();
};
//**It will handel the pagination
// setCurrentPage(page) This line calls the setCurrentPage function,passing the page parameter as an argument. 
// It implies that there is a setCurrentPage
//  function defined elsewhere in the code, which will update the value of the currentPage state variable. */
const handlePageChange = (page) => {
  setCurrentPage(page);
};
 //Delete Permit Record
  //**The purpose of passing the permit value is to identify and specify which record should be deleted on the backend.
 // The backend code that handles the /permitdelete endpoint will receive the permit value 
 //in the req.body object and use it to perform the appropriate deletion operation. */
 const deletepermit = (permit) =>
 {
   axios.post('/permitdelete',{permit})
   .then((result)=>{
    loadpermit();
   })
   .catch(()=>{
     alert('Error in the Code');
   });
 };

  return (
    <div>
      <Helmet> 
        <title> </title>
      </Helmet>
      {/* search box */}
      <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Vehicle no..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button className="allbutton" onClick={handleReset}>Reset</button>
      
      </form>
      {/* table to display data from backend */}
        <table id="permittable">
            <thead>
                <tr>
                    <th><input type="checkbox"/></th>
                    <th>Vehcile Reg Number</th>
                    <th>Chassis Number</th>
                    <th>Owner Name</th>
                    <th>Np Auth Number</th>
                    <th>Permit For State</th>
                    <th>Permit Type</th>
                    <th>Permit From</th>
                    <th>Permit Upto</th>
                    <th>Class Of Vehicle</th>
                    <th>Permit Cost</th>
                    <th>Status</th>
                    <th>Delete</th>
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
           records.map((rc, keys) => {
              return (
                <tr key={keys}>
                    <td><input type="checkbox"/></td>
                  
                  <td><NavLink to={`/Vehicle/permit/${rc.permitid}`} className="addlink">{rc.vehicleno}</NavLink></td>
                  <td>{rc.chassisno}</td>
                  <td>{rc.ownername}</td>
                  <td>{rc.npauth}</td>
                  <td>{rc.state}</td>
                  <td>{rc.permittype}</td>
                  <td>{rc.permitfrom}</td>
                  <td>{rc.permitupto}</td>
                  <td>{rc.vechileclass}</td>
                  <td>{rc.cost}</td>
                  <td>{rc.status}</td>

                  <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you want to delete "+ rc.vehicleno +" ?"
                          )
                          if (confirmBox === true) {
                             //send the permitid to backend
                            deletepermit(rc.permitid)
                          }
                        }}/> 
                        </td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
         {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default ListviewPermit
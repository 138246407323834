import React, { useEffect, useState } from 'react'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink,useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';


const Allsupplier = () => {
  const history = useHistory();
  const[data,setData]= useState([]);
  const token = sessionStorage.getItem('jwtToken');
    //for search
  const [searchValue, setSearchValue] = useState('');
  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);
   //display all the Supplier in table
    const loadSupplier= async(data)=>{
        
        const res = await axios.get("/SupplierGet", {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            limit: itemsPerPage,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        loadSupplier();
    },[currentPage, itemsPerPage]);

 //display pages according to pagination
 const handlePageChange = (page) => {
  setCurrentPage(page);
};
//hangel the search operation
const handleSearch = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.get(`/SupplierGet?q=${searchValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(response.data);
    setSearchValue('');
  } catch (err) {
    console.log(err);
  }
};
//it will reset the page to 1st page
const handleReset = () => {
  loadSupplier();
};
 const deletesupplier = (value) =>
 {
   axios.post('/SupplierDelete',{value},{
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    }
   })
   .then((result)=>{
    loadSupplier();
   })
   .catch(()=>{
     alert('Error in the Code');
   });
 };

  return (
    <div>
      <Helmet> 
        <title>All Active Supplier|BBS Inventory Management</title>
      </Helmet>
      <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Supplier Name..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button className="allbutton" onClick={handleReset}>Reset</button>
      </form>
        <table id="Supplier">
            <thead>
                <tr>
                    {/* <th><input type="checkbox"/></th> */}
                    {/* <th>Supplier Id</th> */}
                    <th>Supplier Name</th>
                    <th>Company Name</th>
                    <th>Supplier Email</th>
                    <th>Supplier Phoneno</th>
                    <th>Primary Contact</th>
                    <th>Created By</th>
                    <th>Status</th>
                    <th>Delete</th>
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            records.map((value, key) => {
              return (
                <tr key={key}>
                    {/* <td><input type="checkbox"/></td> */}
                    {/* <td>{value.supplierid}</td> */}
                  <td><NavLink to={`/purechases/eachsupplier/${value.supplierid}`} className="addlink">{value.name}</NavLink></td>
                  <td>{value.companyname}</td>
                  <td>{value.email}</td>
                  <td>{value.phoneno}</td>
                  <td>{value.primarycontact}</td>
                  <td>{value.insertedby}</td>
                  <td>{value.status}</td>
                  <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you really want to delete "+ value.name
                          )
                          if (confirmBox === true) {
                            deletesupplier(value.supplierid)
                          }
                        }}/> 
                        </td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
        {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default Allsupplier
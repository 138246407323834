import React from 'react';
import Formone from './Items/Formone';
import { NavLink } from 'react-router-dom';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'


import Allitems from './Items/Allitems';
import Allinventory from './Items/Inventoryadjustments';
import Inventoryform from './Items/Inventoryform';
import Allemployee from '../Employeefolder/NewEmploy/Allemployee';

import EachItem from './Items/Itemdetails';
import Edititem from './Items/Edititem';
import Description from './Items/Description';

import Allitemdropdown from '../../components/Dropdown/Allitemdropdown';
import Sidebar from '../../components/Sidebar';
import AllInactiveitems from './Items/AllinactiveItems';
import AllpresentItems from './Items/AllpresentItems';
import Inventorydropdown from '../../components/Dropdown/Inventorydropdown';
import InactiveInventory from './Items/InactiveInventory';
import AllInventoryPresent from './Items/AllInventoryPresent';





export const Inventory = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
      <div className='modal-container '>Inventory</div>
    </div>
    </div>
  );
};

export const Items = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
     <div className='modal-container '>
     <div className='title'><Allitemdropdown/><h3> </h3>
     
     <div className='title1'>
        <NavLink to='/inventory/additem'><button className="allbutton" >New</button></NavLink> 
        <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="allbutton"
                    table="items"
                    filename="itemxls"
                    sheet="tablexls"
                    buttonText="Export File"/> 
        </div>
     </div>
     <div className='container2' >
<Allitems/>
   </div>
     </div>
    </div>
    </div>
  );
};
export const InactiveItems = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
     <div className='modal-container '>
     <div className='title'><Allitemdropdown/>
     
     <div className='title1'>
        <NavLink to='/inventory/additem'><button className="allbutton" >New</button></NavLink> 
        <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="allbutton"
                    table="items"
                    filename="itemxls"
                    sheet="tablexls"
                    buttonText="Export File"/> 
        </div>
     </div>
     <div className='container2' >
<AllInactiveitems/>
   </div>
     </div>
    </div>
    </div>
  );
};
export const AllPresentItem = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
     <div className='modal-container '>
     <div className='title'><Allitemdropdown/><h3> </h3>
     
     <div className='title1'>
        <NavLink to='/inventory/additem'><button className="allbutton" >New</button></NavLink> 
        <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="allbutton"
                    table="items"
                    filename="itemxls"
                    sheet="tablexls"
                    buttonText="Export File"/> 
        </div>
     </div>
     <div className='container2' >
<AllpresentItems/>
   </div>
     </div>
    </div>
    </div>
  );
};
export const Additem  = () => {
  return (
    <div><Sidebar/>
      <div className='home'>
     <div className='modal-container '>
      <div className='title'><h3>New Item</h3></div>
      <div className='container' ><Formone/> 
      </div>
      </div>
    </div>
    </div>
  );
};


// 

export const eachOne  = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
     <div className=' modal-container'>

      <div className='title'><h3>Each Item details</h3>
    
      </div>
      <div className='container' ><EachItem/>
      </div>
      </div>
    </div>
    </div>
  );
};

export const ItemNameEdit  = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
     <div className=' modal-container'>
      <div className='title'><h3>Edit Item</h3></div>
      <div className='container' ><Edititem/>
     </div>
      </div>
    </div>
    </div>
  );
};

export const Descriptionone  = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
     <div className='modal-container '>
      <div className='title'><h3 className='h3one'>Adjustment details</h3></div>
      <div className='container' ><Description/>
      </div>
      </div>
    </div>
    </div>
  );
};

export const Items1 = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
    <div className='modal-container '>
    <div className='title'><Inventorydropdown/>
    <div className='title1'>
       <NavLink to='/inventory/Inventoryadjustments'><button className="allbutton" >New</button></NavLink> 
       <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="inventory-adjustments"
                   filename="inventoryxls"
                   sheet="tablexls"
                   buttonText="Export File"/> 
       </div>
    </div>
    <div className='container2' >
<Allinventory/>
  </div>
    </div>
   </div>
   </div>
  );
};
export const InventoryInactive = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
    <div className='modal-container '>
    <div className='title'><Inventorydropdown/>
    <div className='title1'>
       <NavLink to='/inventory/Inventoryadjustments'><button className="allbutton" >New</button></NavLink> 
       <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="inventory-adjustments"
                   filename="inventoryxls"
                   sheet="tablexls"
                   buttonText="Export File"/> 
       </div>
    </div>
    <div className='container2' >
    <InactiveInventory/>
  </div>
    </div>
   </div>
   </div>
  );
};
export const InventoryAll = () => {
  return (
    <div> <Sidebar/>
    <div className='reports'>
    <div className='modal-container '>
    <div className='title'><Inventorydropdown/>
    <div className='title1'>
       <NavLink to='/inventory/Inventoryadjustments'><button className="allbutton" >New</button></NavLink> 
       <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="inventory-adjustments"
                   filename="inventoryxls"
                   sheet="tablexls"
                   buttonText="Export File"/> 
       </div>
    </div>
    <div className='container2' >
    <AllInventoryPresent/>
  </div>
    </div>
   </div>
   </div>
  );
};
export const  Inventoryadjustments = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
     <div className='modal-container '>
      <div className='title'><h3>Inventory Adjustment</h3></div>
      <div className='container' ><Inventoryform/>
      </div>
      </div>
    </div>
    </div>
  );
};






import React, { useEffect, useState } from 'react'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';



const InactiveListviewPollution = () => {

   //**initialize the value to update dynamic data in table what ever getting from backend*/
   const[datas,setDatas]= useState([]);
   //**for search operation update the Searchvalue
   const [searchValue, setSearchValue] = useState('');
   //** to get token from sessionStorage
   const token = sessionStorage.getItem('jwtToken');
    //**for pagination current page will be at 1 in initial stage
 const [currentPage, setCurrentPage] = useState(1);
 //** itemperpage will be 50 */
 //**1-50 1st page, 50-100 2nd page like that it will go on
 const [itemsPerPage, setItemsPerPage] = useState(50);
 //**lastIndex of currentpage
 const lastIndex = currentPage * itemsPerPage;
 //**FirstIndex of lastpage
 const firstIndex = lastIndex - itemsPerPage;
 //** find the data between firstIndex, lastIndex*/
 //**this records need to be map inside table
 const records = datas.slice(firstIndex, lastIndex);
 //**Calculate total number of pages
 const totalPages = Math.ceil(datas.length / itemsPerPage);
    //**get all inactive Pollution details from backend
    //**o/p- all pollution details *
    const loadPucc= async(data)=>{
        
        const res = await axios.get('/pollutiongetinactive', {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setDatas(res.data);
        console.log(res.data)
    }
     //**Load pollution details when page loads */
    useEffect(()=>{
      loadPucc();
    },[]);

//**handel the search operation
//**const response = await axios.get(/pollutiongetinactive?q=${searchValue}, { ... })
// This line sends a GET request to the /pollutiongetinactive endpoint with a 
// query parameter q set to the value of the searchValue variable. */
//**o/p- get the search result value and set the datas varible */
const handleSearch = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.get(`/pollutiongetinactive?q=${searchValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDatas(response.data);
    setSearchValue('');
  } catch (err) {
    console.log(err);
  }
};
//it will reset the page to 1st page
const handleReset = () => {
  loadPucc();
};
const handlePageChange = (page) => {
  setCurrentPage(page);
};
 //Delete pucc Record
 const deletepucc = (pucc) =>
 {
   axios.post('/',{pucc})
   .then((result)=>{
    loadPucc();
   })
   .catch(()=>{
     alert('Error in the Code');
   });
 };

  return (
    <div>
      <Helmet> 
        <title> </title>
      </Helmet>
      <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Item Name/Item code..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button className="allbutton" onClick={handleReset}>Reset</button>
      </form>
        <table id="Pucc">
            <thead>
                <tr>
                   
                    <th>Vehicle Regn Number</th>
                    <th>Licence Number</th>
                    <th>Owner Name</th>
                    <th>PUCC Number</th>
                    <th>Year Of Registration</th>
                    <th>Test Date</th>
                    <th>Valid Date</th>
                    <th>Center name</th>
                    <th>Status</th>
                    {/*<th>Delete</th> */}
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            records.map((pollution, keys) => {
              return (
                <tr key={keys}>
                  <td><NavLink to={`/Vehicle/polution/${pollution.pollutionid}`} className="addlink">{pollution.vehicleno}</NavLink></td>
                  <td>{pollution.licencenumber}</td>
                  <td>{pollution.ownername}</td>
                  <td>{pollution.pucc}</td>
                  <td>{pollution.regyear}</td>
                  <td>{pollution.testdate}</td>
                  <td>{pollution.validdate}</td>
                  <td>{pollution.center}</td>
                  <td>{pollution.status}</td>
                  {/* <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you really want to delete "+ invoice.invoicenumber
                          )
                          if (confirmBox === true) {
                            deleteinvoice(invoice.invoiceid)
                          }
                        }}/> 
                        </td> */}
                </tr>
              )
            })
          }
            </tbody>
        </table>
        {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default InactiveListviewPollution
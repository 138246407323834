import React from 'react';
import Sidebar from '../../components/Sidebar';
import ListviewSales from './ListviewSales';
import { NavLink } from 'react-router-dom';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import EachSales from './EachSales';
import EditSales from './EditSales';
import Dispach from './Dispach';
import Salesdropdown from '../../components/Dropdown/Salesdropdown';
import InactiveSales from './InactiveSales';
import AllPresentSales from './AllPresentSales';
import SalesByDate from './SalesByDate';
import DynamicTable from './DynamicTable';

// this will show the content for All active sales
export const Sales = () => {
    return (
        <div> 
        <Sidebar/>
       <div className='home'>
     <div className='modal-container '>
      <div className='title'>
      <Salesdropdown/>
       <div className='title1'>
       {/* <NavLink to='/sales/newsales'><button className="allbutton" >New</button></NavLink>  */}
       {/* here we changed the api so when purchaseorderid will be there then all information will displayed according to purchaseorderid
         and if purchaseorderid is not there then empty form will displayed*/}
       <NavLink to='/purechases/sales/:purchaseorderid'><button className="allbutton" >New</button></NavLink> 
      <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="Sales"
                   filename="tablexls"
                   sheet="tablexls"
                   buttonText="Export File"/>
       </div>
     
       </div>
      
       <div className='container2' >

         <ListviewSales/>
       </div>
      </div>
    </div>
     </div>
    );
  };
  // this will show the content for All sales
  export const AllSales = () => {
    return (
        <div> 
        <Sidebar/>
       <div className='home'>
     <div className='modal-container '>
      <div className='title'>
      <Salesdropdown/>
       <div className='title1'>
       <NavLink to='/sales/newsales'><button className="allbutton" >New</button></NavLink> 
      <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="Sales"
                   filename="tablexls"
                   sheet="tablexls"
                   buttonText="Export File"/>
       </div>
     
       </div>
      
       <div className='container2' >

         <AllPresentSales/>
       </div>
      </div>
    </div>
     </div>
    );
  };
  // this will show the content for All inactive sales
  export const AllInactiveSales = () => {
    return (
        <div> 
        <Sidebar/>
       <div className='home'>
     <div className='modal-container '>
      <div className='title'>
      <Salesdropdown/>
       <div className='title1'>
       <NavLink to='/sales/newsales'><button className="allbutton" >New</button></NavLink> 
      <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="Sales"
                   filename="tablexls"
                   sheet="tablexls"
                   buttonText="Export File"/>
       </div>
     
       </div>
      
       <div className='container2' >

         <InactiveSales/>
       </div>
      </div>
    </div>
     </div>
    );
  };
//this will show the new sales order content

  // export const  Salesorders = () => {
  //   return (
  //     <div> <Sidebar/>
  //     <div className='home'>
  //      <div className='modal-container '>
  //       <div className='title'><h3>New Sales Order </h3></div>
  //       <div className='container' >
  //           <NewSales/>
  //       </div>
  //       </div>
  //     </div>
  //     </div>
  //   );
  // };
//when we will click on any specific sales order it will show the content
  export const  EachSalesorders = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
       <div className='modal-container '>
        <div className='title'><h3> Supply Order </h3></div>
        <div className='container' >
            <EachSales/>
        </div>
        </div>
      </div>
      </div>
    );
  };
// it will show the edit page content of sales order
  export const  EditSalesorders = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
       <div className='modal-container '>
        <div className='title'><h3>Edit Supply Order </h3></div>
        <div className='container' >
            <EditSales/>
        </div>
        </div>
      </div>
      </div>
    );
  };
  //it will show the verify item form details
  export const Dispached = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
       <div className='modal-container '>
        <div className='title'><h3>Verify Item</h3></div>
        <div className='container' ><Dispach/>
        </div>
        </div>
      </div>
      </div>
    );
  };
  
  export const AllSalesByDate = () => {
    return (
        <div> 
        <Sidebar/>
       <div className='home'>
     <div className='modal-container '>
      <div className='title'>
      <Salesdropdown/>
       <div className='title1'>
       <NavLink to='/sales/newsales'><button className="allbutton" >New</button></NavLink> 
      <ReactHtmlTableToExcel
                   id="test-table-xls-button"
                   className="allbutton"
                   table="Sales"
                   filename="tablexls"
                   sheet="tablexls"
                   buttonText="Export File"/>
       </div>
     
       </div>
      
       <div className='container2' >

         <SalesByDate/>
       </div>
      </div>
    </div>
     </div>
    );
  };
  export const Dynamic = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
       <div className='modal-container '>
        <div className='title'><h3>DynamicTable</h3></div>
        <div className='container' ><DynamicTable/>
        </div>
        </div>
      </div>
      </div>
    );
  };
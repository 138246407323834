import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import { AiFillApple } from "react-icons/ai";
import { Redirect, useHistory } from "react-router-dom";
import { BsMicrosoft } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import axios from "../../../helper/axios";
import { Helmet } from "react-helmet";
import bbslogo from "../../../components/Images/BBS_LOGO.png";
import AGM_LOGO from "../../../components/Images/AGM_LOGO.png";
import bcrypt from "bcryptjs";
import Cryptr from "cryptr";

const initialValues = {
  userid: "",
  password: "",
};
const cryptr = new Cryptr("AGM_TEST");
axios.defaults.withCredentials = true;

const Signin = () => {
  const [loginStatus, setLoginStatus] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      // validationSchema: ,
      onSubmit: async (values, action) => {
        try {
          // values.password = bcrypt.hashSync(values.password, 1)
          //here the user entered password will be encrypted at login time
          //(if not encrypted the passowrd will be visible in browser payload)
          values.password = cryptr.encrypt(values.password);
          const response = await axios.post("/AdminLogin", values);
          console.log(response.data);
          if (response.data.success === true) {
            setLoginStatus(response.data.success);
            sessionStorage.setItem("jwtToken", response.data.token);
            return history.push("/dashboard");
            window.location.reload();
          } else {
            setSuccess(false);
            setError(true);
          }
        } catch (error) {
          console.log(error);
          alert("An error occurred");
        }

        action.resetForm();
      },
    });
    // const token = sessionStorage.getItem('jwtToken');
    // const [userRoles, setRole] = useState(null);
    // const loadAll = async () => {
    //   try {
    //     const response = await axios.post('/Login', null, {
    //       headers: { Authorization: `Bearer ${token}` },
    //     });
    //     const userRoles = response.data.person[0][0].role;
    //     console.log(userRoles)
    //     setRole(userRoles);
        
    //   } catch (error) {
    //     console.log(error.response.data);
       
    //   }
    // };
    // useEffect(() => {
    //   loadAll()
       
    // }, []);
    // console.log(userRoles)
  return (
    <div>
      <Helmet>
        {" "}
        <title>Login|BBS Inventory Management</title>
      </Helmet>
      <div className="homeone">
        <div className="sign">
          {/* <img src={bbslogo}style={{width:"100px",height:"80px"}}/> */}
          <div className="signone">
            {success && (
              <div className="form-erroremployees">Login successful.</div>
            )}
            {error && <div className="form-errors">Invalid Login!</div>}
            <div className="wrapping">
              <div className="heading">
                <img src={bbslogo} className="imgstyle" />
              </div>
              <div className="heading">
                <img src={AGM_LOGO} className="imgstyle2" />
              </div>{" "}
            </div>
            <div className="heading">
              <p className="stylepara">BBS Inventory Management </p>
            </div>

            <form className="form" onSubmit={handleSubmit}>
              <div className="wrap">
                <input
                  type="text"
                  className="signinput"
                  autoComplete="off"
                  name="userid"
                  id="userid"
                  placeholder="Enter the Employee Emailid"
                  value={values.userid}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="form-error">{errors.email}</p>
                ) : null}
                {/* </div>

<div className='wrap'> */}

                <input
                  type="password"
                  className="signinput"
                  autoComplete="off"
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password ? (
                  <p className="form-error">{errors.password}</p>
                ) : null}

                {/* </div> */}

                <button className="bluebutt" type="submit">
                  <p style={{ fontSize: "15px" }}>Login</p>
                </button>
                {/* <button className="bluebutt"type="submit"onClick={handleSubmit}><p style={{fontSize:"15px"}}>Sign in</p> </button> */}
                <br></br>
                {/* <div className='pass'>
                          <p style={{color:"#007ac6"}}> Forgot password?</p>
                        </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;

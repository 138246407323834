import React, { useState } from 'react'
import * as RiIcons from 'react-icons/ri';
import { Link } from "react-router-dom";
const Purchasedropdown = () => {
    const [open, setOpen]=useState(false);
     const menus =[{
        title: 'All Purchases',
        path: '/purechases/allthepurchase',
        
      },
      {
        title: 'Active Purchases',
        path: '/purechases/allpurchase',
        
      },
      {
        title: 'Inactive Purchases',
        path: '/purechases/allInactivepurchase',
        
      },
      ]


  return (
    <div> 
        <div
        onClick={()=>setOpen(!open)}>
            <h3>All Purchases<RiIcons.RiArrowDownSFill /></h3>
        </div>    
      {
        open && (
            <div 
            className='dropback'>
            <div>
            
              {
                 menus.map((menu)=>(
                   <ul
                     onClick={()=>setOpen(false)}
                      className='drop'  key={menu.title}> 
                     <Link to={menu.path} className='itemdrop'>{menu.title} </Link> 
                     </ul>
                 ))
               }
             
             </div>
            </div>
        )
      }
    </div>
  )
}

export default Purchasedropdown
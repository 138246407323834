import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { GrEdit } from 'react-icons/gr';
import { NavLink, useParams, useHistory } from 'react-router-dom'


const EachPollutionCertificate = () => {
  //**here will get the pollutionid from the url by using the useParams hook */
  const { pollutionid } = useParams();
  //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  // //**the history object to navigate the user to a different route by calling  return history.push('/Polution'); */
  //const history = useHistory();
  // will update all the value for all input fields of this form here  

  const [values, setValues] = useState({

    vehicleno: "",
    licencenumber: "",
    ownername: "",
    pucc: "",
    regyear: "",
    testdate: "",
    validdate: "",
    center: "",
    status: ""
  });
  // willget the token from  sessionStorage  
  const token = sessionStorage.getItem('jwtToken');
  //When will load the page all the details of pollution per pollutionid will load here
  useEffect(() => {
    eachPucc()
  }, []);
  //will get all the details of pollution per pollutionid here    
  const eachPucc = async () => {
    const res = await axios.get(`/pollutiongetid/${pollutionid}`, {
       //to authorize the api send the token header
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setValues(newProduct)
  }
  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      {/* edit button to edit specific pollution detail */}
      <div className='editbu'>
        <button className='input-bu'><NavLink to={`/Vehicle/polution/${pollutionid}/edit`}><GrEdit /></NavLink></button>
      </div>
      <div className="">
        <form >
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="vehicleno" className="input-labelemployee">
                Vehicle Regn Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="vehicleno"
                  id="vehicleno"
                  placeholder="Registration Number"
                  value={values.vehicleno}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="licencenumber" className="input-labelemployee">
                Licence Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="licencenumber"
                  id="licencenumber"
                  placeholder="Licence Number"
                  value={values.licencenumber}
                />
              </div>
            </div></div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="ownername" className="input-labelemployee">
                Owner Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="ownername"
                  id="ownername"
                  placeholder="Owner Name"
                  value={values.ownername}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="pucc" className="input-labelemployee">
                PUCC Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="pucc"
                  id="pucc"
                  placeholder="Pollution Under Control Certificate"
                  value={values.pucc}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="regyear" className="input-labelemployee">
                Year Of Registration
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="regyear"
                  id="regyear"
                  placeholder="Year Of Registration"
                  value={values.regyear}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="testdate" className="input-labelemployee">
                Test Date
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="testdate"
                  id="testdate"
                  value={values.testdate}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="validdate" className="input-labelemployee">
                Valid Date
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="validdate"
                  id="validdate"
                  value={values.validdate}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="center" className="input-labelemployee">
                Center name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="center"
                  id="center"
                  placeholder="Center Name"
                  value={values.center}
                />
              </div>
            </div>
          </div>
          {/* added new field called status here */}
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="status" className="input-labelemployee">
                Status
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"
                  value={values.status}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EachPollutionCertificate;
import React from 'react';
import {useState} from 'react';

import { IntegrationsDropdown } from './Navitems';
import {Link } from 'react-router-dom';


const Integrations = () => {

    const [dropdown, setDropdown] = useState(false);

    return(
      
      
    <ul
        className={dropdown ? "Integrations-submenu clicked" : "mega-menu"}
        onClick={() => setDropdown(!dropdown)}
      >
<div className='megamenu-items'>
{IntegrationsDropdown.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.className}
                onClick={() => setDropdown(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
        </div>

        <div className='megamenu-items'>
{IntegrationsDropdown.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.className}
                onClick={() => setDropdown(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
        </div>
      </ul>

       
  )
  
  
       }
 export default Integrations


// import React from 'react'

// const SalesByDate = () => {
//   return (
//     <div>SalesByDate</div>
//   )
// }

// export default SalesByDate
import React, { useEffect, useState } from 'react'
import axios from "../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink,useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';


const  SalesByDate = () => {
  const history = useHistory();
    const[data,setData]= useState([]);
    const token = sessionStorage.getItem('jwtToken');
   //display all the Supplier in table
    const loadSales= async(data)=>{
        
        const res = await axios.get("/getItemQuantity", {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
      if(!sessionStorage.getItem('jwtToken')){
        return history.push("/");
      }
        loadSales();
    },[]);

 // Delete Employee Record
//  const deletesupplier = (value) =>
//  {
//    axios.post('/SaleeorderDelete',{value})
//    .then((result)=>{
//     loadSales();
//    })
//    .catch(()=>{
//      alert('Error in the Code');
//    });
//  };
 
  return (
    <div>
      <Helmet> 
        <title>All Sales|BBS Inventory</title>
      </Helmet>
        <table id="Sales">
            <thead>
                <tr>
                    {/* <th></th> */}
                    <th>Sale Date / Service Date</th>
                    <th>Sales Order</th>
                    <th>Item Name</th>
                    <th>Sale Quantity</th>
  
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            data.map((value, key) => {
              return (
                <tr key={key}>
                    {/* <td><input type="checkbox"/></td> */}
                    {/* {value.saletype === "Goods" && ( <td>{value.salesdate}</td>)}
                    {value.saletype === "Services" && ( <td>{value.servicedate}</td>)} */}
                    <td>{value.salesdate}</td>
                  <td>{value.salesorder}</td>
                  <td>{value.itemname}</td>
                  <td>{value.salequantity}</td>
                 
                 
                </tr>
              )
            })
          }
            </tbody>
        </table>
    </div>
  )
}

export default  SalesByDate
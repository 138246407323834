
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { polution } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import  { useHistory } from 'react-router-dom'
const initialValues = {
  
   vehicleno: "",
   licencenumber:"",
    ownername:"",
    pucc:"",
    regyear: "",
    testdate:"",
    validdate:"",
     center:"",
     
    };
    
  
    const Pollution= () => {
      const history = useHistory();
      
      const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
      useFormik({
        initialValues,
        validationSchema: polution,
        
        onSubmit: async(values, action,) => {
         
        try {
            const data = new FormData();
            data.append("document", JSON.stringify(values));
            data.append("avatar",file);
            
            const response = await axios.post("/pollutionPost",data,{
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              }
            });
  
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/Polution');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
        action.resetForm();
        },
        
      });
      const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const token = sessionStorage.getItem('jwtToken');
    const[file,setFile]=useState("");

       const setimgfile=(e)=>{
        setFile(e.target.files[0])
        console.log(e.target.files[0])
       }
       const[getVehicle,setGetVehicle]=useState([]);
       useEffect(()=>{
        loadVehicle()
         
         },[]);
        
         const loadVehicle = async ()=>{
             const res = await axios.get(`/RcGetvehicle`, {
               headers: { 
                 Authorization: `Bearer ${token}`,
               },
             });
             const newProduct = await res.data;
             console.log(newProduct)
             setGetVehicle(newProduct)
            
             }
      return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
  
        <div className="">
          
         <form onSubmit={handleSubmit}>
          
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
        {error && <div className="form-error">Error submitting form. Please try again later.</div>}
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="vehicleno" className="input-labelemployee leftone">
                      Vehicle Number
                      </label>
                      <div className="inputerroremployee">
                      <select
                        type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                       
                        placeholder="Registration Number"
                        value={values.vehicleno}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ><option>Select Vehcile No</option>
                      {getVehicle.map(vehicle => (
                    <option value={vehicle.vehicleno} key={vehicle.id} >{vehicle.vehicleno}</option>

                  ))
                  }
                        </select>
                       {errors.vehicleno && touched.vehicleno ? (
                        <p className="form-erroremployee">{errors.vehicleno}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="licencenumber" className="input-labelemployee">
                   Licence Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="licencenumber"
                        id="licencenumber"
                       
                        placeholder="Licence Number"
                        value={values.licencenumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.licencenumber && touched.licencenumber ? (
                        <p className="form-erroremployee">{errors.licencenumber}</p>
                      ) : null}
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="ownername" className="input-labelemployee">
                      Owner Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="ownername"
                      id="ownername"
                      placeholder="Owner Name"
                      value={values.ownername}
                      onChange={handleChange}
                        onBlur={handleBlur}
                    />
                     
                   
  
                    {errors.ownername && touched.ownername ? (
                        <p className="form-erroremployee">{errors.ownername}</p>
                      ) : null}  
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="pucc" className="input-labelemployee">
                     PUCC Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="pucc"
                        id="pucc"
                        placeholder="Pollution Under Control Certificate"
                        value={values.pucc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.pucc && touched.pucc ? (
                        <p className="form-erroremployee">{errors.pucc}</p>
                      ) : null}
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="regyear" className="input-labelemployee leftone">
                   Year Of Registration
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="regyear"
                        id="regyear"
                        placeholder="Year Of Registration"
                        value={values.regyear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.regyear && touched.regyear ? (
                        <p className="form-erroremployee">{errors.regyear}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="testdate" className="input-labelemployee leftone">
                    Test Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="testdate"
                        id="testdate"
                        
                        value={values.testdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                     
                     {errors.testdate && touched.testdate ? (
                        <p className="form-erroremployee">{errors.testdate}</p>
                      ) : null}
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="validdate" className="input-labelemployee leftone">
                      Valid Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="date"
                        autoComplete="off"
                        name="validdate"
                        id="validdate"
                        
                        value={values.validdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.validdate && touched.validdate ? (
                        <p className="form-erroremployee">{errors.validdate}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="center" className="input-labelemployee">
                    Center name
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                        type="text"
                        autoComplete="off"
                        name="center"
                        id="center"
                        placeholder="Center Name"
                        value={values.center}
                        onChange={handleChange}
                        onBlur={handleBlur}/>
        
        {errors.center && touched.center ? (
                        <p className="form-erroremployee">{errors.center}</p>
                      ) : null}
                    </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="Image" className="input-labelemployee" >Chose PUCC Picture</label>
                      <div className="inputerroremployee">
                      <input type="file"name="avatar"
                          id="avatar"
                          placeholder=""
                          onChange={setimgfile}
                           />
                           </div>
                     </div> 
                      </div>          
                    <div className="modal-buttonsemployee">
                      
                      <button className="input-button" type="submit" >
                       Save
                      </button>   
                       
                      <button className="input-button" onClick={handleReset}type="reset">
                     Cancel
                    </button>
                    </div>
                   
                  </form>
                  </div>     
        </>
      )
    }
    
    export default Pollution ;
import React from "react";
import { useFormik } from "formik";
import { useState,useEffect } from "react";
import { empsignUpSchema } from "../../../helper/schemas";
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";
import {BsFillEyeFill} from 'react-icons/bs';
import { useHistory } from 'react-router-dom'
import axios from "../../../helper/axios";
import Cryptr from 'cryptr'
const initialValues = {
  
  name: "",
  gender: "Male",
   email: "",
   date:"",
   password: "",
   fathersname:"",
   cpassword:"",
   maritalstatus:"",
   phoneno:"",
   address:"",
   state:"",
   pincode:"",
   jobtype:"",
   experience:0,
   department:"",
   joindate:"",
   role:""
  };
  const cryptr = new Cryptr("AGM_EMPLOYEE")
  const EmployRegistration = () => {
    const history =useHistory();
    const [message, setMessage] = useState('');
    
    const handleSwitch = event => {
      const result = event.target.value.replace(/[^a-z]/gi, '');
    
      setMessage(result);
    };
  //  const  handleage = (e) => {
  //     console.log (e.target.value);
  
  //     this.setState({ dob1: e.target.value })
  //     console.log(this.state.dob1);
  //     var age_latest = {age_latest: this.calculate_age}
  //     console.log(age_latest);
  
  //     this.setState({ age1: age_latest })
  //     console.log("Age:", this.state.age1);
  //   }

  // const  calculate_age = (dob1) => {
  //     var today = new Date();
  //     var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
  //     var age_now = today.getFullYear() - birthDate.getFullYear();
  //     var m = today.getMonth() - birthDate.getMonth();
  //     console.log(calculate_age);
  //     if ( m >18) 
  //     {
  //        alert("date is valid")
  //     }
  //     else{
  //       alert ("date is invalid")
  //     }
     
  //   }

    
    const [type, setType]=useState('password');
  const [icon, setIcon]=useState('show');
  const token = sessionStorage.getItem('jwtToken');
  const handleToggle=(e)=>{   
    const gettype=e.target.value;
    console.log(gettype) 
    if(gettype==='password')
    {
      setType('text');      
      setIcon('Hide');
    }
    else{
         
      setType('password');
      setIcon('Show');
    }
  }

    const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: empsignUpSchema,
      //creating new employ
      onSubmit: async(values, action) => {
        values.password = cryptr.encrypt(values.password)
        values.cpassword = cryptr.encrypt(values.cpassword)
        const response =await axios.post("/EmployeeDynamic", values, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          values
        );
        if (response.status === 200) {
          return history.push("/inventory/activeemployee");
        }
      },
    });
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      },[])

    return (
      <>
      <Helmet> 
        <title>New Employee|BBS Inventory</title>
      </Helmet>
      <div className="">
       <form onSubmit={handleSubmit}>
       <h4>Personal Information</h4>
       <div className="input-employeecontainer"> 
       
                  <div className="input-blockemployee">
                    <label htmlFor="name" className="input-labelemployee leftone">
                     Full Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="name"
                      id="name"
                     
                      placeholder="Full Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className="form-erroremployee">{errors.name}</p>
                    ) : null}
                    </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="gender" className="input-labelradio">
                      Gender
                    </label>
                    <div className="inputerroremployee">
                    <div className="radiotype">
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="Male"
                      checked={values.gender==="Male"}
                      onChange={handleChange}
                      
                    />
                    {""}Male
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="Female"
                      checked={values.gender==="Female"}
                      onChange={handleChange}
                      
                    />
                    {""}Female
                    <input
                      type="radio"
                      autoComplete="off"
                      name="gender"
                      className="radioemployee"
                      value="others"
                      checked={values.gender==="others"}
                      onChange={handleChange}
                      
                    />
                    {""}Others
                    </div>
                  </div>
                  </div></div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="email" className="input-labelemployee leftone">
                      Email
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="email"
                      
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email? (
                      <p className="form-erroremployee">{errors.email}</p>
                    ) : null}
                    </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="date" className="input-labelemployee">
                      Date Of Birth
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="date"
                      autoComplete="off"
                      name="date"
                      id="date"
                      placeholder="Date of Birth"
                      
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.date && touched.date? (
                      <p className="form-erroremployee">{errors.date}</p>
                    ) : null}
                    </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="password" className="input-labelemployee leftone">
                      Password
                    </label>
                    <div className="inputerroremployee">
                    
                    
                    <input
                    
                      type={type}
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                     {/* <button type="button" value={type} onClick={handleToggle}>Show</button> */}
                    {errors.password && touched.password ? (
                      <p className="form-erroremployee">{errors.password}</p>
                    ) : null}
                    </div>
                    <button className="show-button" type="button" value={type} onClick={handleToggle}>Show</button>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="fathersname" className="input-labelemployee">
                      Father's Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type="text"
                      autoComplete="off"
                      name="fathersname"
                      id="fathersname"
                      placeholder="Father's Name"
                      value={values.fathersname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.fathersname && touched.fathersname ? (
                      <p className="form-erroremployee">{errors.fathersname}</p>
                    ) : null}

                  </div>
                  </div>
                  </div>
                  <div className="input-employeecontainer"> 
                  <div className="input-blockemployee">
                    <label htmlFor="cpassword" className="input-labelemployee leftone">
                       Confirm Password
                    </label>
                    <div className="inputerroremployee">
                    <input
                    
                      type={type}
                      autoComplete="off"
                      name="cpassword"
                      id="cpassword"
                      placeholder="Confirm Password"
                      value={values.cpassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.cpassword && touched.cpassword ? (
                      <p className="form-erroremployee">{errors.cpassword}</p>
                    ) : null}
                    {/* <button type="button" value={type} onClick={handleToggle}>Show</button> */}
                    </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="maritalstatus" className="input-labelemployee">
                      Marital Status
                    </label>
                    <div className="inputerroremployee">
                    <select
                    
                      type="text"
                      autoComplete="off"
                      name="maritalstatus"
                      id="maritalstatus"
                      placeholder="maritalstatus"
                      value={values.maritalstatus}
                      onChange={handleChange}
                      onBlur={handleBlur}>
                    
                    <option></option>
                   <option>Married</option>
                   <option>Unmarried</option>
                   <option>Divorced
                   </option>
                   <option>Widower</option>

                   </select>
                   </div>
                  </div>
                  
                  </div><br></br>
                  {/* <div className=""> */}
                    <h4>Contact Information</h4>
                    <div className="input-employeecontainer"> 
                  <div className="input-blockemployees">
                      <label htmlFor="phoneno" className="input-labelemployee leftone">
                      Phone Number
                    </label>
                    <div className="inputerroremployee">
                    <input
                   className="input-employee"
                      type="number"
                      autoComplete="off"
                      name="phoneno"
                      id="phoneno"
                      placeholder="Phone Number"
                      value={values.phoneno}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phoneno && touched.phoneno? (
                      <p className="form-erroremployee">{errors.phoneno}</p>
                    ) : null}
                    </div>

                   
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="address" className="input-labelemployee">
                      Address
                    </label>
                    <div className="inputerroremployee">
                    <textarea
                     rows="4" cols="35"
                      type="text"
                      autoComplete="off"
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.address && touched.address? (
                      <p className="form-erroremployee">{errors.address}</p>
                    ) : null}
</div>
                      </div>
                    </div>
                    <div className="input-employeecontainer">
                    
                    <div className="input-blockemployee">
                    <label htmlFor="state" className="input-labelemployee">
                    State
                  </label>
                  <div className="inputerroremployee">
                  <select
                    type="text"
                    autoComplete="off"
                    name="state"
                    id="state"
                    placeholder="State"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                   <option>Select State</option>
  <option>Andaman and Nicobar Islands</option>
  <option>Andhra Pradesh</option>
  <option>Arunachal Pradesh</option>
  <option>Assam</option>
  <option>Bihar</option>
  <option>Chandigarh</option>
  <option>Chhattisgarh</option>
  <option>Dadra and Nagar Haveli</option>
  <option>Daman and Diu</option>
  <option>Delhi</option>
  <option>Goa</option>
  <option>Gujarat</option>
  <option>Haryana</option>
  <option>Himachal Pradesh</option>
  <option>Jammu and Kashmir</option>
  <option>Jharkhand</option>
  <option>Karnataka</option>
  <option>kerala</option>
  <option>Lakshadweep</option>
  <option>Madhya Pradesh</option>
  <option>Maharashtra</option>
  <option>Manipur</option>
  <option>Meghalaya</option>
  <option>Mizoram</option>
  <option>Nagaland</option>
  <option>Odisha</option>
  <option>Puducherry</option>
  <option>Punjab</option>
  <option>Rajasthan</option>
  <option>Sikkim</option>
  <option>Tamil Nadu</option>
  <option>Telangana</option>
  <option>Tripura</option>
  <option>Uttar Pradesh</option>
  <option>Uttarakhand</option>
  <option>West Bengal</option>

                  </select>
                  </div>

                 
                </div>
                <div className="input-blockemployee">
                  <label htmlFor="pincode" className="input-labelemployee">
                    Pincode
                  </label>
                  <div className="inputerroremployee">
                  <input
                   
                    type="number"
                    autoComplete="off"
                    name="pincode"
                    id="pincode"
                    placeholder="Pin Code"
                    value={values.pincode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.pincode && touched.pincode? (
                    <p className="form-erroremployee">{errors.pincode}</p>
                  ) : null}
</div>
                    </div>
                  </div><br></br>
                  {/* </div> */}
                  <div className="">
                  <h4>Professional Information</h4>
                    <div className="input-employeecontainer">
                    
                      <div className="input-blockemployee">
                      <label htmlFor="jobtype" className="input-labelemployee">
                      Job Type
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      value={message}
                      autoComplete="off"
                      name="jobtype"
                      id="jobtype"
                      placeholder="Job Type"
                     onChange={handleSwitch}
                     
                      onBlur={handleBlur}
                      
       
                    />
                    {errors.jobtype && touched.jobtype? (
                      <p className="form-erroremployee">{errors.jobtype}</p>
                    ) : null}

                   </div>
                  </div>
                  <div className="input-blockemployee">
                    <label htmlFor="experience" className="input-labelemployee">
                    Experience (in years)
                    </label>
                    <div className="inputerroremployee">
                    <input
                     
                      type="number"
                      autoComplete="off"
                      name="experience"
                      id="experience"
                      placeholder="Experience"
                      value={values.experience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.experience && touched.experience? (
                      <p className="form-erroremployee">{errors.experience}</p>
                    ) : null}
</div>
                      </div>
                    </div>
                    <div className="input-employeecontainer">
                    
                    <div className="input-blockemployee">
                    <label htmlFor="department" className="input-labelemployee">
                    Department
                  </label>
                  <div className="inputerroremployee">
                  <select
                    type="text"
                    autoComplete="off"
                    name="department"
                    id="department"
                    placeholder="Department"
                    value={values.department}
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                  <option>Select</option>
                  <option>Business management </option>
                  <option>Business Administration</option>
                  <option>Sales</option>
                  <option>Purchase</option>
                  <option>Marketing</option>
                  <option>Technical </option>
                  <option>Non Technical </option>
                  <option>Store</option>
                  <option>IT</option>
                  <option>Others</option>
                  </select>
</div>
                 
                </div>
                <div className="input-blockemployee">
                  <label htmlFor="joindate" className="input-labelemployee leftone">
                    Joining Date
                  </label>
                  <div className="inputerroremployee">
                  <input
                   
                    type="date"
                    autoComplete="off"
                    name="joindate"
                    id="joindate"
                    placeholder="Join Date"
                    value={values.joindate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.joindate && touched.joindate? (
                    <p className="form-erroremployee">{errors.joindate}</p>
                  ) : null}
</div>
                    </div>
                  </div>

                  </div>

                  <div className="input-employeecontainer">
                    
                    <div className="input-blockemployee">
                    <label htmlFor="role" className="input-labelemployee leftone">
                    Role
                  </label>
                  <div className="inputerroremployee">
                  <select
                    type="text"
                    autoComplete="off"
                    name="role"
                    id="role"
                    placeholder="Department"
                    value={values.role}
                    
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                  <option>Select</option>
                  <option>Supperadmin </option>
                  <option>Admin </option>
                  <option>Manager </option>
                  <option>Employee</option>
                  
                  </select>
                  {errors.role && touched.role? (
                    <p className="form-erroremployee">{errors.role}</p>
                  ) : null}
</div>
                 
                </div> </div>
                  <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="reset" onClick={handleReset}>
                   Reset
                  </button></div>
                  </div>
                </form>
                </div>   
      </>
    )
  }
  
  export default EmployRegistration

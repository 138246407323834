import * as Yup from "yup";

export const goodsSchema = Yup.object({
  // reference:Yup.string().min(2).max(25).required("Please enter your reference no"),
  search:Yup.string().required("Please enter the customer name"),
  salesdate:Yup.date().required("Please enter the date"),
  //shipmentdate:Yup.date().required("Please enter the date"),
  //expected:Yup.date().required("Please enter the date"),
 
});
export const servicesSchema = Yup.object({

  search:Yup.string().required("Please enter the customer name"),
 servicedate:Yup.date().required("Please enter the date"),
 
});
export const validationSchema = Yup.object().shape({
 
}).when("saletype", {
  is: "Services",
  then: servicesSchema,
  otherwise: goodsSchema
});

export const userSignin=Yup.object({
  cname: Yup.string().matches(/^[A-Za-z]+$/, 'Only Letter').min(2).max(25).required("Please enter your organization name"), 
  email: Yup.string().email().required("Please enter a valid email address "), 
  password:Yup.string().min(8).max(8).required('Password cannot be less than 8 characters'),
  phoneno:Yup.string().min(10).max(10).required("Please enter a valid mobile number")
})


export const empsignUpSchema = Yup.object({
  // name: Yup.string().matches(/^[A-Za-z]+$/, 'Only Letter').min(2).max(25).required("Please enter your Name"),
  name: Yup.string().min(2).max(25).required("Please enter your Name"),
  email: Yup.string().email().required("Please enter your Email Id"),
  //  pincode:Yup.string().min(6).max(6).required("Pincode must contain 6 digits"),
  phoneno: Yup.string().min(10).max(10).required("Please enter your Phone Number"),
  password: Yup.string().required('Password is required'),
  cpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password is required'),
  // date:Yup.date().required("Please enter the date of birth"),
  joindate:Yup.date().required("Please enter the joiningdate"),
   role:Yup.string().required("Please enter the role"),
});

export const VendorSchema = Yup.object({
   //companyname: Yup.string().matches(/^[A-Za-z]+$/, 'Only Letter').min(2).max(25).required("Please enter company name"),
  name: Yup.string().required("Please enter your name"),
  // email: Yup.string().email().required("Please enter your email id"),
  // phoneno: Yup.string().min(10).max(10).required("Please enter your phoneno"),
//  adress: Yup.string().min(2).max(25).required("Please enter your adress"),
 
});

export const Schema = Yup.object({
  reference:Yup.string().min(2).max(25).required("Please enter your requisition no"),
  requestedby:Yup.string().min(2).max(25).required("Please enter employee name"),
  search:Yup.string().required("Please enter the supplier name"),
  // email: Yup.string().email().required("Please enter your email id"),
  // phoneno: Yup.string().min(10).max(10).required("Please enter your phoneno"),
  purchasedate:Yup.date().required("Please enter the date"),
  //adress: Yup.string().min(2).max(25).required("Please enter your adress"),
});

 
  export const Manufacturer=Yup.object({
      // manufacturername:Yup.string().matches(/^[A-Za-z]+$/, 'Only Letter').min(2).max(40).required("Please enter the Manufacturer's Name")
      manufacturername:Yup.string().min(2).max(40).required("Please enter the Manufacturer's Name")
    });
    
      export const GoodsSchema = Yup.object({
        Name: Yup.string().min(2).max(45).required("Please enter the Item Name"),
        //supplier: Yup.string().required("Please enter the supplier"),
        // Quantity: Yup.string().required("Please enter the quantity"),
      });
     
    export const invoice = Yup.object({
        invoicenumber: Yup.string().min(2).max(25).required("Please enter the Invoicenumber"),
        invoicedate: Yup.date().required("Please enter the  invoicedate"),
        suppliername:Yup.string().required("Please select suppliername"),
        withouttax:Yup.string().min(2).max(35).required("Please enter cost"),
        withtax:Yup.string().min(1).max(35).required("Please enter withtax"),
        tax:Yup.string().min(1).max(35).required("Please enter tax"),
        purchaser:Yup.string().min(2).max(25).required("Please select purchaser"),
   });
      
export const Inventory= Yup.object({
   //Id:Yup.string().min(6).max(25).required("Please select The Item "),
   date: Yup.date().required("Please enter the date"),
   reason:Yup.string().required("Please select reason "),
   //newquantity:Yup.number().required("Please enter new quantity"),
  //description: Yup.string().min(2).max(25).required("Please enter the description"),
  });
//validation schema for vehicle RC
export const RCdoc = Yup.object({
    vehicleno: Yup.string().min(2).max(25).required("Please enter the vehicleno"),
    registrationnumber:Yup.string().required("Please enter registrationnumber"),
    registrationdate:Yup.string().required("Please enter registration date"),
    //regupto:Yup.string().required("Please enter regupto date"),
   
});
//validation schema for employee leave
export const empleave = Yup.object({
  //employeeid: Yup.string().min(2).max(25).required("Please enter the employeeid"),
  //employeename:Yup.string().required("Please enter employee name"),
  leavefrom:Yup.date().required("Please enter leavefrom date"),
  leaveto:Yup.date().required("Please enter leaveto date"),
 
});
//validation schema for vehicle insurance
export const insurance = Yup.object({
   vehicleno: Yup.string().min(2).max(25).required("Please enter the vehicle number"),
   policyno:Yup.string().required("Please enter policy number"),
  effectivedate:Yup.date().required("Please enter issue date"),
   expirydate:Yup.date().required("Please enter expiry date"),
 
});
//validation schema for vehicle permit
export const permit= Yup.object({
  vehicleno: Yup.string().min(2).max(25).required("Please enter the vehicle number"),
  state:Yup.string().required("Please enter the state name"),
  permitfrom:Yup.date().required("Please enter issue date"),
  permitupto:Yup.date().required("Please enter expiry date"),

});
//validation schema for vehicle Roadtax
export const roadTax= Yup.object({
  vehicleno: Yup.string().min(2).max(25).required("Please enter the vehicle number"),
  roadtaxfrom:Yup.date().required("Please enter issue date"),
  taxupto:Yup.date().required("Please enter expiry date"),

});
//validation schema for vehicle Pollution details
export const polution= Yup.object({
  vehicleno: Yup.string().min(2).max(25).required("Please enter the vehicle number"),
  regyear:Yup.date().required("Please enter registration date"),
  testdate:Yup.date().required("Please enter issue date"),
  validdate:Yup.date().required("Please enter expiry date"),

});
//validation schema for vehicle service
export const vehicleservice= Yup.object({
  vehicleno: Yup.string().min(2).max(25).required("Please enter the vehicle number"),
  lastservicedata:Yup.date().required("Please enter last service date"),
  nextservicedate:Yup.date().required("Please enter next service date"),

});
export const SchemaRequisition= Yup.object({
  //requisitionnumber: Yup.string().max(30).required("Please enter the requisition number."),
  //requitionername :Yup.string().required("Please enter the employee name."),
  requisitiondate:Yup.date().required("Please enter the requisition date"),

});
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { NavLink, useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
const EditPermit = () => {
  // The useHistory hook is typically used in a functional component within
  //  a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  //**the history object to navigate the user to a different route by calling  return history.push('/Permit'); */
  const history = useHistory();
  //**here will get the permitid from the url by using the useParams hook */
  const { permitid } = useParams();
  // will update all the value for all input fields of this form here 
  const [permit, setPermit] = useState(
    {
      vehicleno: "",
      chassisno: "",
      ownername: "",
      npauth: "",
      state: "",
      permittype: "",
      permitfrom: "",
      permitupto: "",
      vechileclass: "",
      cost: "",
      status: ""
    }
  )
  // will get the token from  sessionStorage
  const token = sessionStorage.getItem('jwtToken');
  //** when page will load or refresh specific permit details will load */
  useEffect(() => {
    loadPermit()
  }, []);
  //** will get specific Roadtax details according to permitid  */
  const loadPermit = async () => {
    const res = await axios.get(`/PermitGet/${permitid}`, {
      //to authorize the api send the token header
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setPermit(newProduct)
  }
  //  onchange function for all input fields   
  const handleEdit = (e) => {
    setPermit({ ...permit, [e.target.name]: e.target.value })
  }
  //This is onsubmit function for this form 
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //here we are passing file and data so we have created the new data object and then 
      //we pass the values and file inside it using JSON.stringify
      const data = new FormData();
      data.append("document", JSON.stringify(permit));
      data.append("avatar", file);
      const response = await axios.post(`/PermitUpdate/${permitid}`, data, {
        //to authorize the api send the token header
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      });
      //**when the status is 200 will redirect to listview page */
      if (response.status === 200) {
        setSuccess(true);
        setError(false);
        return history.push('/Permit');
      }
    } catch (err) {
      setSuccess(false);
      setError(true);
    }
    //**it will set the input field value to empty field */
    setPermit(
      {
        vehicleno: "",
        chassisno: "",
        ownername: "",
        npauth: "",
        state: "",
        permittype: "",
        permitfrom: "",
        permitupto: "",
        vechileclass: "",
        cost: ""
      })
  }
  //**when we submit the form to get success or error message */
  const [success, setSuccess] = useState(false);
  //**to display input field error message */
  const [error, setError] = useState(false);
  //to upload the file decleared the useState 
  const [file, setFile] = useState("");
  const setimgfile = (e) => {
    setFile(e.target.files[0])
    console.log(e.target.files[0])
  }

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit} >
          {success && <div className="form-erroremployees">Form submitted successfully!</div>}
          {error && <div className="form-error">Error submitting permit details. Please try again later.</div>}
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="vehicleno" className="input-labelemployee">
                Vehcile Reg Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="vehicleno"
                  id="vehicleno"
                  placeholder="Registration Number"
                  value={permit.vehicleno}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="chassisno" className="input-labelemployee">
                Chassis Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="chassisno"
                  id="chassisno"
                  placeholder="Chassis Number"
                  value={permit.chassisno}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div></div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="ownername" className="input-labelemployee">
                Owner Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="ownername"
                  id="ownername"
                  placeholder="Owner Name"
                  value={permit.ownername}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="npauth" className="input-labelemployee">
                Np Auth Number
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="npauth"
                  id="npauth"
                  placeholder="Np Auth Number"
                  value={permit.npauth}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="state" className="input-labelemployee">
                Permit For State
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="state"
                  id="state"
                  placeholder="state"
                  value={permit.state}
                  onChange={(e) => { handleEdit(e) }}
                >
                  <option>Select State</option>
                  <option>All Over India</option>
                  <option> Andaman and Nicobar Islands</option>
                  <option>Andhra Pradesh</option>
                  <option>Arunachal Pradesh</option>
                  <option>Assam</option>
                  <option>Bihar</option>
                  <option>Chandigarh</option>
                  <option>Chhattisgarh</option>
                  <option>Dadra and Nagar Haveli</option>
                  <option>Daman and Diu</option>
                  <option>Delhi</option>
                  <option>Gujarat</option>
                  <option>Haryana</option>
                  <option>Himachal Pradesh</option>
                  <option>Jammu and Kashmir</option>
                  <option>Jharkhand</option>
                  <option>Karnataka</option>
                  <option>kerala</option>
                  <option>Lakshadweep</option>
                  <option>Madhya Pradesh</option>
                  <option>Maharashtra</option>
                  <option>Manipur</option>
                  <option>Meghalaya</option>
                  <option>Mizoram</option>
                  <option>Nagaland</option>
                  <option>Puducherry</option>
                  <option>Punjab</option>
                  <option>Rajasthan</option>
                  <option>Sikkim</option>
                  <option>Tamil Nadu</option>
                  <option>Telangana</option>
                  <option>Tripura</option>
                  <option>Uttar Pradesh</option>
                  <option>Uttarakhand</option>
                  <option>West Bengal</option>
                </select>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="permittype" className="input-labelemployee">
                Permit Type
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="permittype"
                  id="permittype"
                  placeholder="Tax Upto"
                  value={permit.permittype}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="permitfrom" className="input-labelemployee">
                Permit From
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="permitfrom"
                  id="permitfrom"
                  placeholder="Class"
                  value={permit.permitfrom}
                  onChange={(e) => { handleEdit(e) }}
                />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="permitupto" className="input-labelemployee">
                Permit Upto
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="permitupto"
                  id="permitupto"
                  placeholder="permitupto"
                  value={permit.permitupto}
                  onChange={(e) => { handleEdit(e) }} />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label htmlFor="vechileclass" className="input-labelemployee">
                Class Of Vehicle
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="vechileclass"
                  id="vechileclass"
                  placeholder="Class Of Vehicle"
                  value={permit.vechileclass}
                  onChange={(e) => { handleEdit(e) }} />
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="cost" className="input-labelemployee">
                Permit Cost
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="cost"
                  id="cost"
                  placeholder="Permit Cost"
                  value={permit.cost}
                  onChange={(e) => { handleEdit(e) }} />
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            {/* added a new field status here */}
            <div className="input-blockemployee">
              <label htmlFor="status" className="input-labelemployee">
                Status
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"
                  onChange={(e) => { handleEdit(e) }}
                  value={permit.status}
                ><option>active</option>
                  <option>inactive</option>
                </select>
              </div>
            </div>
            <div className="input-blockemployee">
              <label htmlFor="Image" className="input-labelemployee" >Chose Permit Certificate</label>
              <div className="inputerroremployee">
                <input type="file" name="avatar"
                  id="avatar"
                  placeholder=""
                  onChange={setimgfile}
                />
              </div>
            </div>
          </div>
          <div className="modal-buttons">
            <div className="save-button">
              <button className="input-button" type="submit">
                Save
              </button>
              <button className="input-button" type="button">
                Cancel
              </button></div>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditPermit;
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { empleave } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const initialValues = {
  leavetype: "",
  leavefrom: "",
  leaveto: "",
  leavehour: "",
  // leavedate:"",
  description: " ",
};

const EmployeeLeave = () => {
  const history = useHistory();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: empleave,

    onSubmit: async (values, action) => {
      try {
        //const response = await axios.post("/EmployeeLeavePost", values);
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("employeeId", JSON.stringify(employeeId));
        data.append("empRole", JSON.stringify(empRole));
        data.append("empName", JSON.stringify(empName));
        console.log(JSON.stringify(employeeId));

        data.append("currentTime", JSON.stringify(currentTime));
        console.log(JSON.stringify(currentTime));
        data.append("currentDate", JSON.stringify(currentDate));
        const response = await axios.post("/EmployeeLeavePost", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          setSuccess(true);
          setError(false);
        }
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      action.resetForm();
    },
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const token = sessionStorage.getItem("jwtToken");
  let time = new Date().toLocaleTimeString();
  const [currentTime, setCurrentTime] = useState(time);

  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [employee, setEmployee] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [empName, setEmpName] = useState();
  const [empRole, setEmpRole] = useState();

  const UpdateTime = () => {
    let time = new Date().toLocaleTimeString();
    setCurrentTime(time);
  };
  setInterval(UpdateTime, 1000);

  const loadAll = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].empId;
      const UserLogedinName = response.data.person[0][0].name;
      const UserLogedinRole = response.data.person[0][0].role;
      setEmployeeId(UserLogedin);
      setEmpName(UserLogedinName);
      setEmpRole(UserLogedinRole);

      // console.log(UserLogedin);
    } catch (error) {}
  };

  //display all the employee
  // const getEmployee = async () => {
  //   const res = await axios.get("/EmployeeGet", {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   const newemployee = await res.data;
  //   // console.log(newemployee);
  //   setEmployee(newemployee);
  // };
  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/");
    }
    // getEmployee();
    loadAll();
  }, []);

  return (
    <>
      <Helmet>
        <title>New Invoice|BBS Inventory</title>
      </Helmet>

      <div className="">
        <form onSubmit={handleSubmit}>
          {success && (
            <div className="form-erroremployees">
              Form submitted successfully!
            </div>
          )}
          {error && (
            <div className="form-error">
              Error submitting form. Please try again later.
            </div>
          )}

          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee leftone"
              >
                Employee Id
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="employeeid"
                  id="employeeid"
                  placeholder=""
                  value={employeeId}
                  disabled
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {/* {errors.employeeid && touched.employeeid ? (
                  <p className="form-erroremployee">{errors.employeeid}</p>
                ) : null} */}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee"
              >
                Employee Role
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="employeerole"
                  id="employeerole"
                  placeholder="Employee Role"
                  value={empRole}
                  disabled
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {/* {errors.employeerole && touched.employeerole ? (
                  <p className="form-erroremployee">{errors.employeerole}</p>
                ) : null} */}
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee leftone"
              >
                Employee Name
              </label>
              <div className="inputerroremployee">
                <input
                  type="text"
                  autoComplete="off"
                  name="employeename"
                  id="employeename"
                  placeholder="Employee Name"
                  value={empName}
                  disabled
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                />

                {/* {errors.employeename && touched.employeename ? (
                  <p className="form-erroremployee">{errors.employeename}</p>
                ) : null} */}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee"
              >
                Leave Type
              </label>
              <div className="inputerroremployee">
                <select
                  type="text"
                  autoComplete="off"
                  name="leavetype"
                  id="leavetype"
                  value={values.leavetype}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Select leave type</option>
                  <option>Sick Leave</option>
                  <option>Other</option>
                </select>
                {errors.leavetype && touched.leavetype ? (
                  <p className="form-erroremployee">{errors.leavetype}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee leftone"
              >
                Leave From
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="leavefrom"
                  id="leavefrom"
                  placeholder="Leave From"
                  value={values.leavefrom}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.leavefrom && touched.leavefrom ? (
                  <p className="form-erroremployee">{errors.leavefrom}</p>
                ) : null}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee leftone"
              >
                Leave To
              </label>
              <div className="inputerroremployee">
                <input
                  type="date"
                  autoComplete="off"
                  name="leaveto"
                  id="leaveto"
                  placeholder="Leave To"
                  value={values.leaveto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.leaveto && touched.leaveto ? (
                  <p className="form-erroremployee">{errors.leaveto}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor="tax"
                className="input-labelemployee"
              >
                Leave Hour
              </label>
              <div className="inputerroremployee">
                <input
                  type="number"
                  autoComplete="off"
                  name="leavehour"
                  id="leavehour"
                  placeholder="Leave Hour"
                  value={values.leavehour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.leavehour && touched.leavehour ? (
                  <p className="form-erroremployee">{errors.leavehour}</p>
                ) : null}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee"
              >
                Applied On
              </label>
              <div className="inputerroremployee">
                <input
                  // type="date"
                  //   autoComplete="off"
                  className="time"
                  name="leavedate"
                  id="leavedate"
                  placeholder="Leave To"
                  value={ShowTodaydate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.leavedate && touched.leavedate ? (
                  <p className="form-erroremployee">{errors.leavedate}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-employeecontainer">
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee"
              >
                Description
              </label>
              <div className="inputerroremployee">
                <textarea
                  rows="4"
                  cols="30"
                  type="text"
                  autoComplete="off"
                  name="description"
                  id="description"
                  placeholder="Description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description ? (
                  <p className="form-erroremployee">{errors.description}</p>
                ) : null}
              </div>
            </div>
            <div className="input-blockemployee">
              <label
                htmlFor=""
                className="input-labelemployee"
              >
                Current Time
              </label>
              <div className="inputerroremployee">
                <input
                  className="time"
                  name="time"
                  id="time"
                  value={currentTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.leavedate && touched.leavedate ? (
                  <p className="form-erroremployee">{errors.leavedate}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
              >
                Save
              </button>
              <button
                className="input-button"
                onClick={handleReset}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EmployeeLeave;

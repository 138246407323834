import React from 'react'
import { NavLink } from 'react-router-dom';
import Invoicedropdown from '../../components/Dropdown/Invoicedropdown';
import Sidebar from '../../components/Sidebar';
import AllInvoiceInactive from './AllAboutInvoiceRecord/AllInvoiceInactive';
import AllInvoiceRecord from './AllAboutInvoiceRecord/AllInvoiceRecord';
import AllPresentInvoice from './AllAboutInvoiceRecord/AllPresentInvoice';
import EachInvoiceRecord from './AllAboutInvoiceRecord/EachInvoiceRecord';
import EditInvoiceRecord from './AllAboutInvoiceRecord/EditInvoiceRecord';
import NewInvoiceRecord from './AllAboutInvoiceRecord/NewInvoiceRecord';

export const Invoice = () => {
  return (
    <div> <Sidebar/>
    <div className='home'>
<div className='modal-container '>
  <div className='title'>
    <h3>New Invoice Record</h3>
    </div>
        <div className='container' >
        <NewInvoiceRecord/>
        </div>
        </div>
    </div>
    </div>
  )
}

export const ActiveInvoice = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
      <Invoicedropdown/>
      <div className='title1'>
         <NavLink to='/invoicerecord/newinvoice'><button className="allbutton" >New</button></NavLink> 
         {/* <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="customers"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/>  */}
         </div>
      </div>
  
          <div className='container2'>
  <AllInvoiceRecord/>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export const InvoiceNameEach  = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
       <div className='modal-container '>
        <div className='title'><h3>Each Invoice details</h3></div>
        <div className='container' ><EachInvoiceRecord/>
        </div>
        </div>
      </div>
      </div>
    );
  };
  export const AllinactiveInvoice = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
      <Invoicedropdown/>
      <div className='title1'>
         <NavLink to='/invoicerecord/newinvoice'><button className="allbutton" >New</button></NavLink> 
         {/* <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="customers"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/>  */}
         </div>
      </div>
  
          <div className='container2'>
  <AllInvoiceInactive/>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export const AllInvoicesPresent = () => {
    return (
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
      <Invoicedropdown/>
      <div className='title1'>
         <NavLink to='/invoicerecord/newinvoice'><button className="allbutton" >New</button></NavLink> 
         {/* <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="customers"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/>  */}
         </div>
      </div>
  
          <div className='container2'>
  <AllPresentInvoice/>
          </div>
        </div>
      </div>
      </div>
    );
  };

  export const InvoiceNameEdit  = () => {
    return (
      <div> <Sidebar/>
      <div className='home'>
       <div className='modal-container '>
        <div className='title'><h3>Each Invoice details</h3></div>
        <div className='container' ><EditInvoiceRecord/>
        </div>
        </div>
      </div>
      </div>
    );
  };

  
import React, { useEffect, useState } from 'react'
import axios from "../../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink,useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

// const data = [{"id":1,"name":"jyoti","phoneno":"9087654321","adress":"qwewrsfagd"},
// {"id":2,"name":"monali","phoneno":"9863781983","adress":"gvcjsdv"},
// {"id":3,"name":"mishu","phoneno":"9086341678","adress":"vzfxbgfbbv"}]
  // this will take the dynamic data from data base table and show all the purchases

const SupplierInData = () => {
  const history = useHistory();
    const[data,setData]= useState([]);
    const token = sessionStorage.getItem('jwtToken');
   //display all the Supplier in table
    const loadSupplier= async(data)=>{
        
        const res = await axios.get("/AllSupplierGet", {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        loadSupplier();
    },[]);

//  // Delete Employee Record
//  const deletesupplier = (value) =>
//  {
//    axios.post('/SupplierDelete',{value})
//    .then((result)=>{
//     loadSupplier();
//    })
//    .catch(()=>{
//      alert('Error in the Code');
//    });
//  };

  return (
    <div>
      <Helmet> 
        <title>All Supplier|BBS Inventory Management</title>
      </Helmet>
        <table id="Supplier">
            <thead>
                <tr>
                    {/* <th><input type="checkbox"/></th> */}
                    <th>Supplier Id</th>
                    <th>Company Name</th>
                    <th>Supplier Name</th>
                    <th>Supplier Email</th>
                    <th>Supplier Phoneno</th>
                    <th>Primary Contact</th>
                    <th>Status</th>
                    {/* <th>delete</th> */}
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
            data.map((value, key) => {
              return (
                <tr key={key}>
                    {/* <td><input type="checkbox"/></td> */}
                    <td>{value.supplierid}</td>
                  <td>{value.companyname}</td>
                  <td><NavLink to={`/purechases/eachsupplier/${value.supplierid}`} className="addlink">{value.name}</NavLink></td>
                  <td>{value.email}</td>
                  <td>{value.phoneno}</td>
                  <td>{value.primarycontact}</td>
                  <td>{value.status}</td>
                  {/* <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you really want to delete "+ value.name
                          )
                          if (confirmBox === true) {
                            deletesupplier(value.supplierid)
                          }
                        }}/> 
                        </td> */}
                </tr>
              )
            })
          }
            </tbody>
        </table>
    </div>
  )
}

export default SupplierInData
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { SchemaRequisition } from "../../../helper/schemas";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import { Link, useParams, useHistory, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

// here I have initialised all the input values
const initialValues = {
  requitionername: "",
  requisitionnumber: "",
  requisitiondate: "",
  customernotes: "",
  termsconditions: "",
  requisitionstage: "",
};
// this function will handel the form inputs,submitting form and all the operations.
const NewRequisition = () => {
  const history = useHistory();

  //created object for dynamic table
  const itemTemp = {
    itemDetails: "",
    quantity: "",
    supplier: "",
    unit:"",
    Name:"",
    vehicleList:"",
    others:"",
  };
  //add the new row to item dynamic table
  const addItemrow = () => {
    setAddInventory([...addInventory, addInventory]);
  };

  //delete row of dynamic table
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setAddInventory(updateUsers);
  };
  //onchange function for dynamic table
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...addInventory];
    updateUser[index] = { ...updateUser[index], [name]: value };
    setAddInventory(updateUser);
  };
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // // Add a state variable to store the error message
  // const [errorMessage, setErrorMessage] = useState("");
//using useFormik submit the form data to backend
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    // this will validate all the input fields
     validationSchema: SchemaRequisition,
     //when user submit the form this function will be called
    onSubmit: async (values, action) => {
      //if there is no item selected then It will check
      const hasMissingItem = addInventory.some((user) => !user.Name);
  if (hasMissingItem) {
    alert("Please select item.");
    return;
  }
   //if there is quantity is 0 or empty then It will check
  const isAnyRateZero = addInventory.some((user) => Number(user.quantity) === 0);
  const isAnyRateEmpty = addInventory.some((user) => user.quantity === "");
  if (isAnyRateZero || isAnyRateEmpty) {
    alert("Quantity cannot be empty or zero.");
    return;
  }
  //   //if there is no vehicle selected then It will check
  // const hasMissingVehicle = addInventory.some((user) => !user.vehicleno);
  // if (hasMissingVehicle) {
  //   alert("Please select Vehicle.");
  //   return;
  // }
  // Check if both vehicle number and others are missing
const hasMissingInfo = addInventory.every((user) => !user.vehicleno && !user.others);

if (hasMissingInfo) {
  alert("Please fill in Vehicle Number or Others value.");
  return;
}

   else {
      try {
// here we are creating a new object and set all the values here and send the data to backend
        const data = new FormData();
        data.append("document", JSON.stringify(values));
        data.append("addInventory", JSON.stringify(addInventory));
        data.append("avatar", file);
        data.append("gettokenId", JSON.stringify(gettokenId));
        data.append("currentDate", JSON.stringify(currentDate));
        const response = await axios.post(`/requisition/newrequisition`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          //to get one alert message which requisition got created after inserting data to data base
         const requisitionId= "RE" + response.data[0].toString().padStart(4, "0");
          setRequisitionId(requisitionId);
          //console.log(requisitionId)
          alert(`Requisition ID created: ${requisitionId}`);
          return history.push("/requisition");
        }
      
      } catch (err) {
        setSuccess(false);
        setError(true);
      }
      //action.resetForm();
      // alert("Updated successfuly.")
      setFile("");
      
    }
  }
  });
  let Showdate = new Date();
  let ShowTodaydate =
    Showdate.getFullYear() +
    "-" +
    (Showdate.getMonth() + 1) +
    "-" +
    Showdate.getDate();
  const [currentDate, setCurrentDate] = useState(ShowTodaydate);
  const [reference, setReference] = useState({ reference: "" });
  const [file, setFile] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [requisitionId, setRequisitionId] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  // const [getrowdata, setGetrowdata] = useState([]);
  const [gettokenId, setTokenId] = useState();
  const [addInventory, setAddInventory] = useState([itemTemp]);
  // const [selectedName, setSelectedName] = useState("");
  const [employee, setEmployee] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  // const [quantity, setQuantity] = useState([0]);
  // const [purchaseDate, setPurchaseDate] = useState(null);
  // const [expectedDate, setExpectedDate] = useState(null);
  const setimgfile = (e) => {
    setFile(e.target.files[0]);
  };

  //Function is used to send approval mail to specific gmail id
  //when user will click on send for approval button this function will be called
  const handleOrder = async () => {
    const data = new FormData();
    data.append("document", JSON.stringify(values));
    data.append("addInventory", JSON.stringify(addInventory));
    data.append("gettokenId", JSON.stringify(gettokenId));
    
    // Access the requisitionId from the requisition state
    const orderId = requisitionId; 
    let dynamicTableContent = '';
    addInventory.forEach((user, index) => {
      dynamicTableContent += `
        <tr>
          <th>${index + 1}</th>
          <th>${user.Name}</th>
          <th>${user.quantity}</th>
          <th>${user.vehicleno}</th>
        <tr>
      `;
    });
    //Generate the approve link to login in to the application
    const approveLink = `https://agminfra18.com/`;
    const config = {
      //SecureToken:'568ed713-d3ef-4571-9f00-0d53a31083ff',
      SecureToken:'109e681e-7843-4090-ad32-c226d430dc19',
      To: 'agminfra.angul@gmail.com',
     // To: 'jyotiprava.rini@gmail.com',
      From:'agminfra.angul@gmail.com',
      Subject: 'Requisition for your approval',
      Body: `
      <h4>Please review the requisition with RequisitionID: ${orderId} </h4>
      <p style="color:green;">Waiting for your approval</p>
      <p>Login to the application to Approve 
      <button style="background-color: #FF3333; color: white; border-radius: 5px;">
        <a href="${approveLink}" style="text-decoration: none; color: white;">Login</a>
      </button>
      </p>
      <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
      <tr style="border: 1px solid black;">
       <th style="border: 1px solid black;">Requisitioner Name</th>
       <th style="border: 1px solid black;">Requisition Number</th>
       <th style="border: 1px solid black;">Requisition Date</th>
      </tr>
      <tr style="border: 1px solid black;">
        <th style="border: 1px solid black;">${values.requitionername || gettokenId}</td>
        <th style="border: 1px solid black;">${values.requisitionnumber}</td>
        <th style="border: 1px solid black;">${values.requisitiondate}</td>
      </tr>
    </table>
    <br>
    <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
    <tr style="border: 1px solid black;">
      <th style="border: 1px solid black;">Index</th>
      <th style="border: 1px solid black;">ItemName</th>
      <th style="border: 1px solid black;">Quantity Requested</th>
      <th style="border: 1px solid black;">Vehicle</th>
    </tr>
    ${dynamicTableContent}
  </table>
      `
    }; 
  
    try {
      // Send the email
      if (!emailSent && requisitionId !== null && window.Email){
        await window.Email.send(config);
       // console.log('Email sent successfully');
       // alert('We will contact you shortly!');
      } else {
        throw new Error('Email service is not available.');
      }
    } catch (error) {
      console.error(error);
    }
  };
  // whenever the requisitionId changes in the component, the handleOrder() function will be called as a side effect.
  useEffect(() => {
    if (requisitionId !== null) {
      handleOrder();
    }
  }, [requisitionId]);

  //get all the employee from backend
  const getEmployee = async () => {
    const res = await axios.get("/EmployeeGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee);
    //console.log(newusers)
  };
  //get all the supplier from backend
  const loadSupplier = async (supplier) => {
    const res = await axios.get("/SupplierGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSupplier(res.data);
    // console.log(res.data);
  };

  //get all the Item from backend without any supplierid
  const loadAllItems = async (data) => {
    const res = await axios.get("/itemwithsupplier", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
     console.log(res.data);
    setItemList(res.data);
  };

  //get all the vehicle details from backend
  const loadVehicleno = async (data) => {
    const res = await axios.get("/vehicleno", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    //console.log(res.data);
    setVehicleList(res.data);
  };

  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/inventory/items");
    }
  }, []);

  const handleEdit = (e) => {
    setReference({ ...reference, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getEmployee();
    loadSupplier();
    loadAll();
    loadAllItems();
    loadVehicleno();
  }, []);
//get the token which is stored inside the session storage
  const token = sessionStorage.getItem("jwtToken");
  const [gettoken, setToken] = useState();
  const [getRole, setRole] = useState();
  //get the specific user information who is logedin
  const loadAll = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;
      const UserRole = response.data.person[0][0].role;
      const UserLogedinId = response.data.person[0][0].empId;
      setTokenId(UserLogedinId);
      setRole(UserRole)
      console.log(UserRole)
      setToken(UserLogedin);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  //onchange function when user select the item then this function will be called
  const replaceData = async (e, index, type) => {
    const res = await axios.get(`/itemgetwithout/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
//create new array with all the previous details of addInventory
    let newArr = [...addInventory];
    newArr[index] = {
      Id: res.data.Id,
      Name: res.data.Name,
      quantity:0,
     // unit:res.data.Unit,
    };
    if (!(type == "Select Item")) {
      setAddInventory(newArr);
      console.log(addInventory);
    } else {
      setAddInventory([
        ...newArr,
        {
          Name: "Select Item",
          quantity:"",
        },
      ]);
    }
  };
  const handleReset = () => {
    // Reset the form using Formik's resetForm function
    resetForm();

    // Reset the dynamic table to its initial state
    setAddInventory([itemTemp]);
  };
  return (
    <>
      <Helmet>
        <title>New Requisition|BBS Inventory</title>
      </Helmet>
      <div className="">
        <form onSubmit={handleSubmit}>
          {success && (
            <div className="form-erroremployees">
              Requisition submitted successfully!
            </div>
          )}
          {error && (
            <div className="form-error">
              Error submitting Requisition . Please try again later.
            </div>
          )}
          {/* second one */}
          <div className="top-div1">
            <div className="input-container-search">
              <label
                htmlFor="requitionername"
                className="input-label leftone"
              >
                Requisition By
              </label>
              <select
                className="list"
                type="text"
                autoComplete="off"
                name="requitionername"
                id="requitionername"
                // placeholder="Select a vendor"
                //value={values.requitionername}
                value={values.requitionername || gettokenId }
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option>Select Employee</option>
                <option value={gettokenId} selected>
                    {gettoken}
                  </option>
                {employee.map((emp) => (
                  <option
                    value={emp.empId}
                    key={emp.id}
                  >
                    {emp.name}
                  </option>
                ))}
              </select>
            </div>
          {errors.requitionername && touched.requitionername ? (
              <p className="form-error">{errors.requitionername}</p>
            ) : null}
            {/* this link will be only visible to only Admin and Supperadmin */}
            {(getRole === "Admin" || getRole === "Supperadmin") && (<div className="input-container1">
              <div className="input-block">
                <NavLink
                  to="/employee"
                  className="addlink"
                >
                  To add new employee
                </NavLink>
              </div>
            </div> )}
          </div>
          {/* REQUISITION INPUT FIELD */}
          <div className="input-container">
            {/* <div className="input-block">
              <label
                htmlFor="requisitionnumber"
                className="input-label leftone"
              >
                Requisition Number
              </label>
              <div className="input-error">
                <input
                  type="text"
                  id="requisitionnumber"
                  name="requisitionnumber"
                  onChange={handleChange}
                  value={values.requisitionnumber}
                />

                {errors.requisitionnumber && touched.requisitionnumber ? (
                  <p className="form-error">{errors.requisitionnumber}</p>
                ) : null}
              </div>
            </div> */}
          
          {/* DATE INPUT FIELD  */}
         
            <div className="input-block">
              <label
                htmlFor="purchase date"
                className="input-label leftone"
              >
                Requisition Date
              </label>
              <div className="input-error">
                <input
                  type="date"
                  id="requisitiondate"
                  name="requisitiondate"
                  onChange={handleChange}
                  value={values.requisitiondate}
                />

                {errors.requisitiondate && touched.requisitiondate ? (
                  <p className="form-error">{errors.requisitiondate}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <input
              type="hidden"
              autoComplete="off"
              name="insertby"
              id="insertby"
              value={gettokenId}
            />
             <input
              type="hidden"
              autoComplete="off"
              name="insertdate"
              id="insertdate"
              value={currentDate}
            />
  
            <br/>
            <div>Item List </div>
            <div>
              <table id="contacttable">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}>Sl No</th>
                    {/* <th>Supplier</th> */}
                    <th style={{ width: "220px" }}>Item Name</th>
                    {/* <th style={{ width: "280px" }}>Description</th>
                    <th>Quantity On Stock </th> */}
                    <th>Quantity</th>
                    <th style={{ width: "180px" }}>Unit</th>
                    <th>Vehicle Number</th>
                    <th>Others</th>
                  </tr>
                </thead>
                <tbody>
                  {/* dynamic table */}

                  {addInventory.map((user, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>
                        <select
                            className="inputtable"
                            name="itemDetails"
                            onChange={(e) => replaceData(e, index, user.Name)}
                            value={user.itemDetails}
                          >
                            <option value="">{user.Name}</option>

                            {itemList.map((values, index) => (
                              <option value={values.Id} key={values.Id}>
                                {values.Name}
                              </option>
                            ))}
                          </select>
                        </th>
 {/* <th>
                          <input
                            className="inputtable"
                            name="itemDetails"
                            onChange={(e) => onChange(e, index)}
                            value={user.itemDetails}
                          />
                           
                        </th> */}
                        {/* TO INPUT DESCRIPTION */}
                        {/* <th>
                          <input
                            className="inputtable"
                            name="Description"
                            onChange={(e) => onChange(e, index)}
                            value={user.Description}
                          />
                        </th> */}

                        {/* TO INPUT QUANTITY */}
                        {/* <th>
                          <input
                            className="inputtable"
                            name="Quantity"
                            //onChange={(e) => onChange(e, index)}
                            value={user.Quantity}
                            disabled
                          />
                        </th> */}
                        {/* TO INPUT QUANTITY */}
                        <th>
                          <input
                            className="inputtable"
                            name="quantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.quantity}
                          />
                        </th>
                        <th>
                            <select
                              className="inputtable"
                              name="unit"
                              onChange={(e) => onChange(e, index)}
                              value={user.unit}
                              ><option>Select the unit</option>
                              <option>Pieces</option>
                              <option>Box</option> 
                              <option>Dozen</option>
                              <option>Meter</option>
                              <option>Kg</option> 
                              <option>Liter</option>
                             <option>Gallon</option>                      
                            </select>
                          </th>
                        {/* to input vehicle */}
                        <th>
                          <select
                            className="inputtable"
                            name="vehicleno"
                            onChange={(e) => onChange(e, index)}
                            value={user.vehicleList}
                          >
                            <option value=" ">Select vehicle</option>
                            <option>All Vehicle</option>
                            {vehicleList.map((values, index) => (
                              <option
                                value={values.vehicleno}
                                key={values.Id}
                              >
                                {values.vehicleno}
                              </option>
                            ))}
                          </select>
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="others"
                            onChange={(e) => onChange(e, index)}
                            value={user.others}
                          />
                        </th>
                        {/* TO INPUT SUPPLIER */}
                        {/* <th>
                          <select
                            className="inputtable"
                            name="supplier"
                            onChange={(e) => onChange(e, index)}
                            value={user.supplier}
                          >
                            <option value=" ">{user.supplier}</option>

                            {supplier.map((values, index) => (
                              <option
                                value={values.supplierid}
                                key={values.Id}
                              >
                                {values.name}
                              </option>
                            ))}
                          </select>
                        </th> */}

                        {/* DELETE BUTTON */}
                        <th>
                            <IoTrashOutline onClick={() => deleteRow(index)} />
                          </th>
                          <input
                            type="hidden"
                            className="inputtable"
                            name="Quantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.Quantity}
                          />
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* TO ADD A NEW A ITEM */}
              <div className="link">
                <Link
                  to="#"
                  className="addlink"
                  onClick={addItemrow}
                >
                  Add New Line
                </Link>
              </div>
              {/* <div className="link">
                <Link
                  to="/purechases/supplier"
                  className="addlink"
                >
                  Add New supplier
                </Link>
              </div> */}
              {/* <div className="link">
                <Link
                  to="/inventory/additem"
                  className="addlink"
                >
                  Add New Item
                </Link>
              </div> */}
            </div>

            {/* CUSTOMER NOTES */}
            <div className="note">
              <div className="comment">
                <div className="input-block-text">
                  <label
                    htmlFor="customernotes"
                    className="input-label"
                  >
                    Customer Notes
                  </label>
                  <textarea
                    className="textareas"
                    rows="3"
                    cols="52"
                    type="text"
                    autoComplete="off"
                    name="customernotes"
                    id="customernotes"
                    placeholder="Customer notes"
                    value={values.customernotes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.customernotes && touched.customernotes ? (
                    <p className="form-error">{errors.customernotes}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <br />

            {/* TERM AND CONDITIONS */}
            <div className="top-div2">
              <div className="note">
                {/* <div>
                  <div className="input-block-text">
                    <label
                      htmlFor="termsconditions"
                      className="input-label"
                    >
                      Terms & Conditions
                    </label>
                    <textarea
                      className="textareas"
                      rows="3"
                      cols="95"
                      type="text"
                      autoComplete="off"
                      name="termsconditions"
                      id="termsconditions"
                      placeholder="Terms and conditions"
                      value={values.termsconditions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.termsconditions && touched.termsconditions ? (
                      <p className="form-error">{errors.termsconditions}</p>
                    ) : null}
                  </div>
                </div> */}

                {/* attach files */}
                <div className="purchaseorderfile">
                  <label htmlFor="Image">
                    Attach File(s) to Requisition Order
                  </label>

                  <input
                    type="file"
                    name="avatar"
                    onChange={setimgfile}
                  />
                </div>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="adjustedby"
            id="adjustedby"
            value={gettoken}
          />
          <input
            type="hidden"
            name="requisitionstage"
            value={values.requisitionstage}
            onChange={handleChange}
          />{" "}
          <br />
          <br />
          <br />
          <div className="modal-buttons">
            <div className="save-button">
              <button
                className="input-button"
                type="submit"
                onClick={() => {
                  handleChange({
                    target: { name: "requisitionstage", value: "Draft" },
                  });
                }}
              >
                Save As Draft
              </button>
              <button
                className="input-button"
                type="submit"
                onClick={async () => {
                  // First, update the state to "Pending Approval"
                  handleChange({
                    target: { name: "requisitionstage", value: "Pending Approval" },
                  });
              
                  try {
                    // Call the handleOrder function here
                    await handleOrder();
              
                    // handleOrder has completed successfully
                    // If needed, you can add additional logic after the handleOrder call here
                  } catch (error) {
                    console.error(error);
                    // Handle errors if necessary
                  }
                }}
              >
               Send For Approval
              </button>
              <button
                className="input-button"
                type="button"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewRequisition;

import React, { useEffect,useState } from 'react'
import "./formone.css"
import { IoTrashOutline} from "react-icons/io5";
import axios from '../../../helper/axios';
import { NavLink,useHistory } from 'react-router-dom';
import {TbFileDescription} from "react-icons/tb";
import Cookies from 'js-cookie';
import { Helmet } from "react-helmet";

   const Allinventory = () => {
    const [data,setData]=useState([]);
    const history = useHistory();
    const token = sessionStorage.getItem('jwtToken');
    const loadInventory=async(data)=>{
     
      const res = await axios.get("/Inventoryactive", {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      });
      setData(res.data);
      console.log(res.data)


    }
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
      loadInventory();
    },[]);
    const deletetable = (value) =>
 {
   axios.post('/InDelete',{value},{
    headers: {
      Authorization: `Bearer ${token}`,
    }
   })
   .then((result)=>{
    loadInventory();
   })
   .catch(()=>{
     alert('Error in the Code');
   });
 };

    return (
      <div>
        <Helmet> <title>All Active Inventory|BBS Inventory Management</title>
  
  </Helmet>
           <table id="inventory-adjustments">
              <thead>
                  <tr>
                  <th>Date </th>
                      <th>Reason</th>
                      <th>Item</th>
                      <th> Description</th>
                      <th>Status</th>
                      <th>Reference Number</th>
                      <th>Adjusted By</th>
                      <th>Delete</th>
                     
  
                  </tr>
              </thead>
              <tbody>
              
            {
            data.map((value, key) => {
              return (
               
                <tr key={key}>
                    
                  <td>{value.date }</td>
                  <td>{value.reason}</td>
              
                  <td><NavLink to={`/inventory/items1/${value.referencenumber}`} className="addlink"><TbFileDescription></TbFileDescription>{value.Name}</NavLink></td>
                  <td>{value.description}</td>
                  <td>{value.status}</td>
                  <td>{value.referencenumber}</td>
                 
                  <td>{value.adjustedby}</td>
                  <td><IoTrashOutline onClick={()=>{
                    const confirmBox=window.confirm(
                      "Do you really want to delete"+value.referencenumber
                    )
                    if (confirmBox===true){
                      deletetable(value.referencenumber)

                    }
                  }}/>
                  </td>
                 
                </tr>
                
              )
            })
            
          }
          </tbody>

          <tbody>
            



         
             
            </tbody>
            
            </table>
          


















          
        
    </div>
  )
}

export default Allinventory;

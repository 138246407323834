import React, { useState } from 'react'
import * as RiIcons from 'react-icons/ri';
import { Link } from "react-router-dom";
const Invoicedropdown = () => {
    const [open, setOpen]=useState(false);
     const menus =[{
        title: 'All Invoice',
        path: '/invoicerecordall',
        
      },
      {
        title: 'Active Invoice',
        path: '/invoicerecord',
        
      },
      {
        title: 'Inactive Invoice',
        path: '/invoicerecordinactive',
        
      },
      ]


  return (
    <div> 
        <div
        onClick={()=>setOpen(!open)}>
            <h3>All Invoice<RiIcons.RiArrowDownSFill /></h3>
        </div>    
      {
        open && (
            <div 
            className='dropback'>
            <div>
               {
                 menus.map((menu)=>(
                     <ul
                     onClick={()=>setOpen(false)}
                      className='drop'  key={menu.title}> 
                       <Link to={menu.path} className='itemdrop'>{menu.title} </Link> 
                      </ul>
                 ))
               }
             </div>
            </div>
        )
      }
    </div>
  )
}

export default Invoicedropdown
// import React from 'react'

// const AllPresentSales = () => {
//   return (
//     <div>AllPresentSales</div>
//   )
// }

// export default AllPresentSales
import React, { useEffect, useState } from 'react'
import axios from "../../helper/axios"
import { IoTrashOutline} from "react-icons/io5";
import { Helmet } from "react-helmet";
import { NavLink,useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';


const  AllPresentSales = () => {
  const history = useHistory();
    const[data,setData]= useState([]);
    //for search
  const [searchValue, setSearchValue] = useState('');
  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);
    const token = sessionStorage.getItem('jwtToken');
   //display all the Supplier in table
    const loadSales= async(data)=>{
        
        const res = await axios.get("/allsaleget", {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            limit: itemsPerPage,
          },
        });
        setData(res.data);
        console.log(res.data)
    }
    useEffect(()=>{
      // if(!Cookies.get('jwtToken')){
      //   return history.push("/");
      // }
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
        loadSales();
    },[currentPage, itemsPerPage]);
//display pages according to pagination
const handlePageChange = (page) => {
  setCurrentPage(page);
};
//hangel the search operation
const handleSearch = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.get(`/allsaleget?q=${searchValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setData(response.data);
    setSearchValue('');
  } catch (err) {
    console.log(err);
  }
};
//it will reset the page to 1st page
const handleReset = () => {
  loadSales();
};
 // Delete Employee Record
 const deletesupplier = (value) =>
 {
   axios.post('/SaleeorderDelete',{value})
   .then((result)=>{
    loadSales();
   })
   .catch(()=>{
     alert('Error in the Code');
   });
 };
 
  return (
    <div>
      <Helmet> <title>All Sale Item|BBS Inventory Management</title>
  
  </Helmet>
  <form className="Search-field" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search Sales Order..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button className="allbutton" type="submit">Search</button>
        <button  className="allbutton"onClick={handleReset}>Reset</button>
      </form>
        <table id="Sales">
            <thead>
                <tr>
                    {/* <th></th> */}
                   
                    <th>Sales Order</th>
                    <th>Sale Date </th>
                    <th>Reference#</th>
                    <th>Issued To</th>
                    <th>Employee Phone No</th>
                    <th>Vechicle No</th>
                    <th>Total Price</th>
                    <th>Sale Status</th>
                    <th>Sold By</th>
                    <th>Status</th>
                    <th>Delete</th>
                </tr>
            </thead>
             {/* this is the dynamic data from data base */}
            <tbody>
            {
           records.map((value, key) => {
              return (
                <tr key={key}>
                    {/* <td><input type="checkbox"/></td> */}
                  
                  <td><NavLink to={`/sales/${value.salesid}`} className="addlink">{value.salesorder}</NavLink></td>
                  <td>{value.salesdate}</td>
                  <td>{value.reference}</td>
                  <td>{value.name}</td>
                  <td>{value.phoneno}</td>
                  <td>{value.vechicleno}</td>
                  <td>{value.totalAmount}</td>
                  <td>{value.orderstage}</td>
                  <td>{value.name}</td>
                  <td>{value.status}</td>
                  <td> <IoTrashOutline
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Do you want to delete "+ value.name+"?"
                          )
                          if (confirmBox === true) {
                            deletesupplier(value.salesid)
                          }
                        }}/> 
                        </td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
         {/* Pagination controls */}
      <div className="pagination">
        <button className="allbutton"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button className="allbutton"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default  AllPresentSales
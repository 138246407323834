import React, { useState } from "react";
import * as RiIcons from "react-icons/ri";
import { Link } from "react-router-dom";
const ReqDropdown = () => {
  // declares a state variable open using the useState hook. It is initialized with a value of false.
  // The open variable is used to control the visibility of the dropdown menu.
  const [open, setOpen] = useState(false);

  //**declares an array called menus that contains objects representing different pollution-related options.
  //**Each object has a title property representing the title of the menu option and a path property representing
  //** the corresponding path for routing purposes. */
  const menus = [
    {
      title: "All Requisition",
      path: "/requisition/allrequistion",
    },
    {
      title: "Active Requisition",
      path: "/requisition/active",
    },
    {
      title: "Inactive Requisition",
      path: "/requisition/inactive",
    },
  ];

  return (
    <div>
      <div
        //**This div acts as a header or toggle for the dropdown menu. When clicked,
        //it toggles the value of open using the setOpen function. */
        onClick={() => setOpen(!open)}
      >
        <h3>
          All Requisition
          <RiIcons.RiArrowDownSFill />
        </h3>
      </div>
      {/* if open the execute */}
      {open && (
        <div className="dropback">
          <div>
            {/* function. Each menu item is rendered as a <ul> element containing an <Link>
  component from React Router, which represents a link to a specific path. */}
            {menus.map((menu) => (
              <ul
                onClick={() => setOpen(false)}
                className="drop"
                key={menu.title}
              >
                <Link
                  to={menu.path}
                  className="itemdrop"
                >
                  {menu.title}{" "}
                </Link>
              </ul>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReqDropdown;

import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "../../../helper/axios";
import { IoTrashOutline } from "react-icons/io5";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
//validation for Receive Date and Received By using yup
const schema = Yup.object().shape({
  receiveDate: Yup.date().required("Receive date is required"),
});
const schemas = Yup.object().shape({
  receivedBy: Yup.string().required("Received by is required"),
});

// Assuming that `inputValue` is the value of the input field
const validateReceiveDate = (inputValue) => {
  try {
    schema.validateSync({ receiveDate: inputValue });
    return ""; // No error message if validation succeeds
  } catch (err) {
    return err.errors[0]; // Return the error message if validation fails
  }
};
const validateReceivedBy = (inputValue) => {
  try {
    schemas.validateSync({ receivedBy: inputValue });
    return ""; // No error message if validation succeeds
  } catch (err) {
    return err.errors[0]; // Return the error message if validation fails
  }
};

const ReceivedItem = () => {
  const history = useHistory();
  //getting the id of purchase oprder
  const { purchaseorderid } = useParams();
  //what ever dta coming from backend it is updated here
  const [purchaseedit, setPurchaseedit] = useState({
    supplierid: "",
    picked: "organization",
    search: "",
    reference: "",
    purchaseorder: "",
    adress: "",
    purchasedate: "",
    expected: "",
    paymentterms: "",
    shipmentpreference: "",
    customernotes: "",
    termsconditions: "",
    status: "",
    insertedby: "",
    //  avatar:""
    purchaseorderid: "",
    orderstage: "",
    receivedate: "",
    receivedby: "",
  });
  //displaying error
  const [receiveDateError, setReceiveDateError] = useState("");
  const [receivedByError, setReceivedByError] = useState("");
  const [file, setFile] = useState({ avatar: "" });

  const setimgfile = (e) => {
    setFile(e.target.files[0]);
    //  console.log(e.target.files[0])
  };
  //whenever we load the page data will display
  useEffect(() => {
    if (!sessionStorage.getItem("jwtToken")) {
      return history.push("/");
    }
    getPurchases();
    getPriceDetails();
    getEmployee();

  }, []);
  //getting the data from backend according to the purchase id
  const getPurchases = async () => {
    const res = await axios.get(`/Purchase/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = await res.data[0];
    setPurchaseedit(newProduct);
  };
  //getting the dyanmic table price list data from backend according to the purchase id
  const getPriceDetails = async () => {
    const res = await axios.get(`/Price/${purchaseorderid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newPrice = await res.data;
    console.log(newPrice)
    setDatas(newPrice);
    setAddInventory(newPrice);
    const newArr = res.data;
    const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);
    // // Update the GST and subTotal values
    // const newGst = parseFloat(gst) || 0;
    // setGst(newGst);
    // const subTotal = calculateSubTotal(total, newGst);
    // const GstAmount = Math.round(subTotal * 100) / 100;
    // setSubTotal(GstAmount.toFixed(0));

    // const gstAmount = subTotal - total;
    // const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    // setTotalGst(roundedGstAmount.toFixed(0));
  };
  //  const deletepurchase = () =>
  //         {
  //           const data = new FormData();
  //      data.append("document", JSON.stringify(purchaseedit));
  //      data.append("avatar",file);
  //      data.append("gettoken", JSON.stringify(gettoken));

  //     axios.post(`/DeletePurchase/${purchaseorderid}`,data,{
  //             "Content-Type": "multipart/form-data",
  //           })
  //           .then((result)=>{
  //             getPurchases()
  //             getPriceDetails()
  //           })
  //           .catch(()=>{
  //             alert('Error in the Code');
  //           });
  //         };

  // const [totalAmountError, setTotalAmountError] = useState(null);
  //to cancel the form
  const handleCancel = async (e) => {
    e.preventDefault();

    try {
      const confirmBox = window.confirm("Do you want to cancel it ?");
      if (confirmBox === true) {
        const response = await axios.post(
          `/DeletePurchase/${purchaseorderid}`,
          { status: "Inactive", purchaseorderid: purchaseorderid }
        );
        // console.log(response.data); // Do something with the response data
        setStatus("Inactive");
        if (response.status === 200) {
          return history.push("/purechases/allpurchase");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (e) => {
    setPurchaseedit({ ...purchaseedit, [e.target.name]: e.target.value });
    setReceiveDateError(null);
    setReceivedByError(null);
  };
  //to sumit the Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    //validation message for both receiveDate and receivedBy
    const receiveDate = purchaseedit.receivedate;
    const receivedBy = purchaseedit.receivedby;

    const receiveDateErrorMessage = validateReceiveDate(receiveDate);
    if (receiveDateErrorMessage) {
      setReceiveDateError(receiveDateErrorMessage);
      return;
    }

    const receivedByErrorMessage = validateReceivedBy(receivedBy);
    if (receivedByErrorMessage) {
      setReceivedByError(receivedByErrorMessage);
      return;
    }

    // If both receiveDate and receivedBy are valid, clear any error messages
    setReceiveDateError(null);
    setReceivedByError(null);
    // Check if any of the Quanitity Received field is empty
    const isAnyQuanitityReceivedEmpty = addInventory.some(
      (user) => !user.quantityreceive
    );
    if (isAnyQuanitityReceivedEmpty) {
      alert("Please fill in all the Quanitity Received fields.");
      return;
    }
    const isAnyUnitReceivedEmpty = addInventory.some(
      (user) => !user.unit
    );
    if (isAnyUnitReceivedEmpty) {
      alert("Please fill Unit fields.");
      return;
    }
    // Check if any of the rate field is empty
    const isAnyRateZero = addInventory.some((user) => Number(user.rate) === 0);
    const isAnyRateEmpty = addInventory.some((user) => user.rate === "");
    if (isAnyRateZero || isAnyRateEmpty) {
      alert("Rate field cannot be empty or zero.");
      return;
    }
     // Check if any of the Quanitity Received field is empty
     const isAnyGstEmpty = addInventory.some(
      (user) => !user.gst
    );
    if (isAnyGstEmpty) {
      alert("Gst field cannot be empty.");
      return;
    }
    //here we are sending the data to backend
    try {
      const data = new FormData();
      data.append("document", JSON.stringify(purchaseedit));
      data.append("datas", JSON.stringify(datas));
      data.append("addInventory", JSON.stringify(addInventory));
      data.append("avatar", file);
      data.append("gettoken", JSON.stringify(gettoken));
      data.append("totalAmount", JSON.stringify(totalAmount));
      data.append("gst", JSON.stringify(gst));
      data.append("totalGst", JSON.stringify(totalGst));
      data.append("subTotal", JSON.stringify(subTotal));
console.log(JSON.stringify(addInventory))
      const respon = await axios.post(
        `/ReceiveitemUpdate/${purchaseorderid}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //const response = await axios.post(`/PurchaseorderUpdate/${purchaseorderid}`,purchaseedit);
      //const respon = await axios.post(`/PriceUpdate/${purchaseorderid}`,datas);

      if (respon.status === 200) {
        return history.push("/purechases/allpurchase");
      }
    } catch (err) {
      setSuccess(false);
      setError(true);
    }

    //setDatas([{Id:"",description:"",purchasequantity:"",rate:"",tax:"",amount:""}])
    setSupplier([]);
  };

  const itemTemp = {
    itemDetails: "",
    description: "",
    purchasequantity: "",
    rate: "",
    amount: "",
    Quantity: "",
    gst:"",
    quantityreceive: 0,
    unit:""
  };
  const [datas, setDatas] = useState([itemTemp]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  const [addInventory, setAddInventory] = useState([]);

  //adding new row to dynamic table of price list
  const addNewRow = () => {
    setAddInventory([...addInventory, addInventory]);
  };
  //delete new row from dynamic table of price list
  const deleteRow = (index) => {
    const updateUsers = [...addInventory];
    updateUsers.splice(index, 1);
    setDatas(updateUsers);
    setAddInventory(updateUsers);
    //when delete anty row the total amount will be calculated according to that
    const total = updateUsers.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total);
    // Update the GST and subTotal values
    // const newGst = parseFloat(gst) || 0;
    // setGst(newGst);
    // const subTotal = calculateSubTotal(total, newGst);
    // const GstAmount = Math.round(subTotal * 100) / 100;
    // setSubTotal(GstAmount.toFixed(0));

    // const gstAmount = subTotal - total;
    // const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    // setTotalGst(roundedGstAmount.toFixed(0));
  };

  const calculateSubTotal = (total, newGst) => {
    const taxRate = 1 + newGst / 100;
    const gstAmount = total * (taxRate - 1);
    return total + gstAmount;
  };

  //onChange fuction to calculate the  amount in price list dynamic table
  const onChange = (e, index) => {
    const { name, value } = e.target;
    const updateUser = [...datas];
    updateUser[index] = { ...updateUser[index], [name]: value };
    const currentRow = updateUser[index];
    const quantityReceive = parseFloat(currentRow.quantityreceive) || 0;
    const rate = parseFloat(currentRow.rate) || 0;
    const gst = parseFloat(currentRow.gst) || 0;
    let amount, newRate;
  
    // Convert receivedQuantity to a number
    const receivedQuantity = parseFloat(currentRow.receivedQuantity) || 0;
  
    if (name === "quantityreceive" || name === "rate" || name === "gst") {
      const Quantity = parseFloat(currentRow.Quantity) || 0;
      const addedValue = quantityReceive + Quantity;
      currentRow.addedValue = addedValue;
      amount = (quantityReceive + receivedQuantity) * rate * (1 + gst / 100);
      updateUser[index].amount = amount.toFixed(2);
    } else if (name === "amount") {
      amount = parseFloat(updateUser[index].amount) || 0;
      newRate = amount / ((quantityReceive + receivedQuantity) * (1 + gst / 100));
      updateUser[index].rate = newRate.toFixed(2);
    }
    // Calculate the total amount of all items
    const total = updateUser.reduce(
      (sum, user) => sum + Number(user.amount),
      0
    );
    setTotalAmount(total.toFixed(0));
    // Update the GST and subTotal values
    const newGst = parseFloat(gst) || 0;
    setGst(newGst);
    const subTotal = calculateSubTotal(total, newGst);
    const GstAmount = Math.round(subTotal * 100) / 100;
    setSubTotal(GstAmount.toFixed(0));
    const gstAmount = subTotal - total;
    const roundedGstAmount = Math.round(gstAmount * 100) / 100;
    setTotalGst(roundedGstAmount.toFixed(0));
    // setTotalAmountError(null);
    setDatas(updateUser);
    setAddInventory(updateUser);
    console.log(updateUser)
  };

  //getting the supplier id here
  let defaultSupplierId = purchaseedit.supplierid;
  // console.log("---defaultSupplierId" + defaultSupplierId);
  const [item, setItem] = useState([]);
  const [supplierid, setSupplierid] = useState(defaultSupplierId);
  const [getrowdata, setGetrowdata] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  //display all the Supplier in table
  const loadSupplier = async (supplier) => {
    const res = await axios.get("/SupplierGet", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSupplier(res.data);
    //  console.log(res.data);
  };
  //if supplier id changed this call this function
  const handleEdits = async (e) => {
    try {
      const supplierids = e.target.value;
      //console.log(supplierid);
      setSupplierid(supplierids);

      const selectedOption = e.target.options[e.target.selectedIndex];
      const name = selectedOption.text;
      setSelectedName(name);

      setPurchaseedit((prevState) => ({
        ...prevState,
        supplierid: supplierid,
        name: name,
      }));
      //12-05-23 I changed the api /itemget to /itemgetbySupplier
      //get the item according to supplier id
      const response = await axios.get(`/itemgetbySupplier/${supplierid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setItem(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  //onload of website
  // useEffect(() => {
  //   setSupplierid(defaultSupplierId);
  // }, [defaultSupplierId]);
  useEffect(() => {
    setSupplierid(defaultSupplierId);
   // setAddInventory([]);
    //setDatas([]);
    loadSupplier();
    loadloginUser();
    loadItem();
  }, [defaultSupplierId]);
   console.log({ supplierid });
  // useEffect(() => {
  //   const getPurchase = async () => {
  //     try {
  //       //12-05-23 I changed the api /itemget to /itemgetbySupplier
  //       const response = await axios.get(`/itemgetbySupplier/${supplierid}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       const data = response.data;
  //       console.log(data);
  //       setGetrowdata(data);
  //       //setItem(data);
  //     } catch (error) {
  //       console.error(error);
  //       // Handle error here
  //     }
  //   };

  //   getPurchase();
  //   setAddInventory([]);
  //   setDatas([]);
  //   loadSupplier();
  //   loadloginUser();
  //   loadItem();
  // }, [supplierid]);
  const loadItem = async (data) => {
    //12-05-23 I changed the api /itemget to /itemgetbySupplier
    const res = await axios.get(`/itemgetbySupplier/${defaultSupplierId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data);
    setItem(res.data);
  };
  const [employee, setEmployee] = useState([]);
  const [status, setStatus] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const token = sessionStorage.getItem("jwtToken");
  const [gettoken, setToken] = useState();
  const getEmployee = async () => {
    const res = await axios.get(`/EmployeeGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newemployee = await res.data;
    setEmployee(newemployee);
  };
  //to get the user information
  const loadloginUser = async () => {
    try {
      const response = await axios.post("/Login", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const UserLogedin = response.data.person[0][0].name;
      setToken(UserLogedin);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  //onchange function called when addInventory.length == 0
  const getPriceList = async (e, b) => {
    // const res = await axios.get(`/Itemactive/${e.target.value}`);
    const res = await axios.get(
      `/itemgetbySupplier/${supplierid}/${e.target.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(res.data);
    setAddInventory([
      ...addInventory,
      {
        Id: res.data.Id,
        Name: res.data.Name,
        Quantity: res.data.Quantity,
        description: res.data.Descriptionone,
        quantityreceive: 0,
        rate: 0,
        amount: 0,
        gst: 0,
        itemmasterid: res.data.itemmasterid,
        unit: res.data.unit,
      },
    ]);
    setDatas([
      ...addInventory,
      {
        Id: res.data.Id,
        Name: res.data.Name,
        Quantity: res.data.Quantity,
        quantityreceive: 0,
        rate: 0,
        amount: 0,
        gst: 0,
        itemmasterid: res.data.itemmasterid,
        unit: res.data.unit,
      },
    ]);
  };
  //onchange function called when addInventory.length > 0
  const replaceData = async (e, index, type) => {
    // const res = await axios.get(`/Itemactive/${e.target.value}`);
    const res = await axios.get(
      `/itemgetbySupplier/${supplierid}/${e.target.value}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let newArr = [...addInventory];
    newArr[index] = {
      Id: res.data.Id,
      Name: res.data.Name,
      Quantity: res.data.Quantity,
      description: res.data.Descriptionone,
      quantityreceive: res.data.receivedQuantity,
      rate: 0,
      amount: 0,
      gst: 0,
      itemmasterid: res.data.itemmasterid,
      unit: res.data.unit,
    };
    if (!(type == "Select Item")) {
      setAddInventory(newArr);
      setDatas(newArr);
    } else {
      setAddInventory([
        ...newArr,
        {
          Name: "Select Item",
          Quantity: "",
          description: "",
          quantityreceive: "",
          rate: "",
          amount: "",
          gst: "",
          itemmasterid:"",
          unit:""
        },
      ]);
      setDatas([
        ...newArr,
        {
          Name: "Select Item",
          // "hsncode": "",
          // "saccode": "",
          Quantity: "",
          description: "",
          quantityreceive: "",
          rate: "",
          amount: "",
          gst: "",
          itemmasterid:"",
          unit:""
        },
      ]);
    }
    console.log(newArr);
    const total = newArr.reduce((sum, user) => sum + Number(user.amount), 0);
    setTotalAmount(total);
  };

  return (
    <div>
      <Helmet>
        {" "}
        <title>Receive Purchase Item |BBS Inventory Management</title>
      </Helmet>

      <form onSubmit={handleSubmit}>
        {/* <div className="top-div1"> {success && <div className="form-erroremployees">Form submitted successfully!</div>}
      {error && <div className="form-error">Error submitting form. Please try again later.</div>}</div>
      <div style={{padding:"12px", height:"70px"}}><h4 style={{color: "blue"}}>PO Stage:{purchaseedit.orderstage}</h4></div> */}
        <div className="top-div1">
          <div className="input-container-search">
            <label
              htmlFor="name"
              className="input-block_radio"
            >
              Supplier Name
            </label>
            <select
              className="list"
              type="text"
              autoComplete="off"
              name="supplierid"
              id="supplierid"
              // placeholder="Select a vendor"
              value={purchaseedit.supplierid}
              onChange={(e) => {
                handleEdits(e);
              }}
            >
              {supplier.map((supplier) => (
                <option
                  value={supplier.supplierid}
                  key={supplier.id}
                >
                  {supplier.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input-container1">
          <label
            htmlFor="name"
            className="input-label-radio"
          >
            Deliver To
          </label>
          <div className="input-block_radio">
            <div className="input-blockradio ">
              <div className="radio">
                <label
                  htmlFor="name"
                  className="input-label"
                >
                  Organization
                </label>
                <input
                  type="radio"
                  name="picked"
                  id="name"
                  value="organization"
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                  checked={purchaseedit.picked === "organization"}
                />
              </div>

              <div className="radio">
                <label
                  htmlFor="name"
                  className="input-label"
                >
                  Employee
                </label>
                <input
                  type="radio"
                  name="picked"
                  id="name"
                  value="Employee"
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                  checked={purchaseedit.picked === "Employee"}
                />
              </div>
            </div>
            {/* <div className="radiocontent">{purchaseedit.picked} </div> */}
          </div>
        </div>

        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="Reference"
              className="input-label "
            >
              Requisition No
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="reference"
                id="reference"
                placeholder="Reference"
                value={purchaseedit.reference}
                onChange={(e) => {
                  handleEdit(e);
                }}
                disabled
              />
            </div>
          </div>
          <div className="input-block">
            <label
              htmlFor="purchase order"
              className="input-label"
            >
              Purchase Order
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="purchaseorder"
                id="purchaseorder"
                placeholder="Purchase Order"
                value={"PO-" + purchaseedit.purchaseorderid}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="Reference"
              className="input-label "
            >
              Requested By
            </label>
            <div className="input-error">
              <select
                type="text"
                autoComplete="off"
                name="requestedby"
                id="requestedby"
                value={purchaseedit.requestedby}
                //onChange={(e) => { handleEdit(e) }}
              >
                <option>Select Employee</option>
                {employee.map((emp) => (
                  <option
                    value={emp.empId}
                    key={emp.id}
                  >
                    {emp.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="input-block">
            <label
              htmlFor="purchase order"
              className="input-label"
            >
              Requisition Date
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="requisitiondate"
                id="requisitiondate"
                value={purchaseedit.requisitiondate}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="purchase date"
              className="input-label"
            >
              Purchase Date
            </label>
            <div className="input-error">
              <input
                type="date"
                autoComplete="off"
                name="purchasedate"
                id="purchasedate"
                placeholder="Purchase Date"
                value={purchaseedit.purchasedate}
                onChange={(e) => {
                  handleEdit(e);
                }}
              />
            </div>
          </div>

          <div className="input-block">
            <label
              htmlFor="receivedate"
              className="input-label leftone"
            >
              Receive Date
            </label>
            <div className="input-error">
              <input
                type="date"
                autoComplete="off"
                name="receivedate"
                id="receivedate"
                onChange={(e) => {
                  handleEdit(e);
                }}
                value={purchaseedit.receivedate}
              />
              {receiveDateError && (
                <p className="form-error">{receiveDateError}</p>
              )}
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input-block">
            <label
              htmlFor="insertedby"
              className="input-label"
            >
              Inserted By
            </label>
            <div className="input-error">
              <input
                type="text"
                autoComplete="off"
                name="insertedby"
                id="insertedby"
                placeholder="Address"
                value={purchaseedit.insertedby}
              />
            </div>
          </div>
          <div className="input-block">
            <label
              htmlFor="receivedby"
              className="input-label leftone"
            >
              Received By
            </label>
            <div className="input-error">
              <select
                className="list"
                type="text"
                autoComplete="off"
                name="receivedby"
                id="receivedby"
                placeholder="Items Received By"
                value={purchaseedit.receivedby}
                onChange={(e) => {
                  handleEdit(e);
                }}
              >
                {" "}
                <option>Select Employee</option>
                {/* <option selected>{purchaseedit.insertedby}</option> */}
                {employee.map((value) => (
                  <option
                    value={value.id}
                    key={value.id}
                  >
                    {value.name}
                  </option>
                ))}
              </select>
              {receivedByError && (
                <p className="form-error">{receivedByError}</p>
              )}
            </div>
          </div>
        </div>
        {/* <div className="input-block">
                  <label htmlFor="adress" className="input-label">
                    Supplier Address
                    </label>
                    <div className="input-error">
                    <textarea
                    rows="3" cols="42"
                      type="text"
                      autoComplete="off"
                      name="adress"
                      id="adress"
                      placeholder="Address"
                      value={purchaseedit.adress}
                      onChange={(e)=>{handleEdit(e)}}
                      
                    />
                    </div>
                  
                  </div>
                  </div>      */}

        <div className="input-container">
          {purchaseedit.status === "inactive" && (
            <div className="input-block">
              <label
                htmlFor="paymentterms"
                className="input-label"
              >
                Status
              </label>
              <div className="input-error">
                <select
                  className="list"
                  type="text"
                  autoComplete="off"
                  name="status"
                  id="status"
                  value={purchaseedit.status}
                  onChange={(e) => {
                    handleEdit(e);
                  }}
                >
                  <option>active</option>
                  <option>inactive</option>
                </select>
              </div>
            </div>
          )}
        </div>

        <div>
          <div>Price List </div>
          <div>
            <table id="contacttable">
              <thead>
                <tr>
                  <th style={{ width: "60px" }}>Sl No</th>
                  <th style={{ width: "220px" }}>Item Name</th>
                  <th style={{ width: "200px" }}>Description</th>
                  <th>Quantity Hand</th>
                  <th>Quantity Ordered</th>
                  <th>Purchase Unit</th>
                  <th>Quanitity Received</th>
                  <th style={{ width: "180px" }}>Unit</th>
                  <th style={{ width: "100px" }}>Rate</th>
                  <th>Gst%</th>
                  <th style={{ width: "100px" }}>Price</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {addInventory.length == 0 ? (
                  <tr>
                    <th></th>
                    <th>
                      <select
                        className="inputtable"
                        name="itemDetails"
                        onChange={(e) => getPriceList(e)}
                        // value={user.itemDetails}
                      >
                        <option value="">Select Item</option>

                        {item.map((values, index) => (
                          <option
                            value={values.Id}
                            key={values.Id}
                          >
                            {values.Name}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                ) : (
                  addInventory.map((user, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>
                          <select
                            className="inputtable"
                            name="itemDetails"
                            onChange={(e) => replaceData(e, index)}
                            value={user.itemDetails}
                          >
                            <option value="">{user.Name}</option>

                            {item.map((values, index) => (
                              <option
                                value={values.Id}
                                key={values.Id}
                              >
                                {values.Name}
                              </option>
                            ))}
                          </select>
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="description"
                            onChange={(e) => onChange(e, index)}
                            value={user.description}
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="Quantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.Quantity}
                          />
                        </th>

                        <th>
                          <input
                            className="inputtable"
                            name="purchasequantity"
                            onChange={(e) => onChange(e, index)}
                            value={user.purchasequantity}
                          />
                        </th>
                        <th>
                            <input
                              className="inputtable"
                              name="unitforpurchase"
                              disabled
                              value={user.unitforpurchase}
                            />
                          </th>
                        <th>
                          <input
                            className="inputtable"
                            name="quantityreceive"
                            onChange={(e) => onChange(e, index)}
                            value={user.quantityreceive}
                          />
                        </th>
                        <th>
                            <select
                              className="inputtable"
                              name="unit"
                              onChange={(e) => onChange(e, index)}
                              value={user.unit}
                              ><option>Select the unit</option>
                               <option>Pieces</option>
                               <option>Box</option> 
                               <option>Dozen</option>
                               <option>Meter</option>
                               <option>Kg</option> 
                               <option>Liter</option>
                              <option>Gallon(5lt)</option>
                              <option>Gallon(10lt)</option>
                              <option>Gallon(20lt)</option>
                              <option>Gallon(26lt)</option>
                              <option>Gallon(30lt)</option>
                              
                            </select>
                          </th>
                        <th>
                          <input
                            className="inputtable"
                            name="rate"
                            onChange={(e) => onChange(e, index)}
                            value={user.rate}
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="gst"
                            onChange={(e) => onChange(e, index)}
                            value={user.gst}
                          />
                        </th>
                        <th>
                          <input
                            className="inputtable"
                            name="amount"
                            onChange={(e) => onChange(e, index)}
                            value={user.amount}
                          />
                        </th>
                        <th>
                          <IoTrashOutline onClick={() => deleteRow(index)} />
                        </th>
                        <input
                            type="hidden"
                            className="inputtable"
                            name="itemmasterid"
                            onChange={(e) => onChange(e, index)}
                            value={user.itemmasterid}
                          />
                        <input
                          type="hidden"
                          className="inputtable"
                          name="itemid"
                          value={user.Id}
                        />
                        <input
                          type="hidden"
                          className="inputtable"
                          name="addedValue"
                          value={user.addedValue}
                          onChange={(e) => onChange(e, index)}
                        />
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="link">
              {" "}
              <Link
                to="#"
                className="addlink"
                onClick={addNewRow}
              >
                Add New Line
              </Link>
            </div>
            <div className="link">
              {" "}
              <Link
                to="/inventory/additem"
                className="addlink"
              >
                Add New Item
              </Link>
            </div>
          </div>
          <div className="note">
            <div className="comment">
              <div className="input-block-text">
                <label
                  htmlFor="customernotes"
                  className="input-label"
                >
                  Customer Notes
                </label>
                <textarea
                  className="textareas"
                  rows="3"
                  cols="52"
                  type="text"
                  autoComplete="off"
                  name="customernotes"
                  id="customernotes"
                  placeholder="Customer notes"
                  value={purchaseedit.customernotes}
                />
              </div>
            </div>
            <div className="pricediscount">
              <div className="input-container">
                <div className="input-block">
                  <label className="input-label">Total Price</label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="totalAmount"
                      id="totalAmount"
                      value={totalAmount}
                      //value={purchaseedit.totalAmount}
                      readOnly
                    />
                    {/* {totalAmountError && <p style={{ color: "red" }}>{totalAmountError}</p>} */}
                  </div>
                </div>
              </div>
              {/* <div className="input-container">
                <div className="input-block">
                  <label className="input-label">IGST @ %</label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gst"
                      id="gst"
                      //value={purchaseedit.gst}
                      value={gst}
                      onChange={(e) => {
                        const newGst = parseFloat(e.target.value) || 0;
                        setGst(newGst);
                        const subTotal = calculateSubTotal(
                          totalAmount,
                          newGst
                        );
                        setSubTotal(subTotal);
                        const gstAmount = subTotal - totalAmount;
                        setTotalGst(gstAmount);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-block">
                  <label className="input-label">GST Amount</label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="gstamount"
                      id="gstamount"
                      value={totalGst}
                      // value={purchaseedit.totalGst}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-block">
                  <label className="input-label">
                    Final Price(Including GST)
                  </label>
                  <div className="input-error">
                    <input
                      type="text"
                      autoComplete="off"
                      name="subTotal"
                      id="subTotal"
                      value={subTotal}
                      // value={purchaseedit.subTotal}
                      readOnly
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <br />
          <div className="top-div2">
            <div className="note">
              <div>
                <div className="input-block-text">
                  <label
                    htmlFor="termsconditions"
                    className="input-label"
                  >
                    Terms & Conditions
                  </label>
                  <textarea
                    className="textareas"
                    rows="3"
                    cols="95"
                    type="text"
                    autoComplete="off"
                    name="termsconditions"
                    id="termsconditions"
                    placeholder="Terms and conditions"
                    value={purchaseedit.termsconditions}
                  />
                </div>
              </div>
              <div className="purchaseorderfile">
                <label htmlFor="Image">Attach File(s) to Purchase Order</label>

                <input
                  type="file"
                  name="avatar"
                  onChange={setimgfile}
                />
                {purchaseedit.avatar}
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="adjustedby"
            id="adjustedby"
            value={gettoken}
          />
          <input
            type="hidden"
            name="orderstage"
            value={purchaseedit.orderstage}
            onChange={(e) => {
              handleEdit(e);
            }}
          />
        </div>

        <div className="modal-buttons">
          <div className="save-button">
            <button
              className="input-button"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              className="input-button"
              type="submit"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReceivedItem;

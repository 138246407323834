import React, { useEffect,useState } from 'react';
import {ImCalendar} from 'react-icons/im';
import  { Redirect, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';
import axios from '../../../helper/axios';
import Select from 'react-select';
import { Helmet } from "react-helmet";
const options = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'thisweek', label: 'This Week' },
  { value: 'thismonth', label: 'This Month' },
  { value: 'thisyear', label: 'This Year' },
];


const Dashboarditems = () => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState({ value: 'thismonth', label: 'This Month' });
  const [totalSaleQuantity, setTotalSaleQuantity] = useState(0);
  const [totalPurchaseQuantity, setTotalPurchaseQuantity] = useState(0);
  const [totalpurchasecost, setTotalpurchasecost] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [sendemail, setSendEmail] = useState(0);
  const [ItemOrdered, setItemOrdered] = useState(0);
  const [ItemReceived, setItemReceived] = useState(0);
  const [invoicedCount, setInvoicedCount] = useState(0);
  const [readyinvoicedCount, setReadyInvoicedCount] = useState(0);
  const [dispatchedCount, setDispatchCount] = useState(0);
useEffect(() => {
  if (!sessionStorage.getItem('jwtToken')) {
    return history.push('/');
  }
  // if (!Cookies.get('jwtToken')) {
  //   return history.push('/');
  // }
  const token = sessionStorage.getItem('jwtToken')
  axios.get('/itemsendemail', {
    headers: { Authorization: `Bearer ${token}` },
   
  })
  .then((response) => {
    console.log(response.data);
    setSendEmail(response.data); // Update the count state with the API response
  })
  .catch((error) => {
    console.log(error);
  });
  axios.get('/itemordered', {
    headers: { Authorization: `Bearer ${token}` },
   
  })
  .then((response) => {
    console.log(response.data);
    setItemOrdered(response.data); // Update the count state with the API response
  })
  .catch((error) => {
    console.log(error);
  });
  axios.get('/itemreceived', {
    headers: { Authorization: `Bearer ${token}` },
   
  })
  .then((response) => {
    console.log(response.data);
    setItemReceived(response.data); // Update the count state with the API response
  })
  .catch((error) => {
    console.log(error);
  });

  // Make API call to get the invoiced count
  axios.get('/getiteminvoiced', {
    headers: { Authorization: `Bearer ${token}` },
   
  })
    .then((response) => {
      console.log(response.data);
      setInvoicedCount(response.data); // Update the count state with the API response
    })
    .catch((error) => {
      console.log(error);
    });
    axios.get('/getitemreadyinvoiced', {
      headers: { Authorization: `Bearer ${token}` },
     
    })
    .then((response) => {
      console.log(response.data);
      setReadyInvoicedCount(response.data); // Update the count state with the API response
    })
    .catch((error) => {
      console.log(error);
    });
    
    axios.get('/getitemdispatched', {
      headers: { 
        Authorization: `Bearer ${token}`,
        Role : "Admin"
      },
    })
    .then((response) => {
      console.log(response.data);
      setDispatchCount(response.data); // Update the count state with the API response
    })
    .catch((error) => {
      console.log(error);
    });
    axios.get('/allitemquentypresent',{
      headers: { Authorization: `Bearer ${token}` },
     
    })
    .then((response) => {
      console.log(response.data);
      setTotalQuantity(response.data); // Update the count state with the API response
    })
    .catch((error) => {
      console.log(error);
    });
}, []);
useEffect(() => {
  let timeRange;

    switch (selectedOption?.value) {
      case 'today':
        timeRange = 'today';
        break;
      case 'yesterday':
        timeRange = 'yesterday';
        break;
      case 'thisweek':
        timeRange = 'thisweek';
        break;
      case 'thismonth':
        timeRange = 'thismonth';
        break;
      case 'thisyear':
        timeRange = 'thisyear';
        break;
      default:
        break;
    }

    if (timeRange) {
      axios.get(`/purchaseorderperday?timerange=${timeRange}`)
        .then((response) => {
          console.log(response.data);
          setTotalpurchasecost(response.data); // Update the count state with the API response
        })
        .catch((error) => {
          console.log(error);
        });
        axios.get(`/saleorderperday?timerange=${timeRange}`)
        .then((response) => {
          console.log(response.data);
          setTotalSale(response.data); // Update the count state with the API response
        })
        .catch((error) => {
          console.log(error);
        });
        axios.get(`/saleorderquantity?timerange=${timeRange}`)
        .then((response) => {
          console.log(response.data);
          setTotalSaleQuantity(response.data); // Update the count state with the API response
        })
        .catch((error) => {
          console.log(error);
        });
        axios.get(`/purchaseorderquantity?timerange=${timeRange}`)
        .then((response) => {
          console.log(response.data);
          setTotalPurchaseQuantity(response.data); // Update the count state with the API response
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedOption, history]);


  return (
    <>
   <Helmet> <title>Dashboard|BBS Inventory Management</title>
  
  </Helmet>
   
      <div className="dashboard">
      
     <div className='dashboardone'>
    
   
      <div className='dashboardbutton'>
      

      <p style={{textAlign:"center",fontSize:"26px",marginTop:"7px",color:"#268ddd"}}>{sendemail}</p>
     
      
      <h5 className='qty'>Pckgs</h5>
      <br></br>

      <p style={{textAlign:"center",fontSize:"12px",color:"#495569"}}>SEND EMAIL</p>
      </div>
      <div className='dashboardbutton'>
      <p style={{textAlign:"center",fontSize:"26px",marginTop:"7px",color:"#db584b"}}>{ItemOrdered}</p>
      <h5 className='qty'>Pckgs</h5><br/>
       <p style={{textAlign:"center",fontSize:"12px",color:"#495569"}}>ORDERED</p></div>
      <div className='dashboardbutton'>
      <p style={{textAlign:"center",fontSize:"26px",marginTop:"7px",color:"#28a745"}}>{ItemReceived}</p>
      
      
      <h5 className='qty'>Pckgs</h5><br/>
       <p style={{textAlign:"center",fontSize:"12px",color:"#495569"}}>RECEIVED</p></div>
      {/* <div className='dashboardbutton'>
      <p style={{textAlign:"center",fontSize:"26px",marginTop:"7px",color:"#ddb630"}}>67</p>
     
      <h5 className='qty'>Pckgs</h5><br/>
        <p style={{textAlign:"center",fontSize:"12px",color:"#495569"}}>RECEIVED</p></div> */}
        <div className='dashboardbutton'>
      

      <p style={{textAlign:"center",fontSize:"26px",marginTop:"7px",color:"#268ddd"}}>{readyinvoicedCount}</p>
     
      
      <h5 className='qty'>Pckgs</h5>
      <br></br>

      <p style={{textAlign:"center",fontSize:"12px",color:"#495569"}}>READY FOR INVOICE</p>
      </div>
      <div className='dashboardbutton'>
      <p style={{textAlign:"center",fontSize:"26px",marginTop:"7px",color:"#db584b"}}>{invoicedCount}</p>
      <h5 className='qty'>Pckgs</h5><br/>
       <p style={{textAlign:"center",fontSize:"12px",color:"#495569"}}>INVOICED</p></div>
      <div className='dashboardbutton'>
      <p style={{textAlign:"center",fontSize:"26px",marginTop:"7px",color:"#28a745"}}>{dispatchedCount}</p>
      
      
      <h5 className='qty'>Pckgs</h5><br/>
       <p style={{textAlign:"center",fontSize:"12px",color:"#495569"}}>DISPATCHED</p></div>
      </div>
      
      <div className='dashboardtwo'>
      <h1 className='summary'>Inventory Summary</h1>
      <div className='inputinventorymain'>
      <div className='inputinventorymain1'>
        <div>Quantity in Hand</div>
        <div style={{fontSize:"20px",color:"#28a745"}}>{totalQuantity}</div>

      </div>
        </div>
      </div>
      </div>
      
      <div className='tableon'>
      <div className='tableone'>
        <table id="dashboardtable">
          <thead>
            <tr>
              <th colSpan="2">Product Details</th>
            </tr>
          </thead>
          <tbody>
            <tr   >
              <td >Low Stock Items<br></br>
              <br></br>
              All Items

              </td>
              <td>Active  Items</td>
             
              
            </tr>
          </tbody>
        </table>
        </div>
        <div className='tabletwo'>
        <table id="dashboardtabletwo">
          <thead>
            <tr>
              <th >Top Purchasing Items</th>
            </tr>
          </thead>
          <tbody>
            <tr   >
              <td >
             

              </td>
              
             
              
            </tr>
          </tbody>
        </table>

        </div>
        </div>
      

        <div className='purchaseordertable'>
      <div className='purchaseordertableone'>
        <div className='calenderinput'>
          <p style={{ fontSize: "16px", fontWeight: "500", marginTop: "10px", marginLeft: "10px", marginBottom: "10px", marginRight: "32.5%" }}>Purchase Order</p>
          {/* <ImCalendar className='calender' /> */}
          <Select 
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            placeholder='Select Date Range'
          />
        </div>
       <hr className='horizontalone'></hr>
       <br></br>
       <p style={{fontSize:"12px",fontWeight:"200",marginTop:"0px",textAlign:"center",marginBottom:"3%",}}>Quantity Ordered</p>
       {selectedOption && (
  <p style={{fontSize:"18px",fontWeight:"200",marginTop:"0px",textAlign:"center",marginBottom:"3%",color:"rgb(38, 141, 221)"}}>
   
    {selectedOption.value === 'today' ? totalPurchaseQuantity :
    selectedOption.value === 'yesterday' ? totalPurchaseQuantity :
     selectedOption.value === 'thisweek' ? totalPurchaseQuantity :
     selectedOption.value === 'thismonth' ? totalPurchaseQuantity :
     selectedOption.value === 'thisyear' ? totalPurchaseQuantity : ''}
  </p>
)}
       <br></br>
       <hr className='horizontalone'></hr>
       <br></br>
       <p style={{fontSize:"12px",fontWeight:"200",marginTop:"0px",textAlign:"center",marginBottom:"3%",}}> Total Cost: </p>
        {selectedOption && (
  <p style={{fontSize:"18px",fontWeight:"200",marginTop:"-10px",textAlign:"center",marginBottom:"3%",color:"rgb(38, 141, 221)"}}>
RS.
    {selectedOption.value === 'today' ? totalpurchasecost :
    selectedOption.value === 'yesterday' ? totalpurchasecost :
     selectedOption.value === 'thisweek' ? totalpurchasecost :
     selectedOption.value === 'thismonth' ? totalpurchasecost :
     selectedOption.value === 'thisyear' ? totalpurchasecost : ''}
  </p>
)}
     
      <br></br>
      

        </div>
        <div className='purchaseordertabletwo'>
        <div className='calenderinput'>
          <p style={{ fontSize: "16px", fontWeight: "500", marginTop: "10px", marginLeft: "10px", marginBottom: "10px", marginRight: "32.5%" }}>Sales Order</p>
          {/* <ImCalendar className='calender' /> */}
          <Select 
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            placeholder='Select Date Range'
          />
        </div>
       <hr className='horizontalone'></hr>
       <br></br>
       <p style={{fontSize:"12px",fontWeight:"200",marginTop:"0px",textAlign:"center",marginBottom:"3%",}}>Quantity Sold</p>
       {selectedOption && (
  <p style={{fontSize:"18px",fontWeight:"200",marginTop:"0px",textAlign:"center",marginBottom:"3%",color:"rgb(38, 141, 221)"}}>
   
    {selectedOption.value === 'today' ? totalSaleQuantity :
    selectedOption.value === 'yesterday' ? totalSaleQuantity :
     selectedOption.value === 'thisweek' ? totalSaleQuantity :
     selectedOption.value === 'thismonth' ? totalSaleQuantity :
     selectedOption.value === 'thisyear' ? totalSaleQuantity : ''}
  </p>
)}
       <br></br>
       <hr className='horizontalone'></hr>
       <br></br>
       <p style={{fontSize:"12px",fontWeight:"200",marginTop:"0px",textAlign:"center",marginBottom:"3%",}}> Total Cost: </p>
        {selectedOption && (
 <p style={{fontSize:"18px",fontWeight:"200",marginTop:"-10px",textAlign:"center",marginBottom:"3%",color:"rgb(38, 141, 221)"}}>
   RS.
   {selectedOption.value === 'today' ? totalSale :
    selectedOption.value === 'yesterday' ? totalSale :
     selectedOption.value === 'thisweek' ? totalSale :
     selectedOption.value === 'thismonth' ? totalSale :
     selectedOption.value === 'thisyear' ? totalSale : ''}
  </p>
)}</div>
      </div>
      <div className='salestable'>

      </div>
      
   
    </>
  )
}

export default Dashboarditems

import React from 'react'
import Sidebar from '../../components/Sidebar';
import { NavLink } from 'react-router-dom';
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import NewPermitDetails from './Permit/NewPermitDetails';
import ListviewPermit from './Permit/ListviewPermit';
import EachPermit from './Permit/EachPermit';
import EditPermit from './Permit/EditPermit';
import PermitDropdown from '../../components/Dropdown/PermitDropdown';
import InactivePermit from './Permit/InactivePermit';
import AllPermit from './Permit/AllPermit';


//will diplay the new permit page where we can enter the new permit details
export const PermitDetails = () => {
    return (
      //implemeted side bar here
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3> New Permit Details</h3>
      </div>
          <div className='container' >
            {/* render new permit form */}
          <NewPermitDetails/>
          </div>
          </div>
      </div>
      </div>
    )
  };
   //will display the listview page for active Permit detail
  export const VehiclePermit = () => {
    return (
      //implemeted side bar here
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
     {/* Implemented the filter dropdown for permit  */}
        <PermitDropdown/>
      <div className='title1'>
        {/* when will click on this new button will navigate to the new permit page */}
         <NavLink to='/Vehicle/permit'><button className="allbutton" >New</button></NavLink> 
          {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
             {/* searchbar, dynamic table and pagination for active Pollution is inside  <ListviewPermit/> */}
 <ListviewPermit/>
          </div>
        </div>
      </div>
      </div>
    );
  };
//will display the listview page for inactive Permit detail
  export const VehicleInactivePermit = () => {
    return (
      //implemeted side bar here
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
     {/* Implemented the filter dropdown for permit  */}
        <PermitDropdown/>
      <div className='title1'>
         {/* when will click on this new button will navigate to the new permit page */}
         <NavLink to='/Vehicle/permit'><button className="allbutton" >New</button></NavLink> 
          {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
             {/* searchbar, dynamic table and pagination for active Pollution is inside   <InactivePermit/> */}
 <InactivePermit/>
          </div>
        </div>
      </div>
      </div>
    );
  };
//will display the listview page for all Permit detail
  export const AllVehiclePermit = () => {
    return (
      //implemeted side bar here
      <div> <Sidebar/>
      <div className='reports'>
        <div className='modal-container '>
        <div className='title'>
          {/* Implemented the filter dropdown for permit  */}
        <PermitDropdown/>
      <div className='title1'>
         {/* when will click on this new button will navigate to the new permit page */}
         <NavLink to='/Vehicle/permit'><button className="allbutton" >New</button></NavLink> 
          {/* to export the table in to excel file given the button */}
         <ReactHtmlTableToExcel
                     id="test-table-xls-button"
                     className="allbutton"
                     table="Pucc"
                     filename="Employeexls"
                     sheet="Employeexls"
                     buttonText="Export File"/> 
         </div>
      </div>
  
          <div className='container2'>
             {/* searchbar, dynamic table and pagination for active Pollution is inside  <AllPermit/> */}
      <AllPermit/>
          </div>
        </div>
      </div>
      </div>
    );
  };

//will diplay the indivisual permit details page
  export const EachPermitDetails = () => {
    return (
      //implemeted side bar here
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3> Permit Details</h3>
      </div>
          <div className='container' >
            {/* render indivisual permit details form */}
           <EachPermit/>
          </div>
          </div>
      </div>
      </div>
    )
  };
//will diplay the indivisual permit details edit page
  export const EditPermitDetails = () => {
    return (
      //implemeted side bar here
      <div> <Sidebar/>
      <div className='home'>
  <div className='modal-container '>
    <div className='title'>
      <h3>Edit Permit Details</h3>
      </div>
          <div className='container' >
             {/* render indivisual permit edit details form */}
           <EditPermit/>
          </div>
          </div>
      </div>
      </div>
    )
  };
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { vehicleservice } from "../../../helper/schemas";
import { Helmet } from "react-helmet";
import axios from "../../../helper/axios";
import {useHistory} from 'react-router-dom';
// here I have initialised all the input values
const initialValues = {
   vehicleno: "",
   lastservicedata:"",
    ownername:"",
    nextservicedate:"",
    servicecost: "",
    };
    // this function will handel the form inputs,submitting form and all the operations.
    const NewServiceEntry = () => {
      //     The useHistory hook is typically used in a functional component within
  //      a React application to access the browser's history object and manipulate the browser's URL programmatically.  
  // //**the history object to navigate the user to a different route by calling  return history.push('/Service'); */
      const history = useHistory();
      //all bydefault function for useFormik declared here
      const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit, } =
      useFormik({
        initialValues,
        //validation
        validationSchema: vehicleservice,
       // i/p- we are sending the values to backend
        onSubmit: async(values, action,) => {
        try {
            const response = await axios.post("/servicePost",values,{
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              }
            })
          if (response.status === 200) {
            setSuccess(true);
            setError(false);
            return history.push('/Service');
          }
        } catch (err) {
          setSuccess(false);
          setError(true);
        }
        action.resetForm();
        },
        
      });
      const token = sessionStorage.getItem('jwtToken');
      const [success, setSuccess] = useState(false);
      const [error, setError] = useState(false);
      
    // const[file,setFile]=useState("");

    //    const setimgfile=(e)=>{
    //     setFile(e.target.files[0])
    //     console.log(e.target.files[0])
    //    }
    useEffect(()=>{
      if (!sessionStorage.getItem('jwtToken')) {
        return history.push('/');
      }
     
    },[]);

      return (
        <>
        <Helmet> 
          <title></title>
        </Helmet>
        
  
        <div className="">
          
         <form onSubmit={handleSubmit}>
          
         {success && <div className="form-erroremployees">Form submitted successfully!</div>}
        {error && <div className="form-error">Error submitting form. Please try again later.</div>}
        
         <div className="input-employeecontainer"> 
         
                    <div className="input-blockemployee">
                      <label htmlFor="vehicleno" className="input-labelemployee leftone">
                      Vehicle Number
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="text"
                        autoComplete="off"
                        name="vehicleno"
                        id="vehicleno"
                       
                        placeholder="Registration Number"
                        value={values.vehicleno}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.vehicleno && touched.vehicleno ? (
                        <p className="form-erroremployee">{errors.vehicleno}</p>
                      ) : null}
                      </div>
                    </div>
                    <div className="input-blockemployee">
                    <label htmlFor="lastservicedata" className="input-labelemployee leftone">
                   Last Service Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="lastservicedata"
                        id="lastservicedata"
                       
                        placeholder="Registration Date"
                        value={values.lastservicedata}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.lastservicedata && touched.lastservicedata ? (
                        <p className="form-erroremployee">{errors.lastservicedata}</p>
                      ) : null}
                    </div>
                    </div></div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                      <label htmlFor="ownername" className="input-labelemployee">
                      Owner Name
                    </label>
                    <div className="inputerroremployee">
                    <input
                      type="text"
                      autoComplete="off"
                      name="ownername"
                      id="ownername"
                      placeholder="Owner Name"
                      value={values.ownername}
                      onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.ownername && touched.ownername ? (
                        <p className="form-erroremployee">{errors.ownername}</p>
                      ) : null}  
                    </div>
  
                    </div>
                    <div className="input-blockemployee">
                      <label htmlFor="nextservicedate" className="input-labelemployee leftone">
                      Next Service Date
                      </label>
                      <div className="inputerroremployee">
                      <input
                        type="date"
                        autoComplete="off"
                        name="nextservicedate"
                        id="nextservicedate"
                        placeholder="REG/FC Upto"
                        value={values.nextservicedate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.nextservicedate && touched.nextservicedate ? (
                        <p className="form-erroremployee">{errors.nextservicedate}</p>
                      ) : null}
                      </div>
                    </div>
                    </div>
                    <div className="input-employeecontainer"> 
                    <div className="input-blockemployee">
                    <label htmlFor="servicecost" className="input-labelemployee">
                   Last Service Cost
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="servicecost"
                        id="servicecost"
                        placeholder="Service Cost"
                        value={values.servicecost}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                       {errors.servicecost && touched.servicecost ? (
                        <p className="form-erroremployee">{errors.servicecost}</p>
                      ) : null}
                      </div>
                    </div>
                    {/* <div className="input-blockemployee">
                    <label htmlFor="taxupto" className="input-labelemployee">
                    Tax Upto
                      </label>
                      <div className="inputerroremployee">
                      <input
                      
                      type="text"
                        autoComplete="off"
                        name="taxupto"
                        id="taxupto"
                        placeholder="Tax Upto"
                        value={values.taxupto}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                     
                     {errors.taxupto && touched.taxupto ? (
                        <p className="form-erroremployee">{errors.taxupto}</p>
                      ) : null}
                    </div>
                    </div> */}
                    </div>
                             
                    <div className="modal-buttons">
                  
                  <div className="save-button">
                    <button className="input-button" type="submit">
                   Save
                  </button>
                  <button className="input-button" type="button">
                   Cancel
                  </button></div>
                  </div>
                   
                  </form>
                  </div>     
        </>
      )
    };
    
    export default NewServiceEntry ;